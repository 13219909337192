import React, { Component } from 'react';

import Toggle from './Toggle';
import Tooltip from './Tooltip';
import { NameInput } from './SimpleInputs';
import { AuthSubmitButton } from './Buttons';
import Switch from './Switch';
import HeaderButtons from './HeaderButtons';

export default class CharacterLimitInput extends Component {
  onChange(e) {
    this.props.onChange(e.target.value);
  }

  validate() {
    return;
  }

  render() {
    let inputs = null;
    let advancedInputs = null;

    if (this.props.active) {
      inputs = (
      <div className="metafield-inputs">
        <div style={{ width: '100%', display: 'inline-block', paddingRight: 3 }}>
          <NameInput
            name="value"
            label="value"
            value={ this.props.value }
            onChange={this.onChange.bind(this)}
            noValidate={true}
            type="text"
            placeholder="Get $2 of store credit for answering this question!"
          />
        </div>
      </div>
      );
    }

    return (<div
      className={`dynamic-render-input ${this.props.disabled ? 'disabled' : ''}`} style={{ position: 'relative', zIndex: 1 }}
      active={this.props.active}
    >
      <div style={{ position: 'relative', marginBottom: this.props.active ? 20 : 0 }}>
        <label>Custom Reward Banner<Tooltip>If you want to have a "Reward Banner" for this particular slide only, use this option. If you want to change the Reward Banner for all slides, create a "Reward" type slide at the end of this survey.</Tooltip></label>
        <div className="inline-toggle">
          <Toggle
            active={this.props.active}
            onChange={(value) => {
              if (value === false) {
                this.props.onChange(false);
              } else {
                this.props.onChange('');
              }
            }}
          />
          <div className="subtle">Create a "Reward Banner" for this slide only.</div>
        </div>
      </div>

      { inputs }
    </div>);
  }
}