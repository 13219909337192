import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useragent from 'express-useragent';

import _ from 'lodash';
import moment from 'moment';
import qs from 'query-string';

import CreatableSelect from 'react-select/creatable';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import Breadcrumbs from '../components/Breadcrumbs';
import SectionHeader from '../components/SectionHeader';
import Pagination from '../components/Pagination';
import ConnectedMap from '../components/ConnectedMap'
import SentimentCard from '../components/SentimentCard';
import ShopifyCustomerDataCard from '../components/ShopifyCustomerDataCard';
import ShopifyOrderDataCard from '../components/ShopifyOrderDataCard';
import DeleteConfirm from '../components/DeleteConfirm'
import Confirm from '../components/Confirm'
import * as AccountsActions from '../actions/Accounts';
import * as ParticipantsActions from '../actions/Participants';

import { applyTheme, decode, encode, getDate, getTimestamp, getSlideLabel, truncate, getSentimentTitle, getSlideIcon, getSentimentClass, isUrl, getDateAsTimestamp, getDateFromUpdatedAt, getVisibilitySettings, getDisplaySettings } from '../utils';

function generateParticipantsUrl ({ accountId, pollId, slideId }) {
  let url = `/participants`;

  if (accountId) {
    url = url + `/a/${encode(accountId)}`;
  }
  if (pollId) {
    url = url + `/p/${encode(pollId)}`;
  }
  if (slideId) {
    url = url + `/s/${encode(slideId)}`;
  }

  return url;
}

const Row = (props) => {
  if (props.label === undefined || props.value === undefined) { return null; }
  return (<div className="row">
    <label className="inline">{props.label}:</label><div>{ props.value }</div>
  </div>)
}

let shop = '';
function printOrderId(orderId, confirmationNumber, checkoutToken) {
  if (!shop) {
    if (orderId) {
      return <span>id {orderId}</span>
    } else if (confirmationNumber) {
      return <span>confirmation number {confirmationNumber}</span>;  
    } else {
      return <span>token {checkoutToken}</span>;
    }
  }

  if (orderId) {
    return <span>id <a target="_blank" rel="noopener noreferrer" href={`https://${shop}/admin/orders/${orderId}`}>{orderId}</a></span>;
  } else if (confirmationNumber) {
    return <span>confirmation #<a target="_blank" rel="noopener noreferrer" href={`https://${shop}/admin/orders/${confirmationNumber}`}>{confirmationNumber}</a></span>;    
  } else {
    return <span>token {checkoutToken}</span>;
  }
}

function printCheckoutId(checkoutId) {
  if (!shop) {
    return checkoutId;
  }
  return <a target="_blank" rel="noopener noreferrer" href={`https://${shop}/admin/checkouts/${checkoutId}`}>{checkoutId}</a>;
}

function getCopy(event, metadata) {
  const type = event.type;
  if (!metadata) {
    metadata = event.metadata;
  }

  console.log('hello');
  console.log(metadata);

  if (type === 'render') {
    return 'Viewed survey';
  }
  if (type === 'open') {
    return 'Opened survey';    
  }
  if (type === 'close') {
    return 'Closed survey';    
  }
  if (type === 'hide') {
    return 'Hide survey';    
  }
  if (type === 'complete') {
    return 'Completed survey';    
  }
  if (type === 'shopify-checkout-created') {
    console.log(event);
    return <span>Checkout created with {printOrderId(metadata.shopify_order_id, undefined,metadata.shopify_checkout_token)}</span>;
  }

  if (type === 'shopify-order-placed') {
    return <span>Order placed with {printOrderId(metadata.shopify_order_id, metadata.shopify_confirmation_number)}</span>;
  }
  if (type === 'shopify-order-paid-email' || type === 'shopify-order-paid-sms') {
    return <span>Order paid with {printOrderId(metadata.shopify_order_id, metadata.shopify_confirmation_number)}</span>
  }
  if (type === 'shopify-order-fulfilled-email' || type === 'shopify-order-fulfilled-sms') {
    return <span>Order fulfilled with {printOrderId(metadata.shopify_order_id, metadata.shopify_confirmation_number)}</span>
  }
  if (type === 'shopify-abandoned-checkout-email') {
    return <span>Abandoned checkout created with id ${printCheckoutId(metadata.shopify_checkout_id)}</span>;
  }
  if (type === 'manual-email-campaign') {
    return 'Received Email';
  }
  if (type === 'manual-sms-campaign') {
    return 'Received SMS';
  }

  return event.label || `Event occured with id ${event.uniqueId}`;
}

function getIcon(type) {
  if (type === 'render') {
    return 'fa-eye';
  }
  if (type === 'open') {
    return 'fa-door-open';
  }
  if (type === 'close') {
    return 'fa-door-closed';    
  }
  if (type === 'hide') {
    return 'fa-close';    
  }
  if (type === 'complete') {
    return 'fa-check';
  }
  if (type === 'shopify-checkout-created') {
    return 'fa-sack-dollar';
  }
  if (type === 'shopify-order-placed') {
    return 'fa-sack-dollar';
  }
  if (type === 'shopify-order-paid-email' || type === 'shopify-order-paid-sms') {
    return 'fa-dollar-sign';    
  }
  if (type === 'shopify-order-fulfilled-email' || type === 'shopify-order-fulfilled-sms') {
    return 'fa-truck';    
  }
  if (type === 'shopify-abandoned-checkout-email') {
    return 'fa-shopping-bag';    
  }
  if (type === 'manual-email-campaign') {
    return 'fa-envelope';
  }
  if (type === 'manual-sms-campaign') {
    return 'fa-sms';
  }


  return 'fa-bell';
}

function hideOpens(account, poll) {
  const displaySettings = getDisplaySettings(account, poll);
  const visibilitySettings = getVisibilitySettings(account, poll);

  if (displaySettings.hideLauncher) {
    return true;
  }
  if (visibilitySettings.autoOpenDuration !== false) {
    return true;
  }
  if (displaySettings.align === 'modal') {
    return true;
  }

  return false;
}

function printCount(item = {}) {
  if (item.type === 'complete' || item.type === 'hide') { return null }; 
  if (item.count === 1) { return null; }
  return (<span> {item.count} time{ item.count === 1 ? '' : 's' }</span>);
}

class EventBlock extends Component {
  constructor(props) {
    super(props);
    this.state = { showMore: false };
  }

  render() {
    const events = this.props.events;
    const key = this.props.key;
    const accountId = this.props.accountId;
    const poll = this.props.poll;

    /* Add link if account has shop attached to it (only new ones will have it, consider migrating). Also style this.  */
    const metadata = events[0].event.metadata;
    const userAgent = events[0].event.userAgent;

    let metadataOutput = null;
    let userAgentOutput = null;

    if (metadata && this.state.showMore) {
      let keys = Object.keys(metadata);
      keys = _.sortBy(keys, (key) => {
        if (key.indexOf('shopify') !== -1) { return 3 };
        if (key.indexOf('Timestamp') !== -1) { return -1 };
        if (key.indexOf('Participant') !== -1) { return -1 };
      });
      metadataOutput = (<div>
      <div className="heading-tag"><i className="fas fa-asterisk" />Metadata</div>
      { keys.map((key) => {
        let val = metadata[key];

        if (key === 'shopify_order_id' && this.props.account.shop) {
          val = <a target="_blank" rel="noopener noreferrer" href={`https://${this.props.account.shop}/admin/orders/${val}`}>{val}</a>
        } else if (key === 'shopify_customer_id' && this.props.account.shop) {
          val = <a target="_blank" rel="noopener noreferrer" href={`https://${this.props.account.shop}/admin/customers/${val}`}>{val}</a>
        } else if (key === 'shopify_checkout_id' && this.props.account.shop) {
          val = <a target="_blank" rel="noopener noreferrer" href={`https://${this.props.account.shop}/admin/checkouts/${val}`}>{val}</a>
        } else {
          val = isUrl(val) ? <a href={val} title={val} target="_blank">{truncate(val, 40)}</a> : val;
        }

        if (val === undefined) {
          return null;
        }

        let icon = null;
        if (key.indexOf('shopify') !== -1) {
          icon = <i className="fa-brands fa-shopify" />
        }
        return (<div className="row">
          <label>{ icon }{ key }</label>
          <div>{ val }</div>
        </div>);
      }) }
      <div className="row">
        <label>Timestamp</label>
        <div>{getDateAsTimestamp(events[0].event._id)}</div>
      </div>
      </div>);
    }

    if (userAgent && this.state.showMore) {
      userAgentOutput = (<div>
      <div className="heading-tag"><i className="fas fa-laptop" />Device</div>
      <UserAgent participant={events[0].event} />
      </div>);
    }

    let showDetails = <div className="show-more" onClick={() => this.setState({ showMore: true })}><div className="show-more-toggle"><i className="fas fa-plus" />More Details</div></div>;
    if (this.state.showMore) {
      showDetails = null;
    }

    return (<div key={key}>
      <div className={`object-list events ${this.props.loadingEvents ? 'loading' : ''}`}>
      <div className="events-meta"><span className="date">{ getDate(events[0].event._id) }</span><i className="fas fa-comment" />Responded to survey: <Link to={`/a/${encode(accountId)}/p/${encode(poll._id)}`} title={poll.title}>{ truncate(poll.title, 30) }</Link>.</div>
      <div>
        <div className="heading-tag"><i className="fas fa-comment-alt" />Responses</div>
        { events.map(({ event }) => <Event showDeleteResponses={this.props.showDeleteResponses} selectedResponses={this.props.selectedResponses} onSelect={this.props.onSelect} {...event} />)}
      </div>
      { showDetails }
      { metadataOutput }
      { userAgentOutput }
    </div>
  </div>);
  }
}

class Events extends Component {
  render() {
    if (!this.props.timeline || !this.props.timeline.length) {
      return (
        <div className="card empty-object-list emails" style={{marginTop: 0}}>
          <h3>No engagements found.</h3>
          <p>This user hasn't engaged with any of your surveys yet. When he or she does a list of his or interactions will show up here.</p>
        </div>
      );
    }

    const groups = {};
    const keys = [];
    const events = [...this.props.timeline];

    console.log(this.props.timeline);

    let rand;
    let prevType;

    const sortedEvents = [...events];

    for (let i = 0; i < events.length; ++i) {
      const item = events[i];

      const nextItem = events[i+1] || {};
      const prevItem = events[i-1] || {};

      const nextItemType = nextItem.type;
      const prevItemType = prevItem.type;

      if (item.type === 'complete' && (prevItemType === 'response' && nextItemType === 'response')) {
        sortedEvents[i] = nextItem;
        sortedEvents[i+1] = item;
      }
    }

    let lastPrecursorEvent = null;
    sortedEvents.forEach((item) => {
      const event = item.event || {};

      let hidden = false;
      if ((item.type === 'open' || item.type === 'hide') && hideOpens(this.props.account, item.poll)) {
        hidden = true;
      }
      if (item.type === 'render' && (item.count <= 1) && lastPrecursorEvent) {
        hidden = true;
      }
      if (item.type === 'close' && prevType === 'complete') {
        hidden = true;
      }
      if ((prevType !== item.type) && !hidden) {
        rand = Math.random();
      }
      let key = `${event.browserSessionId}-${item.pollId}-${item.type}-${rand}`;

      if (item.uniqueId) {
        lastPrecursorEvent = item;
      }

      if (item.type === 'response' && lastPrecursorEvent) {
        if (lastPrecursorEvent.metadata.shopify_confirmation_number) {
          lastPrecursorEvent.metadata.shopify_order_id = item.event.metadata.shopify_order_id;
        }
        if (lastPrecursorEvent.metadata.shopify_checkout_token) {
          lastPrecursorEvent.metadata.shopify_order_id = item.event.metadata.shopify_order_id;
        }
        lastPrecursorEvent = null;
      }

      if (item.type !== 'response') {
        key = `${item.type}-${rand}`;
      }
      // else {
      //   key = `${event.browserSessionId}-${item.type}-${rand}`;
      // }

      if (hidden) {
        key = `${item.type}-${rand}-hidden`;
      }

      _.pull(keys, key);
      keys.unshift(key);

      if (!groups[key]) {
        groups[key] = [];
      }

      groups[key].push(item);

      if (key.indexOf('hidden') === -1){
        prevType = item.type;
      }
    });

    return (
      <div className={keys.length > 1 ? 'has-chain' : ''}>
        { keys.map((key) => {
          const events = groups[key];
          const poll = events[0].poll;
          const accountId = this.props.account._id;

          if (!poll) { return null; }
          if (key.indexOf('hidden') !== -1) { return null; }

          if (events[0].uniqueId) {
            let metadata = events[0].metadata;
            if (events[1]) {
              /* Prefer to get an actual event instead of precursor */
              metadata = events[1].metadata;
            }
            console.log('here');
            console.log(events);

            return <div><div className="object-list events">
              <div className="events-meta"><i className={`fas ${getIcon(events[0].type)}`} />{getCopy(events[0], metadata)}.</div>
              </div>
              { metadata.shopify_order_id ? <ShopifyOrderDataCard key={metadata.shopify_order_id} orderId={metadata.shopify_order_id} /> : null }
            </div>
          }

          if (key.indexOf('response') === -1) {
            return <div className="object-list events">
              <div className="events-meta"> <span className="date">{ getDateFromUpdatedAt(events[0].lastTimestamp) }</span><i className={`fas ${getIcon(events[0].type)}`} />{getCopy(events[0])} <Link to={`/a/${encode(accountId)}/p/${encode(poll._id)}`}>{ poll.title }</Link>{ printCount(events[0]) }.</div>
            </div>
          }

          if (!events[0].event) { return null; }

          return <EventBlock {...this.props} key={key} events={events} poll={poll} account={this.props.account} accountId={accountId} />
        }) }
      </div>
    );
  }
}

class BelowEvent extends Component {
  render() {
    return (
      <div className="object-metadata">
        <div className="time" title="Time">{getDate(this.props._id)}</div>
        { this.props.href ? <div className="page" title="Page"><a target='_blank' href={this.props.href}>{truncate(this.props.href, 40)}</a></div> : null }
        { this.props.ipAddress ? <div className="ip-address" title="IP Address">{this.props.ipAddress}</div> : null }
      </div>
    )
  }
}

class Event extends Component {
  render() {
    if (!this.props.poll || !this.props.slide || !this.props.account) {
      return null;
    }

    let response = <div>{this.props.response.value || this.props.response.title}</div>;

    if (this.props.responses) {
      if (this.props.valueType === 'form-response') {
        response = (<div>
          { this.props.responses.map(({ label, value }) => {
            if (!value) { return null; }

            return (<div className="value" style={{ clear: 'both', paddingTop: 3 }}><strong style={{ fontWeight: 700 }}>{label}</strong>: {value}</div>);
          })}
        </div>)
      } else {
        response = <div>{ this.props.responses.map(({ title, value }) => value || title).join(', ') }</div>
      }
    } else if (this.props.response.type === 'date') {
      response = <div>{moment(this.props.response.value || this.props.response.title).format('MM/DD/YYYY')}</div>
    } else if (this.props.response.type === 'rank') {
      response = <div>{ _.sortBy(this.props.responses, 'rank').map(({ title, value, rank }) => {
        return <div>#{rank} — {value || title}</div>
      })}</div>
    } else if (this.props.response.type === 'file-upload') {
      response = <div>{ this.props.response.value.split(',').map((url) => {
        return <div><a href={url} target="_blank">{ url }</a></div>
      }) }</div>
    }

    let checkbox = null;
    if (this.props.showDeleteResponses) {
      checkbox = <div
        className="delete-checkbox"
        onClick={() => {
          this.props.onSelect(this.props._id)
        }}
      ><i className={`${this.props.selectedResponses.indexOf(this.props._id) === -1 ? 'far fa-square' : 'fas fa-square-check'}`} /></div>;
    }

    return <div className="row">
      { checkbox }
      <label><i className={`fas ${getSlideIcon(this.props.slide.type)}`} /><Link to={`/a/${encode(this.props.account._id)}/p/${encode(this.props.poll._id)}/s/${encode(this.props.slide._id)}`}>{ truncate(this.props.slide.handle || this.props.slide.title, 25).replace(/(\r\n|\n|\r)/gm, "") }</Link></label>
      <div style={{ wordBreak: 'break-word' }}>{ response }</div>
    </div>
  }
}

class DeviceData extends Component {
  render() {
    const { participant } = this.props;

    if (!participant || (!participant.ipAddress && !participant.userAgent)) {
      return null; 
    }

    return (
      <div>
        <div className="heading-tag"><i className="fas fa-laptop" />Last Used Device</div>
        <div className="row">
          <label className="inline">IP Address:</label> <div title={participant.ipAddress}>{ participant.ipAddress }</div>
        </div>
        <UserAgent participant={participant} />
      </div>
    );
  }
}

class IpDetails extends Component {
  render() {
    const { participant } = this.props;

    if (
      !participant || 
      (!participant.ipDetails) || 
      (!participant.ipDetails.latitude) || 
      (!participant.ipDetails.longitude)
    ) {
      return null; 
    }

    let ipAddress = null;
    if (participant.ipAddress) {
      ipAddress = (<div className="row"><label className="inline">IP Address:</label> {participant.ipAddress}</div>);
    }

    return (
      <div>
        <div className="heading-tag"><i class="fas fa-map-marker-alt" />Location</div>

        { participant.ipDetails.country_name && <Row label="Country" value={participant.ipDetails.country_name} /> }
        { participant.ipDetails.region_name && <Row label="Region" value={participant.ipDetails.region_name} /> }
        { participant.ipDetails.city && <Row label="City" value={participant.ipDetails.city} /> }
        { participant.ipDetails.zip && <Row label="Zip" value={participant.ipDetails.zip} /> }

        <div className="row" style={{
          marginBottom: -25,
          marginLeft: -25,
          width: `calc(100% + 50px)`,
          marginTop: 20,
          borderTop: `1px solid rgba(0, 0, 0, 0.05)`
        }}>
          <ConnectedMap
            {...participant.ipDetails }
          />
        </div>
      </div>
    );
  }
}

class UserAgent extends Component {
  render() {
    const { participant } = this.props;
    if (!participant || !participant.userAgent) { return null; }

    const agent = useragent.parse(participant.userAgent);

    let device = null;
    if (agent.isMobile) {
      device = 'Mobile';
    }
    if (agent.isTablet) {
      device = 'Tablet';
    }
    if (agent.isDesktop) {
      device = 'Desktop'
    }

    return (
      <div>
        <Row label="Browser" value={`${agent.browser} (${ agent.version })`} />
        <Row label="OS" value={agent.os} />
        <Row label="Platform" value={agent.platform} />
        <Row label="Device" value={device} />
      </div>
    );
  }
}

class Metadata extends Component {
  render() {
    const metadata = this.props.metadata || {};
    let keys = Object.keys(metadata);
    keys = _.sortBy(keys, (key) => {
      if (key.indexOf('shopify') !== -1) { return 3 };
      if (key.indexOf('Timestamp') !== -1) { return -1 };
      if (key.indexOf('Participant') !== -1) { return -1 };
    });

    if (!keys.length) { return null; }

    return (<div>
      <div className="heading-tag"><i className="fas fa-asterisk" />Metadata</div>
      { keys.map((key) => {
        let val = metadata[key];

        let icon = null;
        if (key.indexOf('shopify') !== -1) {
          icon = <i className="fa-brands fa-shopify" />
        }
        if (key === 'shopify_customer_id' && this.props.account.shop) {
          val = (<a target="_blank" rel="noopener noreferrer" href={`https://${this.props.account.shop}/admin/customers/${val}`}>{val}</a>)
        } else {
          val = isUrl(val) ? <a href={val} target="_blank">{truncate(val, 30)}</a> : val;
        }

        if (val === undefined) {
          return null;
        }

        return (<div className="row">
          <label>{ icon }{ key }</label>
          <div>{ val }</div>
        </div>)
      })}
    </div>);
  }
}

class TagInputSelect extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isMenuOpen: false,
      value: this.props.value || []
    };
  }

  onChange(selectedOptions) {
    let value;
    if (selectedOptions) {
      value = selectedOptions.map(({ value }) => value);
    }

    this.props.onChange(value);
  }

  render() {
    let list = this.props.value || [];

    if (list) {
      list = list.map((val) => ({ value: val, label: val }))
    }

    return (<span style={{ marginTop: 5, marginBottom: 5, display: 'block' }}>
      <CreatableSelect
        value={list}
        isMulti
        name="react-select"
        className={this.state.isMenuOpen ? 'react-select active' : 'react-select'}
        onMenuOpen={() => this.setState({ isMenuOpen: true })}
        onMenuClose={() => this.setState({ isMenuOpen: false })}
        theme={applyTheme}
        onChange={this.onChange.bind(this)}
      />
    </span>);
  }
}

class UserInputForm extends Component {
  constructor(props) {
    super(props);
    this.state = { tags: this.props.participant.tags }
  }

  render() {
    return (<div>
      <label>Tags</label>
      <TagInputSelect
        value={this.state.tags}
        onChange={(tags) => {
          this.setState({ tags });
          this.props.submitTags(tags, this.props.participant);
        }}
      />
    </div>);
  }
}

const swap = (array, index1, index2) => {
  // Checking parameters for errors
  if (
    index1 < 0 || index2 < 0 ||
    index1 >= array.length || index2 >= array.length
  ) {
    console.error('Invalid index passed to swapArrayElements()');

    return array;
  }

  // Swapping elements and returning the mutated array 
  [array[index1], array[index2]] = [array[index2], array[index1]];

  return array;
}


class Participant extends Component {
  constructor(props) {
    super(props);
    this.state = { showDeleteConfirm: false, selectedResponses: [] };

    props.fetchParticipant(this.props.participantId, this.props.useId);

    if (!this.props.account) {
      props.fetchAccount();
    }
  }

  render() {
    if (this.props.loading || !this.props.participant || !this.props.account) {
      return <ColumnLoading />
    }

    const { participant } = this.props;
    const { events } = participant;

    shop = this.props.account.shop;

    let id = (<div className="row"><label className="inline">ID:</label><div>{participant.id}</div></div>);

    let handle = null;
    if (participant.handle) {
      handle = <div className="row"><label className="inline">Handle:</label><div>{participant.handle || 'No handle provided'}</div></div>
      id = null;
    }

    let sentiment = null;
    if (participant.sentiment) {
      sentiment = <div className="row"><label className="inline">Sentiment:</label><div className={`sentiment ${getSentimentClass(participant.sentiment)}`}>{getSentimentTitle(participant.sentiment)}</div></div>
    }

    let timeline = [];
    const timelines = this.props.participant.timelines;
    if (timelines) {
      Object.keys(timelines).forEach((pollIdentifier) => {
        const flat = timelines[pollIdentifier].map((item) => {
          const pollId = pollIdentifier.split('-').shift();
          return { ...item, pollId }
        });
        timeline = [ ...flat, ...timeline ];
      })
    }

    const eventsStore = {};
    const engagedPolls = {};
    this.props.participant.engagedPolls.forEach((poll) => {
      engagedPolls[poll._id] = poll;
    });
    participant.events.forEach((event) => {
      eventsStore[event._id] = event;
    });
    timeline.forEach((item) => {
      if (item.eventId && engagedPolls[item.pollId]) {
        item.event = eventsStore[item.eventId];
        delete eventsStore[item.eventId];
      }
      if (engagedPolls[item.pollId]) {
        item.poll = engagedPolls[item.pollId];
      }
    });
    if (Object.keys(eventsStore).length) {
      Object.keys(eventsStore).forEach((key) => {
        const event = eventsStore[key];
        timeline.push({ type: 'response', lastTimestamp: getTimestamp(event._id), event: event, eventId: event._id, poll: engagedPolls[event.pollId] });
      });
    }
    timeline = _.sortBy(timeline, ({ lastTimestamp }, idx) => new Date(lastTimestamp).getTime());
    timeline.forEach(({ uniqueId }, idx) => {
      /* Swap precuror events so they go behind the meaningful event */
      if (uniqueId) {
        swap(timeline, idx, idx-1);
      }
    });

    return (
      <ColumnLayout
        title={`Participant Details`}
        className="participant"
        graphics={true}
        //breadcrumbs={<Breadcrumbs currentPageTitle={`${participant.handle || (participant.id.slice(0, 10) + '...') }`} includeDashboard={true} />}
      >

        <div className="top-nav" style={{ maxWidth: 1800, margin: '0px auto', marginBottom: '20px' }}>
          <div className="wrapper" style={{ padding: '0 10px' }}>
            <Link to={generateParticipantsUrl(this.props)} className="back-arrow" ><i className="fas fa-arrow-left-long" />Participants</Link>
          </div>
        </div>

        <div className="split">
          <div>
            <div className="section-header" style={{ marginTop: 0 }}>
              <div className="section-subtitle" style={{ marginTop: 10 }}>
                <span><i className="fas fa-id-badge" />Participant Information</span>
              </div>
            </div>

            <div className="object-list participant" style={{ overflow: 'hidden' }}>
              <div>
                { id }
                { handle }
                { sentiment }
              </div>

              <DeviceData participant={participant} />
              <IpDetails participant={participant} />
              <Metadata
                account={this.props.account}
                metadata={participant.metadata} />
              <UserInputForm
                { ...this.props }
              />

            </div>

            { this.props.account.shop ? <ShopifyCustomerDataCard key="shopifyData" /> : null }

            <div className="more-actions-divider">
              <div onClick={() => {
                this.setState({ moreActions: !this.state.moreActions });
              }}>{ this.state.moreActions ? <span><i className="fas fa-minus" />Hide Actions</span> : <span><i className="fas fa-plus" />More Actions</span> }</div>
            </div>

            { this.state.moreActions && <div className="participant-management card">
              <div>
                <label>Delete Participant</label>
                <div>
                  <div className="subtle">This will erase the participant, and their responses.</div>
                  <button
                    className="delete-participant"
                    onClick={(value) => {
                      this.setState({ showDeleteConfirm: true });
                    }}
                  >Delete</button>
                </div>
              </div>
              <div style={{ marginTop: 25 }}>
                <label>Delete Responses</label>
                <div>
                  <div className="subtle">{ !this.state.showDeleteResponses ? 'Click "enable" and you will be prompted to remove certain responses only.' : 'Select the responses you want to remove using the red checkboxes on the right and then click confirm.' }</div>
                  { this.state.showDeleteResponses && <button
                    className="delete-response-confirm"
                    onClick={(value) => {
                      this.setState({ showDeleteResponsesModal: true });
                    }}
                  >Delete Selection</button> }
                  <button
                    className="delete-response-toggle"
                    onClick={(value) => {
                      this.setState({ selectedResponses: [] });
                      this.setState({ showDeleteResponses: !this.state.showDeleteResponses });
                    }}
                  >{ this.state.showDeleteResponses ? 'Cancel' : 'Enable' }</button>
                </div>
              </div>
            </div> }

          </div>

          <div>
            <div className="section-header" style={{ marginTop: 0 }}>
              <div className="section-subtitle" style={{ marginTop: 10 }}>
                <span><i className="fas fa-bell" />Engagement History</span>
              </div>
              <Pagination
                showPagination={events.pages}
                showNext={events.page !== events.pages}
                showPrev={events.page !== 0}
                next={() => { this.props.fetchEvents(this.props.participantId, events.page + 1); }}
                prev={() => { this.props.fetchEvents(this.props.participantId, events.page - 1); }}
                curPage={events.page + 1 }
                pageLength={events.pages + 1}
              />
            </div>
          </div>

          <div className={`${events.pages ? 'events-header' : '' }`}>
            <Events
              onSelect={(eventId) => {
                let selectedResponses = [ ...this.state.selectedResponses ];
                const idx = selectedResponses.indexOf(eventId);
                if (idx === -1) {
                  selectedResponses.push(eventId);
                } else {
                  selectedResponses.splice(idx, 1);
                }
                this.setState({ selectedResponses });
              }}
              timeline={timeline}
              selectedResponses={this.state.selectedResponses}
              showDeleteResponses={this.state.showDeleteResponses}
              account={this.props.account}
              events={participant.events.data}
              loadingEvents={this.props.loadingEvents}
              ipDetails={participant.ipDetails}
            />
          </div>

          <DeleteConfirm 
            title="Are you sure?"
            subtitle="This will permanently erase the participant and their responses forever."
            show={this.state.showDeleteConfirm}
            onConfirm={() => this.props.deleteParticipant(this.props.participant.id )}
            onCancel={() => {
              this.setState({ showDeleteConfirm: false });
            }}
          />

          <Confirm 
            title="Are you sure?"
            subtitle="This will permanently erase the selected responses forever."
            show={this.state.showDeleteResponsesModal}
            onConfirm={() => {
              this.setState({ selectedResponses: [] });
              this.setState({ showDeleteResponses: false });

              this.props.deleteParticipantResponses(this.props.participant.id, this.state.selectedResponses)
            }}
            onCancel={() => {
              this.setState({ showDeleteResponsesModal: false });
            }}
          />
        </div>
      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { useId } = qs.parse(ownProps.location.search)

  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];

  const pollId = decode(ownProps.match.params.pollId);
  const slideId = decode(ownProps.match.params.slideId);

  let participantId = decode(ownProps.match.params.participantId);
  if (useId) {
    participantId = ownProps.match.params.participantId;
  }

  const allParticipants = state.participants || [];
  const participant = allParticipants[participantId];

  return {
    accountId,
    account,
    pollId,
    slideId,
    participant,
    participantId,
    loading: state.participants.loading,
    loadingEvents: state.participants.loadingEvents,
    useId
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountsActions, ...ParticipantsActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Participant));
