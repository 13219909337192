import React, { Component } from 'react';

import $ from 'jquery';
import _ from 'lodash';

import BrowserFrame from './BrowserFrame';
import Embed from './Embed';
import PagePreview from './PagePreview';

export default class VisualDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = { currentIdx: 0, active: true, prevIndexes: [] };
    this.resize = _.throttle(this.resize.bind(this), 100, { leading: true, trailing: true });
    this.el = React.createRef();
  }

  componentDidMount() {
    // $(window).on('resize.display', this.resize);
    // this.resize();
    window.pollState = 'before-submission';
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showLink !== this.props.showLink) {
      this.resize();
    }

    if ((prevProps.active !== this.props.active) && this.props.active) {
      this.resize();
    }

    return null;
  }

  componentWillUnmount() {
    // $(window).off('resize.display', this.resize)
  }

  resize() {
    // const $this = $(this.el.current);
    // $this.height($this.prev().height());
  }

  next() {
    this.state.prevIndexes.push(this.state.currentIdx);
    this.setState({ currentIdx: this.state.currentIdx + 1 });
  }

  prev() {
    const idx = this.state.prevIndexes.pop();
    if (idx !== undefined) {
      this.setState({ currentIdx: idx });
    }
  }

  goto(slideId) {
    let idx = undefined;
    this.props.slides.forEach(({ _id }, i) => {
      if (_id === slideId) {
        idx = i;
      }
    })
    if (idx === undefined) { return this.next(); }

    this.state.prevIndexes.push(this.state.currentIdx);
    this.setState({ currentIdx: idx });
  }

  reset() {
    this.setState({ currentIdx: 0, prevIndexes: [] });
  }

  render() {
    let slides = this.props.slides || [];
    let renderSlidesWarning = (slides.length === 0 && this.props.showSlideWarning);

    if (slides.length === 0) {
      slides = [{
        title: 'Example Slide',
        // subtitle: "You're doing great!",
        copy: `<p>This is an example slide. Your published zigpoll will look look something like this given your current settings.</p>`,
        settings: {
          showTitle: true
        }
      }]
    }

    let previewType = <Embed
      {...this.props}
      scalePoll={this.props.scalePoll === false ? false : true}
      slides={slides}
      next={this.next.bind(this)}
      prev={this.prev.bind(this)}
      goto={this.goto.bind(this)}
      currentIdx={this.state.currentIdx}
      pollState={window.pollState}
      isEmbed={this.props.pollType === 'embed'}
    />

    if (['widget', 'embed', 'api', 'exit-intent'].indexOf(this.props.pollType) === -1) {
      previewType = <PagePreview
        {...this.props}
        scalePoll={this.props.scalePoll === false ? false : true}
        slides={slides}
        next={this.next.bind(this)}
        prev={this.prev.bind(this)}
        goto={this.goto.bind(this)}
        currentIdx={this.state.currentIdx}
        pollState={window.pollState}
      />
    }

    let belowVisualDisplay = this.props.belowVisualDisplay || null;

    let title, subtitle, showTitle;
    if (renderSlidesWarning) {
      previewType = null;
      showTitle = true;
      subtitle = <div><i className="fas fa-info-circle warning"/>There are no slides for this survey.</div>
    }

    return (
    <div className="visual-display-wrapper">
      <div className="visual-display" ref={this.el}>
        <BrowserFrame 
          title={title}
          subtitle={subtitle}
          showTitle={showTitle}
        />

        { previewType }
      </div>
      { belowVisualDisplay }
    </div>
    );
  }
}