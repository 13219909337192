import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import _ from 'lodash';

import * as PollActions from '../actions/Polls';

import Toggle from './Toggle';
// import Datetime from 'react-datetime';
import AuthForm from './AuthForm';
import { NameInput, DisplayRulesInput, RecipientsInput } from './SimpleInputs';
import SingleImageUploader from './SingleImageUploader';

import DateInput from './DateInput';
import NumberInput from './NumberInput';
import CreatableSelect from 'react-select/creatable';

import Tooltip from './Tooltip';
import Switch from './Switch';

import DynamicRenderInput from './DynamicRenderInput';
import DynamicOpenInput from './DynamicOpenInput';
import Confirm from './Confirm';
import SectionHeader from './SectionHeader';

import HeaderButtons from './HeaderButtons';
import TopBarNotification from './TopBarNotification';
import CreateEmailTemplateModal from './CreateEmailTemplateModal';

import { AuthSubmitButton } from './Buttons';

import { shopifyExitIntentCartOptions, getPollDisplayType, generatePageDisplaySettings, getDisplaySettings, getRecipients } from '../utils';

import AccountSettingsVisualDisplay from './AccountSettingsVisualDisplay';
import SurveyPreviewHeader from '../components/SurveyPreviewHeader';

const applyTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#2167f5",
    danger: "#F26c57",
  }
});

class TargetingRow extends Component {
  render() {
    const [ value, operator, inputVal ] = this.props.row;
    const selectedOption = _.find(shopifyExitIntentCartOptions, (option) => option.value === value);

    let operators;
    let inputType = null;
    if (selectedOption) {
      inputType = selectedOption.inputType;
      operators = <div className="select-wrapper"><select value={operator} onChange={(e) => { this.props.onChange(e.target.value, 1, this.props.rowIdx, this.props.sectionIdx) }}>
      { selectedOption.operators.map((operator) => <option value={operator.value}>{operator.name}</option>) }
      </select></div>
    }

    let input = null;

    if (value) {
      input = <input type="text" value={inputVal} onChange={(e) => { this.props.onChange(e.target.value, 2, this.props.rowIdx, this.props.sectionIdx) }} />;

      if (inputType === 'number') {
        input = <NumberInput
          value={inputVal}
          onChange={(value) => { 
            this.props.onChange(value, 2, this.props.rowIdx, this.props.sectionIdx) }} />
      }
      if (inputType === 'date') {
        input = <DateInput
          type="date"
          value={inputVal}
          onChange={(value) => { console.log('on change'); console.log(value); this.props.onChange(value, 2, this.props.rowIdx, this.props.sectionIdx) }} />
      }
      if (inputType === 'tags') {
        let list = [];
        if (inputVal) {
          list = inputVal.split(',').map((val) => ({ label: val, value: val }));
        }

        input = <CreatableSelect
          value={list}
          isMulti
          name="react-select"
          placeholder={selectedOption.placeholder || 'Add tags...'}
          className={true ? 'react-select active' : 'react-select'}
          // onMenuOpen={() => this.setState({ isMenuOpen: true })}
          // onMenuClose={() => this.setState({ isMenuOpen: false })}
          theme={applyTheme}
          components={{ 
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null
           }}
          onChange={(value) => {
            let val = value;
            if (value) {
              val = value.map(({ value }) => value).join(',');
            }
            this.props.onChange(val, 2, this.props.rowIdx, this.props.sectionIdx);
          }}
        />
      }
    }

    return (
    <div className="targeting-row">
      {/*<div>{ value }{ operator }{ inputVal }</div>*/}

      <div className="select-wrapper"><select value={value} onChange={(e) => {
        const value = e.target.value;
        const selectedOption = _.find(shopifyExitIntentCartOptions, (option) => option.value === value);
        this.props.onChange(value, 0, this.props.rowIdx, this.props.sectionIdx, selectedOption.operators[0].value);
      }}>
        <option disabled="disabled" selected={true}>Select a condition...</option>
        { shopifyExitIntentCartOptions.map((option) =>
        <option value={option.value}>{ option.name }</option>
        )}
      </select></div>

      { operators }

      { input }

      { value ? <div className="remove" onClick={() => this.props.removeRow(this.props.rowIdx, this.props.sectionIdx)} ></div> : null }
    </div>);
  }
}

// class CustomExitIntentRulesForm extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       nextTopInput: false,
//       nextBottomInput: false
//     };
//   }

//   removeRow(rowIdx, sectionIdx) {
//     const updates = [ ...this.props.exitIntentCartRules ];
//     const section = [ ...updates[sectionIdx] ];
//     section.splice(rowIdx, 1);

//     if (section.length === 0) {
//       if (sectionIdx === 0) {
//         this.setState({ nextTopInput: true });
//       } else {
//         this.setState({ nextBottomInput: true });        
//       }
//     }

//     updates[sectionIdx] = section;
//     this.props.onChange(updates);
//   }

//   onChange(value, updateIdx, rowIdx, sectionIdx, operator) { 
//     const updates = [ ...this.props.exitIntentCartRules ];
//     const isFirst = rowIdx === undefined;

//     if (isFirst) {
//       if (sectionIdx === 0) {
//         this.setState({ nextTopInput: false });
//       } else {
//         this.setState({ nextBottomInput: false });        
//       }
//       updates[sectionIdx].push([]);
//       rowIdx = updates[sectionIdx].length - 1;
//     }

//     const val = updates[sectionIdx][rowIdx];
//     val[updateIdx] = value;

//     /* Operator, value, and input move in lock step */
//     if (operator) {
//       val[1] = operator;
//       val[2] = undefined;
//     }

//     updates[sectionIdx][rowIdx] = val;


//     this.props.onChange(updates);
//   }

//   render() {
//     let [ top, bottom ] = this.props.exitIntentCartRules;

//     let bottomSection = null;
//     if (bottom.length !== 0 || this.state.showAnd) {
//       bottomSection = <div className="bottom">{ bottom.map((row, idx) => <TargetingRow key={idx} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={row} sectionIdx={1} rowIdx={idx} /> )}
//         { this.state.nextBottomInput || bottom.length === 0 ? <TargetingRow key={-1} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={[]} sectionIdx={1} isLast={true}/> : null }
//         { this.state.nextBottomInput ? null : <div className="or-button" onClick={() => this.setState({ nextBottomInput: true })}>OR</div>}</div>
//     }

//     return (
//       <div className="targeting-form mini" style={{ marginBottom: 25 }}>
//         { top.map((row, idx) => <TargetingRow key={idx} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={row} sectionIdx={0} rowIdx={idx} /> )}
//         { this.state.nextTopInput || top.length === 0 ? <TargetingRow key={-1} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={[]} sectionIdx={0} isLast={true}/> : null }
//         { this.state.nextTopInput ? null : <div className="or-button" onClick={() => this.setState({ nextTopInput: true })}>OR</div> }

//         <div className="and-button-wrapper"><div className="optional">Optional</div><div className="and-button" onClick={() => this.setState({ showAnd: true, nextBottomInput: true })}>AND</div></div>

//         { bottomSection }
//       </div>
//     );
//   }
// }

// class InlineInput extends Component {
//   onChange(e) {
//     const value = e.target.value.replace(/[^\d.-]/g,'');
//     const name = e.target.name;

//     this.props.onChange(name, value);
//   }

//   render() {
//     return (
//       <div className="inline-input" style={{ marginTop: 15 }}>
//         <span className="prefix">{ this.props.prefix }</span>
//         <input
//           type="text"
//           name={ this.props.name }
//           value={ this.props.value }
//           onChange={ this.onChange.bind(this) }
//         />
//         <span className="suffix">{ this.props.suffix }</span>
//       </div> 
//     );
//   }
// }

// class ModalDynamicOpenInput extends DynamicOpenInput {
//   render() {
//     return (
//       <div
//         className={`dynamic-open-input ${this.props.disabled ? 'disabled' : ''}`}
//       >
//         <div style={{ marginBottom: 25, marginTop: -15, position: 'relative' }}>
//           { this.props.warning }
//           { this.renderInputs() }
//           <div className="inline-input" style={{ marginTop: 20, marginBottom: 0 }}>
//             <label>Exit Prompt<Tooltip>Allow users to exit by clicking outside the modal or require them to confirm in order to close.</Tooltip></label>

//             <Switch
//               options={[
//                 { label: 'Allow users to exit at any time.', value: false },
//                 { label: "Don't Allow users to exit until they complete the survey.", value: 'oncomplete' },
//                 { label: "Show a prompt before allowing users to exit.", value: 'prompt' },
//               ]}
//               vertical={true}
//               value={this.props.modalExitRules}
//               onChange={(value) => {
//                 this.props.onChange({ 'modalExitRules': value });
//               }}
//             />
//           </div>
//         </div>
//       </div>
//     )
//   }
// }

export default class VisibilitySettingsForm extends AuthForm {
  constructor(props) {
    super(props);

    const showDynamicRules = this.props.settings.autoOpenDuration !== false || this.props.settings.autoOpenPosition !== false || this.props.settings.autoOpenDevice !== false;

    const showDynamicRenderRules = this.props.settings.renderDuration !== false || this.props.settings.renderPosition !== false || this.props.settings.renderDevice !== false;

    const settings = { ...this.props.settings };
    settings.openAttempts = settings.openAttempts === undefined ? '10' : settings.openAttempts;

    this.state = {
      valid: undefined,
      inputs: {
        visibilitySettings: settings || {},
        recipients: this.props.poll.recipients,
        settings: this.props.settings
      },
      showDynamicRules: showDynamicRules,
      showDynamicRenderRules: showDynamicRenderRules,
      launcherActive: true
    };

    this.inputStates = {};
    this.setInitialState();
  }

  onSubmit(e) {
    e.stopPropagation();
    e.preventDefault();

    const poll = {};
    poll._id = this.props.poll._id;
    // poll.visibilitySettings = this.state.inputs.visibilitySettings;
    poll.settings = this.state.inputs.settings;
    poll.recipients = this.state.inputs.recipients;

    let formData;
    if (poll.settings.localLogoImage) {
      formData = new FormData();
      formData.append('logoImage', poll.settings.localLogoImage);
      delete poll.settings.localLogoImage;
      // delete poll.settings.logoImage;
    }

    this.props.updatePoll(poll, 'The behavior settings have been updated.', formData);
  }

  showConfirmModal() {
    this.setState({ showConfirmModal: true });
  }

  onChange(name, value) {
    if (value === 0) {
      value = 1;
    }
    this.setState({ openAttempts: value });
    this.queueUpdate();
  }

  updateAttempts() {
    const poll = {};
    poll._id = this.props.poll._id;
    poll.visibilitySettings = {
      openAttempts: parseInt(this.state.openAttempts)
    };
    this.props.updatePoll(poll, "The behavior settings have been updated.");
  }

  queueUpdate() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }

    this.timeout = setTimeout(() => {
      if (_.isNaN(parseInt(this.state.openAttempts))) {
        return;
      }

      this.updateAttempts();
      this.timeout = undefined;
    }, 1500);
  }

  updateSettings(settings) {
    const inputs = this.state.inputs;
    inputs.settings = settings;
    this.setState({ inputs });
  }

  onLogoImageUpload(image) {
    const inputs = this.state.inputs;
    const settings = inputs.settings;

    if (!image) {
      settings.logoImage = undefined;
      settings.localLogoImage = undefined;
    } else {
      settings.localLogoImage = image;
      settings.logoImage = image.url;
    }

    this.setState(inputs);
  }

  showCreateEmailTemplateModal(onClose, onSave) {
    this.setState({ 
      showCreateEmailTemplateModal: true,
      onCreateModalClose: () => {
        if (onClose) {
          onClose();
        }
        this.setState({ showCreateEmailTemplateModal: false });
      },
      onCreateModalSave: (emailTemplateId) => {
        if (onSave) {
          onSave(emailTemplateId);
        }        
        this.setState({ showCreateEmailTemplateModal: false });
      }
    });
  }

  showEditEmailTemplateModal(emailTemplate) {
    this.setState({ showEditEmailTemplateModal: emailTemplate });
  }

  // clearStrategySettings() {
  //   const strats = [
  //     'showAfterSubmission',
  //     'hideAfterSubmission',
  //     'hideAfterClose'
  //   ]
  //   strats.forEach((value) => {
  //     this.updateSetting(value, false);
  //   });
  // }

  reset() {
    this.props.removeDisplaySettings('visibilitySettings').then(() => {
      window.location.reload();
    });
  }

  renderInputs() {
    // let tooltip = null;
    // if (this.props.poll && this.props.poll.slides.length === 0) {
    //   tooltip = (<Tooltip>Only a survey that has slides can be marked as visible.</Tooltip>);
    // }

    // if (this.props.poll && this.props.poll.isArchived) {
    //   tooltip = (<Tooltip>A survey that is archived cannot be visible.</Tooltip>);
    // }

    // let hideAfterSubmissionsTooltip = (<Tooltip>This will immediatly hide the survey once the user completes it and presses close. By default a completed survey will stay on the page until the next refresh.</Tooltip>);
    // if (this.state.inputs.visibilitySettings.showAfterSubmission) {
    //   hideAfterSubmissionsTooltip = (<Tooltip>This can only be enabled if "Show After Completed" is disabled..</Tooltip>);
    // }

    // let pollAttemptsInput = null;
    // if (this.state.inputs.visibilitySettings.openAttempts !== true) {
    //   pollAttemptsInput = (
    //     <InlineInput
    //       name="openAttempts"
    //       value={ this.state.inputs.visibilitySettings.openAttempts }
    //       prefix="Stop showing survey after "
    //       suffix=" attempts."
    //       onChange={ this.updateSetting.bind(this) }
    //     />
    //   );
    // }

    let pageDisplaySettings = this.props.account.pageDisplaySettings || generatePageDisplaySettings(this.props);
    if (this.props.poll && this.props.poll.pageDisplaySettings) {
      pageDisplaySettings = this.props.poll.pageDisplaySettings;
    }

    // let visibilityToggle = null;
    // if (this.props.poll) {
    //   visibilityToggle = (
    //     <div>
    //       <label>Visible{tooltip}</label>
    //       <div className="inline-toggle">
    //         <Toggle
    //           active={this.state.isVisible}
    //           disabled={this.props.poll.slides.length === 0 || this.props.poll.isArchived}
    //           onChange={(value) => {
    //             this.setState({ isVisible: value }, this.updateSettings);
    //           }}
    //         />
    //         <div className="subtle">Only visible polls will be displayed.</div>
    //       </div>
    //     </div>
    //   );
    // }

    // let warning = null;
    // let shareOnlyWarning = null;
    // if (this.props.pollType === 'embed') {
    //   warning = (<div className="input-warning"><div className="content"><div>Your survey is set to be embedded. This setting will not have any effect.</div></div></div>)
    // }
    // if (this.props.pollType === 'link') {
    //   shareOnlyWarning = (<div className="input-warning"><div className="content"><div>Your survey is set to be link only. This setting will not have any effect.</div></div></div>)
    // }
    // if (this.props.pollType === 'email') {
    //   shareOnlyWarning = (<div className="input-warning"><div className="content"><div>Your survey is set to be email only. This setting will not have any effect.</div></div></div>)      
    // }

    // let exitIntentWarning = null;
    // let exitIntentTimeoutInput = null;
    // let exitIntentDeviceInput = null;
    // let exitIntentIntensityInput = null;
    // if (this.state.inputs.visibilitySettings.renderOnExitIntent) {
    //   exitIntentWarning = (<div className="input-warning"><div className="content"><div>Your survey is set to appear on exit intent. This setting will not have any effect.</div></div></div>)

    //   const isShopify = this.props.account.shop === undefined ? false : true;
    //   let shopifyCartOption = null;
    //   let customExitIntentCartRules = null;
    //   if (isShopify) {
    //     shopifyCartOption = <div style={{marginBottom: 25 }}>
    //       <label>Exit Intent conditions<Tooltip>The exit intent survey will only appear after the user has been on your site for at least this amount of time.</Tooltip></label>

    //       <Switch
    //         value={this.state.inputs.visibilitySettings.exitIntentCondition || ''}
    //         vertical={true}
    //         options={[
    //           { label: 'Only show when there is at least one item in the cart.', value: 'cartOnly' },
    //           { label: 'Only show when the cart is empty.', value: 'emptyCartOnly' },
    //           { label: 'Show no matter what.', value: '' },
    //           { label: 'Set custom rules.', value: 'customCartRules' },
    //         ]}
    //         onChange={(value) => {
    //           this.updateSetting('exitIntentCondition', value);
    //         }}
    //       />
    //     </div>

    //     if (this.state.inputs.visibilitySettings.exitIntentCondition === 'customCartRules') {        
    //       customExitIntentCartRules = <CustomExitIntentRulesForm
    //         exitIntentCartRules={this.state.inputs.visibilitySettings.exitIntentCartRules || [[],[]]}
    //         onChange={(value) => {
    //           const inputs = { ...this.state.inputs };
    //           let visibilitySettings = inputs.visibilitySettings;
    //           visibilitySettings.exitIntentCartRules = value;
    //           inputs.visibilitySettings = visibilitySettings;
    //           this.setState({ inputs });
    //         }}
    //       />
    //     }
    //   }

    //   exitIntentTimeoutInput = (<div className="has-dynamic-input inline-toggle" style={{ marginTop: 0 }}>

    //     { shopifyCartOption }
    //     { customExitIntentCartRules }

    //     <label>Exit Intent Timer<Tooltip>The exit intent survey will only appear after the user has been on your site for at least this amount of time.</Tooltip></label>
    //     <InlineInput
    //       name="exitIntentTimeout"
    //       value={ this.state.inputs.visibilitySettings.exitIntentTimeout === undefined ? 20 : this.state.inputs.visibilitySettings.exitIntentTimeout }
    //       prefix="Enable exit intent after "
    //       suffix=" seconds spent on your site."
    //       onChange={ this.updateSetting.bind(this) }
    //     />
    //   </div>);

    //   exitIntentDeviceInput = (
    //     <div className="inline-input" style={{ marginTop: -15, marginBottom: 25 }}>
    //       <label>Device<Tooltip>Choose if you want to render the survey on all devices or just one or the other.</Tooltip></label>

    //       <Switch
    //         options={[
    //           { label: 'All Devices', value: false },
    //           { label: 'Desktop Only', value: 'desktop' },
    //           { label: 'Mobile Only', value: 'mobile' },
    //         ]}
    //         value={ this.state.inputs.visibilitySettings.exitIntentDevice === undefined ? false : this.state.inputs.visibilitySettings.exitIntentDevice }
    //         onChange={(value) => {
    //           this.updateSetting('exitIntentDevice', value); 
    //         }}
    //       />
    //     </div>
    //   );

    //   exitIntentIntensityInput = (
    //     <div className="inline-input" style={{ marginTop: 0, marginBottom: 25 }}>
    //       <label>Sensitivity<Tooltip>Choose how sensitive you want us to be when displaying an exit intent survey. High sensitivity will have more false positives then less sensitivity but less sensitivity will be shown fewer times.</Tooltip></label>

    //       <Switch
    //         options={[
    //           { label: 'Less Sensitive', value: 'low' },
    //           { label: 'Default', value: 'normal' },
    //           { label: 'Very Sensitive', value: 'high' },
    //         ]}
    //         value={ this.state.inputs.visibilitySettings.exitIntentSensitivity === undefined ? 'normal' : this.state.inputs.visibilitySettings.exitIntentSensitivity }
    //         onChange={(value) => {
    //           this.updateSetting('exitIntentSensitivity', value); 
    //         }}
    //       />
    //     </div>
    //   );
    // }

    // let launcherHiddenWarning = null;
    // if (this.props.displaySettings.hideLauncher && (!this.state.showDynamicRules || !this.state.showDynamicRenderRules)) {
    //   // launcherHiddenWarning = (<div className="input-warning"><div className="content"><div>Your survey has it's launcher set to hidden. This means you must open your survey using dynamic rules if you want your customers to be able to see it.</div></div></div>)
    // }

    const pollType = getPollDisplayType(this.props.poll);
    // let content = (
    //   <div>
    //     {!shareOnlyWarning && !warning && !exitIntentWarning && <span><SectionHeader
    //       title=<span><i class="far fa-square"></i>Survey Pop-up Settings</span>
    //       subtitle="Adjust when your survey will pop-up on your site (if applicable)."
    //       bottom={true}
    //       className="small"
    //     />

    //     <div className="account-settings card" style={{ marginTop: 0, marginBottom: 25 }}>
    //       <DynamicOpenInput
    //         toggleWarning={warning || launcherHiddenWarning}
    //         warning={shareOnlyWarning || warning || exitIntentWarning}
    //         autoOpenPosition={this.state.inputs.visibilitySettings.autoOpenPosition}
    //         autoOpenDuration={this.state.inputs.visibilitySettings.autoOpenDuration}
    //         autoOpenDurationType={this.state.inputs.visibilitySettings.autoOpenDurationType}
    //         autoOpenDevice={this.state.inputs.visibilitySettings.autoOpenDevice}
    //         modalExitRules={this.state.inputs.visibilitySettings.modalExitRules || false}
    //         showDynamicRules={this.state.showDynamicRules}
    //         onChange={(state) => {
    //           const inputs = { ...this.state.inputs };
    //           let visibilitySettings = inputs.visibilitySettings;
    //           visibilitySettings = { ...visibilitySettings, ...state }
    //           inputs.visibilitySettings = visibilitySettings;
    //           this.setState({ inputs });
    //         }}
    //         onSubmit={(autoOpenDuration, autoOpenPosition, autoOpenDevice) => {
    //           console.log('submit should never be called')
    //           // this.setState({ autoOpenDuration, autoOpenPosition, autoOpenDevice }, () => this.updateSettings('The behavior settings have been updated.'));
    //         }}
    //         onToggle={(value) => {
    //           this.setState({ showDynamicRules: value });
    //         }}
    //       />
    //     </div></span> }

    //     { (pollType === 'widget') && !shareOnlyWarning && !warning && !exitIntentWarning && <span><SectionHeader
    //       title=<span><i class="far fa-circle"></i>Launcher Pop-up Settings</span>
    //       subtitle="Adjust when your launcher will pop-up on your site (if applicable)."
    //       bottom={true}
    //       className="small"
    //     />

    //     <div className="account-settings card" style={{ marginTop: 0, marginBottom: 25 }}>
    //       <DynamicRenderInput
    //         toggleWarning={warning || launcherHiddenWarning}
    //         warning={shareOnlyWarning || warning || exitIntentWarning}
    //         renderPosition={this.state.inputs.visibilitySettings.renderPosition}
    //         renderDurationType={this.state.inputs.visibilitySettings.renderDurationType}
    //         renderDuration={this.state.inputs.visibilitySettings.renderDuration}
    //         renderDevice={this.state.inputs.visibilitySettings.renderDevice}
    //         showDynamicRules={this.state.showDynamicRenderRules}
    //         onChange={(state) => {
    //           const inputs = { ...this.state.inputs };
    //           let visibilitySettings = inputs.visibilitySettings;
    //           visibilitySettings = { ...visibilitySettings, ...state }
    //           inputs.visibilitySettings = visibilitySettings;
    //           this.setState({ inputs });
    //         }}
    //         onSubmit={(renderDuration, autoOpenPosition, autoOpenDevice) => {
    //           console.log('submit should never be called')
    //         }}
    //         onToggle={(value) => {
    //           this.setState({ showDynamicRenderRules: value });
    //         }}
    //       />
    //     </div>
    //   </span> }
    //   </div>
    // );

    // let modalWarning = null;
    // if (this.props.displaySettings.align === 'modal') {
    //   modalWarning = (<div className="input-warning"><div className="content"><div>Your survey is set to appear as a modal. This setting will not have any effect.</div></div></div>)

    //   content = (<div>
    //     { !shareOnlyWarning && !exitIntentWarning && <span><SectionHeader
    //       title=<span><i class="far fa-square-full"></i>Survey Pop-up Settings</span>
    //       subtitle="Adjust when your survey will pop-up on your site (if applicable)."
    //       bottom={true}
    //       className="small"
    //     />
    //     <div className="account-settings card " style={{ marginTop: 0, marginBottom: 25 }}>
    //       <ModalDynamicOpenInput
    //         warning={shareOnlyWarning || exitIntentWarning}
    //         autoOpenPosition={this.state.inputs.visibilitySettings.modalOpenPosition || 0}
    //         autoOpenDuration={this.state.inputs.visibilitySettings.modalOpenDuration || 0}
    //         autoOpenDurationType={this.state.inputs.visibilitySettings.modalOpenDurationType || 0}
    //         autoOpenDevice={this.state.inputs.visibilitySettings.modalOpenDevice || false}
    //         modalExitRules={this.state.inputs.visibilitySettings.modalExitRules || false}
    //         onChange={(state) => {
    //           const inputs = { ...this.state.inputs };
    //           let visibilitySettings = inputs.visibilitySettings;
    //           visibilitySettings = { ...visibilitySettings, ...state }
    //           inputs.visibilitySettings = visibilitySettings;
    //           console.log('updating');
    //           console.log(inputs);
    //           this.setState({ inputs });
    //         }}
    //         onSubmit={(autoOpenDuration, autoOpenPosition, autoOpenDevice) => {
    //           console.log('submit should never be called')
    //           // this.setState({ autoOpenDuration, autoOpenPosition, autoOpenDevice }, () => this.updateSettings('The behavior settings have been updated.'));
    //         }}
    //         names={['modalOpenDuration', 'modalOpenPosition', 'modalOpenDevice', 'modalOpenDurationType']}
    //         labels={[ 'Modal Timing', 'Modal Position', 'Device' ]}
    //         onToggle={(value) => {
    //           this.setState({ showDynamicRules: true });
    //         }}
    //       />
    //     </div></span>}
    //   </div>)
    // }

    // if (exitIntentWarning) {
      // content = null;
    // }

    // if (this.props.displaySettings.hideLauncher) {
      // modalWarning = (<div className="input-warning"><div className="content"><div>Your poll does not have a launcher. These settings will not have any effect. The poll will be hidden immediatley when closed.</div></div></div>)
    // }

    // let strategy = 'hideAfterPageRefresh';
    // if (this.state.inputs.visibilitySettings.showAfterSubmission) {
    //   strategy = 'showAfterSubmission';
    // }
    // if (this.state.inputs.visibilitySettings.hideAfterSubmission) {
    //   strategy = 'hideAfterSubmission';
    // }
    // if (this.state.inputs.visibilitySettings.hideAfterClose) {
    //   strategy = 'hideAfterClose';
    // }

    // let advancedOptions = null;
    // if (this.state.showAdvancedOptions) {
    //   advancedOptions = (
    //     <div>
    //       <div style={{ position: 'relative' }} className={`${false ? 'disabled' : ''} has-dynamic-input`}>
    //         { shareOnlyWarning }
    //         <label>Memory Options<Tooltip>By default the survey will keep these settings applied after each visit. If you prefer to have them reset each time a user visits your site, you can select "reset" instead.</Tooltip></label>

    //         <div className="inline-toggle">
    //           <Switch
    //               value={ this.state.inputs.visibilitySettings.resetEachSession === true ? true : false }
    //               vertical={true}
    //               options={[
    //                 { label: 'Persist presentation settings after a user leaves your site.', value: false },
    //                 { label: 'Reset presentation settings after a user leaves your site.', value: true },
    //               ]}
    //               onChange={(value) => {
    //                 this.updateSetting('resetEachSession', value); 
    //               }}
    //             />
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }

    let recipientsInput = null;
    // if (this.state.inputs.settings.notifyOnComplete) {
      recipientsInput = (
        <RecipientsInput
          disabled={!this.state.inputs.settings.notifyOnComplete}
          onChange={(e) => {
            const inputs = this.state.inputs;
            inputs.recipients = e.target.value;
            this.setState({ inputs });
          }}
          onValidate={this.onValidate.bind(this)}
          label="Notification Email Recipients"
          moreInfo={<span>By default, this will send to the account notification settings. If you would like to override this behavior, set custom recipients instead.</span>}
          placeholder="email@domain.com"
          name="recipients"
          value={ getRecipients(this.state.inputs.recipients, this.props.account) }
          errorMessage="Please enter a valid domain name."
          ref={this.setRef.bind(this)}
        />
      );
    // }

    const isShopify = this.props.account.shop === undefined ? false : true;

    let shopifyPollSettings = null;
    if (isShopify) {
    // if (true) {
      let shopifyTagInput = null;
      if (!this.state.inputs.settings.disableShopifyTags) {
        shopifyTagInput = (
          <NameInput
            value={this.state.inputs.settings.shopifyCustomerTag === undefined ? 'Completed Zigpoll Survey' : this.state.inputs.settings.shopifyCustomerTag}
            label={'Shopify Customer Tag'}
            placeholder="Completed Zigpoll Survey"
            onChange={(e) => {
              const settings = this.state.inputs.settings;
              settings.shopifyCustomerTag = e.target.value;
              this.updateSettings(settings);
            }}
            onValidate={() => {
              console.log('validate');
            }}
            errorMessage="Please enter a value."
          />
        );
      }

      let shopifyCustomerTagInput = <div>
        <label>Shopify Tags<Tooltip>This will automatically tag the Shopify Customer when he or she completes a survey. The tag can be specified below.</Tooltip></label>
        <div className="inline-toggle" style={{ marginBottom: this.state.inputs.settings.disableShopifyTags ? 0 : 25 }}>
          <Toggle
            active={!this.state.inputs.settings.disableShopifyTags}
            onChange={(value) => {
              const settings = this.state.inputs.settings;
              settings.disableShopifyTags = !value;
              this.updateSettings(settings);
            }}
          />
          <div className="subtle">Tag customers who complete the survey in Shopify.</div>
        </div>
        {shopifyTagInput}
      </div>

      let acceptsMarketingInput = <div className="input">
        <label>Accepts Marketing<Tooltip>Whenever a customer is created from Zigpoll we can mark accepts marketing as true or false based on this flag.</Tooltip></label>
        <div className="inline-toggle absolute">
          <Toggle
            active={!this.state.inputs.settings.disableAcceptsMarketing}
            onChange={(value) => {
              const settings = this.state.inputs.settings;
              settings.disableAcceptsMarketing = !value
              this.updateSettings(settings);
            }}
          />
          <div className="subtle">Mark created customers as "accepts marketing".</div>
        </div>
      </div>

      if (this.state.inputs.settings.disableCreateNewCustomers) {
        acceptsMarketingInput = null;
      }

      shopifyPollSettings = (
      <span>
        <SectionHeader
          title=<span><i class="fas fa-pencil-alt" />Shopify Write Settings</span>
          subtitle="Control how Zigpoll data interacts with your Shopify store."
          tooltip="Save time and make connections by merging any data collected by Zigpoll with your Shopify admin dashboard."
          className="no-margin small"
        />
        <div className="inputs account-settings card" style={{ marginBottom: 25 }}>
          <div className="input" style={{ marginTop: 0 }}>
            <label>Write to order details<Tooltip>Whenever an order id is passed through to Zigpoll we will log the results to that particular order in your Shopify store under the "Additional Details" section. This lets you view all your data within the Shopify dashboard.</Tooltip></label>
            <div className="inline-toggle absolute">
              <Toggle
                active={this.state.inputs.settings.writeToOrder}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.writeToOrder = value
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Save responses to the <strong>Additional Details</strong> section of your Shopify order dashboard.</div>
            </div>
          </div>

          <div className="input">
            <label>Write to order notes<Tooltip>Whenever an order id is passed through to Zigpoll we will log the results to that particular order in your Shopify store under the "Notes" section. This is useful if you prefer notes over the additional details section.</Tooltip></label>
            <div className="inline-toggle absolute">
              <Toggle
                active={this.state.inputs.settings.writeToOrderNote}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.writeToOrderNote = value
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Save responses to the <strong>Notes</strong> section of your Shopify order dashboard.</div>
            </div>
          </div>

          <div className="input">
            <label>Write to customer notes<Tooltip>Whenever a customer id is passed through to Zigpoll we will log the results to that particular customer in your Shopify store. This lets you view all your data within the Shopify dashboard.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.inputs.settings.writeToCustomer}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.writeToCustomer = value
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Save responses to the Shopify customer object.</div>
            </div>
          </div>

          <div className="input">
            <label>Write to customer metafields<Tooltip>Whenever a customer id is passed through to Zigpoll we will log the results to that particular customer in your Shopify store. This lets you view all your data within the Shopify dashboard.</Tooltip></label>
            <div className="inline-toggle absolute">
              <Toggle
                active={this.state.inputs.settings.writeToCustomerMetafields}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.writeToCustomerMetafields = value
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Write customer responses to individual metafields on the Shopify customer object.</div>
            </div>
          </div>

          { this.state.inputs.settings.writeToCustomerMetafields && (
          <div className="input">
              <NameInput
                value={this.state.inputs.settings.customerMetafieldNamespace === undefined ? 'Zigpoll' : this.state.inputs.settings.customerMetafieldNamespace}
                label={"Metafield Namespace"}
                moreInfo={"By default, we use Zigpoll as the namespace. If you want to use a different one, enter it below."}
                placeholder="zigpoll"
                onChange={(e) => {
                  const settings = this.state.inputs.settings;
                  settings.customerMetafieldNamespace = e.target.value;
                  this.updateSettings(settings);
                }}
                onValidate={() => {
                  console.log('validate');
                }}
                errorMessage="Please enter a value."
              />
          </div>
          ) }

          <div className="input">
            <label>Create new customers<Tooltip>Whenever an email is found we will automatically create a new customer in your Shopify dashboard if it does not already exist.</Tooltip></label>
            <div className="inline-toggle absolute">
              <Toggle
                active={!this.state.inputs.settings.disableCreateNewCustomers}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.disableCreateNewCustomers = !value
                  this.updateSettings(settings);
                }}
              />
              <div className="subtle">Create new customers in Shopify if they do not already exist.</div>
            </div>
          </div>

          { acceptsMarketingInput }

          { shopifyCustomerTagInput }
        </div>
      </span>
      );
    }
    let metadataWhitelistInput = null;
    if (this.state.inputs.settings.emailMetadataRules === 'whitelist') {
      metadataWhitelistInput = (
        <div style={{ marginTop: 10 }}>
          <label>Metadata Whitelist</label>
          <textarea
            className="textarea"
            style={{ marginTop: 0, minHeight: 200 }}
            value={this.state.inputs.settings.emailMetadataWhitelist || ''}
            placeholder="completed_poll, order_total"
            onChange={(e) => {
              const settings = this.state.inputs.settings;
              settings.emailMetadataWhitelist = e.target.value;
              this.updateSettings(settings);
            }}
          />
        </div>
      );
    }

    let advancedEmailOptions = null;
    if (this.state.showAdvancedEmailOptions) {
      advancedEmailOptions = (
      <div>
        <div style={{ marginTop: 20, marginBottom: 0 }}>
          <label>Notification Email Metadata Rules<Tooltip>Determine how much metadata to show on your notifications.</Tooltip></label>
          <div className="inline-toggle">
            <Switch
              vertical={true}
              options={[
                { label: 'Print out all metadata', value: 'all' },
                { label: 'Hide all metadata ', value: 'none' },
                { label: 'Show certain metadata only', value: 'whitelist' },
              ]}
              value={this.state.inputs.settings.emailMetadataRules || 'all'}
              onChange={(value) => {
                const settings = this.state.inputs.settings;
                settings.emailMetadataRules = value;
                this.updateSettings(settings);
              }}
            />
          </div>
        </div>
        { metadataWhitelistInput }

        <div style={{ marginTop: 20, marginBottom: 0 }}>
          <label>Notification Email Logo<Tooltip>Use a custom logo for your email heading. Useful if you plan to reply to the customer directly via email.</Tooltip></label>

          <SingleImageUploader
            hideLabel={true}
            key={this.state.inputs.settings.logoImage}
            image={this.state.inputs.settings.logoImage}
            onChange={this.onLogoImageUpload.bind(this)} />
        </div>
      </div>
      );
    }

    let followUpEmailForm = null;
    if (this.state.inputs.settings.followUpEmail) {
      const emailTemplates = this.props.account.emailTemplates || [];
      let editButton = null;

      if (this.state.inputs.settings.followUpEmailTemplate && _.find(emailTemplates, ({ id }) => id === this.state.inputs.settings.followUpEmailTemplate)) {
        editButton = <div className="edit-button" onClick={(e) => { 
          const emailTemplate = _.find(emailTemplates, ({ id }) => id === this.state.inputs.settings.followUpEmailTemplate);
          this.showEditEmailTemplateModal(emailTemplate);
        }}><i className="fas fa-edit" /></div>
      }

      followUpEmailForm = <div className="inline-input" style={{ marginTop: 15 }}>
        <span className="prefix">Send <div className="select-wrapper" style={{ maxWidth: 230 }}><select
          style={{ width: '100%' }}
          value={this.state.inputs.settings.followUpEmailTemplate}
          onChange={(e) => {
            const value = e.target.value;

            if (value === 'create') {
              this.showCreateEmailTemplateModal(() => {
                console.log('Do nothing');
              }, (emailTemplateId) => {
                const settings = this.state.inputs.settings;
                settings.followUpEmailTemplate = emailTemplateId;
                this.updateSettings(settings);
              });
            } else {
              const settings = this.state.inputs.settings;
              settings.followUpEmailTemplate = value;
              this.updateSettings(settings);
            }
          }}
        >
          <option value="" disabled selected>Select an email template</option>
          { emailTemplates.map((template) => {
            return <option value={template.id}>{ template.title || template.subject }</option>
          }) }
          <option value="create">+ Create Email Template</option>
        </select></div>{ editButton } after</span>
        <input
          type="text"
          name='followUpEmailDelay'
          value={ this.state.inputs.settings.followUpEmailDelay }
          onChange={(e) => {
            let val = e.target.value.replace(/[^\d.-]/g,'');
            const settings = this.state.inputs.settings;
            settings.followUpEmailDelay = val;
            this.updateSettings(settings);
          }}
        />
        <span className="suffix"><select onChange={(e) => {
          e.preventDefault();
          e.stopPropagation();
          let val = e.target.value;
          const settings = this.state.inputs.settings;
          settings.followUpEmailDelayInterval = val;
          this.updateSettings(settings);
        }} value={this.state.inputs.settings.followUpEmailDelayInterval || 'hours'} style={{ textAlign: 'left', width: 'auto', display: 'inline-block' }}><option value="minutes">minutes</option><option value="hours">hours</option><option value="days">days</option></select></span>
      </div> 
    }

    let advancedGeneralOptions = null;
    if (this.state.showAdvancedGeneralOptions) {
      advancedGeneralOptions = (
      <div style={{ marginBottom: 25 }}>
        <div style={{ marginTop: 20, marginBottom: 0 }}>
          <label>Anonymous Mode</label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.inputs.settings.anonMode}
              onChange={(value) => {
                const settings = this.state.inputs.settings;
                settings.anonMode = value
                this.updateSettings(settings);
              }}
            />
            <div className="subtle">Do not collect any information from participants.</div>
          </div>
        </div>
        <div style={{ marginTop: 20, marginBottom: 0 }}>
          <label>Hide X Button</label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.inputs.settings.hideXButton}
              onChange={(value) => {
                const settings = this.state.inputs.settings;
                settings.hideXButton = value
                this.updateSettings(settings);
              }}
            />
            <div className="subtle">Hide the X button on the top right of the survey.</div>
          </div>
        </div>
        <div style={{ marginTop: 20, marginBottom: 0 }}>
          <label>Hide Close Button</label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.inputs.settings.hideCloseButton}
              onChange={(value) => {
                const settings = this.state.inputs.settings;
                settings.hideCloseButton = value
                this.updateSettings(settings);
              }}
            />
            <div className="subtle">Hide the close button at the end of the survey.</div>
          </div>
        </div>
        <div style={{ marginTop: 20, marginBottom: 0 }}>
          <label>Hide Back Button</label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.inputs.settings.hideBackButton}
              onChange={(value) => {
                const settings = this.state.inputs.settings;
                settings.hideBackButton = value
                this.updateSettings(settings);
              }}
            />
            <div className="subtle">Prevent the back button from appearing on this survey.</div>
          </div>
        </div>
      </div>);
    }
    return (
      <div className="account-display">
        <div className="split big">
          <div>
            { this.props.title }

            {/*
            <SectionHeader
              title=<span><i class="fas fa-window-restore"></i>Visibility Strategy</span>
              subtitle="Adjust how your survey will behave after being filled out or closed."
              bottom={true}
              className="small no-margin"
            />

            <div className="card has-advanced-options has-dynamic-input account-settings">
              { visibilityToggle }

              <div className={false ? 'disabled' : ''} style={{ position: 'relative', marginBottom: 20 }}>
                {shareOnlyWarning || modalWarning}
                <label>Pop-up Visibility Strategy<Tooltip>Select which behavior you would like enabled from the options below. Different strategies are useful for different types of surveys.</Tooltip></label>
                <p className="subtle bold" style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}>{this.props.isAccountVisibilitySettingsForm ? 'All surveys will' : 'This survey will'}<i className="subtle-arrow fas fa-arrow-turn-down" /></p>
                <div className="inline-toggle">
                  <Switch
                    value={strategy}
                    vertical={true}
                    options={[
                      { label: 'Remain visible when completed until page is refreshed.', value: 'hideAfterPageRefresh' },
                      { label: 'Keep appearing even after completed.', value: 'showAfterSubmission' },
                      { label: 'Hide immediately when closed and completed.', value: 'hideAfterSubmission' },
                      { label: 'Hide immediately when closed even if not completed.', value: 'hideAfterClose' },
                    ]}
                    onChange={(value) => {
                      this.clearStrategySettings();
                      this.updateSetting(value, true);
                    }}
                  />
                </div>
              </div>

              <div style={{ position: 'relative' }} className={warning ? 'disabled' : ''}>
                {warning}
                <label>Hide Launcher Once Completed{hideAfterSubmissionsTooltip}</label>
                <div className="inline-toggle">
                  <Toggle
                    active={this.state.inputs.visibilitySettings.hideAfterSubmission}
                    disabled={this.state.inputs.visibilitySettings.showAfterSubmission}
                    onChange={(value) => {
                      this.updateSetting('hideAfterSubmission', value);
                    }}
                  />
                  <div className="subtle">The poll will disappear after it has been completed.</div>
                </div>
              </div>

              <div>
                <label>Continue to appear once completed{ this.state.inputs.visibilitySettings.hideAfterSubmission ? <Tooltip>This can only be enabled if "Hide After Submission" is disabled.</Tooltip> : <Tooltip>If you think people will want to revisit the results, enable this. If you think people will want to complete the pull multiple times, enable "Allow Resubmissions" on the general tab instead.</Tooltip> }</label>
                <div className="inline-toggle">
                  <Toggle
                    active={this.state.inputs.visibilitySettings.showAfterSubmission}
                    disabled={this.state.inputs.visibilitySettings.hideAfterSubmission}
                    onChange={(value) => {
                      this.updateSetting('showAfterSubmission', value );
                    }}
                  />
                  <div className="subtle">The poll will still render after it's been completed.</div>
                </div>
              </div>

              <div style={{ position: 'relative' }} className={`${false ? 'disabled' : ''} has-dynamic-input`}>
                { shareOnlyWarning }
                <label>Presentation Options<Tooltip>By default the survey will appear forever until it's completed. If you prefer to not show the survey as often you can adjust how many time's it's shown by filling in the input box below.</Tooltip></label>

                <div className="inline-toggle">
                  <Switch
                      value={ this.state.inputs.visibilitySettings.openAttempts === true ? true : false }
                      vertical={true}
                      options={[
                        { label: this.props.isAccountVisibilitySettingsForm ? 'Present the surveys forever if not completed.' : 'Present the survey forever if not completed.', value: true },
                        { 
                          inlineInput: true,
                          name: "openAttempts",
                          value: false,
                          inputValue: this.state.inputs.visibilitySettings.openAttempts === true ? '' :  this.state.inputs.visibilitySettings.openAttempts,
                          prefix: "Stop showing survey after ",
                          suffix: " attempts."
                         },
                      ]}
                      onChange={(value) => {
                        if (value === true) {
                          this.updateSetting('openAttempts', value);
                        } else if ((value === false)) {
                          if (this.state.inputs.visibilitySettings.openAttempts === true || this.state.inputs.visibilitySettings.openAttempts === false) {
                            this.updateSetting('openAttempts', '10'); 
                          }
                        } else {
                          this.updateSetting('openAttempts', value); 
                        }
                      }}
                    />
                </div>
              </div>

              { advancedOptions }

              <div className="show-advanced" onClick={() => this.setState({ showAdvancedOptions: !this.state.showAdvancedOptions})} >{ !this.state.showAdvancedOptions ? 'More Options' : 'Less Options' }</div>
            </div>
            */}

            <SectionHeader
              title=<span><i class="fas fa-vote-yea" />Submission Rules</span>
              subtitle="Manage how often users can fill out this survey."
              bottom={true}
              className="small no-margin"
            />

            <div className="card account-settings has-advanced-options" >
              {/*
              <div className="inline-toggle">
                <label>URL Dependant<Tooltip>This will treat each survey as unique if it is visited on a new url. This is useful if you have dynamic pages and want to run the same survey multiple times depending on the context. For example: running a survey on a post purchase page.</Tooltip></label>
                <div className="inline-toggle absolute">
                  <Toggle
                    active={this.state.inputs.settings.urlDependant}
                    onChange={(value) => {
                      const settings = this.state.inputs.settings;
                      settings.urlDependant = value;
                      this.updateSettings(settings);
                    }}
                  />
                  <div className="subtle">Vistors can complete this survey again if it is delivered on a new URL.</div>
                </div>
              </div>
              */}
              <div className="inline-toggle">
                <label>Allow Edits</label>
                <Toggle
                  active={this.state.inputs.settings.allowEdits}
                  onChange={(value) => {
                    const settings = this.state.inputs.settings;
                    settings.allowEdits = value
                    this.updateSettings(settings);
                  }}
                />
                <div className="subtle">Allow users to edit their answers after submitting.</div>
              </div>
              <div className="inline-toggle">
                <label>Automatically Advance Slides</label>
                <Toggle
                  active={this.state.inputs.settings.automaticallySwitchSlides}
                  onChange={(value) => {
                    const settings = this.state.inputs.settings;
                    settings.automaticallySwitchSlides = value
                    this.updateSettings(settings);
                  }}
                />
                <div className="subtle">Automatically move to the next slide on selection.</div>
              </div>
              <div className="inline-toggle">
                <label>Automatically Close Survey</label>
                <Toggle
                  active={this.state.inputs.settings.autoClose}
                  onChange={(value) => {
                    const settings = this.state.inputs.settings;
                    settings.autoClose = value
                    this.updateSettings(settings);
                  }}
                />
                <div className="subtle">Automatically close the survey once it has been completed.</div>
              </div>
              <div className="inline-toggle">
                <label>Allow Resubmissions<Tooltip>This is useful for <strong>contact us</strong> style forms where you want to collect feedback without any limits.</Tooltip></label>
                <Toggle
                  active={this.state.inputs.settings.allowResubmissions}
                  onChange={(value) => {
                    // if (this.props.poll.settings.isVisible) {
                    //   this.showConfirmModal();
                    // } else {
                      const settings = this.state.inputs.settings;
                      settings.allowResubmissions = value;
                      this.updateSettings(settings);
                    // }
                  }}
                />
                <div className="subtle">Let users fill out this survey multiple times.</div>
              </div>

              { this.state.inputs.settings.allowResubmissions && <div className="inline-toggle">
                <label>Reset Session<Tooltip>This is useful for <strong>kiosk</strong> style forms where you want to collect feedback from a single survey using a tablet or other device.</Tooltip></label>
                <Toggle
                  active={this.state.inputs.settings.resetSessions}
                  onChange={(value) => {
                    const settings = this.state.inputs.settings;
                    settings.resetSessions = value;
                    this.updateSettings(settings);
                  }}
                />
                <div className="subtle">Each survey completion will be treated as a brand new participant.</div>
              </div> }

              { advancedGeneralOptions }

              <div className="show-advanced" onClick={() => this.setState({ showAdvancedGeneralOptions : !this.state.showAdvancedGeneralOptions })} >{ !this.state.showAdvancedGeneralOptions  ? 'More Options' : 'Less Options' }</div>

            </div>

            {/*
            <SectionHeader
              title=<span><i class="fas fa-up-right-from-square"></i>Exit Intent</span>
              subtitle="Control your exit intent settings (if applicable)."
              bottom={true}
              className="small"
            />

            <div className="card account-settings">
              <div style={{ position: 'relative' }} className={false ? 'disabled' : ''}>
                { shareOnlyWarning }

                <label>Appear On Exit Intent Only <Tooltip>The survey will render and open when the user tries to exit the page.</Tooltip></label>
                <div className="inline-toggle">
                  <Toggle
                    active={ this.state.inputs.visibilitySettings.renderOnExitIntent }
                    onChange={(value) => {
                      this.updateSetting('renderOnExitIntent', value);
                    }}
                  />
                  <div className="subtle">Render and open when the user shows Exit Intent.</div>
                </div>
              </div>

              { exitIntentIntensityInput }
              { exitIntentTimeoutInput }
              { exitIntentDeviceInput }
            </div>

            { content }
            */}

            <SectionHeader
              title=<span><i class="fas fa-route"></i>Actions</span>
              subtitle="Actions to take when a survey is fully or partially completed."
              tooltip="By default, all notifications are sent to the account owner."
              bottom={true}
              className="small no-margin"
            />

            <div className="inputs has-dynamic-input email-notifications-form card account-settings" style={{ marginBottom: 25, paddingBottom: this.state.inputs.settings.followUpEmail }}>

              <div style={{ marginBottom: 20 }}>
                <label>Follow-Up Email<Tooltip>Send a follow up email to the respondant.</Tooltip></label>
                <div className="inline-toggle">
                  <Toggle
                    active={this.state.inputs.settings.followUpEmail}
                    onChange={(value) => {
                      const settings = this.state.inputs.settings;
                      settings.followUpEmail = value;
                      this.updateSettings(settings);
                    }}
                  />
                  <div className="subtle">Send a follow-up email to the respondant.</div>
                </div>

                { followUpEmailForm }
              </div>

              <div style={{ marginBottom: 0, paddingBottom: 20 }}>
                <label>Notification Email<Tooltip>This will treat each survey as unique if it is visited on a new url. This is useful if you have dynamic pages and want to run the same survey multiple times depending on the context. For example: running a survey on a post purchase page.</Tooltip></label>
                <div className="inline-toggle">
                  <Toggle
                    active={this.state.inputs.settings.notifyOnComplete}
                    onChange={(value) => {
                      const settings = this.state.inputs.settings;
                      settings.notifyOnComplete = value;
                      this.updateSettings(settings);
                    }}
                  />
                  <div className="subtle">Notify me or my team by email.</div>
                </div>
              </div>

              { this.state.inputs.settings.notifyOnComplete && <div style={{ marginBottom: this.state.inputs.settings.notifyOnComplete ? 25 : 25 }}>
                <label>Notification Email Rules<Tooltip>Configure how often and under what circumstances we should notify you of your survey responses.</Tooltip></label>
                <div className="inline-toggle">
                  <Switch
                    vertical={true}
                    options={[
                      { label: 'Email if the survey is partially completed ', value: true },
                      { label: 'Email only if the survey is fully completed', value: 'strict' },
                      { label: 'Email only if the respondent submitted an email address', value: 'email-only' },
                      { label: 'Email only if an open-ended response is submitted', value: 'open-ended-only' }
                    ]}
                    value={this.state.inputs.settings.notifyOnComplete}
                    onChange={(value) => {
                      const settings = this.state.inputs.settings;
                      settings.notifyOnComplete = value;
                      this.updateSettings(settings);
                    }}
                  />
                </div>
              </div> }

              { this.state.inputs.settings.notifyOnComplete && recipientsInput }

              { this.state.inputs.settings.notifyOnComplete && advancedEmailOptions }

              { this.state.inputs.settings.notifyOnComplete && <div className="show-advanced" onClick={() => this.setState({ showAdvancedEmailOptions: !this.state.showAdvancedEmailOptions})} >{ !this.state.showAdvancedEmailOptions ? 'More Options' : 'Less Options' }</div> }
            </div>

            { shopifyPollSettings }

            { this.renderActions() }
            { this.renderModals() }
          </div>
          <div className="preview-container-wrapper">
            <SurveyPreviewHeader
              account={this.props.account}
              poll={this.props.poll}
            />
            <div className="preview-container">
              <AccountSettingsVisualDisplay
                pageDisplaySettings={pageDisplaySettings}
                slides={this.props.poll ? this.props.poll.slides : []}
                active={this.state.launcherActive}
                pollType={pollType}
                toggleActive={() => this.setState({ launcherActive: !this.state.launcherActive })}
                belowVisualDisplay={this.props.belowVisualDisplay}
                scalePoll={false}
                {...getDisplaySettings(this.props.account, this.props.poll)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderModals() {
    return <span>
      { this.state.showCreateEmailTemplateModal && <CreateEmailTemplateModal 
        isOpen={this.state.showCreateEmailTemplateModal}
        settings={this.props.poll.emailDisplaySettings || this.props.account.emailDisplaySettings}
        poll={this.props.poll}
        account={this.props.account}
        onClose={() => this.state.onCreateModalClose()}
        onSave={(emailTemplateId) => this.state.onCreateModalSave(emailTemplateId)}
      /> }
      { this.state.showEditEmailTemplateModal && <CreateEmailTemplateModal 
        isOpen={this.state.showEditEmailTemplateModal}
        isEdit={true}
        settings={this.state.showEditEmailTemplateModal}
        poll={this.props.poll}
        account={this.props.account}
        onClose={() => {
          this.setState({ showEditEmailTemplateModal: false })
        }}
        onSave={() => {
          this.setState({ showEditEmailTemplateModal: false })
        }}
      /> }
    </span>
  }

  renderActions() {
    return (
      <div className="actions card account-settings" style={{ marginTop: 20 }}>
        <AuthSubmitButton
          title="Save Changes" 
          disabled={!this.hasChanged()}
        />
        
        {/*
        { this.props.poll ? 
          <button 
            className="reset"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ showResetModal: true });
            }}
          >Reset</button> : <div className="login">
          <div>Need help with behavior settings?</div>
          <a href={`https://docs.zigpoll.com/${this.props.isAccountVisibilitySettingsForm ? 'accounts' : 'polls'}/visibility-settings`} target="_blank" rel="noopener noreferrer">Learn more</a>
        </div> }
        */}
        <div className="login">
          <div>Need help with behavior settings?</div>
          <a href={`https://docs.zigpoll.com/${this.props.isAccountVisibilitySettingsForm ? 'accounts' : 'polls'}/visibility-settings`} target="_blank" rel="noopener noreferrer">Learn more</a>
        </div>

        <Confirm 
          title="Are you sure?"
          subtitle={<span>This will reset your survey's behavior settings back to your defaults.</span>}
          show={this.state.showResetModal}
          onConfirm={() => {
            this.reset();
          }}
          onCancel={() => {
            this.setState({ showResetModal: false });
          }}
        />

        <HeaderButtons>
          <button 
            disabled={!this.hasChanged()}
            onClick={this.onSubmit.bind(this)}>Save Changes</button>
        </HeaderButtons>
      </div>
    )
  }

  render() {
    return (
      <form onSubmit={this.onSubmit.bind(this)} className={`${this.state.valid === false ? 'invalid' : ''} ${this.state.loading || this.props.loading ? 'loading' : ''} ${this.props.className}`}>
          { this.renderInputs() }
      </form>
    );
  }
}
