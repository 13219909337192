import React, { Component } from 'react';

import $ from 'jquery';

import { uuid } from '../utils';

class Checkbox extends Component {
  render() {
    return (
      <div
        className={`checkbox ${this.props.selected ? 'selected' : ''}`}
        onClick={(e) => { 
          e.preventDefault();
          e.stopPropagation();
          this.props.onClick({ label: this.props.label, value: this.props.value }) 
        }}
      >
        <span><i className="fas fa-check" /></span>
        <div>{ this.props.label }</div>
      </div> 
    );
  }
}

export default class PopoutCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.id = uuid();
  }

  toggle(e) {
    e.preventDefault();
    e.stopPropagation();

    let active = !this.state.active;
    $(window).off(`click.${this.id}`);

    if (active) {
      setTimeout(() => {
        $(window).on(`click.${this.id}`, (e) => {
          this.toggle(e);
        });
      }, 0);
    }

    this.setState({ active });
  }

  render() {
    return (
      <div className={`popout popout-checkbox ${this.state.active ? 'active' : ''}`}>
        <div className="title" onClick={this.toggle.bind(this)}>{ this.props.title }</div>
        <div className={`content`} onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
          { this.props.options.map(({ label, value }) => <Checkbox
            value={value}
            label={label}
            selected={this.props.value.indexOf(value) !== -1}
            onClick={({ label, value, selected }) => {
              let val = [...this.props.value];
              const idx = val.indexOf(value)
              if (idx !== -1) {
                val.splice(idx, 1)
              } else {
                val.push(value);
              }
              this.props.onClick(val);
            }}
          /> )}
        </div>
      </div>
    );
  }
}