import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import tinycolor from 'tinycolor2';
import { getCodes, getName } from 'country-list';

import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import tinyColor from 'tinycolor2';

import { EmbedButton, EmbedBackButton } from './EmbedButtons';
import { handleize, decode, uuid, stripTags } from '../utils';

import PhoneInput from 'react-phone-number-input'
import Radio from './Radio'
import Checkbox from './Checkbox'
import RangeInput from './RangeFormInput';
import SatisfactionInput from './SatisfactionFormInput';
import StarRatingInput from './StarRatingFormInput';
import BinaryInput from './BinaryFormInput';

import veryUnsatisfiedImg from '../images/very-unsatisfied.png';
import unsatisfiedImg from '../images/unsatisfied.png';
import neutralImg from '../images/neutral.png';
import satisfiedImg from '../images/satisfied.png';
import verySatisfiedImg from '../images/very-satisfied.png';

import DatePicker from "react-datepicker";
import Dropzone from 'react-dropzone'

import Slider from 'rc-slider';
import RCTooltip from 'rc-tooltip';
const Handle = Slider.Handle;

function includesOperator(operator, input, value) {
  let isValid = false;

  if (operator === 'includes-all') {
    let includesAll = true;
    input.forEach((tag) => {
      if (value.indexOf(tag) === -1) {
        includesAll = false;
      }
    });
    isValid = includesAll;
  } else if (operator === 'excludes-all') {
    let includesAll = true;
    input.forEach((tag) => {
      if (value.indexOf(tag) === -1) {
        includesAll = false;
      }
    });
    isValid = !includesAll;
  } else if (operator === 'includes-any') {
    let includesAny = false;
    input.forEach((tag) => {
      if (value.indexOf(tag) !== -1) {
        includesAny = true;
      }
    });
    isValid = includesAny;
  } else if (operator === 'excludes-any') {
    let includesAny = false;
    input.forEach((tag) => {
      if (value.indexOf(tag) !== -1) {
        includesAny = true;
      }
    });
    isValid = !includesAny;
  }

  return isValid;
}

function getNextSlideFromLogic(slides, currentSlide, response = '') {
  /* Get from logic instead of deprecated nextSlide */
  const logic = currentSlide.logic || [];
  response = response.toLowerCase();

  let nextSlideId;
  let nextSlide;
  let defaultLogic;

  function processRow(row) {
    row.rules.some(({ rule, ruleValue }) => {
      if (Array.isArray(ruleValue)) {
        /* Do nothing */
      } else {
        ruleValue = ruleValue ? ruleValue.toLowerCase() : '';
      }
      let conditionMet = false;

      if (rule === 'submitted') {
        conditionMet = true;
      }
      if (rule === '=') {
        let val = response ? response : '';

        if (val === ruleValue) {
          conditionMet = true;
        }
      }
      if (rule === '>') {
        let val = response ? +response : -100;

        if (val > ruleValue) {
          conditionMet = true;
        }
      }
      if (rule === '>=') {
        let val = response ? +response : -100;

        if (val >= ruleValue) {
          conditionMet = true;
        }
      }
      if (rule === '<') {
        let val = response ? +response : 100;

        if (val < ruleValue) {
          conditionMet = true;
        }
      }
      if (rule === '<=') {
        let val = response ? +response : 100;

        if (val <= ruleValue) {
          conditionMet = true;
        }
      }
      if (rule === 'contains') {
        let val = response ? response : '';
        conditionMet = val.indexOf(ruleValue) !== -1;
      }
      if (rule === 'not-contains') {
        let val = response ? response : '';
        conditionMet = val.indexOf(ruleValue) === -1;
      }
      if (['includes-any', 'includes-all', 'excludes-any', 'excludes-all'].indexOf(rule) !== -1) {
        if (!Array.isArray(ruleValue)) {
          ruleValue = [];
        }

        let answersArray = response.split(', ');

        /* Use the raw array if you have it otherwise it breaks on commas */
        if (currentSlide.submittedAnswers) {
          answersArray = currentSlide.submittedAnswers.map((answer) => {
            return ''+(answer.title || answer.handle || '').toLowerCase()
          });
        }

        const ruleValueArray = ruleValue.map((v) => v.toLowerCase());
        conditionMet = includesOperator(rule, ruleValueArray, answersArray);
      }

      if (conditionMet) {
        row.actions.some(({ action, actionValue }) => {
          if (action === 'end') {
            nextSlideId = -1;
            return true;
          }
          if (action === 'skip') {
            nextSlideId = actionValue;
            nextSlide = _.find(slides, ({ _id }) => nextSlideId === _id);
            return nextSlide;
          }
          if (action === 'continue') {
            nextSlideId = 1;
            return true;
          }
        });
      }

      return conditionMet;
    })
  }

  logic.slice().reverse().forEach((row) => {
    if (row.type === 'default') {
      defaultLogic = row;
      return;
    }

    processRow(row);
  });

  /* If you are not matched and you have defaultLogic, run against defaultLogic */
  if (nextSlideId === undefined && defaultLogic) {
    processRow(defaultLogic);
  }

  if (nextSlideId === -1) {
    return undefined;
  }

  /* Requirement for APP only I think. Breaks logic branching without it. */
  if (nextSlideId === 1 && response) {
    return nextSlideId;
  }

  if (!nextSlide) {
    nextSlideId = undefined;
  }

  return nextSlideId;
}

function surveyEndsHere(currentSlide, response = '') {
  /* Get from logic instead of deprecated nextSlide */
  const logic = currentSlide.logic || [];
  response = response.toLowerCase();

  let nextSlideId;

  logic.slice().reverse().forEach((row) => {
    if (!row.rules) { return; }
    row.rules.some(({ rule, ruleValue }) => {
      if (Array.isArray(ruleValue)) {
        /* Do nothing */
      } else {
        ruleValue = ruleValue ? ruleValue.toLowerCase() : '';
      }
      let conditionMet = false;

      if (rule === 'submitted') {
        conditionMet = true;
      }
      if (rule === '=') {
        let val = response ? response.toLowerCase() : '';

        if (val === ruleValue) {
          conditionMet = true;
        }
      }
      if (rule === '>') {
        let val = response ? +response.toLowerCase() : -100;

        if (val > ruleValue) {
          conditionMet = true;
        }
      }
      if (rule === '>=') {
        let val = response ? +response.toLowerCase() : -100;

        if (val >= ruleValue) {
          conditionMet = true;
        }
      }
      if (rule === '<') {
        let val = response ? +response.toLowerCase() : 100;

        if (val < ruleValue) {
          conditionMet = true;
        }
      }
      if (rule === '<=') {
        let val = response ? +response.toLowerCase() : 100;

        if (val <= ruleValue) {
          conditionMet = true;
        }
      }
      if (rule === 'contains') {
        let val = response ? response.toLowerCase() : '';
        conditionMet = val.indexOf(ruleValue) !== -1;
      }
      if (rule === 'not-contains') {
        let val = response ? response.toLowerCase() : '';
        conditionMet = val.indexOf(ruleValue) === -1;
      }
      if (['includes-any', 'includes-all', 'excludes-any', 'excludes-all'].indexOf(rule) !== -1) {
        if (!Array.isArray(ruleValue)) {
          ruleValue = [];
        }
        const answersArray = response.split(', ');
        const ruleValueArray = ruleValue.map((v) => v.toLowerCase());
        conditionMet = includesOperator(rule, ruleValueArray, answersArray);
      }

      if (conditionMet) {
        row.actions.some(({ action, actionValue }) => {
          if (action === 'end') {
            nextSlideId = -1;
            return true;
          }
          if (action === 'skip') {
            nextSlideId = actionValue;
            return nextSlideId;
          }
        });
      }

      return conditionMet;
    })
  });

  if (nextSlideId === -1) {
    return true;
  }

  return nextSlideId;
}

function getNotificationStyles(backgroundColor, hover) {
  let style = {};

  if (backgroundColor) {
    const color = backgroundColor;
    const darkerColor = tinycolor(color).darken(10).toString();

    style = {
      background: color,
      borderColor: 'transparent',
      // textShadow: `0 2px 0 ${darkerColor}`,
      textShadow: `0 2px 0 transparent`,
    };

    if (hover) {
      style.background = darkerColor;
    }
  }

  return style;
}

function getLauncherStyles(backgroundColor, hover) {
  let launcherStyle = {};
  let iconStyle = {};

  if (backgroundColor) {
    const color = backgroundColor;
    const darkerColor = tinycolor(backgroundColor).darken(10).toString();

    launcherStyle = {
      // background: color,
      borderColor: 'transparent'
    };

    iconStyle = {
      // textShadow: `0 2px 0 ${darkerColor}`,
      textShadow: `0 2px 0 transparent`
    };

    if (hover) {
      // launcherStyle.background = darkerColor;
    }
  }

  return { launcherStyle, iconStyle };
}

function getLauncherPosition(align = '', position, shape) {
  const isVerticalAligned = align.indexOf('-middle') !== -1;
  align = align.replace('-middle', '');
  const styles = {};

  if (shape === 'bumper') {
    position = [...position];
    position[0] = 0;
  }

  if (!isVerticalAligned) {
    if (shape === 'circle') {
      styles.bottom = `${position[0]}px`;
    } else {
      styles.bottom = 0;
    }
    styles[align] = `${position[1]}px`;
  } else {
    styles[align] = 0;
  }

  if (align === 'modal') {
    styles.display = 'none';
  }

  return styles;
}

function getNotificationPosition(align, position, width) {
  const styles = { bottom: `${parseInt(position[0]) + 40}px` };
  if (align === 'right') {
    styles[align] = `${parseInt(position[1]) - 10}px`;  
  } else {
    styles[align] = `${parseInt(position[1]) + width - 20}px`;
  }
  return styles;
}

function getPollPosition(active, align, position, shape, hideLauncher, launcherFontSize) {

  let diff = (launcherFontSize - 16) * 3;

  const isVerticalAligned = align.indexOf('-middle') !== -1;
  align = align.replace('-middle', '');

  if (shape === 'bumper') {
    position = [...position];
    if (hideLauncher) {
      position[0] = 10;
    } else {
      position[0] = 0;      
    }
  }

  const styles = {};
  if (hideLauncher) {
    styles.bottom = `${parseInt(position[0]) + 0}px`;
  } else {
    styles.bottom = `${parseInt(position[0]) + 60}px`;    
  }
  styles[align] = `${position[1]}px`;

  if (!active) {
    if (align === 'left') {
      styles[align] = `-2000px`;
    } else {
      styles[align] = `10000px`;      
    }
  }

  if (align === 'modal') {
    styles.top = '0';
    styles.bottom = '0';
    styles.right = '0';
    styles.margin = 'auto';
    styles.left = '-2000px';

    if (active) {
      styles.left = '0';
    }
  }

  if (isVerticalAligned) {
    if (active) {
      if (shape === 'bumper') {
        if (hideLauncher) {
          styles[align] = 10;
        } else {
          styles[align] = 55;
        }
      } else {
        if (hideLauncher) {
          styles[align] = 10;
        } else {
          styles[align] = 65;
        }
      }
    }
  }

  return styles;
}

function getFontSize(answers) {
  const sizes = [];

  answers.forEach(({ title }) => {
    const len = title.length;
    let fontSize = 17;
    if (len > 25) { fontSize = 16 }
    if (len > 28) { fontSize = 15; }
    if (len > 31) { fontSize = 14; }

    sizes.push(fontSize);
  });

  return { fontSize: _.min(sizes) }
}

function getRangeFontSize(len) {
  if (len > 8) {
    return 15;
  }
  if (len > 6) {
    return 17;
  }
  return 18;
}

export function getBoxRatio(len, containerWidth = 410, isStar) {
  let perc = 30;
  let limit = 50;
  if (len > 5) {
    perc = 100;
    // return '100%';
  } else if (len > 4) {
    perc = 80;
    // return '80%';
  } else if (len > 3) {
    perc = 60;
    // return '60%';
  } else if (len > 2) {
    perc = 45;
    // return '45%';
  }
  if (isStar) {
    perc = 100;
    limit = 60;
  }

  if ( ((containerWidth / len) * (perc/100)) > limit) {
    return limit+'px';
  }
  // return '30%';
  return perc+'%';
}

function getWidth(hasIcon, copyWidth) {
  let padding = 16;
  if (hasIcon) {
    padding = 40;
  }
  return copyWidth + padding;
}

function getBorderRadius(shape, borderRadius) {
  if (borderRadius == undefined) {
    if (shape === 'circle') {
      return { borderRadius: 25 };
    } else {
      const br = 10;
      return { borderTopLeftRadius: br, borderTopRightRadius: br };
    }
  }
  if (shape === 'circle') {
    return { borderRadius };
  } else {
    const br = borderRadius;
    return { borderTopLeftRadius: br, borderTopRightRadius: br };
  }
}

export function getTitleFontSize(title, align) {
  let bump = 0;
  if (align === 'modal') {
    bump = 1;
  }
  const len = stripTags(title).length;
  let fontSize = 22 + bump + bump;

  // if (len > 20) { fontSize = 23; }
  if (len > 30) { fontSize = 21 + bump + bump; }
  if (len > 35) { fontSize = 20 + bump; }
  if (len > 40) { fontSize = 19 + bump; }

  return fontSize;
}

function buildColumns(numColumns = 2) {
  const columns = [];
  for (let i = 0; i < numColumns; i++) {
    columns.push([]);
  }
  return columns;
}

const families = {
  'sans': ['proxima-nova', 'Helvetica', 'sans-serif'],
  'slab': ['rockwell', 'slab-serif'],
  'serif': ['adobe-caslon-pro', 'times', 'serif']
};

export function getFontFamilies(fontFamily = 'sans', customFont) {
  if (fontFamily === 'custom') {
    return customFont || families['sans'];
  }

  if (!families[fontFamily]) {
    return families['sans'];
  }

  return families[fontFamily];
}

const icons = {
  'very-unsatisfied': veryUnsatisfiedImg,
  'unsatisfied': unsatisfiedImg,
  'neutral': neutralImg,
  'satisfied': satisfiedImg,
  'very-satisfied': verySatisfiedImg
};

const emojiIcons = {
  'very-unsatisfied': '😠',
  'unsatisfied': '☹️',
  'neutral': '😐',
  'satisfied': '🙂',
  'very-satisfied': '😍' 
};

const alt = {
  'very-unsatisfied': 'Very Unsatisfied',
  'unsatisfied': 'Unsatisfied',
  'neutral': 'Neutral',
  'satisfied': 'Satisfied',
  'very-satisfied': 'Very Satisfied'
};

const starAlt = [
  '1 Star',
  '2 Stars',
  '3 Stars',
  '4 Stars',
  '5 Stars'
];

const starColors = [
'rgb(220, 58, 40)',
'rgb(228, 133, 48)',
'rgb(242,205,48)',
'rgb(148,206,53)',
'rgb(102,181,125)'
]

class Count extends Component {
  constructor(props) {
    super(props);
    this.state = { prevPerc: 0, targetPerc: 0 };

    setTimeout(() => this.animatePercents(), 0);
  }

  generatePercent() {
    const slides = this.props.slides;
    const currentIdx = this.props.currentIdx;
    const total = this.props.slides.length - 1;
    const currentSlide = slides[currentIdx];
    const isLastSlide = currentIdx === total;

    let perc = Math.ceil((currentIdx/total) * 100);
    if (this.props.slides.length === 1) {
      perc = 100;
    }

    if (isLastSlide) {
      perc = 100;
    }

    return perc;
  }

  animatePercents() {
    this.setState({ prevPerc: this.state.targetPerc, curPerc: this.state.targetPerc, targetPerc: this.generatePercent() });
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.currentIdx !== this.props.currentIdx)
    ) {
      this.animatePercents();
    }
  }

  render() {
    const RADIUS = 10;
    const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

    const progress = this.state.targetPerc / 100;
    const dashOffset = CIRCUMFERENCE * (1 - progress);
    const strokeDashArray = CIRCUMFERENCE;

    return (
      <div className={`count ${this.state.targetPerc !== 0 ? 'active' : ''} ${this.state.targetPerc === 100 && (this.props.currentIdx === this.props.slides.length - 1) ? 'complete' : ''}`} style={{ color: this.props.color }}>
        <div className="progress-circle">
          <svg className="progress" width="100%" height="100%" viewBox="0 0 24 24">
            <circle className="meter" style={{ }} cx="12" cy="12" r="10" strokeWidth="2" />
            <circle className="value" style={{ }} cx="12" cy="12" r="10" strokeWidth="2" strokeDashoffset={dashOffset} strokeDasharray={strokeDashArray} />
          </svg>
        </div>

        <i className="fas fa-check" />

      </div>
    );
  }
}

class FitText extends Component {
  constructor(props) {
    super(props);

    this.state = { fontSize: 17 };

    this.textContainer = React.createRef();
    this.text = React.createRef();
  }

  componentDidMount() {
    this.resize();
    $('#zigpoll-embed').on('load', () => this.resize());
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({ fontSize: 17 }, this.resize.bind(this));
      return;
    }

    this.resize();
    $('#zigpoll-embed').on('load', () => this.resize());
  }

  resize() {
    const $text = this.text.current;
    const $textContainer = this.textContainer.current;

    if (!$text || !$textContainer) { return; }
    const tcw = $textContainer.offsetWidth;
    const tw = $text.offsetWidth;

    if (!tcw || !tw) { return; }
    if ((tw > (tcw - 2)) && (this.state.fontSize >= 8)) {
      this.setState({ fontSize: (this.state.fontSize - 1) });
    }
  }

  render() {
    return (
      <div
        className={`title ${this.props.className}`}
        ref={this.textContainer}
        onClick={() => {
          if (this.props.onClick) {
            this.props.onClick();
          }
        }}
        // style={{
          // fontSize: this.state.fontSize,
          // whiteSpace: 'nowrap',
          // textOverflow: 'ellipsis',
          // overflow: 'hidden'
        // }}
      >
        <span
          ref={this.text}
          // style={{ 
            // display: 'inline',
            // verticalAlign: 'top',
            // fontSize: this.state.fontSize,
          // }}
          >{this.props.value || <span style={{ opacity: 0 }}>placeholder</span>}</span>
      </div>
    );
  }
}

class Select extends Component {
  render() {
    return (
      <div className="select-container"><select
        value={this.props.value}
        onChange={(e) => {
          this.props.onChange(e.target.value);
        }}
        disabled={this.props.disabled}
      >
        <option hidden disabled selected value>{ this.props.placeholder || "Please select one" }</option>
        { this.props.options.map((option) => <option value={option}>{ option }</option>)}
      </select></div>
    );
  }
}

class DumbSlideTitle extends Component {
  render() {
    const style = { 
      textShadow: `0 2px 0 transparent`,
      fontSize: getTitleFontSize(this.props.title, this.props.align),
      fontFamily: this.props.fontFamilies
    }

    if (this.props.fontFamily !== 'sans') {
      style.fontWeight = 300;
    }

    if (this.props.fontFamily === 'serif') {
      // style.lineHeight = 1.2;
      style.marginTop = 4;
    }

    if (this.props.fontFamily === 'slab') {
      // style.lineHeight = 1.1;
    }

    return (<h3
      style={style}
      dangerouslySetInnerHTML={{ __html : this.props.title }}
    />)
  }
}

class DumbSlideSubtitle extends Component {
  render() {
    const style = { fontFamily: this.props.fontFamilies };

    if (this.props.fontFamily === 'serif') {
      // style.fontFamily = null;
      style.fontWeight = 400;
    }

    if (this.props.fontFamily === 'slab') {
      style.fontFamily = null;
      // style.fontWeight = 400;
    }

    return (
      <h5
        style={style}      
        className="subtitle">{ this.props.subtitle }</h5>
    );
  }
}

class DumbSlideCopy extends Component {
  render() {
    const style = {};

    if (this.props.fontFamily === 'serif') {
      style.fontFamily = this.props.fontFamilies;
    }

    return (
      <div
        style={style}
        className="copy" 
        dangerouslySetInnerHTML={{ __html : this.props.copy }} />
    );
  }
}

class DumbSlideDisclaimer extends Component {
  render() {
    const style = {};

    if (this.props.fontFamily === 'serif') {
      style.fontFamily = this.props.fontFamilies;
    }

    if (!this.props.disclaimer) {
      return null;
    }

    return (
      <div
        style={style}
        className="disclaimer" 
        dangerouslySetInnerHTML={{ __html : this.props.disclaimer }} />
    );
  }
}

class EmailCaptureForm extends Component {
  render() {
    return (
      <form
        className='input'
      >
        <label>Email</label>
        <input
          type={ this.props.type }
          placeholder={ this.props.placeholder || 'email@domain.com' }
          name="email"
          value=''
          autoComplete="new-password"
          disabled={true}
          autofill="off"
        />
        <EmbedButton
          type="submit"
          color={this.props.color}
          disabled={true}
        >
          <div>
            <span>{ this.props.translations['submit'] }</span>
            <span>{ this.props.translations['thanks'] }</span>
          </div>
        </EmbedButton>

        <div className="reset">{ this.props.translations['reset'] }</div>
        <div className="message">{ this.props.translations['email-error-message'] }</div>
      </form>
    );
  }

}

class Answer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const props = this.props;

    return (
      <div 
        className='answer'
        onMouseEnter={() => this.props.onMouseEnter(this.props.idx)}
        onMouseLeave={() => this.props.onMouseLeave(this.props.idx)}
        onClick={this.props.onClick}
      >
        <span />
        <div className="title-slug">{ this.props.title || 'placeholder' }</div>
      </div>
    );
  }
}

class DynamicAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const props = this.props;

    return (
      <div 
        className={`answer ${this.props.hover ? 'hover' : ''}`}
        onMouseEnter={() => this.props.onMouseEnter(this.props.idx)}
        onMouseLeave={() => this.props.onMouseLeave(this.props.idx)}
        onClick={this.props.onClick}
      >
        <span />
        <form disabled={true} onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
          <input
            type="text"
            placeholder={this.props.title}
            value={this.state.value}
            disabled={true}
            onMouseEnter={() => this.props.onMouseEnter(this.props.idx)}
            onMouseLeave={() => this.props.onMouseLeave(this.props.idx)}
            // style={this.props.fontSize}
          />
          { !this.props.hideSubmit && <EmbedButton
            type="submit"
            color={props.color}
          >
            <div>
              <span>{ this.props.translations['submit'] }</span>
              <span>{ this.props.translations['thanks'] }</span>
            </div>
          </EmbedButton> }
        </form>
      </div>
    );
  }
}

class FormInput extends Component {
  render() {
    const { label, type, optional, value, idx, placeholder } = this.props;

    let options = this.props.options || [];

    let input = <input />
    let message = this.props.translations["empty-error-message"];
    if (type === 'short-answer') {
      input = <input
        value={value}
        placeholder={placeholder}
        disabled={true}
        name={idx}
      />
    } else if (type === 'long-answer') {
      input = <textarea
        value={value}
        disabled={true}
        placeholder={placeholder}
      />
    } else if (type === 'email-capture') {
      input = <input
        type="email"
        value={value}
        disabled={true}
        placeholder={placeholder}
      />
      message = this.props.translations["email-error-message"];
    } else if (type === 'phone-number') {
      input = <PhoneInput
        flagUrl='https://cdn.zigpoll.com/country-flag-icons/3x2/{XX}.svg'
        placeholder={placeholder || "Enter phone number"}
        value={value}
        key={this.props.country}
        disabled={true}
        onChange={(value) => {
          console.log('do nothing');
        }}
        defaultCountry={this.props.country}
      />

      message = this.props.translations["phone-error-message"];
    } else if (type === 'select') {
      input = <Select
        options={options}
        value={value}
        disabled={true}
        placeholder={placeholder}
        onChange={(value) => {
          console.log('do nothing')
        }}
      />
    } else if (type === 'country') {
      const countryCodes = getCodes();
      const options = countryCodes.map((code) => {
        const name = getName(code);
        return name
      });

      input = <Select
        options={options}
        placeholder={placeholder || 'Select your country'}
        onChange={(value) => {
          console.log('do nothing')
        }}
      />
    } else if (type === 'date') {
      input = <input
        type="date"
        disabled={true}
      />
    } else if (type === 'radio') {
      input = <Radio
        options={options}
        value={value}
        color={this.props.actionColor}
        disabled={true}
        onChange={(value) => {
          this.props.onChange(this.props.idx, value);
        }}
      />
    } else if (type === 'checkbox') {
      input = <Checkbox
        options={options}
        value={value}
        color={this.props.actionColor}
        disabled={true}
        onChange={(value) => {
          this.props.onChange(this.props.idx, value);
        }}
      />
    } else if (type === 'binary') {
      input = <BinaryInput
        options={this.props.options}
        value={value}
        color={this.props.actionColor}
        disabled={true}
        onChange={(value) => {
          this.props.onChange(this.props.idx, value);
        }}
      />
    } else if (type === 'satisfaction') {
      input = <SatisfactionInput
        options={['Very Unsatisfied', 'Unsatisfied', 'Neutral', 'Satisfied', 'Very Satisfied']}
        value={value}
        color={this.props.actionColor}
        disabled={true}
        labels={this.props.labels}
        maxWidth={this.props.maxWidth}
        onChange={(value) => {
          this.props.onChange(this.props.idx, value);
        }}
      />
    } else if (type === 'star-rating') {
      input = <StarRatingInput
        options={['1 Star', '2 Stars', '3 Stars', '4 Stars', '5 Stars']}
        value={value}
        color={this.props.actionColor}
        disabled={true}
        labels={this.props.labels}
        maxWidth={this.props.maxWidth}
        onChange={(value) => {
          this.props.onChange(this.props.idx, value);
        }}
      />
    } else if (type === 'range') {
      input = <RangeInput
        className="embed-range-input"
        options={this.props.options}
        value={value}
        color={this.props.actionColor}
        disabled={true}
        labels={this.props.labels}
        maxWidth={this.props.maxWidth}
        onChange={(value) => {
          this.props.onChange(this.props.idx, value);
        }}
      />
    }

    return (<div className={`form-input ${type} ${this.props.idx === 0 ? 'first' : ''}`}>
      <label><span>{ optional ? `${this.props.translations['optional'] }` : ''}</span>{ label }</label>
      { input }
      <div className="message">{ message }</div>
    </div>)
  }
}

class FormInputs extends Component {
  render() {
    const formInputs = this.props.formInputs;

    return (
      <form
        className={`form-container`}
      >
        { formInputs.map((formInput, idx) => <FormInput
            idx={idx}
            maxWidth={this.props.maxWidth}
            actionColor={this.props.actionColor}
            { ...formInput }
            translations={this.props.translations}
          />)}
      </form>
    );
  }
}

class Slide extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onMouseEnter(idx) {
    this.setState({ hoverIdx: idx });
  }

  onMouseLeave() {
    this.setState({ hoverIdx: undefined });
  }

  onClick(nextSlide, isEmbedButton) {
    if (this.props.isLast) { return; }
    if (!this.props.goto) { return; }

    let idx = nextSlide;

    if (idx === undefined || idx === null) {
      const slide = this.props;
      nextSlide = getNextSlideFromLogic(this.props.slides, slide, '');
      if (nextSlide) {
        return this.props.goto(nextSlide)
      }
      this.props.next(!isEmbedButton);
    } else {
      this.props.goto(idx);
    }
  }

  render() {
    const slide = this.props;
    const settings = slide.settings || { showTitle: true };
    let customCTA = this.props.customCTA;

    let title = null;
    if (slide.title && settings.showTitle) {
      // title = (<h3 style={{ textShadow: `0 2px 0 ${tinycolor(this.props.backgroundColor).darken(10).toString()}`, fontSize: getTitleFontSize(slide.title) }}>{slide.title}</h3>);
      title = (<DumbSlideTitle
            title={this.props.title}
            align={this.props.align}
            fontFamily={this.props.fontFamily}
            fontFamilies={getFontFamilies(this.props.fontFamily, this.props.customFont)}
          />)
    }

    let subtitle = null;
    if (slide.subtitle) {
      subtitle = (<h5 className="subtitle">{slide.subtitle}</h5>);
    }

    let copy = null;  
    if (slide.copy) {
      copy = (<div
        className="copy" 
        dangerouslySetInnerHTML={{ __html : slide.copy }}
      />);
    }

    let input = null;

    let leftLabel;
    let rightLabel;
    let labels;

    if (slide.leftLabel) {
      leftLabel = <div className="left-label">{ this.props.leftLabel }</div>
    }
    if (slide.rightLabel) {
      rightLabel = <div className="right-label">{ this.props.rightLabel }</div>
    }
    if (slide.leftLabel || slide.rightLabel) {
      labels = <div className="labels">
        { leftLabel }
        { rightLabel }
      </div>
    }

    /* Get nextslide from logic instead of answer.nextslide */
    if (slide.type === 'question') {
      const fontSize = getFontSize(slide.answers);

      input = (<div className="answers-container">
        <div className="answer-group">
          { slide.answers.map(({ title, handle, dynamic }, idx) => {
            if (!handle) { return null;}
            const nextSlide = getNextSlideFromLogic(this.props.slides, slide, title);

            if (dynamic) {
              return (<DynamicAnswer
                hideSubmit={true}
                hover={this.state.hoverIdx === idx}
                onMouseEnter={this.onMouseEnter.bind(this)}
                onMouseLeave={this.onMouseLeave.bind(this)}
                onClick={() => this.onClick(nextSlide)}
                key={idx} title={title} handle={handle} idx={idx} color={this.props.actionColor} translations={this.props.translations} />);
            } else {
              return (<Answer
                onMouseEnter={this.onMouseEnter.bind(this)}
                onMouseLeave={this.onMouseLeave.bind(this)}
                onClick={() => this.onClick(nextSlide)}
                key={idx} title={title} handle={handle} idx={idx} color={this.props.actionColor} />);
            }
          }) }
        </div>
        <div className="results">
        {slide.answers.map(({ title, handle, votes, dynamic }, idx) => {
          return (
            <div key={idx} className={`result ${this.state.hoverIdx === idx ? 'hover' : ''}`}>
              <div className="fill"></div>
              <div className="perc">0</div>
              <FitText className={dynamic ? 'one-line' : ''} value={title} />
            </div>
          );
        })}
        </div>
      </div>);
    }

    if (slide.type === 'checkbox') {
      const fontSize = getFontSize(slide.answers);

      input = (<div className="answers-container">
        <div className="answer-group">
          { slide.answers.map(({ title, handle, dynamic }, idx) => {
            const nextSlide = getNextSlideFromLogic(this.props.slides, slide, title);

            if (dynamic) {
              return (<DynamicAnswer
                onClick={() => this.onClick(nextSlide)}
                onMouseEnter={this.onMouseEnter.bind(this)}
                onMouseLeave={this.onMouseLeave.bind(this)}
                hover={this.state.hoverIdx === idx}
                hideSubmit={true}
                key={idx} title={title} handle={handle} idx={idx} color={this.props.actionColor} translations={this.props.translations} />);
            } else {
              return (<Answer
                onClick={() => this.onClick(nextSlide)}
                onMouseEnter={this.onMouseEnter.bind(this)}
                onMouseLeave={this.onMouseLeave.bind(this)}
                key={idx} title={title} handle={handle} idx={idx} color={this.props.actionColor} />);
            }
          }) }
        </div>
        <div className="results">
        {slide.answers.map(({ title, handle, votes, dynamic }, idx) => {
          return (
            <div key={idx} className={`result ${this.state.hoverIdx === idx ? 'hover' : ''}`}>
              <div className="fill"></div>
              <div className="perc">0</div>
              <FitText className={dynamic ? 'one-line' : ''} value={title} />
            </div>
          );
        })}
        </div>
      </div>);
    }

    if (slide.type === 'inline-multiple-choice') {
      const fontSize = getFontSize(slide.answers);

      input = (<div className="inline-multi-choice-container">
        <div className="answer-group">
          { slide.answers.map(({ title, handle, dynamic }, idx) => {
            const nextSlide = getNextSlideFromLogic(this.props.slides, slide, title);

            if (dynamic) {
              return <div 
                className={`answer dynamic ${false ? 'active' : ''} ${true ? 'disabled' : ''} ${this.props.dynamicAnswer ? 'selected' : ''}`}
                onMouseEnter={() => this.setState({ hover: true })}
                onMouseLeave={() => this.setState({ hover: false })}
              >
                <form disabled={true} onSubmit={(e) => { e.preventDefault(); e.stopPropagation();}} className={``} data-value={title}>
                  <input
                    disabled={true}
                    size={1}
                    type="text"
                    placeholder={title}
                    style={{ cursor: 'not-allowed' }}
                  />
                </form>
              </div>
            } else {
              return <div
                key={handle}
                className={`answer ${this.state.hover ? 'hover' : ''} ${false ? 'selected' : ''} ${this.props.className || ''}`}
                style={{}}
                onMouseEnter={() => this.onMouseEnter()}
                onMouseLeave={() => this.onMouseLeave()}
                title={handle}
              >
                <span style={{}}>{ title }</span>
              </div>  
            }
          }) }
        </div>
      </div>);
    }

    if (slide.type === 'action') {
      let actions = slide.actions || [];
      input = (<div className="actions-container">
      { actions.map((action) => {
        if (!action.title) { return null; }

        let icon = null;
        if (action.icon) {
          icon = <i className={`${action.icon}`} />
        }

        return <EmbedButton
          color={action.backgroundColor || slide.backgroundColor}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >{ icon }{ action.title }</EmbedButton>
      })}
      </div>);
    }


    if (slide.type === 'form') {
      input = (
        <FormInputs
          color={this.props.actionColor}
          placeholder={slide.placeholder}
          formInputs={slide.formInputs}
          translations={this.props.translations}
          actionColor={slide.actionColor}
          maxWidth={this.props.maxWidth}
        />)
    }

    if (slide.type === 'email-capture') {
      input = (
        <div className="email-input">
          <EmailCaptureForm
            color={this.props.actionColor}
            placeholder={slide.placeholder}
            translations={this.props.translations}
          />
        </div>);
    }

    if (slide.type === 'short-answer') {
      input = (
      <div
        className={`response-input short-answer`}
      >
        <form
          disabled={true}
          className={`input`}
        >

          <input
            type="text"
            placeholder={slide.placeholder}
            value={''}
            autoComplete="new-password"
            autofill="off"
            name="value"
            disabled={true}
          />

        </form>
      </div>
      );
    }

    if (slide.type === 'long-answer') {
      input = (
      <div
        className={`response-input long-answer`}
      >
        <form
          disabled={true}
          className={`input`}
        >

          <textarea
            type="text"
            placeholder={slide.placeholder}
            value={''}
            autoComplete="new-password"
            autofill="off"
            name="value"
            disabled={true}
          />
        </form>
      </div>
      );
    }

    if (slide.type === 'binary') {
      input = (
        <div
          className={`binary-container`}
        >
        <div className="answer-group">
        { slide.answers.map(({ title, handle, votes }, idx) => {
          const nextSlide = getNextSlideFromLogic(this.props.slides, slide, title);
          return (<FitText
            value={title}
            onClick={() => {
              this.onClick(nextSlide)
            }}
          />)
        }) }
        </div>
      </div>
      );
    }

    if (slide.type === 'reward' && (this.props.rewardCode || this.props.rewardCodeType === 'dynamic')) {
      input = (
        <div
          className={`reward-code`}
        >
          <div className="reward-container">
            <span>{ (this.props.rewardCode && this.props.rewardCodeType === 'static') ? this.props.rewardCode : 'Dynamic Code' }</span>
            <i className="fas fa-copy" />
          </div>
        </div>
      );
      if (this.props.rewardCodeType === 'store-credit') {
        input = null;
      }
    }

    if (slide.type === 'legal-consent') {
      // let checkboxStyle = { borderColor: tinycolor(this.props.actionColor).darken(10).toString() };
      input = (
        <div
          className={`legal-consent disabled`}
        >
          <div className="legal-consent-checkbox">
            <span 
              style={{}}
            />
            <div className="label"><div>{ this.props.answers[0].label }</div></div>
          </div>
        </div>
      );
    }

    if (slide.type === 'calendar') {
      input = (
        <div className={`date embed-react-datepicker`}>
          <DatePicker
            minDate={true ? new Date() : undefined}
            maxDate={true ? moment().subtract(1, 'day').toDate() : undefined}
            inline
          />
        </div>
      );
    }

    if (slide.type === 'date') {
      let dateInput = <span>
          <input
            type="date"
            disabled={true}
          />
          <i className="fas fa-calendar" />
      </span>

      let format = slide.settings.format || this.props.format;
      let hideMonth = slide.settings.hideMonth || this.props.hideMonth;
      let hideDay = slide.settings.hideDay || this.props.hideDay;
      let hideYear = slide.settings.hideYear || this.props.hideYear;
      let dateFormat = slide.settings.dateFormat || this.props.dateFormat;
      const translations = this.props.translations;

      if (format === 'dropdowns') {
        let monthPicker = <div className="select-container"><select disabled><option>{ translations['month'] }</option></select></div>
        let dayPicker = <div className="select-container"><select disabled><option>{ translations['day'] }</option></select></div>
        let yearPicker = <div className="select-container"><select disabled><option>{ translations['year'] }</option></select></div>

        if (hideMonth) {
          monthPicker = null;
        }
        if (hideDay) {
          dayPicker = null;
        }
        if (hideYear) {
          yearPicker = null;
        }

        dateInput = <span className="date-dropdown-wrapper">
          { monthPicker }
          { dayPicker }
          { yearPicker }
        </span>

        if (dateFormat === 'dd-mm-yyyy') {
          dateInput = <span className="date-dropdown-wrapper">
            { dayPicker }
            { monthPicker }
            { yearPicker }
          </span>
        }
      }

      input = (
      <div
        className={`response-input date`}
      >
        <form
          disabled={true}
          className={`input`}
        >
        <div className="date-input-wrapper">
          { dateInput }
        </div>
        </form>
      </div>
      );
    }

    if (slide.type === 'rank') {
      input = (
      <div
        className={`rank-container disabled completed`}
      >
        <div className="answers">
        { this.props.answers.map(({ title, handle, selected }, idx) => {
          // let spanStyle = { borderColor: tinycolor(this.props.actionColor).toString() };

          return (<div
            key={handle}
            onClick={() => {
            }}
            className={`answer ${selected ? 'selected' : 'not-selected'}`}
            title={title}
          >
            <span style={{}}>{ null }</span><div className="title">{title}</div>
          </div>)
        }) }
        </div>
      </div>
      );
    }

    if (slide.type === 'file-upload') {
      input = (<div
        className={`upload-input ${this.props.submittedResponse ? 'completed' : 'incomplete' }`}
      >

        <Dropzone 
          onDrop={() => {}}
          multiple={false}
          disabled={true}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p><i className="fas fa-file-import" />{ this.props.placeholder || this.props.translations['file-upload'] }</p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>);
    }

    if (slide.type === 'slider') {
      const marks = undefined;
      const min = this.props.min || 0;
      const max = this.props.max || 100;
      const step = this.props.step || 5;
      const hideTooltip = this.props.hideTooltip;
      const { suffix, prefix } = this.props;

      let answers = [ ...this.props.answers ];
      const handle = (props) => {
        const { value, dragging, index, ...restProps } = props;

        if (hideTooltip) {
          return <Handle value={value} {...restProps} />
        }

        return (
          <RCTooltip
            prefixCls="rc-slider-tooltip"
            overlay={`${prefix || ''}${value}${suffix || ''}`}
            visible={dragging}
            placement="top"
            key={index}
            getTooltipContainer={() => {
              return $("#zigpoll-poll-iframe").contents().find(".slide.active .rc-slider")[0]
            }}
          >
            <Handle value={value} {...restProps} />
          </RCTooltip>
        );
      };
      input = (
        <div className={`slider-container`}>
          <div className="slider">
            <Slider 
              handle={handle}
              min={min}
              max={max}
              step={step}
              value={(max-min)/2}
              marks={marks}
              disabled={true}
              onChange={(value) => {
                /* Do nothing */
              }}
            />
          </div>
          { labels }
        </div>
      );
    }

    if (slide.type === 'image-choice') {
      /* Hack since non-saved state is not saved to settings. */
      const showTitle = this.props.showAnswerTitle || this.props.settings.showAnswerTitle;
      const numColumns = this.props.numColumns || this.props.settings.numColumns || 2;
      const columns = buildColumns(numColumns);

      this.props.answers.forEach((answer, idx) => {
        answer = { ...answer }
        answer.idx = idx;
        columns[idx % numColumns].push(answer);
      });

      input = (<div
      className={`image-answers-container`}
    >
      <div className="answer-group">
      { columns.map((column) => {
        return <div className="answer-column" style={{width: (100 / numColumns) + '%'}}>{ column.map(({ title, handle, url, selected, idx }) => {

          // let style = {};
          // if (selected) {
          //   style = { borderColor: this.props.actionColor };
          // }

          return (<div
            key={handle}
            onClick={() => {
              return false;
            }}
            className={`answer ${selected ? 'selected' : ''}`}
            title={title}
            style={{}}
          >
            { url ? <img style={{}} src={url} /> : <div className="image-upload-slug"><i className="fas fa-image" /></div> }
            { showTitle && <div className="title">{ title }</div> }
          </div>)

        })}</div>
      }) }
      </div>
    </div>);
    }

    if (slide.type === 'dropdown') {
      let dynamicAnswer = false;
      input = (
        <div
          className={`dropdown`}
        >
          <div className="dropdown-container">
            <div className="select-container"><select>
              <option value="-1" selected>{ this.props.placeholder || this.props.translations['dropdown-placeholder'] }</option>
              { this.props.answers.map((answer) => {
                const { title, handle, dynamic } = answer;

                if (dynamic) { dynamicAnswer = answer; return null; }

                return (<option
                  value={handle}
                >
                  {title}
                </option>)
              }) }
            </select></div>
            { dynamicAnswer && <form onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                <div className="or">{ this.props.translations['or'] }</div>
                <input
                  type="text"
                  disabled={true}
                  placeholder={dynamicAnswer.title}
                  value={''}
                  onChange={(e) => {
                  }}
                />
              </form>
             }
          </div>
        </div>
      );      
    }

    if (slide.type === 'country') {
      const countryCodes = getCodes();
      const answers = countryCodes.map((code) => {
        const name = getName(code);
        return { handle: name, title: name }
      });
      input = (
        <div
          className={`dropdown`}
        >
          <div className="dropdown-container">
            <div className="select-container"><select>
              <option value="-1" selected>{ this.props.placeholder || this.props.translations['dropdown-placeholder'] }</option>
              { answers.map((answer) => {
                const { title, handle } = answer;

                return (<option
                  value={handle}
                >
                  {title}
                </option>)
              }) }
            </select></div>
          </div>
        </div>
      )
    }

    if (slide.type === 'range') {
      let answers = [ ...this.props.answers ];

      if (settings.reverseOrder) {
        answers.reverse();
      }

      input = (
      <div
        className={`range-container`}
      >
      <div className="answer-group">
      { answers.map(({ title, handle, votes }) => {
        const selected = false;
        const width = (100 / this.props.answers.length) + '%';
        const fontSize = getRangeFontSize(this.props.answers.length);

        const style = {width: width, fontSize};
        let spanStyle = { paddingTop: getBoxRatio(this.props.answers.length, this.props.maxWidth) };
        if (selected) {
          // spanStyle = { ...spanStyle, background: tinycolor(this.props.actionColor).toString(), borderColor: tinycolor(this.props.actionColor).darken(10).toString() };
        }

        const nextSlide = getNextSlideFromLogic(this.props.slides, slide, title);
        return (<div
          style={style}
          className={`${selected ? 'selected' : ''}`}
          title={title}
          onClick={() => this.onClick(nextSlide)}
        >
          <span style={spanStyle}><span>{title}</span></span>
        </div>)
      }) }
      </div>
      { labels }
      </div>
      );
    }

    if (slide.type === 'star-rating') {
      input = (
      <div
        className={`range-container`}
      >
      <div className="answer-group">
      { this.props.answers.map(({ title, handle, votes }, idx) => {
        const selected = false;
        const width = (100 / this.props.answers.length) + '%';

        const style = {width: width};
        const spanStyle = { paddingTop: getBoxRatio(5, this.props.maxWidth, true) };
        const iStyle = { transform: 'scale(1.20) translateY(-50%)' };
        // if (selected) {
        //   spanStyle = { background: tinycolor(this.props.actionColor).toString(), borderColor: tinycolor(this.props.actionColor).darken(10).toString() };
        // }

        const activeIdx = this.state.hoverIdx;

        if (idx <= activeIdx) {
          if (this.props.displayStyle === 'simple') {
            iStyle.color = "#ffcc00";
            spanStyle.opacity = 1;
            iStyle.transform = 'scale(1.80) translateY(-50%)';
          } else if (this.props.displayStyle === 'emoji') {
            spanStyle.opacity = 1;
            spanStyle.filter = 'grayscale(0)';
          } else {
            spanStyle.backgroundColor = starColors[activeIdx];
            spanStyle.borderColor = tinycolor(starColors[activeIdx]).darken(12);
            iStyle.color = "#ffffff";
            iStyle.transform = 'scale(1.60) translateY(-50%)';
          }
        }

        const nextSlide = getNextSlideFromLogic(this.props.slides, slide, title);
        let star = <i style={iStyle} className={`fas fa-star`} />;
        if (this.props.displayStyle === 'emoji') {
          star = <span>⭐</span>
        }

        return (<div
          key={handle}
          style={style}
          onMouseEnter={(e) => this.onMouseEnter(idx)}
          onMouseLeave={(e) => this.onMouseLeave()}
          onClick={() => this.onClick(nextSlide)}
          title={starAlt[idx]}
          className={`${this.props.displayStyle}-star`}
        >
          <span style={spanStyle}>{ star }</span>
        </div>)
      }) }
      </div>
      { labels }
      </div>
      );
    }

    if (slide.type === 'satisfaction') {
      let answers = [ ...this.props.answers ];

      if (settings.reverseOrder) {
        answers.reverse();
      }

      input = (
      <div
        className={`range-container`}
      >
      <div className="answer-group">
      { answers.map(({ title, handle, votes }, idx) => {
        const selected = false;
        const width = (100 / this.props.answers.length) + '%';

        const style = {width: width};
        let spanStyle = { paddingTop: getBoxRatio(this.props.answers.length, this.props.maxWidth) };

        const nextSlide = getNextSlideFromLogic(this.props.slides, slide, title);

        let icon = <span style={spanStyle}><img src={ icons[handle] } alt={ title } /></span>;
        if (this.props.emojiStyle === 'native') {
          icon = <span style={spanStyle}><span className="emoji">{ emojiIcons[handle] }</span></span>
        }

        return (<div
          key={handle}
          style={style}
          className={`${selected ? 'selected' : ''}`}
          title={alt[handle]}
          onClick={() => this.onClick(nextSlide)}
        >
          { icon }
        </div>)
      }) }
      </div>
      { labels }
    </div>
      );
    }


    const props = this.props;
    const className = `slide ${props.idx === props.currentIdx ? 'active' : ''} ${props.idx <= (props.currentIdx - 1) ? 'prev' : '' } ${props.idx >= (props.currentIdx + 1) ? 'next' : '' } ${handleize(this.props.type)}`;

    let next = null;
    if (props.isLast) {
      next = (<EmbedButton color={this.props.actionColor} onClick={(e) => {
        e.stopPropagation(); e.preventDefault(); this.props.close(); }} className="close">{ this.props.translations['close'] }</EmbedButton>);
      if (props.hideCloseButton) {
        next = null;
      }
    } else {
      next = (<EmbedButton disabled={this.props.isOnly} color={this.props.actionColor} onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.onClick(undefined, true) }} className="next">{ customCTA || this.props.translations['next'] }</EmbedButton>);
    }

    /* Override for slide detail page */
    if (props.buttonType === 'close') {
      next = (<EmbedButton color={this.props.actionColor} onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.props.close(); }} className="close">{ this.props.translations['close'] }</EmbedButton>);
      if (props.hideCloseButton) {
        next = null;
      }
    } else if (props.buttonType === 'next') {
      next = (<EmbedButton disabled={this.props.isOnly} color={this.props.actionColor} onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.onClick(undefined, true) }} className="next">{ customCTA || this.props.translations['next'] }</EmbedButton>);
    }

    if (surveyEndsHere(slide) === true) {
      next = (<EmbedButton color={this.props.actionColor} onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.props.close(); }} className="close">{ this.props.translations['close'] }</EmbedButton>);
    }

    let back = null;
    if (props.idx !== 0) {
      back = (<EmbedBackButton color={this.props.actionColor} onClick={(e) => { e.stopPropagation(); e.preventDefault(); props.prev(); }} className="back">{ this.props.translations['back'] }</EmbedBackButton>);
    }

    return (
      <div className={className}>
        <div className="content">
          <div>
            <div className="embed-card">
              <div className="content-container">
                <div className="copy-container">
                  { this.props['slide-image-0'] ? <img
                    onLoad={() => {
                      $(window).trigger('resize.poll');
                    }}
                    className="content-image" src={this.props['slide-image-0']} /> : null }

                  { title }

                  { this.props.image || this.props['slide-image-1'] ? <img
                    onLoad={() => {
                      $(window).trigger('resize.poll');
                    }}
                    className="content-image" src={this.props['slide-image-1']  || this.props['image']} /> : null }

                  <DumbSlideSubtitle
                    subtitle={this.props.subtitle}
                    fontFamily={this.props.fontFamily}
                    fontFamilies={getFontFamilies(this.props.fontFamily, this.props.customFont)}
                  />
                  <DumbSlideCopy
                    copy={this.props.copy}
                    fontFamily={this.props.fontFamily}
                    fontFamilies={getFontFamilies(this.props.fontFamily, this.props.customFont)}
                  />

                  { this.props['slide-image-2'] ? <img
                    onLoad={() => {
                      $(window).trigger('resize.poll');
                    }}
                    className="content-image" src={this.props['slide-image-2']} /> : null }
                </div>

                { input }

                <DumbSlideDisclaimer
                  disclaimer={this.props.disclaimer}
                  fontFamily={this.props.fontFamily}
                  fontFamilies={getFontFamilies(this.props.fontFamily, this.props.customFont)}
                />

                <div className="actions">
                  { next }
                  { back }
                </div>
              </div>
            </div>
          </div>  
        </div>    
      </div>
    );
  }
}

function prepareColor(color) {
  const brightness = tinyColor(color).getBrightness();
  if (brightness <= 127.5) {
    return color;
  }
  const diff = 50 * (brightness/255);

  return tinyColor(color).darken(diff).toString();
}

function darken(color, amount) {
  const luminance = tinycolor(color).getLuminance();
  if (tinycolor(color).isDark()) {
    let offset = 0;
    if (luminance < .02) {
      offset = 5;
    }
    return tinycolor(color).brighten(amount + offset);
  }
  return tinycolor(color).darken(amount);
}


class Embed extends Component {
  constructor(props) {
    super(props);
    this.state = { hover: false, active: false, rewardBannerHeight: 0 };
    this.contents = React.createRef();
    this.resize = _.throttle(this.resize.bind(this), 300, { leading: true, trailing: true });
    this.uuid = uuid();

    // if (props.delayRender) {
      this.state.noTransition = true;
      setTimeout(() => this.setState({ noTransition: false }), 250);
    // }
  }

  componentDidMount() {
    $(window).on('resize.poll', this.resize);
    this.resize();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState);
  }

  componentDidUpdate(prevProps, prevState) {
    /* dont get in a cycle */
    if (prevState.width !== this.state.width) {
      return;
    }

    setTimeout(() => {
      this.resize();
    }, 10);
    setTimeout(() => {
      this.resize();
    }, 500);

    // if (prevProps.showLink !== this.props.showLink) {
    //   this.resize();
    // }

    // if ((prevProps.active !== this.props.active) && this.props.active) {
    //   this.resize();
    // }

    return null;
  }

  componentWillUnmount() {
    $(window).off('resize.poll', this.resize)
  }

  resize() {
    let $contents = $(`#frame.${this.uuid}`);
    const $frame = $(`#zigpoll-poll-frame.${this.uuid}`);

    const $slide = $contents.find('.slide.active');

    const $cc = $slide.find('.content-container');
    const top = $cc.offset() ? $cc.offset().top : 0;
    const cch = (+this.props.maxHeight || 575) - $slide.find('.actions').outerHeight(true) - top;
    $slide.find('.content-container').removeClass('tall');
    if ($slide.find('.content-container').height() > cch) {
      $slide.find('.content-container').addClass('tall');
    }

    // const ch = $slide.find('.content').outerHeight();
    // $contents.find('.content-bg').height(ch);

    const { showLink } = this.props;

    let h = $slide.outerHeight();
    let fh = h;

    if ($frame.find("#reward-banner")[0]) {
      fh += $frame.find("#reward-banner").innerHeight();

      let oh = $frame.find("#reward-banner").innerHeight();
      if (oh !== this.state.rewardBannerHeight) {
        this.setState({ rewardBannerHeight: oh - 0 });
      }

      $contents.css('margin-top', ($frame.find("#reward-banner").innerHeight() - 0));
    } else {
      $contents.css('margin-top', 0);
    }

    if (showLink) {
      fh += 34;
    }

    $contents.height(Math.floor(h));

    /* If the poll is taller then the window, allow for scrolling */
    $frame.height(fh);
    $("#zigpoll-poll-slug").height(parseInt(fh));

    /* Launcher */
    $contents = $(this.contents.current);

    const state = {}

    let copyWidth = 0;
    if (!$contents.find('h5')[0]) { 
      copyWidth = 0;
    } else {
      copyWidth = $contents.find('h5').outerWidth();
    }

    if ($(window).width() <= 600 ) {
      state.mobile = true;
      copyWidth = 0;
    } else {
      state.mobile = false;
    }

    state.width = getWidth(this.launcherSettings.launcherDisplayType !== 'text-only', copyWidth);
    this.setState(state);
  }

  toggleActive() {
    const align = this.props.isEmbed ? 'modal' : this.props.align;

    if (align !== 'modal' && !this.props.hideLauncher) {
      this.props.toggleActive();
    }
  }

  setCSSVars() {
    let { actionColor, fontColor, pollBackgroundColor, borderRadius, actionFontColor, pollBackgroundImage } = this.props;

    actionColor = actionColor || '#000000';
    pollBackgroundColor = pollBackgroundColor || '#ffffff';
    actionFontColor = actionFontColor || '#ffffff';
    borderRadius = borderRadius === undefined ? '10' : borderRadius;

    document.documentElement.style.setProperty('--zigpoll-action-color', tinycolor(actionColor));
    document.documentElement.style.setProperty('--zigpoll-action-color-rgb', tinycolor(actionColor).toRgbString().replace('rgb(', '').replace(')', ''));
    document.documentElement.style.setProperty('--zigpoll-action-color-darken-10', darken(actionColor, 10).toString());
    document.documentElement.style.setProperty('--zigpoll-action-color-alpha-25', tinycolor(actionColor).setAlpha(.25).toRgbString());
    document.documentElement.style.setProperty('--zigpoll-action-color-darken-5', darken(actionColor, 5).toString());

    document.documentElement.style.setProperty('--zigpoll-action-font-color', tinycolor(actionFontColor).toRgbString());
    document.documentElement.style.setProperty('--zigpoll-action-font-color-rgb', tinycolor(actionFontColor).toRgbString().replace('rgb(', '').replace(')', ''));

    document.documentElement.style.setProperty('--zigpoll-poll-background-color', tinycolor(pollBackgroundColor).toRgbString());
    document.documentElement.style.setProperty('--zigpoll-poll-background-color-darken-2', darken(pollBackgroundColor, 1.5).toRgbString());
    document.documentElement.style.setProperty('--zigpoll-poll-background-color-darken-5', darken(pollBackgroundColor, 5).toRgbString());
    document.documentElement.style.setProperty('--zigpoll-poll-background-color-darken-10', darken(pollBackgroundColor, 10).toRgbString());
    document.documentElement.style.setProperty('--zigpoll-poll-background-color-darken-12', darken(pollBackgroundColor, 12).toRgbString());
    document.documentElement.style.setProperty('--zigpoll-poll-background-color-darken-15', darken(pollBackgroundColor, 15).toRgbString());
    document.documentElement.style.setProperty('--zigpoll-poll-background-color-darken-20', darken(pollBackgroundColor, 30).toRgbString());

    document.documentElement.style.setProperty('--zigpoll-border-radius', borderRadius+'px');
    document.documentElement.style.setProperty('--zigpoll-border-radius-3', (borderRadius/3)+'px');
    document.documentElement.style.setProperty('--zigpoll-border-radius-5', (borderRadius/2)+'px');
    document.documentElement.style.setProperty('--zigpoll-border-radius-8', (borderRadius/1.25)+'px');

    document.documentElement.style.setProperty('--zigpoll-font-color-rgb', tinycolor(fontColor).toRgbString().replace('rgb(', '').replace(')', ''));
    document.documentElement.style.setProperty('--zigpoll-font-color', tinycolor(fontColor).toRgbString());
    document.documentElement.style.setProperty('--zigpoll-font-color-grey', tinycolor(fontColor).setAlpha(.60).toRgbString());
    document.documentElement.style.setProperty('--zigpoll-font-color-grey-darken-10', darken(fontColor, 10).toRgbString());
    document.documentElement.style.setProperty('--zigpoll-font-color-grey-darken-14', darken(fontColor, 14).toRgbString());
    document.documentElement.style.setProperty('--zigpoll-font-color-grey-darken-20', darken(fontColor, 20).toRgbString());

    $("#zigpoll-embed").find('#frame').css('background-image', '');
    if (pollBackgroundImage) {
      $("#zigpoll-embed").find("#frame").css('background-image', `url("${pollBackgroundImage}")`);
    }
  }

  render() {
    const notificationStyle = getNotificationStyles(this.props.notificationColor, this.state.hover);
    const { launcherStyle, iconStyle } = getLauncherStyles(this.props.actionColor, this.state.hover);
    /* Set css vars */
    this.setCSSVars();

    let launcherSettings = this.props.launcherSettings || {};
    this.launcherSettings = launcherSettings['before-submission'] || {};
    if (this.props.pollState === 'after-submission' && launcherSettings['after-submission']) {
      this.launcherSettings = launcherSettings['after-submission'];
    }

    let footer = (
      <div className="footer">
        <a href="https://www.zigpoll.com" rel="noopener noreferrer" target="_blank">Powered by <span>Zigpoll</span></a>
      </div>
    );

    if (this.props.showLink === false) {
      footer = null;
    }

    let count = null;
    if (this.props.showCounter) {
      count = <Count { ...this.props } color={this.props.backgroundColor} />
    }

    /*
    let status = (
      <div
        id="zigpoll-status-frame"
        className={`${this.props.active ? 'active' : ''} ${this.state.hover ? 'hover' : ''} loaded`}
        style={getNotificationPosition(this.props.align, this.props.position, this.props.active ? 60 : this.state.width )}
      >
        <div
          id="status"
          onMouseEnter={() => { this.setState({ hover: true }) }}
          onMouseLeave={() => { this.setState({ hover: false }) }}
          onClick={this.toggleActive.bind(this)}
          style={notificationStyle}
        ><i className="fas fa-bell" /></div>
      </div>
    );

    if (this.props.hideStatus) {
      status = null;
    }
    */

    let iconKey = 'fa-poll'
    if (this.launcherSettings.launcherIcon) {
      iconKey = this.launcherSettings.launcherIcon;
    }

    let icon = (<span className={`fas ${iconKey} ${ this.launcherSettings.launcherDisplayType === 'text-only' && !this.state.mobile ? 'hidden' : ''}`} style={iconStyle} />);

    if (this.launcherSettings.launcherImage) {
      icon = <span className={`${ this.launcherSettings.launcherDisplayType === 'text-only' && !this.state.mobile ? 'hidden' : ''}`} style={iconStyle}><img src={`${this.launcherSettings.launcherImage}`} /></span>
    }

    let copy = null;
    if (this.launcherSettings.launcherText) {
     copy = <h5 style={iconStyle}>{ this.launcherSettings.launcherText }</h5>;
    }

    if (this.launcherSettings.launcherDisplayType === 'icon-only') {
      copy = null;
    }

    let zoom = 1;
    let launcherZoom = 1;

    if (this.props.scalePoll) {
      zoom = this.props.fontSize / 16;
      if (this.props.launcherFontSize) {
        launcherZoom = this.props.launcherFontSize / 16;
      }
    }

    // let headerStyle = { borderColor: this.props.backgroundColor };
    // if (this.props.backgroundImage) {
    //   headerStyle.backgroundImage = `url(${this.props.backgroundImage})`;
    //   headerStyle.backgroundSize = '100% auto';
    // }

    const hideLauncher = this.props.isEmbed ? true : this.props.hideLauncher;
    const align = this.props.isEmbed ? 'modal' : this.props.align;

    let pollStyle = getPollPosition(this.props.active, align, [20,20], this.props.shape, hideLauncher, this.props.launcherFontSize);
    pollStyle.borderColor = this.props.backgroundColor;
    pollStyle.backgroundColor = this.props.pollBackgroundColor;
    pollStyle.borderRadius = this.props.borderRadius;
    pollStyle.borderWidth = this.props.borderWidth;
    pollStyle.maxWidth = parseInt(this.props.maxWidth);

    if (pollStyle.maxWidth > 460) {
      pollStyle.maxWidth = 460;
    }

    let marginTop = 0;
    if (align.indexOf('-middle') !== -1) {
      if (this.props.active) {
        marginTop = -30;
      } else {
        if (this.state.width) {
          const w = this.state.width / 2;
          marginTop = -w;
        }
      }
    }

    let slides = this.props.slides;
    if (!this.props.singleSlidePage && !this.props.showHidden) {
      slides = slides.filter((slide) => { 
        if (slide.settings) {
          return !slide.settings.hidden;
        }
        return true;
      });
    }

    let rewardBanner;
    if (this.props.rewardBanner) {
      rewardBanner = <div id="reward-banner"
        className={this.props.textDirection ? this.props.textDirection : ''}
        dangerouslySetInnerHTML={{ __html : this.props.rewardBanner }}
        // style={{ backgroundColor: this.props.backgroundColor }}
      />;
    }
    slides.forEach((slide) => {
      if (slide.rewardBanner && slide.type === 'reward') {
        rewardBanner = <div id="reward-banner"
          className={this.props.textDirection ? this.props.textDirection : ''}
          dangerouslySetInnerHTML={{ __html : slide.rewardBanner }}
          // style={{ backgroundColor: this.props.backgroundColor }}
        />;
      }
    });

    const currentSlide = this.props.slides[this.props.currentIdx || 0] || {};
    let customRewardBanner = currentSlide.customRewardBanner;

    if (customRewardBanner) {
      rewardBanner = <div id="reward-banner"
          className={this.props.textDirection ? this.props.textDirection : ''}
          dangerouslySetInnerHTML={{ __html : customRewardBanner }}
        />;
    }

    let overlayColor = tinycolor(this.backgroundColor).darken(20).setAlpha(.05).toHex8String();
    if (this.props.overlayColor) {
      overlayColor = tinycolor(this.props.overlayColor);
    }

    let overlayImage = undefined;
    let overlayImageStyles = {};

    if (this.props.overlayImage) {
      overlayImageStyles = {
        backgroundImage: `url(${this.props.overlayImage})`,
        backgroundSize: 'cover'
      }
    }

    let modalLogoImage = null;
    if (this.props.modalLogoImage) {
      modalLogoImage = <img
        src={this.props.modalLogoImage}
        style={{
          height: 40,
          position: 'absolute',
          top: 0,
          left: '50%',
          marginTop: -8,
          transform: 'translateY(-100%) translateX(-50%)'
        }}
      />;
    }

    let modalSideImage = null;
    let modalSideImagePosition = this.props.modalSideImagePosition;
    let containerStyle = {};

    if (this.props.modalSideImage && this.props.align === 'modal') {
      containerStyle = { 
        height: `${440 - (this.state.rewardBannerHeight)}px`,
      };

      modalSideImage = (
        <div 
          className="side-image"
          style={{ 
            height: `calc(100% - ${this.state.rewardBannerHeight}px)`,
            backgroundImage: `url(${this.props.modalSideImage})`,
          }} />
      );

      pollStyle.maxWidth = pollStyle.maxWidth + 410;
      zoom = zoom * .65;
    }

    return (
      <div
        id="zigpoll-embed"
        className={`zigpoll-${align} ${this.props.active ? 'zigpoll-active' : ''} loaded widget-container ${this.state.noTransition ? 'no-transition' : ''}`}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
        {/* status */}
          <div
            id="zigpoll-launcher-frame"
            className={`zigpoll-loaded zigpoll-${this.props.shape || 'circle'} zigpoll-${align}`}
            style={{ ...getLauncherPosition(align, [20,20], this.props.shape), width: this.props.active ? 40 : this.state.width, marginTop, display: hideLauncher || align === 'modal' ? 'none' : 'block', ...getBorderRadius(this.props.shape, this.props.launcherBorderRadius), zoom: launcherZoom }}
          >
            <div
              id="launcher"
              className={`loaded ${this.props.active ? 'active' : ''} ${this.state.hover ? 'hover' : ''} ${this.props.shape || 'circle' } ${align}`}
              onMouseEnter={() => { this.setState({ hover: true }) }}
              onMouseLeave={() => { this.setState({ hover: false }) }}
              onClick={this.toggleActive.bind(this)}
              style={{ ...launcherStyle, ...getBorderRadius(this.props.shape, this.props.launcherBorderRadius) }}
              ref={this.contents}
            >
              { copy }
              { icon }
            </div>
          </div>

          <div
            id="zigpoll-poll-frame"
            className={`${this.props.active ? 'zigpoll-active' : ''} loaded zigpoll-${align} ${this.uuid} ${this.props.textDirection ? this.props.textDirection : ''} ${this.props.createPoll || this.props.isEmbed || this.props.pagePreview ? '' : 'normal-view' }`}
            style={{ ...pollStyle, zoom }}
          >

          <div className="frame-content">
            <div className={`frame-container ${this.props.showLink ? 'has-footer' : ''} ${modalSideImage ? 'has-modal-side-image' : ''} ${modalSideImagePosition === 'right' ? 'right-side-image' : 'left-side-image'} ${this.props.textDirection ? this.props.textDirection : ''}`}>
              { rewardBanner }
              { modalSideImage }

              <div className="frame-content-container" style={containerStyle}>

              <div id="frame" class={`${this.uuid} ${this.props.textDirection ? this.props.textDirection : ''}`}>
                  { slides.map((slide, idx) => 
                    <Slide 
                      {...slide}
                      slides={slides}
                      translations={this.props.translations}
                      key={idx}
                      close={this.toggleActive.bind(this)}
                      goto={this.props.goto}
                      next={this.props.next}
                      prev={this.props.prev}
                      idx={idx}
                      maxWidth={pollStyle.maxWidth}
                      currentIdx={this.props.currentIdx || 0}
                      isLast={idx === slides.length - 1}
                      isOnly={slides.length === 1}
                      backgroundColor={this.props.backgroundColor}
                      pollBackgroundColor={this.props.pollBackgroundColor}
                      pollBackgroundImage={this.props.pollBackgroundImage}
                      fontColor={this.props.fontColor}
                      actionColor={this.props.actionColor}
                      fontFamily={this.props.fontFamily || 'sans'}
                      customFont={this.props.customFont || 'sans'}
                      hideCloseButton={this.props.hideCloseButton}
                    />
                  ) }

                  { !this.props.hideXButton && <button
                    className="mobile-close"
                    style={{ color: prepareColor(this.props.backgroundColor) }}
                    onClick={ this.props.close }
                  ><i className="fa fa-times" /></button> }
                </div>
        
                </div>
                { footer }
              </div>
            </div>
          </div>
          { (this.props.align === 'modal') && <div 
            id="zigpoll-modal-components-frame"
            className={`${this.props.active ? 'zigpoll-active' : ''} ${this.props.loaded ? 'zigpoll-loaded' : ''}`}
          >
            <div>
              <div id="zigpoll-poll-slug" style={{ ...pollStyle, zoom }} class={`${this.props.active ? 'zigpoll-active' : ''} ${this.props.loaded ? 'zigpoll-loaded' : ''}`}>
                {modalLogoImage}
              </div>
              <div
                id="modal-components-container"
                className={`${this.props.active ? 'active' : ''}`}
              >
                <div className="overlay" style={{
                  backgroundColor: overlayColor,
                  ...overlayImageStyles
                }} />
              </div>
            </div>
          </div> }
        </div>
      </div>
    );
  }
}

const defaultTranslations = {
  "next": "Next",
  "back": "Back",
  "submit": "Submit",
  "reset": "Edit",
  "skip": "Skip",
  "thanks": "Thanks!",
  "close": "Close",
  "refresh": "Refresh",
  "load-more": "Load More",
  "optional": "Optional",
  "answer": "Answer",
  "or": "or",
  "dropdown-placeholder": "Please select one",
  "top-responses": "Top Responses",
  "no-responses": "No Responses have been approved yet. Check back later.",
  "copy-to-clipboard": "Copied to clipboard",
  "empty-error-message":"This cannot be left blank.",
  "invalid-date-message":"Please enter a valid date.",
  "email-error-message":"Please enter a valid email.",
  "phone-error-message":"Please enter a valid phone number.",
  "modal-exit-confirmation": "You haven't completed the survey are you sure you want to exit?",
  "month": "Month",
  "day": "Day",
  "year": "Year",
  "file-upload": "Click here to upload your file(s)"
}

function cleanTranslation(translation = {}) {
  Object.keys(translation).forEach((key) => {
    if (!translation[key]) {
      delete translation[key];
    }
  });

  return translation;
}

function mergeTranslations(translationA = {}, translationB = {}) {
  translationA = cleanTranslation(translationA);
  translationB = cleanTranslation(translationB);
  return { ...translationA, ...translationB };
}

export function getTranslations(account, poll) {
  let languageSettings = {};
  if (poll && poll.languageSettings) {
    if (poll.languageSettings.default) {
      /* Using new translation settings */
      const defaults = { ...defaultTranslations, ...poll.languageSettings.default };
      const languageOverrides = {};

      if (!window.Zigpoll.languages) {
        const languages = Object.keys(poll.languageSettings);
        window.Zigpoll.languages = languages;
        // window.Zigpoll.selectedLanguage = getBrowserLanguage();
      }

      /* If it's an empty string, dont show it... */
      return mergeTranslations(defaults, languageOverrides);
    }
  }

  if (account && account.languageSettings) {
    languageSettings = account.languageSettings;
  }
  if (poll && poll.languageSettings) {
    languageSettings = poll.languageSettings;
  }

  const translations = mergeTranslations(defaultTranslations, languageSettings);
  return translations;
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || [];
  const account = accounts[accountId];

  const pollId = decode(ownProps.match.params.pollId);
  const polls = state.polls || [];
  let poll = polls[pollId];

  let displaySettings = {};
  if (account) {
    displaySettings = account.displaySettings;
  }

  if (poll && poll.displaySettings) {
    displaySettings = poll.displaySettings;
  }

  if (!poll && ownProps.languageSettings) {
    poll = { languageSettings: ownProps.languageSettings };
  }

  const translations = ownProps.translations || getTranslations(account, poll);

  return {
    translations,
    showLink: false
  }
}

export default withRouter(connect(mapStateToProps)(Embed));

