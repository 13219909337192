import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { url } from '../settings';

import _ from 'lodash';

import * as AccountActions from '../actions/Accounts';
import * as FlashNotificationActions from '../actions/FlashNotifications';

import ColumnLayout from '../components/ColumnLayout';
import ColumnLoading from './ColumnLoading';

import SectionHeader from '../components/SectionHeader';
import AuthForm from '../components/AuthForm';
import LoadingIndicator from '../components/LoadingIndicator';

import { decode } from '../utils';

import HeaderButtons from '../components/HeaderButtons';
import { AuthSubmitButton } from '../components/Buttons';

import Toggle from '../components/Toggle';
import Tooltip from '../components/Tooltip';
import DateInput from '../components/DateInput';
import { BasicRecipientsInput} from '../components/SimpleInputs';

import klaviyoLogo from '../images/klaviyoLogo.jpg';
import mailchimpLogo from '../images/mailchimpLogo.png';
import getResponseLogo from '../images/getResponseLogo.png';
import gorgiasLogo from '../images/gorgiasLogo.png';
import gaLogo from '../images/gaLogo.svg';
import activeCampaignLogo from '../images/activeCampaignLogo.png';
import slackLogo from '../images/slackLogo.png';

import copy from 'copy-to-clipboard';

function isAllowed (plan) {
  const reject = ['lite', 'basic'];
  if (plan.allowAutomations) { return true; }
  if (reject.indexOf(plan.key) !== -1) { return false; }
  return true;
}

class AutomationInputRow extends Component {
  render() {
    const polls = this.props.account.polls || [];

    let googleSheetsExport = null;
    if (this.props.account.integrationSettings && this.props.account.integrationSettings['googleSheets'] && this.props.account.integrationSettings['googleSheets'].scope) {
      googleSheetsExport = <option value="export-to-google-sheets">Export CSV to Sheets</option>
    }

    return (
    <div className="card automation-form" style={{ marginBottom: 25 }}>
      <div className="remove" onClick={() => this.props.remove(this.props.idx)}></div>
      <div className="row">
        <div className="cell">
          <div className="label"><span>1</span><div>What should we automate?</div></div>
          <div className="select-wrapper small"><select value={this.props.action || 'export-csv'} onChange={(e) => {
            this.props.updateSetting('action', e.target.value, this.props.idx);
          }}>
            <option value="export-csv">Export CSV</option>
            { googleSheetsExport }
            <option value="post-json">POST JSON to Endpoint</option>
          </select></div>
          <div className="prefix">for</div>
          <div className="select-wrapper small"><select value={this.props.params ? this.props.params.pollId : undefined} style={{ maxWidth: 165 }} onChange={(e) => {
            this.props.updateSetting('params.pollId', e.target.value, this.props.idx);
          }}>
            <option value="">All Surveys</option>
            { polls.map(poll =>
              <option value={poll._id}>{ poll.title }</option>
            )}
          </select></div>
        </div>
        <div className="cell">
          <div className="prefix">including results from the last</div>
          <div className="inline-input">
            <input 
              type="text"
              value={ this.props.params ? this.props.params.daysToExport : undefined }
              name="daysToExport"
              onChange={(e) => {
                this.props.updateSetting('params.daysToExport', e.target.value, this.props.idx);
              }}
            />
          </div>
          <div className="suffix">days</div>
        </div>
      </div>
      <div className="row" style={{marginTop: 20}}>
        <div className="cell">
          <div className="label"><span>2</span><div>When should we run it?</div></div>
          <div className="prefix">Every</div>
          <div className="inline-input">
            <input 
              type="text"
              value={this.props.schedule ? this.props.schedule.frequency : undefined}
              onChange={(e) => {
                this.props.updateSetting('schedule.frequency', e.target.value, this.props.idx)
              }}
            />
          </div>
          <div className="suffix">days at</div>
          <div className="select-wrapper small"><select value={this.props.schedule ? this.props.schedule.time : undefined} onChange={(e) => {

            this.props.updateSetting('schedule.time', e.target.value, this.props.idx)
          }}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select></div>
          <div className="select-wrapper small"><select value={this.props.schedule ? this.props.schedule.period : undefined} onChange={(e) => {

            this.props.updateSetting('schedule.period', e.target.value, this.props.idx)
          }}>
            <option value="am">AM</option>
            <option value="pm">PM</option>
          </select></div>
          <div className="suffix"></div>
        </div>
        <div className="cell">
          <div className="prefix">starting on</div>
          <div className="date-input">
            <DateInput
              onChange={(date) => {
                console.log('date changed');
                console.log(date);
                this.props.updateSetting('schedule.startDate', date, this.props.idx);
              }}
              value={this.props.schedule ? new Date(this.props.schedule.startDate) : undefined}
            />
          </div>
        </div>
      </div>
      { this.props.action !== 'post-json' && <div className="row" style={{marginTop: 20}}>
        <div className="cell" style={{ width: 320 }}>
          <div className="label"><span>3</span><div>Who should we { this.props.action === 'export-csv' ? 'send it to?' : 'notify?' }</div></div>
          <BasicRecipientsInput
            onChange={(e) => {
              this.props.updateSetting('params.recipients', e.target.value, this.props.idx)
            }}
            onValidate={() => {}}
            label="Automation Recipients"
            placeholder="email@domain.com"
            name="recipients"
            value={this.props.params ? this.props.params.recipients : [""]}
            toggleCopy="Send to the account owner only."
            errorMessage="Please enter a valid domain name."
            hideLabel={true}
          />
        </div>
      </div> }
      { this.props.action === 'post-json' && <div className="row" style={{marginTop: 20}}>
        <div className="cell" style={{ width: 320 }}>
          <div className="label"><span>3</span><div>Where should we POST it?</div></div>
          <div className="input">
            <input 
              type="text"
              value={ this.props.params ? this.props.params.endpoint : undefined }
              placeholder={'https://api.yourwebsite.com/csv'}
              name="endpoint"
              onChange={(e) => {
                this.props.updateSetting('params.endpoint', e.target.value, this.props.idx);
              }}
            />
          </div>
        </div>
      </div> } 
    </div>);
  }
}

const defaultAutomationSettings = {
  schedule: {
    startDate: new Date(),
    time: 1,
    period: 'am',
    frequency: 7
  },
  action: 'export-csv',
  params: {
    daysToExport: 7,
    timezoneOffset: new Date().getTimezoneOffset()
  },
}

class AutomationSettingsForm extends AuthForm {
  constructor(props) {
    super(props);

    let automationSettings = this.props.account.automationSettings || [];

    this.state = {
      inputs: { automationSettings },
    }
    this.setInitialState();
  }

  validate() {
    const automationSettings = this.state.inputs.automationSettings;
    let isValid = true;

    automationSettings.forEach((automationSetting) => {
      if (!isValid) { return; }

      const { schedule, params } = automationSetting;

      if (schedule) {
        const { frequency } = schedule;
        if (!frequency || frequency <= 0) {
          this.props.flash('Please enter a number >= 1 for frequency');
          isValid = false;
          return false;
        }
      }
      if (params) {
        const { daysToExport } = params;
        if (!daysToExport || daysToExport <= 0) {
          this.props.flash('Please enter a number >= 1 for days');
          isValid = false;
          return false;
        }
      }
    })

    return isValid;
  }

  onSubmit(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!this.validate()) { return; }

    // if (!isAllowed(this.props.account.plan)) {
    //   this.props.flash('Upgrade to a standard plan or higher to use automations.');
    //   return;
    // }

    const account = {};
    account._id = this.props.account._id;
    account.automationSettings = this.state.inputs.automationSettings;

    this.props.updateAccount(account, "Automation settings have been updated.");
  }

  onChange(e) {
    this.updateSetting(e.target.name, e.target.value);
  }

  pushAutomation(value) {
    const inputs = this.state.inputs;
    const automationSettings = [ ...inputs.automationSettings ];
    automationSettings.push(_.cloneDeep(value));
    inputs.automationSettings = automationSettings;;

    this.setState({ inputs });
  }

  removeAutomation(idx) {
    const inputs = this.state.inputs;
    const automationSettings = [ ...inputs.automationSettings ];
    _.pullAt(automationSettings, idx);
    inputs.automationSettings = automationSettings;;

    this.setState({ inputs });    
  }

  updateSetting(key, value, idx) {
    const inputs = this.state.inputs;
    const automationSettings = [ ...inputs.automationSettings ];

    _.set(automationSettings[idx], key, value);

    if (!value) {
      _.unset(automationSettings[idx], key, value);
    }

    inputs.automationSettings = automationSettings;;
    this.setState({ inputs });
  }

  renderInputs() {
    return (
      <span>
        { this.state.inputs.automationSettings.map((automation, idx) => {
          return <AutomationInputRow remove={this.removeAutomation.bind(this)} idx={idx} updateSetting={this.updateSetting.bind(this)} account={this.props.account} {...automation} />
        }) }

        { this.state.inputs.automationSettings.length === 0 ? 
          <div className="no-automations empty-object-list" style={{ marginBottom: 25 }}>
            <h3>No automations configured.</h3>
            <p>Click Add Automation to create one.</p>
          </div>
        : null }

        <div className="wrapper">
          <div
            className="button create-additional"
            style={{ margin: "0 auto 25px" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.pushAutomation({ ...defaultAutomationSettings });
            }}
          >Add Automation</div>
        </div>
      </span>
    );
  }

  renderActions() {
    return (
      <div className="actions card">
        <AuthSubmitButton
          title="Save Changes" 
          disabled={!this.hasChanged()}
        />

        <div className="login">
          <div>Need help with automations?</div>
          <a href={`https://docs.zigpoll.com/automations`} target="_blank" rel="noopener noreferrer">Learn more</a>
        </div>

        <HeaderButtons>
          <button 
            disabled={!this.hasChanged()}
            onClick={this.onSubmit.bind(this)}>Save Changes</button>
        </HeaderButtons>
      </div>
    )
  }

  render() {
    return (
      <form onSubmit={this.onSubmit.bind(this)} className={`${this.state.valid === false ? 'invalid' : ''} ${this.state.loading || this.props.loading ? 'loading' : ''} ${this.props.className}`}>
          { this.renderInputs() }
          { this.renderActions() }
      </form>
    );
  }
}

class AutomationSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    if (!this.props.account && this.props.accountId) {
      this.props.fetchAccount(this.props.accountId);
    }
  }

  render() {
    if (!this.props.account) {
      return <ColumnLoading />
    }

    return (
      <ColumnLayout title="Automations" graphics={true}>

        <SectionHeader
          title=<span><i className="fas fa-arrows-spin" />Automations</span>
          subtitle="Set up jobs to automate routine tasks."
          className="no-margin"
        />

        <AutomationSettingsForm
          account={this.props.account}
          polls={this.props.polls}
          updateAccount={this.props.updateAccount}
          flash={this.props.flash}
          loading={this.props.loading}
        />

        <LoadingIndicator loading={this.props.accounts.loading} />

      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);
  const slideId = decode(ownProps.match.params.slideId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];
  const polls = state.polls || [];

  return {
    accountId,
    pollId,
    polls,
    slideId,
    accounts,
    account,
    loading: accounts.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...FlashNotificationActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AutomationSettings);
