import React, { Component } from 'react';
import { decode, encode, getTab, capitalize, getParams, generatePageDisplaySettings, buildShareLink } from '../utils';

export default class SurveyPreviewHeader extends Component {
  render() {
    let previewLink = null;
    let shareLink = null;

    let params = '';
    if (this.props.selectedLanguageCode) {
      params = `?lang=${this.props.selectedLanguageCode}`;
    }

    if (this.props.poll && this.props.poll._id) {
      previewLink = (<span><a target='_blank' rel="noopener noreferrer" title="Preview your survey" className="poll-preview" href={`/preview/${this.props.account._id}/${this.props.poll._id.toString()}${params}`}>Click here</a> for a full preview.</span>);

      if (this.props.poll.settings && this.props.poll.settings.apiOnly && this.props.poll.isVisible) {
        shareLink = (<span><a target='_blank' rel="noopener noreferrer" title="View your survey" className="poll-preview" href={buildShareLink(this.props.account, this.props.poll)}>Click here</a> to view it live.</span>);
      }
    }

    return (
      <div className="section-header small no-margin">
        <div className="top">
          <div className="section-subtitle"><i className="fas fa-magnifying-glass" />Survey Preview</div>
        </div>
        <div className="section-description">{previewLink || 'Survey representation below.'} {shareLink}</div>
      </div>
    );
  }
}