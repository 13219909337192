import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import Breadcrumbs from '../components/Breadcrumbs';
import SingleFormPageHeader from '../components/SingleFormPageHeader';
import SlideInfo from '../components/SlideInfo';
import Pagination from '../components/Pagination';
import Participant from '../components/Participant';
import HeaderButtons from '../components/HeaderButtons';
import SectionHeader from '../components/SectionHeader';

import Toggle from '../components/Toggle';
import Tooltip from '../components/Tooltip';
import ExportCsvModal from '../components/ExportCsvModal'

import * as ParticipantsActions from '../actions/Participants';

import { decode, encode, getDate, renderParticipantId } from '../utils';

class Slide extends Component {
  render() {
    const slide = this.props;

    return (<div className='poll-card slide-dashboard'>
      <div>
        <div className="poll-content">
          <SlideInfo slide={slide} diffs={this.props.diffs} hideLinks={true} />
        </div>
      </div>
    </div>)
  }
}

class Participants extends Component {
  constructor(props) {
    super(props);

    this.state = { handle: '', selectedHandle: '', showExportModal: false };
    props.fetchSlideAndParticipants();
  }

  export() {
    // this.props.exportSlideParticipants();
    this.setState({ showExportModal: true });
  }

  updateFilter() {
    this.setState({ handle: this.state.selectedHandle });
    this.props.fetchParticipantsForSlide(0, this.state.selectedHandle);
  }

  render() {
    if (!this.props.participants || !this.props.slide) {
      return <ColumnLoading />
    }

    let results = null;
    let answers = this.props.slide.answers || [];

    if (this.props.participants.data.length) {
      let filterForm = null;
      let appliedFilter = null;

      if (this.state.showFilters) {
        appliedFilter = (<div className="applied-filters">Showing all participants</div>);
        filterForm = (
        <form onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          this.updateFilter();
        }}>
          <div>
            <strong>Filter by people who answered:</strong>
            <div className="select-wrapper" style={{ display: 'block' }}><select
              style={{ width: '100%' }}
              onChange={(e) => {
                this.setState({ selectedHandle: e.target.value });
              }}
            >{ answers.map(({ title, handle }) => <option value={handle}>{title}</option>)}</select></div>
            <button type="submit">Filter</button>
          </div>
        </form>
        );
      }

      if (this.state.handle) {
        let answer = null;

        answers.forEach(({ title, handle }) => {
          if (handle === this.state.handle) {
            answer = title;
          }
        });

        appliedFilter = (
          <div className="applied-filters">Showing participants who answered: <strong>"{answer}"</strong></div>
        )
      }

      let filters = (
      <div>
        <div className="filters card" style={{ marginBottom: 25 }}>
          <label>Filters<Tooltip>Use this if you want see who responded to a particular answer.</Tooltip></label>

          <div className="inline-toggle">
            <Toggle 
              active={this.state.showFilters}
              onChange={(value) => {
                if (!value && this.state.handle) {
                  this.setState({ selectedHandle: '', handle: '' }, () => this.updateFilter())
                }
                if (value) {
                  this.setState({ selectedHandle: answers[0] ? answers[0].handle : '' });
                }
                this.setState({ showFilters: value })
              }}
            />
            <div className="subtle">Toggle filters on and off.</div>
          </div>

          { filterForm }
        </div>
        { appliedFilter }
      </div>
      )

      if (['email-capture', 'copy', 'short-answer', 'long-answer', 'date', 'file-upload'].indexOf(this.props.slide.type) !== -1) {
        filters = null;
      }

      results = (<span>
        <div className="responses-header" style={{ marginBottom: 15 }}>
          <button
            className="download-csv"
            onClick={this.export.bind(this)}
          >Download CSV</button>
        
          <Pagination
            showPagination={this.props.participants.pages}
            showNext={this.props.participants.page !== this.props.participants.pages}
            showPrev={this.props.participants.page !== 0}
            next={() => { this.props.fetchParticipantsForSlide(this.props.participants.page + 1, this.state.handle); }}
            prev={() => { this.props.fetchParticipantsForSlide(this.props.participants.page - 1, this.state.handle); }}
            curPage={this.props.participants.page + 1 }
            pageLength={this.props.participants.pages + 1}
          />
        </div>

        { filters }

        <div className={`card object-list emails ${this.props.loading ? 'loading' : ''}`}>
          {this.props.participants.data.map((p) => <Participant url={`/participants/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props.slideId)}/pa/${encode(p._id)}`} accountId={this.props.accountId} {...p} />)}
        </div>
      </span>);
    } else {
      results = (
        <div className="card empty-object-list emails">
          <h3>No Participants Yet</h3>
          <p>A list will show up here once someone has interacted with this slide.</p>
        </div>
      );
    }

    return (
      <ColumnLayout
        title={`Slide → Participants`}
        className="participants"
        graphics={true}
//        breadcrumbs={<Breadcrumbs currentPageTitle="Participants" includeDashboard={true} includeTail={true} />}
      >

        {/*
        <Slide {...this.props.slide} diffs={this.props.diffs} />
        */}
        {/* 
        <div className="heading below">
          <h5>Slide Participants</h5>
        </div>
        */}

        <SectionHeader
          title=<span><i className="fas fa-users" />Slide Participants</span>
          subtitle="Everyone who responded to this slide."
          className="no-margin"
        />

        { results }

        <div className="card info wide center" style={{ marginTop: 20 }}>
          <div className="top">Want to view all participants?</div>
          <p>For more data, <Link to={`/participants/a/${encode(this.props.accountId)}`}>View All Participants</Link> or <Link to={`/reports/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}`}>Generate Some Reports</Link>.</p>
        </div>

        <HeaderButtons>
          <button
            onClick={this.export.bind(this)}
            disabled={this.props.participants.data.length ? false : true}
          >Download CSV</button>
        </HeaderButtons>

        <ExportCsvModal
          show={this.state.showExportModal}
          onCancel={() => this.setState({ showExportModal: false })}
          onConfirm={({ email, responsePollId, dateRange, type }) => {
            this.props.exportSlideParticipants({ email, responsePollId, dateRange, type });
          }}
          pollId={this.props.pollId}
          slideId={this.props.slideId}
          title={'Export Slide Participants'}
          subtitle={'This will export all participants who have interacted with this slide.'}
          copy={'The CSV will be sent to the email listed below. It can take up to 1 hour to recieve a CSV export. The duration will depend on the amount of data your account has accumulated.'}
          email={this.props.user.email}
        />

      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);
  const participantId = decode(ownProps.match.params.slideId);
  const slideId = decode(ownProps.match.params.slideId);

  const allParticipants = state.participants || [];
  const participants = allParticipants[participantId];

  const slide = state.slides[slideId];

  return {
    user: state.user,
    accountId,
    pollId,
    participants,
    slideId,
    slide,
    diffs: state.diffs,
    loading: state.participants.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ParticipantsActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Participants));
