import React, { Component } from 'react';
import { connect } from 'react-redux';

import { decode, truncate } from '../utils';
import { Link, withRouter } from 'react-router-dom';

const titles = {
  a: 'Account',
  p: 'Survey',
  s: 'Slide',
  pa: 'Participants'
}

class Breadcrumbs extends Component {
  render() {
    const crumbs = [];
    const pathname = this.props.location.pathname
      .replace('/dashboard', '')
      .replace('/responses', '')
      .replace('/emails', '')
      .replace('/manage', '')
      .replace('/participants', '')
      .replace('/reports', '')
      .replace('/integrations', '')
      .replace('/automations', '')
      .replace('/insights', '')
      .replace('/campaigns', '')
      .replace('/charts', '')
      .replace('/settings', '')
      .replace('/activity', '')
      .replace('/api', '')
      .replace('/affiliate', '')
      .replace('/preview', '')
      .replace('/checkout', '')

    let path = pathname.split('/').slice(1, -2);
    // if (this.props.includeTail) {
      path = pathname.split('/').slice(1);
    // }

    // if (this.props.includeDashboard) {
    //   let uri = `/dashboard/a/${this.props.match.params.accountId}`;
    //   let title = "Dashboard";
    //   crumbs.push(<li key={uri}><Link to={uri} title={title}>{title}</Link></li>)
    // }

    for (let i = 0; i < path.length; i+=2) {
      let uri = pathname.split('/').slice(0, i + 3).join('/');
      let title = titles[path[i]];

      if (i+5 > path.length) {
        if (path[i] === 'a' && this.props.account.title) {
          // title += `: ${truncate(this.props.account.title, 50)}`;
          uri += '?tab=general';
        }
        if (path[i] === 'p' && this.props.poll.title) {
          title += `: ${truncate(this.props.poll.title, 40)}`;
          uri += '?tab=general';
        }
        if (path[i] === 's' && this.props.slide.handle) {
          title += `: ${truncate(this.props.slide.handle, 40)}`;
        }
      }

      if (uri.indexOf('pa') !== -1) {
        uri = `/participants${uri.split('/pa').shift()}`;
      }

      crumbs.push(<li key={uri}><Link
        to={uri}
        title={title}
        onClick={(e) => {
          if (uri === (window.location.pathname + window.location.search)) {
            e.preventDefault();
            e.stopPropagation();
            return;
          }
        }}
      >{title}</Link></li>);

      /* Hack for more sensible breadcrumbs on slide leaf */
      // if (title === 'Slide') {
      //   if (this.props.currentPageTitle === 'Slide Details' || this.props.currentPageTitle === 'Create Slide') {
      //     crumbs.pop();
      //   }
      // }
    }


    let tail = null;
    // if (crumbs.length > 1) {
    //   let currentType = titles[this.props.location.pathname.split('/').slice(-2)[0]];
    //   tail = (<li>{currentType} {this.props.currentPageTitle}</li>);
    // } else {
      tail = (<li>{this.props.currentPageTitle.split('→').pop()}</li>);      
    // }

    if (this.props.currentPageTitle === 'Loading') {
      tail = null;
    }

    let prefix = null;
    if (this.props.prefix) {
      prefix = (<li>{this.props.prefix}</li>);      
    }

    return (
      <div id="breadcrumbs">
        <div className="wrapper">
          <ul className="crumbs">
            { crumbs.map(c => c) }
            { prefix }
            { tail }
          </ul>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);
  const slideId = decode(ownProps.match.params.slideId);

  const accounts = state.accounts || {};
  const account = accounts[accountId] || {};

  const polls = state.polls || [];
  const poll = polls[pollId] || {};

  const slides = state.slides || [];
  const slide = slides[slideId] || {};

  return {
    account,
    poll,
    slide,
  }
}

export default withRouter(connect(mapStateToProps)(Breadcrumbs));