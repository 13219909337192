import React, { useCallback, useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import { url } from '../settings';
import { post } from '../ajax';
import { parseUrl } from '../utils';

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test secret API key.
const { stripeApiKey } = require('../settings');
const stripePromise = loadStripe(stripeApiKey);

export const CheckoutForm = (props) => {
  const fetchClientSecret = useCallback(() => {
    if (!props.user) { return; }
    const [ accountId ] = parseUrl(window);

    // Create a Checkout Session
    return post(`${url}/create-checkout-session`, { _id: props.user._id, accountId, planId: props.planId })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, [props.user]);

  const options = { fetchClientSecret };
  return (
    <div id="stripe-checkout" style={{ background: "#ffffff", padding: '50px 0' }}>
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}

export const Return = (props) => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    post(`${url}/session-status?session_id=${sessionId}`, { _id: props.user._id })
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
      });
  }, []);

  if (status) {
    if (status !== 'complete') {
      props.navigateTo('/checkout');
    } else {
      props.navigateTo('/dashboard');
    }
  }

  return null;
}