import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import qs from 'query-string';
import _ from 'lodash';

import Dropdown from './Dropdown';
import AccountsDropdown from './AccountsDropdown';
import PollsDropdown from './PollsDropdown';
import SlidesDropdown from './SlidesDropdown';
import Tooltip from './Tooltip';

import MonthlyCounter from '../components/MonthlyCounter';

import { encode, decode, getAccountId, getSlideIcon, truncate } from '../utils';

function setPrevUrls(type) {
  if (!window.prevUrls) { window.prevUrls = {}; }
  if (window.prevLocation) {
    window.prevUrls[type] = `${window.prevLocation.pathname}${window.prevLocation.search}`;
  }
  return undefined;
}

class LinkItem extends Component {
  render() {
    let subNav = null;

    if (this.props.className === 'break') {
      return <div className="break"><p dangerouslySetInnerHTML={{__html: this.props.title }} /></div>;
    }

    if ((this.props.isActive || this.props.showSubNav) && this.props.subNav) {
      const { tab } = qs.parse(this.props.location.search);
      subNav = <div className="sub-nav">
        { this.props.subNav.map(({ title, key, active, icon, rightIcon, condensed, url, subNav, slug, className = '' }) => {
          let subNavUrl = url || `${this.props.url}?tab=${key}`;
          let i = null;
          if (icon) {
            i = <i className={`fas ${icon}`} />
          }
          let ri = null;
          if (rightIcon) {
            ri = <i className={`right-icon fas ${rightIcon}`} />
          }
          if (subNav) {
            let open = false;
            subNav.forEach(({ key }) => {
              if (key === tab) {
                open = true;
              }
            });
            return <div className={`sub-nav-wrapper ${(key === tab || active) && !slug ? 'active' : ''} ${open ? 'open' : ''}`}>
            <Link className={`${(key === tab || active) && !slug ? 'active' : ''} ${title.split(' ').length === 1 ? 'single-word' : ''} ${condensed ? 'condensed' : ''}`} to={subNavUrl}>{ i }{ title }</Link>
            <div className="sub-nav">{subNav.map(({ title, key, active, icon, condensed, url, subNav }) => {
              let subNavUrl = url || `${this.props.url}?tab=${key}`;
              let i = null;
              if (icon) {
                i = <i className={`fas ${icon}`} />
              }
              return <Link className={`${key === tab || active ? 'active' : ''} ${title.split(' ').length === 1 ? 'single-word' : ''} ${condensed ? 'condensed' : ''}`} to={subNavUrl}>{ i }{ title }</Link>
            })}</div></div>
          }

          return (<Link className={`${key === tab || active ? 'active' : ''} ${title.split(' ').length === 1 ? 'single-word' : ''} ${condensed ? 'condensed' : ''} ${className}`} to={subNavUrl}>{ i }{ title }{ ri }</Link>);
        })}
      </div>
    }

    let url = this.props.url;
    if (this.props.subNav) {
      url = `${url}?tab=${this.props.subNav[0].key}`;
    }

    let rightLink = null;
    if (this.props.rightLink) {
      rightLink = <span className="right-link">{ this.props.rightLink }</span>
    }

    let bottomLink = null;
    if (this.props.bottomLink) {
      bottomLink = (
        <div className="bottom-link"><Link to={this.props.bottomLink.url}><i className={`fas fa-arrow-left-long`} />{ this.props.bottomLink.title }</Link>
        </div>
      );
    }

    let icon = <i className={`fas ${this.props.icon}`} />;
    if (this.props.user) {
      let username = this.props.user.name || 'Zigpoll';
      icon = <div className="profile-icon">{ username.slice(0,1) }</div>
    }
    return (
      <div className="nav">
        <div className={`${this.props.disabled ? 'disabled' : ''} top-link ${this.props.rightLink ? 'has-right-link' : ''}`}><Link to={url} className={`${this.props.className} ${this.props.isActive ? 'active' : ''} ${this.props.subNav ? 'has-subnav' : ''}`}>{ icon }{ this.props.title }</Link>
          { rightLink }
        </div>
        { subNav }
        { bottomLink }
      </div>
    );
  }
}

class Sidebar extends Component {
  render() {
    let hasPolls = this.props.polls ? this.props.polls.length > 0 : true;
    let createPoll = this.props.match.path.indexOf('/p/create') !== -1;
    let links = [
      { className: 'break',
        title: '<span>Account</span>Setup',
      },
      { className: 'account',
        title: 'Account',
        icon: 'fa-folder',
        url: `/a/${encode(this.props.accountId)}`,
        path: '/a/:accountId',
        showSubNav: true,
        active: createPoll,
        subNav: [
        {
          title: 'Settings',
          key: 'general',
          // active: createPoll,
          // rightIcon: 'fa-arrow-right-long',
        },
        { 
          title: 'Installation',
          key: 'installation'
        },
        {
          title: 'Defaults',
          key: 'design',
          slug: true,
          subNav: [
            {
              title: 'Design',
              key: 'design'
            },
            // { 
            //   title: 'Behavior',
            //   key: 'behavior'
            // },
            { 
              title: 'Targeting',
              key: 'targeting'
            },
          ]
        },        
        // { 
        //   title: 'Language',
        //   key: 'language'
        // },
        {
          title: 'Surveys',
          key: 'surveys',
          active: createPoll,
          // rightIcon: 'fa-arrow-right-long',
        }
      ] },
      { className: 'break',
        title: '<span>Account</span>Metrics',
      },
      { className: 'dashboard',
        title: 'Dashboard',
        icon: 'fa-chart-bar',
        url: `/dashboard/a/${encode(this.props.accountId)}`,
        disabled: !hasPolls,
        path: '/dashboard/a/:accountId' },
      { className: 'reports',
        title: 'Reports',
        icon: 'fa-columns',
        url: `/reports/a/${encode(this.props.accountId)}`,
        disabled: !hasPolls,
        path: '/reports/a/:accountId' },
      { className: 'activity',
        title: 'Activity',
        icon: 'fa-paper-plane',
        url: `/activity/a/${encode(this.props.accountId)}`,
        disabled: !hasPolls,
        path: '/activity/a/:accountId'
      },
      { className: 'responses',
        title: 'Responses',
        icon: 'fa-comment-alt',
        url: `/responses/a/${encode(this.props.accountId)}`,
        disabled: !hasPolls,
        path: '/responses/a/:accountId'
      },
      { className: 'charts',
        title: 'Charts',
        icon: 'fa-chart-pie',
        url: `/charts/a/${encode(this.props.accountId)}`,
        disabled: !hasPolls,
        path: '/charts/a/:accountId'
      },
      { className: 'insights',
        title: 'Insights',
        icon: 'fa-wand-magic-sparkles',
        url: `/insights/a/${encode(this.props.accountId)}`,
        disabled: !hasPolls,
        path: '/insights/a/:accountId'
      },
      { className: 'emails',
        title: 'Email Addresses',
        icon: 'fa-at',
        url: `/emails/a/${encode(this.props.accountId)}`,
        disabled: !hasPolls,
        path: '/emails/a/:accountId'
      },
      // { className: 'campaigns',
      //   title: 'Campaigns',
      //   icon: 'fa-envelopes-bulk',
      //   url: `/campaigns/a/${encode(this.props.accountId)}`,
      //   path: '/campaigns/a/:accountId'
      // },
      { className: 'participants',
        title: 'Participants',
        icon: 'fa-users',
        url: `/participants/a/${encode(this.props.accountId)}`,
        path: '/participants/a/:accountId'
      },
      { className: 'integrations',
        title: 'Integrations',
        icon: 'fa-plug',
        url: `/integrations/a/${encode(this.props.accountId)}`,
        path: '/integrations/a/:accountId'
      },
      { className: 'automations',
        title: 'Automations',
        icon: 'fa-arrows-spin',
        url: `/automations/a/${encode(this.props.accountId)}`,
        path: '/automations/a/:accountId'
      },
      // { className: 'api',
      //   title: 'API Config',
      //   icon: 'fa-code',
      //   url: `/api/a/${encode(this.props.accountId)}`,
      //   path: '/api/a/:accountId'
      // },
      { className: 'settings',
        title: 'Profile',
        user: this.props.user,
        icon: 'fa-cog',
        path: '/settings/a/:accountId',
        url: `/settings/a/${encode(this.props.accountId)}`
      }
    ];

    let createSlide = this.props.match.path.indexOf('/s/create') !== -1;

    let preview = (<a target='_blank' rel="noopener noreferrer" title="Preview your survey" href={`/preview/${this.props.accountId}/${this.props.pollId}`}><i className="fas fa-external-link-alt" /></a>);
    let hasSlides = true;
    if (this.props.poll && this.props.poll.slides && this.props.poll.slides.length === 0) {
      hasSlides = false;
      preview = (<a target='_blank' rel="noopener noreferrer" title="Preview your survey" className="disabled bottom" href={`/preview/${this.props.accountId}/${this.props.pollId}`} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}><i className="fas fa-external-link-alt" /><div className="content"><div>You need to add at least one slide before you can preview your survey.</div></div></a>)
    }
    let pollLinks = [
      { className: 'break',
        title: '<span>Survey</span>Setup',
      },
      { className: 'poll',
        title: 'Survey',
        icon: 'fa-chart-pie',
        rightLink: (preview),
        url: `/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}`,
        path: '/a/:accountId/p/:pollId',
        showSubNav: true,
        active: createSlide,
        subNav: [
          {
            title: 'Settings',
            key: 'general',
            subNav: [
            { 
              title: 'Delivery',
              key: 'delivery'
            },
            { 
              title: 'Behavior',
              key: 'behavior'
            },
            { 
              title: 'Design',
              key: 'design'
            },
            { 
              title: 'Targeting',
              key: 'targeting'
            },
            { 
              title: 'Language',
              key: 'language'
            },
            // { 
              // title: 'Manage',
              // key: 'manage'
            // }
            ],
          },
          /*
          {
            title: 'Actions',
            key: 'email',
            slug: true,
            subNav: [
              { 
                title: 'Send Emails',
                key: 'email'
              },
              { 
                title: 'Manage',
                key: 'manage'
              }
            ]
          },
          */
          { 
            title: 'Send Emails',
            key: 'email'
          },
          { 
            title: 'Slides',
            key: 'slides',
            // rightIcon: 'fa-arrow-right-long',
            active: createSlide
          },
        ],
        bottomLink:{
          title: 'Surveys',
          url: `/a/${encode(this.props.accountId)}?tab=surveys`
        }
      },
      { className: 'break',
        title: '<span>Survey</span>Metrics',
      },
      { className: 'dashboard',
        title: 'Dashboard',
        icon: 'fa-chart-bar',
        url: `/dashboard/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}`,
        disabled: !hasSlides,
        path: '/dashboard/a/:accountId/p/:pollId'
      },
      { className: 'reports',
        title: 'Reports',
        icon: 'fa-columns',
        url: `/reports/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}`,
        disabled: !hasSlides,
        path: '/reports/a/:accountId/p/:pollId' },
      { className: 'activity',
        title: 'Activity',
        icon: 'fa-paper-plane',
        url: `/activity/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}`,
        disabled: !hasSlides,
        path: '/activity/a/:accountId/p/:pollId'
      },
      { className: 'responses',
        title: 'Responses',
        icon: 'fa-comment-alt',
        url: `/responses/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}`,
        disabled: !hasSlides,
        path: '/responses/a/:accountId/p/:pollId'
      },
      { className: 'charts',
        title: 'Charts',
        icon: 'fa-chart-pie',
        url: `/charts/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}`,
        disabled: !hasSlides,
        path: '/charts/a/:accountId/p/:pollId'
      },
      { className: 'insights',
        title: 'Insights',
        icon: 'fa-wand-magic-sparkles',
        url: `/insights/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}`,
        disabled: !hasSlides,
        path: '/insights/a/:accountId/p/:pollId'
      },
      { className: 'emails',
        title: 'Email Addresses',
        icon: 'fa-at',
        url: `/emails/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}`,
        path: '/emails/a/:accountId/p/:pollId'
      },
      { className: 'participants',
        title: 'Participants',
        icon: 'fa-users',
        url: `/participants/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}`,
        path: '/participants/a/:accountId/p/:pollId'
      },
      // { className: 'activity',
      //   title: 'Activity',
      //   icon: 'fa-paper-plane',
      //   path: '/activity/a/:accountId/p/:pollId',
      //   url: `/activity/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}`
      // },
      { className: 'settings',
        title: 'Profile',
        user: this.props.user,
        icon: 'fa-cog',
        path: '/settings/a/:accountId/p/:pollId',
        // url: `/settings/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}`
        url: `/settings/a/${encode(this.props.accountId)}`
      }
    ];

    let slideLinks = [
      { className: 'break',
        title: '<span>Slide</span>Setup',
      },
      { className: 'slide',
        title: 'Slide',
        icon: 'fa-poll-h',
        url: `/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props.slideId)}`,
        path: '/a/:accountId/p/:pollId/s/:slideId',
        showSubNav: true,
        active: createSlide,
        disabled: createSlide,
        subNav: [
          {
            title: 'Edit',
            key: 'details' 
          },
          {
            title: 'Create',
            key: 'create',
            url: `/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/create`
          },
        ],
        bottomLink:{
          title: 'Slides',
          url: `/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}?tab=slides`
        }
      },
      { className: 'break',
        title: '<span>Slide</span>Metrics',
      },
      { className: 'dashboard',
        title: 'Dashboard',
        icon: 'fa-chart-bar',
        url: `/dashboard/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props.slideId)}`,
        path: '/dashboard/a/:accountId/p/:pollId/s/:slideId',
        disabled: !this.props.slideId,
      },
      { className: 'responses',
        title: 'Responses',
        icon: 'fa-comment-alt',
        url: `/responses/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props.slideId)}`,
        path: '/responses/a/:accountId/p/:pollId/s/:slideId',
        disabled: !this.props.slideId
      },
      { className: 'charts',
        title: 'Charts',
        icon: 'fa-chart-pie',
        url: `/charts/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props.slideId)}`,
        path: '/charts/a/:accountId/p/:pollId/s/:slideId',
        disabled: !this.props.slideId
      },
      { className: 'insights',
        title: 'Insights',
        icon: 'fa-wand-magic-sparkles',
        url: `/insights/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props.slideId)}`,
        path: '/insights/a/:accountId/p/:pollId/s/:slideId',
        disabled: !this.props.slideId,
      },
      { className: 'emails',
        title: 'Email Addresses',
        icon: 'fa-at',
        url: `/emails/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props.slideId)}`,
        path: '/emails/a/:accountId/p/:pollId/s/:slideId',
        disabled: !this.props.slideId,
      },
      { className: 'participants',
        title: 'Participants',
        icon: 'fa-users',
        url: `/participants/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props.slideId)}`,
        disabled: !this.props.slideId,
        path: '/participants/a/:accountId/p/:pollId/s/:slideId'
      },
      // { className: 'activity',
      //   title: 'Activity',
      //   icon: 'fa-paper-plane',
      //   path: '/activity/a/:accountId/p/:pollId/s/:slideId',
      //   url: `/activity/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props.slideId)}` },
      { className: 'settings',
        title: 'Profile',
        user: this.props.user,
        icon: 'fa-cog',
        path: '/settings/a/:accountId/p/:pollId/s/:slideId',
        // url: `/settings/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(this.props.slideId)}` 
        url: `/settings/a/${encode(this.props.accountId)}`
      },
    ];

    const hasSelectedPoll = this.props.match.path.indexOf('pollId') !== -1;
    const hasSelectedSlide = this.props.match.path.indexOf('slideId') !== -1;
    const isCreatingNewSlide = this.props.match.path.indexOf('/s/create') !== -1;

    let pollsDropdown = null;
    let slidesDropdown = null;

    let type = 'account';
    if (hasSelectedPoll) {
      type = 'poll';
    }
    if (hasSelectedSlide) {
      type = 'slide';
    }

    setPrevUrls(type);
    if (hasSelectedPoll) {
      let urlPrefix = `/dashboard/a/${encode(this.props.accountId)}/p/`;
      let urlSuffix = '';

      if (type === 'poll' && window.location.pathname.indexOf('/pa/') === -1) {
        const loc = window.location.pathname.split('/p/');
        urlPrefix = loc[0] + '/p/';
        urlSuffix = loc[1] ? loc[1].split('/').slice(1).join('/') : '';

        const params = new URLSearchParams(window.location.search);
        let search = {};
        if (params.get('tab')) {
          search.tab = params.get('tab');
        }
        if (params.get('type')) {
          search.type = params.get('type');
        }
        search = new URLSearchParams(search).toString();
        urlSuffix = urlSuffix + (search ? '?' + search : '');
      }

      pollsDropdown = <PollsDropdown
        items={this.props.polls}
        currentId={this.props.pollId}
        // urlPrefix={`/dashboard/a/${encode(this.props.accountId)}/p/`}
        urlPrefix={urlPrefix}
        urlSuffix={urlSuffix}
        isOnPage={type === 'poll'}
        prevUrl={window.prevUrls['poll']}
        key="poll"
      />;
      links = pollLinks;
    }

    if (hasSelectedSlide || isCreatingNewSlide) {
      slidesDropdown = <SlidesDropdown
        items={this.props.slides}
        currentId={this.props.slideId}
        urlPrefix={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/`}
        urlSuffix="?tab=details"
        isOnPage={type === 'slide'}
        prevUrl={window.prevUrls['slide']}
        key="slides"
      />
      links = slideLinks;

      /* Assign slides to nav */
      if (this.props.slides) {
        slideLinks[1].subNav = this.props.slides.map((slide) => {
          return { 
            title: truncate(slide.handle, 45),
            key: truncate(slide.handle, 45),
            icon: getSlideIcon(slide.type),
            condensed: true,
            active: this.props.slideId === slide._id,
            url: `/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/${encode(slide._id)}`
          }
        });
      }
      const { insertIdx } = qs.parse(this.props.location.search);
      const addSlide = {
        title: isCreatingNewSlide ? 'New Slide' : 'Add Slide',
        icon: 'fa-plus',
        key: 'create',
        className: 'create-button',
        url: `/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/create`,
        active: isCreatingNewSlide,
        condensed: true,
      };

      if (insertIdx !== undefined) {
        addSlide.className = 'create-button no-margin';
        slideLinks[1].subNav.splice(insertIdx, 0, addSlide)
      } else {
        slideLinks[1].subNav.push(addSlide);
      }
    }

    const isParticipant = this.props.match.path.indexOf('participantId') !== -1;

    if (isParticipant) {
      const settings = links.pop();
      const idx = _.findIndex(links, ({ className }) => className === 'participants')
      links[idx].showSubNav = true;
      links[idx].active = true;
      // links[idx].subNav = [{
      //   title: 'Individual',
      //   key: 'individual',
      //   active: true
      // }];
      links.push(settings);
    }

    let createAccount = !this.props.accountId;
    if (createAccount) {
      links = [
      { className: 'account',
        title: 'New Account',
        icon: 'fa-plus',
        url: `/a/create`,
        path: '/a/create' },
      { className: 'settings',
        title: 'Profile',
        user: this.props.user,
        icon: 'fa-cog',
        path: '/settings/a/:accountId/p/:pollId/s/:slideId',
        url: `/settings` }
      ]
    }

    let accountsDropdown = (
      <AccountsDropdown
        items={this.props.accounts}
        currentId={this.props.accountId}
        urlPrefix={`/dashboard/a/`}
        urlSuffix=""
        isOnPage={type === 'account'}
        prevUrl={window.prevUrls['account']}
        key="account"
      />
    );

    const is404 = this.props.location.pathname === '/404';
    if (is404) {
      accountsDropdown = null;
      links = [
        { className: 'home',
          title: 'Home',
          icon: 'fa-home',
          url: `/`,
          path: '/asd123' },
        { className: 'settings',
          title: 'Profile',
          user: this.props.user,
          icon: 'fa-cog',
          path: '/settings',
          url: `/settings` }
      ]
    }

    if (this.props.location.pathname.indexOf('manage') !== -1) {
      links[links.length-1].active = true;
    }

    if (this.props.location.pathname.indexOf('affiliate') !== -1) {
      links[links.length-1].active = true;
    }

    // if (type === 'slide') {
    //   accountsDropdown = null;
    // }

    return (
      <div className={`sidebar`}>
        <div className={`content active`}>
          <div>
            { accountsDropdown }
            { pollsDropdown }

            <div style={{ display: 'none' }}>
            { slidesDropdown }
            </div>

            { links.map((item, idx) => <LinkItem {...item} isActive={this.props.match.path === item.path || item.active} location={this.props.location} isLast={idx === links.length -1} account={this.props.account} /> )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let accountId = decode(ownProps.match.params.accountId);

  const accounts = state.accounts || [];
  const account = accounts[accountId] || {};

  const pollId = decode(ownProps.match.params.pollId);
  const polls = state.polls || [];
  const poll = polls[pollId] || {};

  return {
    accountId,
    user: state.user || {},
    pollId: decode(ownProps.match.params.pollId),
    slideId: decode(ownProps.match.params.slideId),
    accounts: state.user.accounts,
    account: account,
    polls: account.polls,
    poll,
    slides: poll.slides
  }
}

export default withRouter(connect(mapStateToProps)(Sidebar));
