import download from 'downloadjs'
import { replace, push } from 'connected-react-router';
import { pushServerError, clearNotifications } from './Notifications';
import { fetchAccount } from './Accounts';
import { flash } from './FlashNotifications';
import { post, postFormData } from '../ajax';
import { encode, parseUrl, move, generateFilename, needsCheckoutExtension } from '../utils';
import { url } from '../settings';

export const CREATING_POLL = 'CREATING_POLL';
export const CREATED_POLL = 'CREATED_POLL';

export const UPDATING_POLL = 'UPDATING_POLL';
export const UPDATED_POLL = 'UPDATED_POLL';

export const FETCHING_POLL = 'FETCHING_POLL';
export const FETCHED_POLL = 'FETCHED_POLL';

export const DELETING_POLL = 'DELETING_POLL';
export const DELETED_POLL = 'DELETED_POLL';

export const DUPLICATING_POLL = 'DUPLICATING_POLL';
export const DUPLICATED_POLL = 'DUPLICATED_POLL';

export const ARCHIVING_POLL = 'ARCHIVING_POLL';
export const ARCHIVED_POLL = 'ARCHIVED_POLL';

export const UNARCHIVING_POLL = 'UNARCHIVING_POLL';
export const UNARCHIVED_POLL = 'UNARCHIVED_POLL';

export const UPDATE_QUERY_VARS = 'UPDATE_QUERY_VARS';

export function creatingPoll() {
  return {
    type: CREATING_POLL
  };
}

export function createdPoll(json, accountId) {
  return {
    type: CREATED_POLL,
    poll: json,
    accountId
  };
}

function updatingPoll() {
  return {
    type: UPDATING_POLL
  };
}

function updatedPoll(json, accountId) {
  return {
    type: UPDATED_POLL,
    poll: json,
    accountId
  };
}

function fetchingPoll() {
  return {
    type: FETCHING_POLL
  };
}

function fetchedPoll(json, accountId) {
  return {
    type: FETCHED_POLL,
    poll: json,
    accountId
  };
}

function deletingPoll() {
  return {
    type: DELETING_POLL
  };
}

function deletedPoll(accountId, pollId) {
  return {
    type: DELETED_POLL,
    accountId,
    pollId
  };
}

function duplicatingPoll() {
  return {
    type: DUPLICATING_POLL
  };
}

function duplicatedPoll(accountId, poll) {
  return {
    type: DUPLICATED_POLL,
    accountId,
    poll
  };
}

function archivingPoll() {
  return {
    type: ARCHIVING_POLL
  };
}

function archivedPoll(account, poll) {
  return {
    type: ARCHIVED_POLL,
    poll,
    account
  };
}

function unarchivingPoll() {
  return {
    type: UNARCHIVING_POLL
  };
}

function unarchivedPoll(account, poll) {
  return {
    type: UNARCHIVED_POLL,
    poll,
    account
  };
}

function showCheckoutExtensionModal() {
  return {
    type: 'SHOW_CHECKOUT_EXTENSION_MODAL',
  };
}

export function createPoll(poll) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(creatingPoll());

    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll`, poll)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(createdPoll(json, accountId))
        dispatch(push(`/a/${encode(accountId)}/p/${encode(json._id)}`));
        if (json.slides.length) {
          dispatch(push(`/a/${encode(accountId)}/p/${encode(json._id)}?tab=slides`));
        } else {
          dispatch(push(`/a/${encode(accountId)}/p/${encode(json._id)}/s/create`));
        }
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

function reduce(poll) {
  if (!poll.slides) { return poll; }

  poll.slides = poll.slides.map(s => s._id);
  return { ...poll };
}

export function updatePoll(poll, message, formData) {
  return (dispatch, getState) => {
    const [ accountId, pollId ] = parseUrl(getState().router);
    poll = reduce(poll);

    dispatch(clearNotifications());
    dispatch(updatingPoll());

    if (formData) {
      postFormData(`${url}/account/${accountId}/poll/${pollId}/update-logo`, formData)
      .then(response => response.json())
      .then(json => {
        if (json.error) {
          dispatch(pushServerError(json.error))
        } else {
          dispatch(updatedPoll(json, accountId))
        }
      })
      .catch(error => dispatch(pushServerError(error)))
    }

    return post(`${url}/account/${accountId}/poll/${pollId}/update`, poll)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(updatedPoll(json, accountId))
        if (message) {
          dispatch(flash(message));
        }
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

// export function updatePollSettings(formData, message) {
//   return (dispatch, getState) => {
//     const [ accountId, pollId ] = parseUrl(getState().router);
//     poll = reduce(poll);

//     dispatch(clearNotifications());
//     dispatch(updatingPoll());

//     return post(`${url}/account/${accountId}/poll/${pollId}/update-settings`, formData)
//     .then(response => response.json())
//     .then(json => {
//       if (json.error) {
//         dispatch(pushServerError(json.error))
//       } else {
//         dispatch(updatedPoll(json, accountId))
//         if (message) {
//           dispatch(flash(message));
//         }
//       }
//     })
//     .catch(error => dispatch(pushServerError(error)))
//   };
// }

export function updatePollDisplaySettings(formData, message) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    return postFormData(`${url}/account/${accountId}/poll/${pollId}/update-display`, formData)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(updatedPoll(json))
        if (message) {
          dispatch(flash(message));
        }
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function updatePollEmailDisplaySettings(formData, message) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    return postFormData(`${url}/account/${accountId}/poll/${pollId}/update-email-display`, formData)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(updatedPoll(json))
        if (message) {
          dispatch(flash(message));
        }
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function updatePollPageDisplaySettings(formData, message) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    return postFormData(`${url}/account/${accountId}/poll/${pollId}/update-page-display`, formData)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(updatedPoll(json))
        if (message) {
          dispatch(flash(message));
        }
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function updatePollSmsDisplaySettings(formData, message) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    return postFormData(`${url}/account/${accountId}/poll/${pollId}/update-sms-display`, formData)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(updatedPoll(json))
        if (message) {
          dispatch(flash(message));
        }
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function sendSmsTest(number) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/send-sms-test`, { number })
    .then(response => response.json())
    .then(json => {
      dispatch(updatedPoll({}))
      if (json.error) {
        dispatch(flash(json.error))
      } else {
        dispatch(flash('A test SMS was sent'));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function toggleVisibility(poll, message) {
  return (dispatch, getState) => {
    const [ accountId, pollId ] = parseUrl(getState().router);

    if (poll.loading) { return; }

    dispatch(updatingPoll());
    return post(`${url}/account/${accountId}/poll/${pollId}/toggle-visibility`, { isVisible: !poll.isVisible })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error))
      } else {
        if (needsCheckoutExtension(json)) {
          if (json.isVisible) {
            dispatch(showCheckoutExtensionModal());
          }
        }
        dispatch(updatedPoll(json, accountId))
        if (message) {
          dispatch(flash(message));
        }
      }
    })
    .catch(error => dispatch(flash(error)))
  };
}

export function toggleVisibilityById(pollId, poll) {
  return (dispatch, getState) => {
    const [ accountId ] = parseUrl(getState().router);

    if (poll.loading) { return; }

    dispatch(updatingPoll());
    return post(`${url}/account/${accountId}/poll/${pollId}/toggle-visibility`, { isVisible: !poll.isVisible })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error))
      } else {
        if (needsCheckoutExtension(json)) {
          if (json.isVisible) {
            dispatch(showCheckoutExtensionModal());
          }
        }
        dispatch(updatedPoll(json, accountId))
      }
    })
    .catch(error => dispatch(flash(error)))
  };
}

export function fetchAccountAndPoll() {
  return (dispatch, getState) => {
    const [ accountId ] = parseUrl(getState().router);
    return dispatch(fetchAccount(accountId)).then(d => dispatch(fetchPoll()))
  }
}

export function fetchPoll(_id) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(fetchingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    if (_id === undefined) {
      _id = pollId;
    }

    return post(`${url}/account/${accountId}/poll/${_id}/fetch`, { _id })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedPoll(json, accountId));
        return json
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function fetchPollWithDateRange(_id, dateRange) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(fetchingPoll());
    dispatch({ type: 'PAGE_AJAX_LOADING' });

    const [ accountId, pollId ] = parseUrl(getState().router);

    if (_id === undefined) {
      _id = pollId;
    }

    return post(`${url}/account/${accountId}/poll/${_id}/fetch`, { _id, dateRange })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      dispatch({ type: 'PAGE_AJAX_LOADED' });

      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedPoll(json, accountId));
        return json
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function reorderSlides(dragIdx, dropIdx) {
  return (dispatch, getState) => {
    const { 1: pollId } = parseUrl(getState().router);
    const poll = { ...getState().polls[pollId] };

    poll.slides = move(poll.slides, dragIdx, dropIdx);

    dispatch(updatePoll(poll));
  }
}

export function fetchPollWithSlides(_id) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(fetchingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    if (_id === undefined) {
      _id = pollId;
    }

    return post(`${url}/account/${accountId}/poll/${_id}/fetch-full`, { _id })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedPoll(json, accountId));
        return json;
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function deletePoll() {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(deletingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/delete`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(deletedPoll(accountId, pollId));
        dispatch(replace(`/a/${encode(accountId)}?tab=surveys`));
        dispatch(flash('The survey has been deleted'));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function deletePollById(pollId) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(deletingPoll());

    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/delete`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(deletedPoll(accountId, pollId));
        // dispatch(replace(`/a/${encode(accountId)}?tab=general`));
        dispatch(flash('The survey has been deleted'));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function duplicatePoll(title) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(duplicatingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/duplicate`, { title })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(duplicatedPoll(accountId, json));
        dispatch(flash('The survey has been duplicated'));
        dispatch(fetchAccount());
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function duplicatePollById(pollId, title) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(duplicatingPoll());

    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/duplicate`, { title })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(duplicatedPoll(accountId, json));
        dispatch(flash('The survey has been duplicated'));
        dispatch(fetchAccount());
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function archivePoll() {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(archivingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/archive`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(archivedPoll(json.account, json.poll));
        dispatch(flash('The survey has been archived'));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function archivePollById(pollId) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(archivingPoll());

    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/archive`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(archivedPoll(json.account, json.poll));
        dispatch(flash('The survey has been archived'));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function unarchivePoll() {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(unarchivingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/unarchive`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(unarchivedPoll(json.account, json.poll));
        dispatch(flash('The survey has been unarchived'));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function runEmailCampaign(recipients) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/run-campaign`, { recipients })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      dispatch(updatedPoll(accountId, {}));

      if (json.error) {
        // dispatch(pushServerError(json.error));
        dispatch(flash(json.error));
      } else {
        dispatch(flash('Your emails have been sent successfully.'));
      }
    })
    .catch(error => dispatch(pushServerError(error)))    
  }
}

export function runCsvEmailCampaign(csv) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/run-csv-campaign`, { csv })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      dispatch(updatedPoll(accountId, {}));

      if (json.error) {
        // dispatch(pushServerError(json.error));
        dispatch(flash(json.error));
      } else {
        dispatch(flash('Your emails have been sent successfully.'));
      }
    })
    .catch(error => dispatch(pushServerError(error)))    
  }
}

export function runCsvImport(csv) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/run-csv-import`, { csv })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      dispatch(updatedPoll(accountId, {}));

      if (json.error) {
        // dispatch(pushServerError(json.error));
        dispatch(flash(json.error));
      } else {
        dispatch(flash('Your csv has been uploaded successfully.'));
      }
    })
    .catch(error => dispatch(pushServerError(error)))    
  }
}

export function generateExportFile(_id) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);
    const poll = getState().polls[_id || pollId];

    return post(`${url}/account/${accountId}/poll/${_id || pollId}/generate-export-file`, {})
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.blob()
    })
    .then((blob) => {
      dispatch(updatedPoll(accountId, {}));
      dispatch(flash('Your export has been generate successfully.'));

      return download(blob, generateFilename(`${poll.title}`, 'json'), 'text/json');
    })
    .catch(error => dispatch(pushServerError(error)))    
  }
}

export function generateShopifyCustomerSegment(targetingRules) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/generate-shopify-customer-segment`, { targetingRules })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      dispatch(updatedPoll(accountId, {}));

      if (json.error) {
        // dispatch(pushServerError(json.error));
        dispatch(flash(json.error));
      } else {
        return json.customerCount;
      }
    })
    .catch(error => dispatch(pushServerError(error)))    
  }
}

export function runShopifySegmentEmailCampaign(targetingRules) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/send-shopify-customer-segment`, { targetingRules })
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      dispatch(updatedPoll(accountId, {}));

      if (json.error) {
        // dispatch(pushServerError(json.error));
        dispatch(flash(json.error));
      } else {
        dispatch(flash('Your emails have been sent successfully.'));
      }
    })
    .catch(error => dispatch(pushServerError(error)))    
  }
}


export function updateLanguageSettings(languageSettings, message) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/update-language-settings`, { languageSettings })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(updatedPoll(json))
        if (message) {
          dispatch(flash(message));
        }
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function updateInsights(accountId, pollId, dateRange) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingPoll());

    return post(`${url}/account/${accountId}/poll/${pollId}/update-insights`, { dateRange })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(updatedPoll(json))
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function updateQueryVars(variables) {
  return (dispatch, getState) => {
    const [ accountId, pollId ] = parseUrl(getState().router);
    return dispatch({
      type: UPDATE_QUERY_VARS,
      pollId: pollId,
      variables
    });
  };
}

export function removeDisplaySettings(type) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingPoll());

    const [ accountId, pollId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/remove-settings`, { type })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(updatedPoll(json))
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function resetPollStats(pollId) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingPoll());

    const [ accountId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/reset-stats`, {})
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(flash('Your survey data has been reset.'));
        dispatch(updatedPoll(json))
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}
