import React, { Component } from 'react';
import { encode } from '../utils';
import Modal from './Modal';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { decode, truncate, loadContactUsSurvey } from '../utils';

import * as UserActions from '../actions/Users';

class Notification extends Component {
  render() {
    return (
      <div className={`notification ${this.props.type}`}>
        <div className="content"
          dangerouslySetInnerHTML={{ __html : this.props.message }} 
        />
        <div 
          className="close"
          onClick={this.props.close}
        ><i className="fas fa-close" /></div>
      </div>
    );
  }
}

class EmailValidationNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  closeModal() {
    this.setState({ showEmailValidationModal: false });
  }

  render() {
    return (
    <div id="header-notifications" className={'active'}>
      <div className={`notification`}>
        <div className="content">
          Your email address has not been validated yet. <span className="link" id="validate-user-account" onClick={() => {
            this.setState({ showEmailValidationModal: true });

            this.props.sendValidationEmail().then(() => {
              // this.setState({ showEmailValidationModal: true });
            })
          }}>Click here</span> to recieve an email with your validation link.<br/>If you need help getting your account validated please <span className="link" onClick={() => {
            loadContactUsSurvey(this.props.user);
          }}>get in touch</span>.
        </div>
      </div>

      <Modal 
        isOpen={this.state.showEmailValidationModal}
        onRequestClose={() => {
          this.closeModal();
        }}
      >
        <div className={`frame`}>
          <div className="close" onClick={this.closeModal.bind(this)} />
          <div className="title">Success</div>
          <div className="content">
            <div class="subtitle" style={{ marginBottom: 10 }}>Your Validation Link Has been sent.</div>
            <p className="copy">Please check your email and click the link inside. Once that has been done please refresh the page.</p>
          </div>

        </div>
      </Modal>
    </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const user = state.user || {};

  return {
    user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...UserActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailValidationNotification);