import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import moment from 'moment';

import * as UserActions from '../actions/Users';

import ColumnLayout from '../components/ColumnLayout';
import UserEditForm from '../components/UserEditForm';
import Logout from '../components/Logout';
import SingleFormPageHeader from '../components/SingleFormPageHeader';
import PaymentCaptureForm from '../components/PaymentCaptureForm';
import AccountsOverview from '../components/AccountsOverview';
import Confirm from '../components/Confirm';
import SectionHeader from '../components/SectionHeader';
import DeleteConfirm from '../components/DeleteConfirm';
import AffiliateSettings from '../components/AffiliateSettings';
import IntegrationSettingsCard from '../components/IntegrationSettingsCard';
import AutomationSettingsCard from '../components/AutomationSettingsCard';
import EmailValidationNotification from '../components/EmailValidationNotification';

import { getActiveCard, getCards } from '../utils';
import $ from 'jquery';

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { card } = this.props;

    let pill = null;
    let actions = (
      <div className="edit">
        <div onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          this.setState({ showConfirm: true });
        }} className="remove" />
        <div
          className="make-default"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            this.props.setDefaultCard(card.id);
          }}
        >Make Default</div>

        <Confirm 
          title="Are you sure?"
          subtitle="All subscriptions on this card will be migrated to the default card."
          show={this.state.showConfirm}
          onConfirm={() => {
            this.props.removeCard(card.id);
          }}
          onCancel={() => {
            this.setState({ showConfirm: false });
          }}
        />
      </div>
    );

    if (card.id === this.props.activeCard.id) {
      pill = (<div className="pill">Default</div>);
      actions = null;
    }

    return (<div className="cc-details" >
      <div style={{ position: 'relative' }}><label>Card Information</label>{pill}</div>
      <p><span className="inline-label">Brand:</span><strong>{ card.brand }</strong></p>
      <p><span className="inline-label">Ending in:</span><strong>{ card.last4 }</strong></p>
      <p><span className="inline-label">Expires:</span><strong>{ card.exp_month } / { card.exp_year }</strong></p>

      { actions }
    </div>);
  }
}

class Cards extends Component {
  onClick(e) {
    e.stopPropagation();
    e.preventDefault();

    this.props.onClick();
  }

  render() {
    const cards = this.props.cards;

    if (this.props.fetching) {
      return (
        <div className="object-list credit-cards">
          <div>Loading card data...</div>
      </div>);
    }

    if (!this.props.validatedEmail || this.props.blockBilling) {
      return (
        <div className="empty-object-list credit-cards">
          <h3>No cards can be added.</h3>
          <p>Please <a style={{ color: 'black', textDecoration: 'underline' }} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            $('#validate-user-account').click();
          }}>validate your account</a> in order to add a credit card and upgrade your subscription.</p>
        </div>
      );
    }

    if (!cards.length) {
      return (
        <div className="empty-object-list credit-cards">
          <h3>No cards have been added.</h3>
          <p>To switch off of the free plan, please add a credit card and then update your subscription.</p>
          <button onClick={this.onClick.bind(this)}>{ this.props.showAddCard ? 'Cancel' : 'Add Card' }</button>
        </div>);
    }

    let message = null;

    if (this.props.showAddCard) {
      message = <p style={{ marginTop: 15 }}>Fill out the form below to add another credit card.</p>
    }

    return (
      <div className="object-list credit-cards">
        { this.props.cards.map((card) => <Card key={card.id} card={card} {...this.props} />)}

        <div className="actions">
          <button onClick={this.onClick.bind(this)}>{ this.props.showAddCard ? 'Cancel' : 'Add Card' }</button>

          { message }
        </div>
      </div>
    );
  }
}

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.user.customerId && !this.props.user.customer) {
      this.props.fetchStripeData();
    }
  }

  render() {
    const activeCard = getActiveCard(this.props.user);
    const cards = getCards(this.props.user) || [];

    let invoices = null;
    if (this.props.user && this.props.user.customer && this.props.user.customer.invoices && this.props.user.customer.invoices.data && this.props.user.customer.invoices.data.length) {
      let inv = this.props.user.customer.invoices.data.filter(({ total, receipt_number }) => {
        return total > 0 && receipt_number;
      });

      invoices = <div className="account-overview">
        <SectionHeader
          title=<span><i className="fas fa-file-invoice-dollar" />Invoices</span>
          subtitle="View and download past invoices."
        />

          <div className="card invoices">
          { inv.map((invoice) => {
            const date = invoice.date;
            return <div className="invoice"><i className="fas fa-receipt" /><a href={invoice.hosted_invoice_url} target="_blank">{moment.unix(date).format('MMMM YYYY')} #{invoice.receipt_number}</a></div>;
          }) }
          </div>

          <div className="actions" style={{ marginTop: 10 }}>
            <p><a href="https://billing.stripe.com/p/login/4gw5kX3dkfth8eY3cc" target="_blank">Edit your billing information and customer information</a></p>
          </div>
      </div>

      if (inv.length === 0) {
        invoices = null;
      }
    }

    let billing = (
      <span>
        <SectionHeader
          title=<span><i className="fas fa-credit-card" />Billing</span>
          subtitle="Add, edit or remove payment info."
          tooltip="The default credit card will be used if not otherwise specified."
        />

        <Cards
          onClick={() => { this.setState({ showAddCard: !this.state.showAddCard }) }}
          activeCard={activeCard}
          cards={cards}
          fetching={this.props.user.fetching}
          showAddCard={this.state.showAddCard}
          setDefaultCard={this.props.setDefaultCard}
          removeCard={this.props.removeCard}
          validatedEmail={this.props.user.email === this.props.user.validatedEmail}
        />

        { this.props.user.email === this.props.user.validatedEmail && <PaymentCaptureForm
          show={this.state.showAddCard}
          cancel={() => this.setState({ showAddCard: false })}
        /> }

        { invoices }
      </span>
    );
    billing = null;

    let deleteUser = (
      <span>
        <div className="heading">Use Caution</div>
        <div className="user-management">
          <div className="account-management card">
            <div>
              <label>Delete User</label>
              <div>
                <div className="subtle">This will delete all accounts that you own and cancel any subscription plans. All data will be lost permanently.</div>
                <button
                  className="delete-poll"
                  onClick={(value) => {
                    this.setState({ showDeleteConfirm: true });
                  }}
                >Delete</button>
              </div>
            </div>
          </div>
        </div>

        <DeleteConfirm 
          title="Are you sure?"
          subtitle="This will permanently erase your profile, accounts, polls, slides, and responses."
          show={this.state.showDeleteConfirm}
          onConfirm={this.props.deleteUser}
          onCancel={() => {
            this.setState({ showDeleteConfirm: false });
          }}
        />
      </span>
    );

    if (this.props.user.type === 'shopify') {
      billing = null;

      deleteUser = (
        <div className="user-management">
          <div className="account-management card">
            <div>
              <div>
                <div className="subtle"><strong>This account is linked with Shopify.</strong></div>
                <div className="subtle" style={{ marginTop: 10, lineHeight: 1.4 }}>To remove the account, all of it's data, and all of it's subscriptions, please uninstall the app from within your Shopify dashboard.</div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let deleteAccountToggle = (
      <div className="delete-account-wrapper">
        <p>Need to delete your account? <span
          onClick={() => {
            this.setState({ showDangerZone: !this.state.showDangerZone })
            setTimeout(() => {
              $('.foreground').scrollTop(10000);
            }, 0);
          }}>Click Here</span>.</p>
      </div>
    );

    if (this.state.showDangerZone) {
      deleteAccountToggle = (
        <div className="delete-account-wrapper">
          <p>Change your mind? <span
            onClick={() => {
              this.setState({ showDangerZone: !this.state.showDangerZone })
              setTimeout(() => {
                $('.foreground').scrollTop(10000);
              }, 0);
            }}>Cancel</span>.</p>
        </div>
      );      
    }

    let notification = null;
    if (this.props.user && !this.props.user.shop && (this.props.user.validatedEmail !== this.props.user.email)) {
      notification = <EmailValidationNotification />
    }

    return (
      <ColumnLayout
        title="Settings"
        graphics={true}
        headerNotification={notification}
      >

        <SectionHeader
          title=<span><i className="fas fa-user" />User Settings</span>
          subtitle="Manage your personal details."
          className="no-margin"
        />

        <UserEditForm />

        <AccountsOverview />

        { billing }
        { invoices }

        <IntegrationSettingsCard />
        {/*<AutomationSettingsCard />*/}
        {/*<ApiSettingsCard />*/}

        <AffiliateSettings isCard={true} user={this.props.user}/>

        <div className="logout-wrapper">
          <Logout />
        </div>

        { deleteAccountToggle }

        <div className={`danger-zone ${this.state.showDangerZone ? 'active' : ''}`}>
          { deleteUser }
        </div>

      </ColumnLayout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;

  let activeCard;
  if (user.customer && user.customer.active_card) {
    activeCard = user.customer.active_card;
  }

  return {
    loading: state.user.loading,
    user: state.user,
    activeCard: activeCard
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(UserActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
