import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { encode } from '../utils';

import Poll from './Poll';
import SectionHeader from './SectionHeader';

import PopoutCheckbox from './PopoutCheckbox';

class ActivePolls extends Component {
  constructor(props) {
    super(props);
    this.state = { filter: ['visible', 'hidden'] }
  }

  render() {
    const props = this.props;
    const polls = [...props.account.polls];
    const filteredPolls = [];

    polls.forEach((poll, idx) => {
      if (poll.isArchived) {
        if (this.state.filter.indexOf('archived') !== -1) {
          filteredPolls.push({ poll, idx });
        }
      } else if (poll.isVisible) {
        if (this.state.filter.indexOf('visible') !== -1) {
          filteredPolls.push({ poll, idx });
        }
      } else {
        if (this.state.filter.indexOf('hidden') !== -1) {
          filteredPolls.push({ poll, idx });
        }
      }
    });

    if (!props.account.polls || !props.account.polls.length) {
      return <div className="empty-object-list" style={{ marginTop: 25 }}>
        <h3>There are no surveys for this account.</h3>
        <p>You will need to create a survey in order to ask questions to your users. Click the link below to get started.</p>
        <Link className="green" to={`/a/${encode(props.accountId)}/p/create`}>New Survey</Link>
      </div>
    }

    return (
      <div className="polls-container">
        <SectionHeader
          title=<span><i className="fas fa-chart-pie" />Your Surveys</span>
          rightComponent={<PopoutCheckbox
            value={this.state.filter}
            title={<span>Filter<i className="fas fa-filter "/></span>}
            options={[
              { label: "Visible", value: "visible" },
              { label: "Hidden", value: "hidden" },
              { label: "Archived", value: "archived" },
            ]}
            onClick={(value) => {
              this.setState({ filter: value })
            }}
          />}
          subtitle="Drag and drop to reorder. Surveys at the top will take priority over the ones below."
          tooltip="The rules for the survey at the top will take precendence over the ones below. If you want to run multiple campaigns on the same page you can group the surveys together."
          className="no-margin small"
          style={{ maxWidth: 1100 }}
          bottom={true}
        />

        <div className="polls">
          { filteredPolls.length === 0 ? <div className="empty-object-list search">
            <h3>No surveys found.</h3>
            <p>Try again with a different filter.</p>
          </div> 
          : filteredPolls.map(({ poll, idx }) => {
            // if (poll.isArchived) { return null; }
            return (<div onClick={() => this.props.onClick(idx)}><Poll
              account={this.props.account}
              key={poll._id}
              accountId={props.accountId}
              idx={idx}
              pollCount={props.account.polls.length}
              toggleVisibility={props.toggleVisibility}
              duplicate={props.duplicate}
              generateExportFile={props.generateExportFile}
              delete={props.delete}
              archive={props.archive}
              active={idx === this.props.selectedPollIdx}
              polls={this.props.account.polls}
              {...poll}
            /></div>);
          }) }
        </div>

        <div className="actions">
          <p>Looking for your archived surveys? Check your <Link to={`/dashboard/a/${encode(this.props.account._id)}`}>Dashboard</Link>.</p>
        </div>

        <Link className="create-additional" to={`/a/${encode(props.accountId)}/p/create`}>New Survey</Link>
      </div>
    );
  }
}

export default DragDropContext(HTML5Backend)(ActivePolls);
