import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import Breadcrumbs from '../components/Breadcrumbs';
import SectionHeader from '../components/SectionHeader';
import Pagination from '../components/Pagination';
import HeaderButtons from '../components/HeaderButtons';

import Toggle from '../components/Toggle';
import Tooltip from '../components/Tooltip';
import Participant from '../components/Participant';
import ExportCsvModal from '../components/ExportCsvModal'

import * as ParticipantsActions from '../actions/Participants';

import { decode, encode, getDate, renderParticipantId } from '../utils';

class Participants extends Component {
  constructor(props) {
    super(props);
    this.state = { showExportModal: false }
    props.fetchAccountAndParticipants();
  }

  export() {
    // console.log('exporting');
    // this.props.exportAccountParticipants();
    this.setState({ showExportModal: true });
  }

  render() {
    if (!this.props.participants) {
      return <ColumnLoading />
    }

    let results = null;
    if (this.props.participants.data.length) {
      results = (
        <span>
          <div className="responses-header" style={{marginBottom: 15}}>
            <button
              className="download-csv"
              onClick={this.export.bind(this)}
            >Download CSV</button>

            <Pagination
              showPagination={this.props.participants.pages}
              showNext={this.props.participants.page !== this.props.participants.pages}
              showPrev={this.props.participants.page !== 0}
              next={() => { this.props.fetchParticipantsForAccount(this.props.participants.page + 1); }}
              prev={() => { this.props.fetchParticipantsForAccount(this.props.participants.page - 1); }}
              curPage={this.props.participants.page + 1 }
              pageLength={this.props.participants.pages + 1}
            />
          </div>

          <div className={`card object-list emails ${this.props.loading ? 'loading' : ''}`}>
            {this.props.participants.data.map((p) => <Participant url={`/participants/a/${encode(this.props.accountId)}/pa/${encode(p._id)}`} accountId={this.props.accountId} {...p} />)}
          </div>
        </span>
      );
    } else {
      results = (
        <div className="card empty-object-list emails">
          <h3>No Participants Yet</h3>
          <p>A list will show up here once someone has interacted with this survey.</p>
        </div>
      );
    }

    return (
      <ColumnLayout
        title={`Participants`}
        className="participants"
        graphics={true}
      >
        <SectionHeader
          title=<span><i className="fas fa-users" />All Participants</span>
          subtitle="Everyone who responded to a survey under this account."
          className="no-margin"
        />
        { results }

        <HeaderButtons>
          <button
            onClick={this.export.bind(this)}
            disabled={this.props.participants.data.length ? false : true}
          >Download CSV</button>
        </HeaderButtons>

        <ExportCsvModal
          show={this.state.showExportModal}
          onCancel={() => this.setState({ showExportModal: false })}
          onConfirm={({ email, responsePollId, dateRange, type }) => {
            this.props.exportAccountParticipants({ email, responsePollId, dateRange, type });
          }}
          title={'Export Account Participants'}
          subtitle={'This will export all participants who have ever interacted with a survey on this account.'}
          copy={'The CSV will be sent to the email listed below. It can take up to 1 hour to recieve a CSV export. The duration will depend on the amount of data your account has accumulated.'}
          email={this.props.user.email}
        />
      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const participantId = decode(ownProps.match.params.accountId);

  const allParticipants = state.participants || [];
  const participants = allParticipants[participantId];

  return {
    user: state.user,
    accountId,
    participants,
    loading: state.participants.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ParticipantsActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Participants));
