import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import VisibilitySettingsForm from './VisibilitySettingsForm';

import * as PollActions from '../actions/Polls';

function mapStateToProps(state, ownProps) {
  const displaySettings = ownProps.poll.displaySettings || ownProps.account.displaySettings;
  // const settings = ownProps.poll.settings || ownProps.account.defaulPollSettings;
  const settings = ownProps.poll.settings || {};

  let pollType = 'widget';
  if (ownProps.poll.settings && ownProps.poll.settings.selector) {
    pollType = 'embed';
  }
  if (ownProps.poll.settings && ownProps.poll.settings.apiOnly) {
    pollType = 'link';
  }
  if (ownProps.poll.settings && ownProps.poll.settings.shopifyAbandonedCheckout) {
    pollType = 'abandoned-checkout';
  }
  if (ownProps.poll.settings && ownProps.poll.settings.shopifyAbandonedCheckout) {
    pollType = 'email';
  }
  if (ownProps.poll.settings && ownProps.poll.settings.shopifyWebhooks) {
    pollType = 'email';
  }

  return {
    settings,
    displaySettings,
    pollType,
    loading: state.polls.loading
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(PollActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(VisibilitySettingsForm);
