import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import * as FlashNotificationActions from '../actions/FlashNotifications';
import * as PollActions from '../actions/Polls';

import CreatableSelect from 'react-select/creatable';

import { NameInput, DisplayRulesInput, RecipientsInput } from './SimpleInputs';
import { AuthSubmitButton } from './Buttons';
import Toggle from './Toggle';
import Confirm from './Confirm';
import Tooltip from './Tooltip';
import Switch from './Switch';
import DateInput from './DateInput';
import NumberInput from './NumberInput';
import AccountSettingsVisualDisplay from '../components/AccountSettingsVisualDisplay';
import DynamicRenderInput from './DynamicRenderInput';
import DynamicOpenInput from './DynamicOpenInput';

import HeaderButtons from './HeaderButtons';
import AuthForm from './AuthForm';
import SectionHeader from './SectionHeader';

import PublicPollLinkCard from '../components/PublicPollLinkCard';
import JSSnippets from '../components/JSSnippets';
import CodeBlock from '../components/CodeBlock';
import EmailSettingsVisualDisplay from '../components/EmailSettingsVisualDisplay';
import SmsSettingsVisualDisplay from '../components/SmsSettingsVisualDisplay';
import Embed from '../components/Embed';
import PagePreview from '../components/PagePreview';
import BrowserFrame from '../components/BrowserFrame';
import SurveyPreviewHeader from '../components/SurveyPreviewHeader';

import SingleImageUploader from './SingleImageUploader';

import { DisplayRulesTemplatesInput } from '../components/TemplateInputs';

import { displayRulesTemplates, defaultEmailDisplaySettings, defaultSmsDisplaySettings } from '../settings';
import { encode, getTemplateFromDisplayRules, prepareShopifyCustomerTargeting, customerTargetingOptions, shopifyCustomerTargetingOptions, getRecipients, getPollDisplayType, generatePageDisplaySettings, previewSlides } from '../utils';

import topPlacement from '../images/ppp_1.png';
import bottomPlacement from '../images/ppp_2.png'
import bottomRightPlacement from '../images/ppp_3.png'

import CreateEmailTemplateModal from './CreateEmailTemplateModal';

class PollTypeOption extends Component {
  render() {
    let selected;
    if (this.props.active) {
      selected = <div className="selected"><i className="fas fa-check" /></div>
    }
    return (<div className={`block ${this.props.active ? 'active' : ''}`} onClick={() => this.props.onChange(this.props.handle)}>
      { this.props.active ? selected : null}
      <div className="block-icon-wrapper">
        <div className="block-icon"><i className={`fas ${this.props.icon}`} /></div>
      </div>
      <div className="block-title">{this.props.title}</div>
      <div className="block-subtitle">{this.props.subtitle}</div>
    </div>)
  }
}

const pollTypes = [
{
  title: "On-site Survey",
  subtitle: "Include the survey as a pop-up on one or more pages of your website.",
  icon: 'fa-poll',
  handle: "widget"
},
{
  title: "Site Embed",
  subtitle: "Embed the survey within a designated spot on your webpage.",
  icon: 'fa-arrows-to-dot',
  handle: "embed"
},
{ 
  title: "Link",
  subtitle: "Present your survey using a link that can be accessed from anywhere.",
  icon: 'fa-link',
  handle: "link"
},
{
  title: "Exit Intent",
  subtitle: "Have the survey appear when a customer tries to leave your website.",
  icon: 'fa-person-running',
  handle: "exit-intent"
},
{ 
  title: "API",
  subtitle: "Load your survey programatically using javascript.",
  icon: 'fa-code',
  handle: "api"
},
{ 
  title: "Email",
  subtitle: "Email your survey to your customers after certain events.",
  icon: 'fa-envelope',
  handle: "email",
  shopify: true
},
{ 
  title: "SMS",
  subtitle: "Send your survey via SMS to customers after certain events.",
  icon: 'fa-sms',
  handle: "sms",
  shopify: true
}
];

const applyTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#2167f5",
    danger: "#F26c57",
  }
});

class TargetingRow extends Component {
  render() {
    const [ value, operator, inputVal ] = this.props.row;
    console.log(this.props.row);
    const selectedOption = _.find(shopifyCustomerTargetingOptions, (option) => option.value === value);

    let operators;
    let inputType = null;
    if (selectedOption) {
      inputType = selectedOption.inputType;
      operators = <div className="select-wrapper"><select value={operator} onChange={(e) => { this.props.onChange(e.target.value, 1, this.props.rowIdx, this.props.sectionIdx) }}>
      { selectedOption.operators.map((operator) => <option value={operator.value}>{operator.name}</option>) }
      </select></div>
    }

    let input = null;

    if (value) {
      input = <input type="text" value={inputVal} onChange={(e) => { this.props.onChange(e.target.value, 2, this.props.rowIdx, this.props.sectionIdx) }} />;

      if (inputType === 'number') {
        input = <NumberInput
          value={inputVal}
          onChange={(value) => { 
            console.log('onchange');
            console.log(value);
            this.props.onChange(value, 2, this.props.rowIdx, this.props.sectionIdx) }} />
      }
      if (inputType === 'date') {
        input = <DateInput
          type="date"
          value={inputVal}
          onChange={(value) => { console.log('on change'); console.log(value); this.props.onChange(value, 2, this.props.rowIdx, this.props.sectionIdx) }} />
      }
      if (inputType === 'tags') {
        let list = [];
        if (inputVal) {
          list = inputVal.split(',').map((val) => ({ label: val, value: val }));
        }
        input = <CreatableSelect
          value={list}
          isMulti
          name="react-select"
          placeholder={selectedOption.placeholder || 'Add tags...'}
          className={true ? 'react-select active' : 'react-select'}
          // onMenuOpen={() => this.setState({ isMenuOpen: true })}
          // onMenuClose={() => this.setState({ isMenuOpen: false })}
          theme={applyTheme}
          components={{ 
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null
           }}
          onChange={(value) => {
            let val = value;
            if (value) {
              val = value.map(({ value }) => _.trim(value).replace(/\./g, "")).join(',');
            }
            this.props.onChange(val, 2, this.props.rowIdx, this.props.sectionIdx);
          }}
        />
        if (operator === 'exists' || operator === 'does_not_exist') {
          input = null;
        }
      }
    }

    return (
    <div className="targeting-row">
      {/*<div>{ value }{ operator }{ inputVal }</div>*/}

      <div className="select-wrapper"><select value={value} onChange={(e) => {
        const value = e.target.value;
        const selectedOption = _.find(shopifyCustomerTargetingOptions, (option) => option.value === value);
        this.props.onChange(value, 0, this.props.rowIdx, this.props.sectionIdx, selectedOption.operators[0].value);
      }}>
        <option disabled="disabled" selected={true}>Select a condition...</option>
        { shopifyCustomerTargetingOptions.map((option) =>{
          if (option.requireOrder && !this.props.hasOrder) { return null }
          if (option.requireCustomer && !this.props.hasCustomer) { return null }
          return (<option value={option.value}>{ option.name }</option>);
        }
        )}
      </select></div>

      { operators }

      { input }

      { value ? <div className="remove" onClick={() => this.props.removeRow(this.props.rowIdx, this.props.sectionIdx)} ></div> : null }
    </div>);
  }
}

class ShopifyCustomerTargetingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nextTopInput: false,
      nextBottomInput: false
    };
  }

  removeRow(rowIdx, sectionIdx) {
    const updates = [ ...this.props.shopifyCustomerTargeting ];
    const section = [ ...updates[sectionIdx] ];
    section.splice(rowIdx, 1);

    if (section.length === 0) {
      if (sectionIdx === 0) {
        this.setState({ nextTopInput: true });
      } else {
        this.setState({ nextBottomInput: true });        
      }
    }

    updates[sectionIdx] = section;
    this.props.onChange(updates);
  }

  onChange(value, updateIdx, rowIdx, sectionIdx, operator) { 
    const updates = [ ...this.props.shopifyCustomerTargeting ];
    const isFirst = rowIdx === undefined;

    if (isFirst) {
      if (sectionIdx === 0) {
        this.setState({ nextTopInput: false });
      } else {
        this.setState({ nextBottomInput: false });        
      }
      updates[sectionIdx].push([]);
      rowIdx = updates[sectionIdx].length - 1;
    }

    const val = updates[sectionIdx][rowIdx];
    val[updateIdx] = value;

    /* Operator, value, and input move in lock step */
    if (operator) {
      val[1] = operator;
      val[2] = undefined;
    }

    if (val[1] === 'exists' || val[1] === 'does_not_exist') {
      val[2] = '';      
    }

    updates[sectionIdx][rowIdx] = val;

    this.props.onChange(updates);
  }

  render() {
    let [ top, bottom ] = this.props.shopifyCustomerTargeting;


    let bottomSection = null;
    if (bottom.length !== 0 || this.state.showAnd) {
      bottomSection = <div className="bottom">{ bottom.map((row, idx) => <TargetingRow hasCustomer={this.props.hasCustomer} hasOrder={this.props.hasOrder} key={idx} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={row} sectionIdx={1} rowIdx={idx} /> )}
        { this.state.nextBottomInput || bottom.length === 0 ? <TargetingRow hasCustomer={this.props.hasCustomer} hasOrder={this.props.hasOrder} key={-1} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={[]} sectionIdx={1} isLast={true}/> : null }
        { this.state.nextBottomInput ? null : <div className="or-button" onClick={() => this.setState({ nextBottomInput: true })}>OR</div>}</div>
    }

    return (
      <div className="card targeting-form" style={{ marginBottom: 25 }}>
        { top.map((row, idx) => <TargetingRow hasCustomer={this.props.hasCustomer} hasOrder={this.props.hasOrder} key={idx} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={row} sectionIdx={0} rowIdx={idx} /> )}
        { this.state.nextTopInput || top.length === 0 ? <TargetingRow hasCustomer={this.props.hasCustomer} hasOrder={this.props.hasOrder} key={-1} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={[]} sectionIdx={0} isLast={true}/> : null }
        { this.state.nextTopInput ? null : <div className="or-button" onClick={() => this.setState({ nextTopInput: true })}>OR</div> }

        <div className="and-button-wrapper"><div className="optional">Optional</div><div className="and-button" onClick={() => this.setState({ showAnd: true, nextBottomInput: true })}>AND</div></div>

        { bottomSection }
      </div>
    );
  }
}

class CustomExitIntentRulesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nextTopInput: false,
      nextBottomInput: false
    };
  }

  removeRow(rowIdx, sectionIdx) {
    const updates = [ ...this.props.exitIntentCartRules ];
    const section = [ ...updates[sectionIdx] ];
    section.splice(rowIdx, 1);

    if (section.length === 0) {
      if (sectionIdx === 0) {
        this.setState({ nextTopInput: true });
      } else {
        this.setState({ nextBottomInput: true });        
      }
    }

    updates[sectionIdx] = section;
    this.props.onChange(updates);
  }

  onChange(value, updateIdx, rowIdx, sectionIdx, operator) { 
    const updates = [ ...this.props.exitIntentCartRules ];
    const isFirst = rowIdx === undefined;

    if (isFirst) {
      if (sectionIdx === 0) {
        this.setState({ nextTopInput: false });
      } else {
        this.setState({ nextBottomInput: false });        
      }
      updates[sectionIdx].push([]);
      rowIdx = updates[sectionIdx].length - 1;
    }

    const val = updates[sectionIdx][rowIdx];
    val[updateIdx] = value;

    /* Operator, value, and input move in lock step */
    if (operator) {
      val[1] = operator;
      val[2] = undefined;
    }

    updates[sectionIdx][rowIdx] = val;


    this.props.onChange(updates);
  }

  render() {
    let [ top, bottom ] = this.props.exitIntentCartRules;

    let bottomSection = null;
    if (bottom.length !== 0 || this.state.showAnd) {
      bottomSection = <div className="bottom">{ bottom.map((row, idx) => <TargetingRow key={idx} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={row} sectionIdx={1} rowIdx={idx} /> )}
        { this.state.nextBottomInput || bottom.length === 0 ? <TargetingRow key={-1} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={[]} sectionIdx={1} isLast={true}/> : null }
        { this.state.nextBottomInput ? null : <div className="or-button" onClick={() => this.setState({ nextBottomInput: true })}>OR</div>}</div>
    }

    return (
      <div className="targeting-form mini" style={{ marginBottom: 25 }}>
        { top.map((row, idx) => <TargetingRow key={idx} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={row} sectionIdx={0} rowIdx={idx} /> )}
        { this.state.nextTopInput || top.length === 0 ? <TargetingRow key={-1} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={[]} sectionIdx={0} isLast={true}/> : null }
        { this.state.nextTopInput ? null : <div className="or-button" onClick={() => this.setState({ nextTopInput: true })}>OR</div> }

        <div className="and-button-wrapper"><div className="optional">Optional</div><div className="and-button" onClick={() => this.setState({ showAnd: true, nextBottomInput: true })}>AND</div></div>

        { bottomSection }
      </div>
    );
  }
}

class InlineInput extends Component {
  onChange(e) {
    const value = e.target.value.replace(/[^\d.-]/g,'');
    const name = e.target.name;

    this.props.onChange(name, value);
  }

  render() {
    return (
      <div className="inline-input" style={{ marginTop: 15 }}>
        <span className="prefix">{ this.props.prefix }</span>
        <input
          type="text"
          name={ this.props.name }
          value={ this.props.value }
          onChange={ this.onChange.bind(this) }
        />
        <span className="suffix">{ this.props.suffix }</span>
      </div> 
    );
  }
}

class ModalDynamicOpenInput extends DynamicOpenInput {
  render() {
    return (
      <div
        className={`dynamic-open-input ${this.props.disabled ? 'disabled' : ''}`}
      >
        <div style={{ marginBottom: 25, marginTop: -15, position: 'relative' }}>
          { this.props.warning }
          { this.renderInputs() }
          <div className="inline-input" style={{ marginTop: 25, marginBottom: 0 }}>
            <label>Exit Prompt<Tooltip>Allow users to exit by clicking outside the modal or require them to confirm in order to close.</Tooltip></label>

            <Switch
              options={[
                { label: 'Allow users to exit at any time.', value: false },
                { label: "Don't Allow users to exit until they complete the survey.", value: 'oncomplete' },
                { label: "Show a prompt before allowing users to exit.", value: 'prompt' },
              ]}
              vertical={true}
              value={this.props.modalExitRules}
              onChange={(value) => {
                this.props.onChange({ 'modalExitRules': value });
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}


class PollTypeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { active: false }
  }

  render() {
    let selectedType;

    return (<div className={`blocks-input thin ${this.state.active ? 'editable' : 'condensed'}`} onClick={() => this.setState({ active: !this.state.active })} style={{ marginBottom: 25, width: this.props.isShopify ? 700 : 950 }}>
      { pollTypes.map((type) => {
        if (!this.props.isShopify && type.shopify) { return null; }
        if (this.props.selectedType === type.handle) {
          return (<div className="selected"><PollTypeOption
            active={this.props.selectedType === type.handle}
            onChange={() => {
              console.log('Doing nothing');
            }} 
            {...type} /></div>)
        }
        return null;
      })}

      { !this.state.active ? <div className="edit-block-list"><i className="fas fa-plus" />Show more formats</div> : <div className="edit-block-list"><i className="fas fa-minus" />Hide Formats</div> }

      { pollTypes.map((type) => {
        if (!this.props.isShopify && type.shopify) { return null; }

        return (<PollTypeOption
          active={this.props.selectedType === type.handle}
          onChange={(handle) => {
            this.setState({ active: false });
            this.props.onChange(handle)
          }} 
          {...type}
        />)
      })}

    </div>);
  }
}

class PollEditForm extends AuthForm {
  constructor(props)  {
    super(props);

    let showPageExcludeRulesInput = false;
    if (this.props.poll.pageExcludeRules && this.props.poll.pageExcludeRules.length) {
      showPageExcludeRulesInput = true;
    }

    let pollType = 'widget';
    if (this.props.poll.settings && this.props.poll.settings.selector) {
      pollType = 'embed';
    }
    if (this.props.poll.settings && this.props.poll.settings.apiOnly) {
      pollType = 'link';
    }
    if (this.props.visibilitySettings && this.props.visibilitySettings.renderOnExitIntent) {
      pollType = 'exit-intent';
    }
    if (this.props.poll.settings && this.props.poll.settings.apiOnly && this.props.poll.settings.pollType === 'api') {
      pollType = 'api';
    }
    if (this.props.poll.settings && this.props.poll.settings.shopifyAbandonedCheckout) {
      pollType = 'abandoned-checkout';
    }
    if (this.props.poll.settings && this.props.poll.settings.shopifyAbandonedCheckout) {
      pollType = 'email';
    }
    if (this.props.poll.settings && this.props.poll.settings.shopifyWebhooks) {
      pollType = 'email';
    }
    if (this.props.poll.settings && this.props.poll.settings.sms) {
      pollType = 'sms';
    }

    const match = _.find(customerTargetingOptions, ({ value }) => _.isEqual(value, this.props.poll.settings.shopifyCustomerTargeting || [[],[]]));
    let shopifyCustomerTargetingSettingsType = match ? 'basic' : 'advanced';
    if (typeof this.props.poll.settings.shopifyCustomerTargeting === 'string') {
      shopifyCustomerTargetingSettingsType = 'basic';
    }

    let showDynamicRules = this.props.visibilitySettings.autoOpenDuration !== false || this.props.visibilitySettings.autoOpenPosition !== false || this.props.visibilitySettings.autoOpenDevice !== false;
    if (showDynamicRules) {
      showDynamicRules = 'custom';
    }

    let showDynamicRenderRules = this.props.visibilitySettings.renderDuration !== false || this.props.visibilitySettings.renderPosition !== false || this.props.visibilitySettings.renderDevice !== false;
    if (showDynamicRenderRules) {
      showDynamicRenderRules = 'custom';
    }

    this.state = { 
      inputs: {
        title: this.props.poll.title,
        pageRules: this.props.poll.pageRules,
        pageExcludeRules: this.props.poll.pageExcludeRules || [],
        recipients: this.props.poll.recipients,
        settings: this.props.poll.settings,
        visibilitySettings: this.props.visibilitySettings || {}
      },
      showDynamicRules,
      showDynamicRenderRules,
      showPageExcludeRulesInput,
      valid: undefined,
      pollType: pollType,
      shopifyCustomerTargetingSettingsType,
      pollLocationsType: getTemplateFromDisplayRules(this.props.poll.pageRules, this.props.poll.pageExcludeRules) ? 'template' : 'manual'
    };

    if (this.props.account.shop === undefined) {
      this.state.pollLocationsType = 'manual';
    }

    this.inputStates = {};
    this.setInitialState();
  }

  updateSettings(settings) {
    const inputs = this.state.inputs;
    inputs.settings = settings;
    this.setState({ inputs });
  }

  updateVisibilitySetting(key, value) {
    const inputs = this.state.inputs;
    const visibilitySettings = { ...inputs.visibilitySettings };
    visibilitySettings[key] = value;
    inputs.visibilitySettings = visibilitySettings;;
    this.setState({ inputs });
  }

  clearStrategySettings() {
    const strats = [
      'showAfterSubmission',
      'hideAfterSubmission',
      'hideAfterClose'
    ]
    strats.forEach((value) => {
      this.updateVisibilitySetting(value, false);
    });
  }

  updateVisibilitySettings(settings) {
    const inputs = this.state.inputs;
    inputs.visibilitySettings = settings;
    this.setState({ inputs });
  }

  submitAction() {
    const poll = {};

    poll.title = this.state.inputs.title;
    poll.pageRules = this.state.inputs.pageRules;
    poll.recipients = this.state.inputs.recipients;
    poll.settings = this.state.inputs.settings;
    poll.visibilitySettings = this.state.inputs.visibilitySettings;
    poll.pageExcludeRules = this.state.inputs.pageExcludeRules;

    poll._id = this.props.poll._id;
    poll.slides = this.props.poll.slides;

    const settings = poll.settings;
    const shopifyCustomerTargeting = prepareShopifyCustomerTargeting(settings.shopifyCustomerTargeting || '');

    if (shopifyCustomerTargeting) {
      let isValid = true;
      shopifyCustomerTargeting[0].forEach((rule) => {
        if (rule.length !== 3) {
          this.props.flash('Please complete each targeting rule.');
          isValid = false;
          return;
        }
        rule.map((val) => {
          if (val === undefined || val === null) {
            this.props.flash('Please complete each targeting rule.');
            isValid = false;
            return;
          }
        });
      });
      shopifyCustomerTargeting[1].forEach((rule) => {
        if (rule.length !== 3) {
          this.props.flash('Please complete each targeting rule.');
          isValid = false;
          return;
        }
        rule.map((val) => {
          if (val === undefined || val === null) {
            this.props.flash('Please complete each targeting rule.');
            isValid = false;
            return;
          }
        });
      });

      if (!isValid) {
        return;
      }
    }

    // let formData;
    // if (poll.settings.localLogoImage) {
    //   formData = new FormData();
    //   formData.append('logoImage', poll.settings.localLogoImage);
    //   delete poll.settings.localLogoImage;
    //   // delete poll.settings.logoImage;
    // }

    if (poll.visibilitySettings && poll.visibilitySettings.openAttempts) {
      if (parseInt(poll.visibilitySettings.openAttempts) == 0) {
        poll.visibilitySettings.openAttempts = '1';
      }
    }

    this.props.updatePoll(poll, undefined);
  }

  showConfirmModal() {
    this.setState({ showConfirmModal: true });
  }

  onConfirm() {
    const settings = this.state.inputs.settings;
    settings.allowResubmissions = !this.state.inputs.settings.allowResubmissions;
    this.updateSettings(settings);
  }

  onLogoImageUpload(image) {
    const inputs = this.state.inputs;
    const settings = inputs.settings;

    if (!image) {
      settings.logoImage = undefined;
      settings.localLogoImage = undefined;
    } else {
      settings.localLogoImage = image;
      settings.logoImage = image.url;
    }

    this.setState(inputs);
  }

  // showCreateEmailTemplateModal(onClose, onSave) {
  //   this.setState({ 
  //     showCreateEmailTemplateModal: true,
  //     onCreateModalClose: () => {
  //       if (onClose) {
  //         onClose();
  //       }
  //       this.setState({ showCreateEmailTemplateModal: false });
  //     },
  //     onCreateModalSave: (emailTemplateId) => {
  //       if (onSave) {
  //         onSave(emailTemplateId);
  //       }        
  //       this.setState({ showCreateEmailTemplateModal: false });
  //     }
  //   });
  // }

  // showEditEmailTemplateModal(emailTemplate) {
  //   this.setState({ showEditEmailTemplateModal: emailTemplate });
  // }

  renderInputs() {
    const isShopify = this.props.account.shop === undefined ? false : true;

    let pageExcludeRulesInput = null;
    let toggleExcludeRules = (<div
      className={`in-card-more-options toggle-exclude-rules ${this.state.showPageExcludeRulesInput ? 'cancel' : 'button'} ${this.state.inputs.pageRules.length ? '' : 'no-margin'}`}
      onClick={() => {
        this.setState({ showPageExcludeRulesInput: !this.state.showPageExcludeRulesInput }, () => {
          if (!this.state.showPageExcludeRulesInput) {
            const inputs = this.state.inputs;
            inputs.pageExcludeRules = [];
            console.log('UPDATING INPUTS');
            this.setState({ inputs });
          }
        });
      }}><div><i className={`fas fa-${this.state.showPageExcludeRulesInput ? 'remove' : 'plus' }`} />{this.state.showPageExcludeRulesInput ? 'Remove exclusion rules' : 'Add exclusion rules' }</div></div>);
    if (this.state.showPageExcludeRulesInput) {
      pageExcludeRulesInput = (
        <DisplayRulesInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Exclusion Rules"
          hideAllPages={true}
          allowEmpty={true}
          type="text"
          placeholder="URL"
          name="pageExcludeRules"
          moreInfo={<span>Fill out this section if you want to exclude certain paths from showing this survey.</span>}
          value={this.state.inputs.pageExcludeRules}
          errorMessage="Please a title for the account."
          ref={this.setRef.bind(this)}
          domains={this.props.account}
        />
      );
    }

    let recipientsInput = null;
    // if (this.state.inputs.settings.notifyOnComplete) {
      recipientsInput = (
        <RecipientsInput
          disabled={!this.state.inputs.settings.notifyOnComplete}
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Notification Email Recipients"
          moreInfo={<span>By default, this will send to the account notification settings. If you would like to override this behavior, set custom recipients instead.</span>}
          placeholder="email@domain.com"
          name="recipients"
          value={ getRecipients(this.state.inputs.recipients, this.props.account) }
          errorMessage="Please enter a valid domain name."
          ref={this.setRef.bind(this)}
        />
      );
    // }

    let warning = null;
    if (this.state.pollType === 'api') {
      warning = (<div className="input-warning" style={{ top: 25, right: 20 }}><div className="content"><div>Your survey is set to be triggered via the API only. This means any page rules will be ignored.</div></div></div>)
    }

    let abandonedCheckoutSettings = null;
    // if (this.props.user.type === 'shopify' || true) {
    //   abandonedCheckoutSettings = (
    //       <div style={{ marginTop: 25, display: 'none' }}>
    //         <label>Abandoned Checkout<Tooltip>This will automatically email the survey one hour after a customer has abandoned his or her checkout on Shopify. We recommend also making this survey link only by flipping the switch above.</Tooltip></label>
    //         <div className="inline-toggle">
    //           <Toggle
    //             active={this.state.inputs.settings.shopifyAbandonedCheckout}
    //             onChange={(value) => {
    //               const settings = this.state.inputs.settings;
    //               settings.shopifyAbandonedCheckout = value
    //               this.updateSettings(settings);
    //             }}
    //           />
    //           <div className="subtle">Email this survey when a checkout is abandoned.</div>
    //         </div>
    //       </div>

    //   );
    // }

    let pollLocations = null;

    if (['widget','embed','exit-intent'].indexOf(this.state.pollType) !== -1) {
      let pollLocationContent = (
        <div className="inputs card" style={{ paddingBottom: 1, marginBottom: 25 }} >
          { warning }
          <DisplayRulesInput
            onChange={this.onChange.bind(this)}
            onValidate={this.onValidate.bind(this)}
            label="Page Rules"
            type="text"
            placeholder="URL"
            name="pageRules"
            moreInfo={<span>Fill out this section if you want different surveys to show up on different pages of your website. If you decide to fill it out, the survey will only show up on pages which match the rules you add. If you want the survey to show up on all pages select <strong>show survey on all pages</strong>.</span>}
            value={this.state.inputs.pageRules}
            errorMessage="Please a title for the account."
            ref={this.setRef.bind(this)}
            domains={this.props.account}
          />

          <div style={{ position: 'relative', paddingBottom: this.state.showPageExcludeRulesInput ? 0 : 0 }}>
            { toggleExcludeRules }
            { pageExcludeRulesInput }
          </div>
        </div>
      );

      if (this.state.pollLocationsType === 'template') {
        const template = getTemplateFromDisplayRules(this.state.inputs.pageRules, this.state.inputs.pageExcludeRules, true);
        let extensibilityNotice = null;
        if (template && template.showExtensibilityNotice) {
          extensibilityNotice = (
          <div className="card info checkout-extensibility-notice" style={{ maxWidth: 691 }}>
            <p>If you <strong>are</strong> using <a href="https://help.shopify.com/en/manual/checkout-settings/customize-checkout-configurations/checkout-extensibility" target="_blank">checkout or account extensibility</a> → Please <a href="https://www.zigpoll.com/blog/zigpoll-shopify-checkout-extensions" target="_blank">add the app block to your post purchase and customer profile pages</a>.</p><br/>
            <p>If you <strong>are not</strong> using checkout or account extensibility → <a href="https://www.zigpoll.com/blog/zigpoll-legacy-post-purchase-installation" target="_blank">Add the Zigpoll script to your post purchase pages</a>.</p>
          </div>
          );
        }

        let displayRulesTemplateToggle = null;
        let selectedTemplate = getTemplateFromDisplayRules(this.state.inputs.pageRules, this.state.inputs.pageExcludeRules, true);
        if (selectedTemplate) {
          displayRulesTemplateToggle = (
            <div className='selected'><div className={`block active`}>
              <div>
                <div className="content">
                  <div className="block-icon-wrapper">
                    <i className={`block-icon fas ${selectedTemplate.icon || 'fa-theater-masks' }`} />
                  </div>
                  <p className="block-title"><div dangerouslySetInnerHTML={{ __html: selectedTemplate.title }}/></p>
                  <p className="block-subtitle">{ selectedTemplate.description }</p>
                </div>
                <div className="selected"><i className="fas fa-check" /></div>
              </div>
              { extensibilityNotice }
            </div></div>
          );
        }

        pollLocationContent = <div><div className={`blocks-input thin ${this.state.displayRulesTemplatesActive || !selectedTemplate ? 'editable' : 'condensed'}`} onClick={() => this.setState({ displayRulesTemplatesActive: !this.state.displayRulesTemplatesActive })}>
          { displayRulesTemplateToggle }

          { selectedTemplate && (!this.state.displayRulesTemplatesActive ? <div className="edit-block-list"><i className="fas fa-plus" />Show more templates</div> : <div className="edit-block-list"><i className="fas fa-minus" />Hide templates</div>) }

          <DisplayRulesTemplatesInput
            isShopify={isShopify}
            account={this.props.account}
            hideInputBlockClass={true}
            displayRulesTemplate={getTemplateFromDisplayRules(this.state.inputs.pageRules, this.state.inputs.pageExcludeRules)}
            onChange={(e) => {
              const value = e.target.value;
              const template = _.find(displayRulesTemplates, ({handle}) => handle === value);

              if (!template) {
                return;
              }

              const inputs = this.state.inputs;
              inputs.pageRules = template.pageRules || [];
              inputs.pageExcludeRules = template.pageExcludeRules || [];

              if (template.settings) {
                inputs.settings.urlDependant = template.settings.urlDependant;
              } else {
                inputs.settings.urlDependant = false;
                inputs.settings.shopifyCustomerTargeting = '';
              }
              this.setState(inputs);
              this.setState({ displayRulesTemplatesActive: false });
            }}
            page="edit"
          />
        </div>
      </div>
      }

      pollLocations = (
      <span>
        <SectionHeader
          title=<span><i className="fas fa-map-marked-alt" />Locations</span>
          subtitle="The survey will only appear on the pages you specify below."
          bottom={true}
          className="no-margin"
        />

        { isShopify && <div>
          <div className={`medium-switch ${this.state.pollLocationsType === 'template' ? 'left' : 'right'}`}>
            <div 
              onClick={() => this.setState({ pollLocationsType: 'template' })}
              className={`${this.state.pollLocationsType === 'template' ? 'active' : ''}`}>Use a Template</div>
            <div 
              onClick={() => this.setState({ pollLocationsType: 'manual' })}
              className={`${this.state.pollLocationsType === 'manual' ? 'active' : ''}`}>Do It Yourself</div>
          </div>
        </div> }

        <div style={{ margin: '0px auto' }}>
          { pollLocationContent }
        </div>

      </span>);
    }

    let embedTarget = null;
    if (this.state.pollType === 'embed') {
      console.log(this.state.inputs.settings);
      let selector = this.state.inputs.settings.selector || '';
      embedTarget = (
      <div style={{ marginTop: 25 }}>
        <SectionHeader
          title=<span><i class="fas fa-arrows-to-dot"></i>Embed Settings</span>
          subtitle="Configure how you want your survey to appear on the page."
          tooltip="By default, all surveys show up as a pop-up. You can embed a survey on a page if you prefer."
          className="no-margin"
        />

        <div className="inputs card" style={{ marginBottom: 25, paddingBottom: 1 }}>
          {/*
          <div style={{ marginBottom: 0 }}>
            <label>Embed the poll</label>
            <div className="inline-toggle">
              <Switch 
                options={[
                  { label: 'Include as a widget', value: 'widget'},
                  { label: 'Embed on your page', value: 'embed'}
                ]}
                value={this.state.pollType}
                onChange={(value) => {
                  if (value === 'widget') {
                    const settings = this.state.inputs.settings;
                    settings.selector = false;
                    this.updateSettings(settings);
                  } else {
                    const settings = this.state.inputs.settings;
                    settings.selector = '#zigpoll-embed-target';
                    this.updateSettings(settings);
                  }
                  this.setState({ pollType: value });
                }}
              />
            </div>
          </div>
          */}
          { this.state.pollType === 'embed' ? (<div style={{ marginTop: 0 }}>
            <p className="subtle">Paste the code below where you want the survey to appear or use a custom css selector by entering it the input box.</p>

            <div style={{ marginBottom: 20 }}>
              <CodeBlock
                language="html"
                code={`<div id="${selector.replace(/#/g, '')}"></div>`}
              />
            </div>

            <NameInput
              value={this.state.inputs.settings.selector || ''}
              label={'Selector'}
              placeholder="#zigpoll-embed-target"
              onChange={(e) => {
                const settings = this.state.inputs.settings;
                settings.selector = e.target.value;
                this.updateSettings(settings);
              }}
              onValidate={() => {
                console.log('validate');
              }}
              errorMessage="Please enter a value."
            />
          </div>) : null }
        </div>
      </div>
      );

      if (
        (['post-purchase-only', 'order-status-only'].indexOf(getTemplateFromDisplayRules(this.state.inputs.pageRules, this.state.inputs.pageExcludeRules)) !== -1) 
        && (this.props.account.shop !== undefined)
      ) {
        embedTarget = <div style={{ marginTop: 25 }}><SectionHeader
          title=<span><i class="fas fa-arrows-to-dot"></i>Embed Settings</span>
          subtitle="Configure where you want your survey to appear on the page."
          tooltip="By default, all polls show up as a pop-up. You can embed a survey on a page if you prefer."
          className="no-margin"
        />
          <div className="image-placement" style={{ marginBottom: 25 }}>
            <div className="left">
              <div className="input card">
                <label>Checkout Position<Tooltip>This placement tool is for classic post purchase stores only. If you are using checkout extensions place the app block where you want the survey to appear.</Tooltip></label>
                <Switch 
                  options={[
                    { label: 'Top Left', value: 'top-left'},
                    { label: 'Bottom Right', value: 'bottom-right'},
                    { label: 'Bottom Left', value: 'bottom-left'},
                  ]}
                  vertical={true}
                  value={this.state.inputs.settings.shopifyCheckoutPosition || 'top-left'}
                  onChange={(value) => {
                    const settings = this.state.inputs.settings;
                    settings.shopifyCheckoutPosition = value;
                    this.updateSettings(settings);
                  }}
                />
              </div>
            </div>

            <div className="images">
              <img className={`${this.state.inputs.settings.shopifyCheckoutPosition === 'top-left' || this.state.inputs.settings.shopifyCheckoutPosition === undefined ? 'active' : '' }`} src={topPlacement} />
              <img className={this.state.inputs.settings.shopifyCheckoutPosition === 'bottom-left' ? 'active' : '' } src={bottomPlacement} />
              <img className={this.state.inputs.settings.shopifyCheckoutPosition === 'bottom-right' ? 'active' : '' } src={bottomRightPlacement} />
            </div>
          </div>

          <div className="card info" style={{ marginTop: 0, }}>
            <p className="title">If you <strong>are</strong> using <a href="https://help.shopify.com/en/manual/checkout-settings/customize-checkout-configurations/checkout-extensibility" target="_blank">Checkout Extensibility</a></p><br/>
            <p>Make sure to add the app block to your thank you and order status pages. <a href="https://www.zigpoll.com/blog/zigpoll-shopify-checkout-extensions" target="_blank">Click here for a step by step guide</a>.</p>

            <hr/>

            <p className="title">If you <strong>are not</strong> using <a href="https://help.shopify.com/en/manual/checkout-settings/customize-checkout-configurations/checkout-extensibility" target="_blank">Checkout Extensibility</a></p><br/>

            <p>Shopify has <a href="https://shopify.dev/docs/apps/build/online-store/blocking-script-tags" target="_blank">stopped supporting automatic script tags</a> so you will need to add the code manually. To get Zigpoll working on your post purchase pages, add the embed code below into your additional scripts section.<br/><br/> Learn more by following our <a href="https://www.zigpoll.com/blog/zigpoll-legacy-post-purchase-installation" target="_blank">legacy post purchase installation guide</a>.</p>
          </div>
        </div>;

        embedTarget = null;
      }
    }

    let postPurchaseTargeting = null;
    let displayRulesTemplate = getTemplateFromDisplayRules(this.state.inputs.pageRules, this.state.inputs.pageExcludeRules);

    if (
      (((displayRulesTemplate === 'post-purchase-only' || displayRulesTemplate === 'order-status-only' || displayRulesTemplate === 'checkout' ) &&
      ['email', 'link', 'api', 'exit-intent'].indexOf(this.state.pollType) === -1)) ||
      (
        _.isEqual(this.state.inputs.settings.shopifyWebhooks, ['orders/fulfilled']) ||
        _.isEqual(this.state.inputs.settings.shopifyWebhooks, ['orders/paid']) ||
        _.isEqual(this.state.inputs.settings.shopifyWebhooks, ['orders/cancelled']) ||
        _.isEqual(this.state.inputs.settings.shopifyWebhooks, ['fulfillment_events/create']) ||
        this.state.inputs.settings.shopifyAbandonedCheckout
      )
    ) {

      const shopifyCustomerTargetingInput = prepareShopifyCustomerTargeting(this.state.inputs.settings.shopifyCustomerTargeting || '');
      let targetingForm = null;

      if (this.state.shopifyCustomerTargetingSettingsType === 'basic') {
        const { info, handle } = _.find(customerTargetingOptions, ({ value }) => _.isEqual(value, shopifyCustomerTargetingInput));

        targetingForm = 
          <div className="card" style={{ marginBottom: 25 }}>
            <div className="select-wrapper" style={{ width: '100%' }}>
              <select style={{width: '100%'}} value={handle} onChange={(e) => {
                const settings = this.state.inputs.settings;
                const val = e.target.value;

                const value = _.find(customerTargetingOptions, ({ handle }) => handle === val).value;

                settings.shopifyCustomerTargeting = value;

                this.updateSettings(settings);
              }}>
                { customerTargetingOptions.map(({ value, handle, label, requireCustomer }) => {
                  if (displayRulesTemplate === 'checkout' && requireCustomer) {
                    return null;
                  }
                  return (<option value={handle} key={handle}>{label}</option>)
                })}
              </select>
              <p className="subtle" style={{ marginTop: 15 }}>{info}</p>
            </div>
          </div>
      }

      if (this.state.shopifyCustomerTargetingSettingsType === 'advanced') {
        targetingForm = <ShopifyCustomerTargetingForm
          shopifyCustomerTargeting={shopifyCustomerTargetingInput}
          hasOrder={this.state.inputs.settings.shopifyAbandonedCheckout ? false : true}
          hasCustomer={displayRulesTemplate === 'checkout' ? false : true}
          onChange={(value) => {
            const settings = this.state.inputs.settings;
            settings.shopifyCustomerTargeting = value;            
            this.updateSettings(settings);
          }}
        />
      }
      postPurchaseTargeting = <div style={{marginTop: 25 }}>
        <SectionHeader
          title=<span><i class="fas fa-bullseye" />Customer Targeting Settings</span>
          subtitle="Configure which types of customers should see this survey."
          tooltip="By default, all surveys will show to all customers. If you would like to show only to new or existing customers, adjust the dropdown below."
          className="no-margin"
        />

        <div>
          <div className={`medium-switch ${this.state.shopifyCustomerTargetingSettingsType === 'advanced' ? 'right' : 'left'}`}>
            <div 
              onClick={() => {
                const settings = this.state.inputs.settings;
                settings.shopifyCustomerTargeting = [[],[]];
                this.updateSettings(settings);
                this.setState({shopifyCustomerTargetingSettingsType: 'basic' });
              }}
              className={`${this.state.shopifyCustomerTargetingSettingsType === 'basic' ? 'active' : ''}`}>Basic Options</div>
            <div 
              onClick={() => this.setState({ shopifyCustomerTargetingSettingsType: 'advanced' })}
              className={`${this.state.shopifyCustomerTargetingSettingsType === 'advanced' ? 'active' : ''}`}>Advanced Rules</div>
          </div>
        </div>

        { targetingForm }

      </div>
    } else {
      /* If you have customer targeting saved for some other reason, clear it. */
      if (this.state.inputs.settings.shopifyCustomerTargeting) {
        const settings = this.state.inputs.settings;
        settings.shopifyCustomerTargeting = false;
        this.updateSettings(settings);
      }
    }

    let emailSettingsForm = null;
    let emailPreviewForm = null;
    if (this.state.pollType === 'email' || this.state.pollType === 'sms') {
      const settings = this.state.inputs.settings;
      const emailDisplaySettings = this.props.poll.emailDisplaySettings || this.props.account.emailDisplaySettings || defaultEmailDisplaySettings;

      const smsDisplaySettings = this.props.poll.smsDisplaySettings || this.props.account.smsDisplaySettings || defaultSmsDisplaySettings;
      let selectVal;
      if (settings.shopifyAbandonedCheckout) {
        selectVal = 'abandoned-checkout';
      }
      if (settings.shopifyWebhooks) {
        selectVal = settings.shopifyWebhooks[0];
      }
      emailSettingsForm = <div style={{marginTop: 25}}>
        <SectionHeader
          title=<span><i className="fas fa-at" />{ this.state.pollType === 'email' ? "Email" : "SMS" } Settings</span>
          subtitle={`Configure when your ${this.state.pollType === 'email' ? 'email' : 'sms'} should be sent to your customers.`}
          className="no-margin"
        /> 

        <div className="card has-dynamic-input email-settings-form">
          <label>Trigger Settings<Tooltip>Adjust these settings in order to determine how and when your survey will be sent to your customers.</Tooltip></label>
          <div className="inline-input" style={{ marginTop: 15 }}>
            <span className="prefix">Send </span>
            <input
              type="text"
              name='emailDelay'
              value={ this.state.inputs.settings.emailDelay }
              onChange={(e) => {
                let val = e.target.value.replace(/[^\d.-]/g,'');
                const settings = this.state.inputs.settings;
                settings.emailDelay = val;
                this.updateSettings(settings);
              }}
            />
            <span className="suffix"><select onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              let val = e.target.value;
              const settings = this.state.inputs.settings;
              settings.emailDelayInterval = val;
              this.updateSettings(settings);
            }} value={this.state.inputs.settings.emailDelayInterval || 'hours'} style={{ textAlign: 'left', width: 'auto', display: 'inline-block' }}><option value="minutes">minutes</option><option value="hours">hours</option><option value="days">days</option></select> after <div className="select-wrapper"><select
              value={selectVal}
              onChange={(e) => {
                let val = e.target.value;
                const settings = this.state.inputs.settings;
                if (val === 'abandoned-checkout') {
                  settings.shopifyWebhooks = false;
                  settings.shopifyAbandonedCheckout = true;
                } else {
                  settings.shopifyWebhooks = [val];
                  settings.shopifyAbandonedCheckout = false;
                }
                this.updateSettings(settings);
              }}
            >
              <option value="orders/fulfilled">Order Fulfilled</option>
              <option value="orders/paid">Order Paid</option>
              <option value="orders/cancelled">Order Cancelled</option>
              <option value="fulfillment_events/create">Order Delivered</option>
              { this.state.pollType === 'email' ? <option value="abandoned-checkout">Abandoned Checkout</option> : null }
            </select></div></span>
          </div> 
        </div>

        { this.state.pollType === 'sms' ? <div className="card info" style={{ marginTop: 20 }}>
            <p>Make sure you are collecting phone numbers at checkout. <a href={`https://www.convertout.com/blog/how-to-make-phone-number-mandatory-in-shopify-checkout-2-min-tutorial`} target="_blank">Click here</a> for steps on how to collect phone numbers as part of your checkout process.</p>
          </div> : null}
      </div>
      emailPreviewForm = (
      <div style={{marginTop: 25}}>
        <SectionHeader
          title=<span><i class="fas fa-envelope" />Email Preview</span>
          subtitle="See how your email will appear in an inbox."
          className="no-margin"
        /> 

        <div style={{ position: 'relative', width: 600, margin: '0px auto' }} className="account-display no-sticky">
          <EmailSettingsVisualDisplay
            poll={this.props.poll}
            {...emailDisplaySettings }
            belowVisualDisplay={<div className="below-visual-display">
              <i className="fas fa-arrow-turn-up" />Want to change the email content for this survey? Go to your <Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}?tab=design&type=email`}>email design settings</Link> and make your changes.
            </div>}
          />
        </div>
      </div>
      )

      if (this.state.pollType === 'sms') {
        emailPreviewForm = (
        <div style={{marginTop: 25}}>
          <SectionHeader
            title=<span><i class="fas fa-sms" />SMS Preview</span>
            subtitle="See how your sms messages will appear to customers."
            className="no-margin"
          /> 

          <div style={{ position: 'relative', width: 600, margin: '0px auto' }} className="account-display">
            <SmsSettingsVisualDisplay
              {...smsDisplaySettings}
              account={this.props.account}
              poll={this.props.poll}
              height={296}
              belowVisualDisplay={<div className="below-visual-display">
                <i className="fas fa-arrow-turn-up" />Want to change the SMS content for this survey? Go to your <Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}?tab=design&type=sms`}>SMS design settings</Link> and make your changes.
              </div>}
            />
          </div>
        </div>
        );
      }
    }

    let jsOnClickSnippet = null;
    if (this.state.pollType === 'api') {
      jsOnClickSnippet = <div>
        <div style={{marginTop: 25}}>
          <JSSnippets
            account={this.props.account}
            poll={this.props.poll}
          />
        </div>
      </div>
    }

    let advancedGeneralOptions = null;
    if (this.state.showAdvancedGeneralOptions) {
      advancedGeneralOptions = (
      <div>
        <div style={{ marginTop: 25, marginBottom: 0 }}>
          <label>Anonymous Mode</label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.inputs.settings.anonMode}
              onChange={(value) => {
                const settings = this.state.inputs.settings;
                settings.anonMode = value
                this.updateSettings(settings);
              }}
            />
            <div className="subtle">Do not collect any information from participants.</div>
          </div>
        </div>
        <div style={{ marginTop: 25, marginBottom: 0 }}>
          <label>Hide X Button</label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.inputs.settings.hideXButton}
              onChange={(value) => {
                const settings = this.state.inputs.settings;
                settings.hideXButton = value
                this.updateSettings(settings);
              }}
            />
            <div className="subtle">Hide the X button on the top right of the survey.</div>
          </div>
        </div>
        <div style={{ marginTop: 25, marginBottom: 0 }}>
          <label>Hide Close Button</label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.inputs.settings.hideCloseButton}
              onChange={(value) => {
                const settings = this.state.inputs.settings;
                settings.hideCloseButton = value
                this.updateSettings(settings);
              }}
            />
            <div className="subtle">Hide the close button at the end of the survey.</div>
          </div>
        </div>
        <div style={{ marginTop: 25, marginBottom: 0 }}>
          <label>Hide Back Button</label>
          <div className="inline-toggle">
            <Toggle
              active={this.state.inputs.settings.hideBackButton}
              onChange={(value) => {
                const settings = this.state.inputs.settings;
                settings.hideBackButton = value
                this.updateSettings(settings);
              }}
            />
            <div className="subtle">Prevent the back button from appearing on this survey.</div>
          </div>
        </div>
      </div>);
    }

    let strategy = 'hideAfterPageRefresh';
    if (this.state.inputs.visibilitySettings.showAfterSubmission) {
      strategy = 'showAfterSubmission';
    }
    if (this.state.inputs.visibilitySettings.hideAfterSubmission) {
      strategy = 'hideAfterSubmission';
    }
    if (this.state.inputs.visibilitySettings.hideAfterClose) {
      strategy = 'hideAfterClose';
    }
    let hideAfterSubmissionsTooltip = (<Tooltip>This will immediatly hide the survey once the user completes it and presses close. By default a completed survey will stay on the page until the next refresh.</Tooltip>);
    if (this.state.inputs.visibilitySettings.showAfterSubmission) {
      hideAfterSubmissionsTooltip = (<Tooltip>This can only be enabled if "Show After Completed" is disabled..</Tooltip>);
    }
    let advancedOptions = null;
    let embedWarning = null;
    let shareOnlyWarning = null;

    if (this.state.showAdvancedOptions) {
      advancedOptions = (
        <div>
          <div style={{ position: 'relative' }} className={`${false ? 'disabled' : ''} has-dynamic-input`}>
            { shareOnlyWarning }
            <label>Memory Options<Tooltip>By default the survey will keep these settings applied after each visit. If you prefer to have them reset each time a user visits your site, you can select "reset" instead.</Tooltip></label>

            <div className="inline-toggle">
              <Switch
                  value={ this.state.inputs.visibilitySettings.resetEachSession === true ? true : false }
                  vertical={true}
                  options={[
                    { label: 'Persist presentation settings after a user leaves your site.', value: false },
                    { label: 'Reset presentation settings after a user leaves your site.', value: true },
                  ]}
                  onChange={(value) => {
                    this.updateVisibilitySetting('resetEachSession', value); 
                  }}
                />
            </div>
          </div>
        </div>
      );
    }

    // const pollType = getPollDisplayType(this.props.poll);
    const pollType = this.state.poll;
    if (pollType === 'embed') {
      embedWarning = (<div className="input-warning"><div className="content"><div>Your survey is set to be embedded. This setting will not have any effect.</div></div></div>)
    }
    if (pollType === 'link') {
      shareOnlyWarning = (<div className="input-warning"><div className="content"><div>Your survey is set to be link only. This setting will not have any effect.</div></div></div>)
    }
    if (pollType === 'email') {
      shareOnlyWarning = (<div className="input-warning"><div className="content"><div>Your survey is set to be email only. This setting will not have any effect.</div></div></div>)      
    }
    let modalWarning = null;
    if (this.props.displaySettings.align === 'modal') {
      modalWarning = (<div className="input-warning"><div className="content"><div>Your survey is set to appear as a modal. This setting will not have any effect.</div></div></div>)
    }

    let exitIntentWarning = null;
    let exitIntentTimeoutInput = null;
    let exitIntentDeviceInput = null;
    let exitIntentIntensityInput = null;
    if (this.state.inputs.visibilitySettings.renderOnExitIntent) {
      exitIntentWarning = (<div className="input-warning"><div className="content"><div>Your survey is set to appear on exit intent. This setting will not have any effect.</div></div></div>)

      const isShopify = this.props.account.shop === undefined ? false : true;
      let shopifyCartOption = null;
      let customExitIntentCartRules = null;
      if (isShopify) {
        shopifyCartOption = <div style={{marginBottom: 25 }}>
          <label>Exit Intent conditions<Tooltip>The exit intent survey will only appear after the user has been on your site for at least this amount of time.</Tooltip></label>

          <Switch
            value={this.state.inputs.visibilitySettings.exitIntentCondition || ''}
            vertical={true}
            options={[
              { label: 'Only show when there is at least one item in the cart.', value: 'cartOnly' },
              { label: 'Only show when the cart is empty.', value: 'emptyCartOnly' },
              { label: 'Show no matter what.', value: '' },
              { label: 'Set custom rules.', value: 'customCartRules' },
            ]}
            onChange={(value) => {
              this.updateVisibilitySetting('exitIntentCondition', value);
            }}
          />
        </div>

        if (this.state.inputs.visibilitySettings.exitIntentCondition === 'customCartRules') {        
          customExitIntentCartRules = <CustomExitIntentRulesForm
            exitIntentCartRules={this.state.inputs.visibilitySettings.exitIntentCartRules || [[],[]]}
            onChange={(value) => {
              const inputs = { ...this.state.inputs };
              let visibilitySettings = inputs.visibilitySettings;
              visibilitySettings.exitIntentCartRules = value;
              inputs.visibilitySettings = visibilitySettings;
              this.setState({ inputs });
            }}
          />
        }
      }

      exitIntentTimeoutInput = (<div className="has-dynamic-input inline-toggle" style={{ marginTop: 0, marginBottom: 30 }}>

        { shopifyCartOption }
        { customExitIntentCartRules }

        <label>Exit Intent Timer<Tooltip>The exit intent survey will only appear after the user has been on your site for at least this amount of time.</Tooltip></label>
        <InlineInput
          name="exitIntentTimeout"
          value={ this.state.inputs.visibilitySettings.exitIntentTimeout === undefined ? 20 : this.state.inputs.visibilitySettings.exitIntentTimeout }
          prefix="Enable exit intent after "
          suffix=" seconds spent on your site."
          onChange={ this.updateVisibilitySetting.bind(this) }
        />
      </div>);

      exitIntentDeviceInput = (
        <div className="inline-input" style={{ marginTop: -15, marginBottom: 25 }}>
          <label>Device<Tooltip>Choose if you want to render the survey on all devices or just one or the other.</Tooltip></label>

          <Switch
            options={[
              { label: 'All Devices', value: false },
              { label: 'Desktop Only', value: 'desktop' },
              { label: 'Mobile Only', value: 'mobile' },
            ]}
            value={ this.state.inputs.visibilitySettings.exitIntentDevice === undefined ? false : this.state.inputs.visibilitySettings.exitIntentDevice }
            onChange={(value) => {
              this.updateVisibilitySetting('exitIntentDevice', value); 
            }}
          />
        </div>
      );

      exitIntentIntensityInput = (
        <div className="inline-input" style={{ marginTop: 0, marginBottom: 25 }}>
          <label>Sensitivity<Tooltip>Choose how sensitive you want us to be when displaying an exit intent survey. High sensitivity will have more false positives then less sensitivity but less sensitivity will be shown fewer times.</Tooltip></label>

          <Switch
            options={[
              { label: 'Default', value: 'normal' },
              { label: 'Less Sensitive', value: 'low' },
              { label: 'Very Sensitive', value: 'high' },
            ]}
            value={ this.state.inputs.visibilitySettings.exitIntentSensitivity === undefined ? 'normal' : this.state.inputs.visibilitySettings.exitIntentSensitivity }
            onChange={(value) => {
              this.updateVisibilitySetting('exitIntentSensitivity', value); 
            }}
          />
        </div>
      );
    }

    let launcherHiddenWarning = null;
    if (this.props.displaySettings.hideLauncher && (!this.state.showDynamicRenderRules)) {
      launcherHiddenWarning = (<div className="input-warning"><div className="content"><div>Your survey has it's launcher set to hidden. This means you must open your survey using dynamic rules if you want your customers to be able to see it.</div></div></div>)
    }

    let popupSettings = (
      <div style={{ marginTop: 20 }}>
        { !this.props.displaySettings.hideLauncher && <span><SectionHeader
          title=<span><i class="far fa-circle"></i>Launcher Pop-up Settings</span>
          subtitle="Adjust when your launcher will pop-up on your site."
          bottom={true}
          className="small"
        />

        <div className="account-settings card" style={{ marginTop: 0, marginBottom: 25 }}>
          <DynamicRenderInput
            toggleWarning={warning || launcherHiddenWarning}
            warning={shareOnlyWarning || warning || exitIntentWarning}
            renderPosition={this.state.inputs.visibilitySettings.renderPosition}
            renderDurationType={this.state.inputs.visibilitySettings.renderDurationType}
            renderDuration={this.state.inputs.visibilitySettings.renderDuration}
            renderDevice={this.state.inputs.visibilitySettings.renderDevice}
            showDynamicRules={this.state.showDynamicRenderRules}
            onChange={(state) => {
              const inputs = { ...this.state.inputs };
              let visibilitySettings = inputs.visibilitySettings;
              visibilitySettings = { ...visibilitySettings, ...state }
              inputs.visibilitySettings = visibilitySettings;
              this.setState({ inputs });
            }}
            onSubmit={(renderDuration, autoOpenPosition, autoOpenDevice) => {
              console.log('submit should never be called')
            }}
            onToggle={(value) => {
              this.setState({ showDynamicRenderRules: value });
            }}
          />
        </div>
      </span> }

      {true && <span><SectionHeader
        title=<span><i class="far fa-square"></i>Survey Pop-up Settings</span>
        subtitle="Adjust when your survey will pop-up on your site."
        bottom={true}
        className="small"
      />

      <div className="account-settings card" style={{ marginTop: 0, marginBottom: 25 }}>
        <DynamicOpenInput
          // toggleWarning={warning || launcherHiddenWarning}
          // warning={shareOnlyWarning || warning || exitIntentWarning}
          hasLauncher={!this.props.displaySettings.hideLauncher}
          autoOpenPosition={this.state.inputs.visibilitySettings.autoOpenPosition}
          autoOpenDuration={this.state.inputs.visibilitySettings.autoOpenDuration}
          autoOpenDurationType={this.state.inputs.visibilitySettings.autoOpenDurationType}
          autoOpenDevice={this.state.inputs.visibilitySettings.autoOpenDevice}
          modalExitRules={this.state.inputs.visibilitySettings.modalExitRules || false}
          showDynamicRules={this.state.showDynamicRules}
          onChange={(state) => {
            const inputs = { ...this.state.inputs };
            let visibilitySettings = inputs.visibilitySettings;
            visibilitySettings = { ...visibilitySettings, ...state }
            inputs.visibilitySettings = visibilitySettings;
            this.setState({ inputs });
          }}
          onSubmit={(autoOpenDuration, autoOpenPosition, autoOpenDevice) => {
            console.log('submit should never be called')
            // this.setState({ autoOpenDuration, autoOpenPosition, autoOpenDevice }, () => this.updateSettings('The behavior settings have been updated.'));
          }}
          onToggle={(value) => {
            this.setState({ showDynamicRules: value });
          }}
        />
      </div></span> }

      </div>
    );

    if (this.props.displaySettings.align === 'modal') {
      popupSettings = (<div style={{ marginTop: 20 }}>
        { !shareOnlyWarning && !exitIntentWarning && <span><SectionHeader
          title=<span><i class="far fa-square-full"></i>Survey Pop-up Settings</span>
          subtitle="Adjust when your survey will pop-up on your site."
          bottom={true}
          className="small"
        />
        <div className="account-settings card " style={{ marginTop: 0, marginBottom: 25 }}>
          <ModalDynamicOpenInput
            warning={shareOnlyWarning || exitIntentWarning}
            autoOpenPosition={this.state.inputs.visibilitySettings.modalOpenPosition || 0}
            autoOpenDuration={this.state.inputs.visibilitySettings.modalOpenDuration || 0}
            autoOpenDurationType={this.state.inputs.visibilitySettings.modalOpenDurationType || 0}
            autoOpenDevice={this.state.inputs.visibilitySettings.modalOpenDevice || false}
            modalExitRules={this.state.inputs.visibilitySettings.modalExitRules || false}
            onChange={(state) => {
              const inputs = { ...this.state.inputs };
              let visibilitySettings = inputs.visibilitySettings;
              visibilitySettings = { ...visibilitySettings, ...state }
              inputs.visibilitySettings = visibilitySettings;
              this.setState({ inputs });
            }}
            onSubmit={(autoOpenDuration, autoOpenPosition, autoOpenDevice) => {
              console.log('submit should never be called')
              // this.setState({ autoOpenDuration, autoOpenPosition, autoOpenDevice }, () => this.updateSettings('The behavior settings have been updated.'));
            }}
            names={['modalOpenDuration', 'modalOpenPosition', 'modalOpenDevice', 'modalOpenDurationType']}
            labels={[ 'Modal Timing', 'Modal Position', 'Device' ]}
            onToggle={(value) => {
              this.setState({ showDynamicRules: true });
            }}
          />
        </div></span>}
      </div>)
    }

    if (this.state.pollType !== 'widget') {
      popupSettings = null;
    }

    let exitIntentSettings = null;
    if (this.state.pollType === 'exit-intent') {
      exitIntentSettings = (
        <div style={{ marginTop: 20 }}>
          <SectionHeader
          title=<span><i class="fas fa-up-right-from-square"></i>Exit Intent Settings</span>
          subtitle="Control your exit intent settings."
          bottom={true}
          className="small"
        />

        <div className="card account-settings">

{/*
          <div style={{ position: 'relative' }} className={false ? 'disabled' : ''}>
            { shareOnlyWarning }

            <label>Appear On Exit Intent Only <Tooltip>The survey will render and open when the user tries to exit the page.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={ this.state.inputs.visibilitySettings.renderOnExitIntent }
                onChange={(value) => {
                  this.updateVisibilitySetting('renderOnExitIntent', value);
                }}
              />
              <div className="subtle">Render and open when the user shows Exit Intent.</div>
            </div>
          </div>
*/}

          { exitIntentIntensityInput }
          { exitIntentTimeoutInput }
          { exitIntentDeviceInput }
          <div className="inline-input" style={{ marginTop: 25, marginBottom: 0 }}>
            <label>Exit Prompt<Tooltip>Allow users to exit by clicking outside the modal or require them to confirm in order to close.</Tooltip></label>

            <Switch
              options={[
                { label: 'Allow users to exit at any time.', value: false },
                { label: "Don't Allow users to exit until they complete the survey.", value: 'oncomplete' },
                { label: "Show a prompt before allowing users to exit.", value: 'prompt' },
              ]}
              vertical={true}
              value={this.state.inputs.visibilitySettings.modalExitRules || false}
              onChange={(value) => {
                this.updateVisibilitySetting('modalExitRules', value);
              }}
            />
          </div>
        </div>
      </div>
      );
    }

    return (
      <div>
        <SectionHeader
          title=<span><i class="fas fa-shapes"></i>Delivery Format</span>
          subtitle="Select how you want your survey to appear."
          bottom={true}
          className="no-margin"
        />

        <PollTypeSelector
          isShopify={isShopify}
          selectedType={this.state.pollType}
          onChange={(value) => {
            const settings = this.state.inputs.settings;
            const visibilitySettings = this.state.inputs.visibilitySettings;
            let pollType = 'widget';
            if (value === 'widget') {
              settings.apiOnly = false
              settings.shopifyAbandonedCheckout = false;
              settings.selector = false;
              settings.shopifyWebhooks = false;
              settings.emailDelay = false;
              settings.sms = false;
            } else if (value === 'embed') {
              settings.apiOnly = false;
              settings.shopifyAbandonedCheckout = false;
              settings.selector = "#zigpoll-embed-target";
              settings.shopifyWebhooks = false;
              settings.emailDelay = false;
              settings.sms = false;
              pollType = 'embed';
            } else if (value === 'exit-intent') {
              settings.apiOnly = false
              settings.shopifyAbandonedCheckout = false;
              settings.selector = false;
              settings.shopifyWebhooks = false;
              settings.emailDelay = false;
              settings.sms = false;
              visibilitySettings.renderOnExitIntent = true;
              pollType = 'exit-intent'
            } else if (value === 'link') {
              settings.apiOnly = true;
              settings.shopifyAbandonedCheckout = false;
              settings.selector = false;
              settings.shopifyWebhooks = false;
              settings.emailDelay = false;
              settings.sms = false;
              pollType = 'link';
            } else if (value === 'api') {
              settings.apiOnly = true;
              settings.shopifyAbandonedCheckout = false;
              settings.selector = false;
              settings.shopifyWebhooks = false;
              settings.emailDelay = false;
              settings.sms = false;
              pollType = 'api';
            } else if (value === 'abandoned-checkout') {
              settings.apiOnly = true;
              settings.shopifyAbandonedCheckout = true;
              settings.selector = false;
              settings.shopifyWebhooks = false;
              settings.emailDelay = 1;
              settings.sms = false;
              pollType = 'abandoned-checkout';
            } else if (value === 'email') {
              settings.apiOnly = true;
              settings.shopifyAbandonedCheckout = false;
              settings.selector = false;
              settings.shopifyWebhooks = settings.shopifyWebhooks || ['orders/fulfilled'];
              settings.emailDelay = 1;
              settings.sms = false;
              pollType = 'email';
            } else if (value === 'sms') {
              settings.apiOnly = true;
              settings.shopifyAbandonedCheckout = false;
              settings.selector = false;
              settings.shopifyWebhooks = settings.shopifyWebhooks || ['orders/fulfilled'];
              settings.emailDelay = 1;
              settings.sms = true;
              pollType = 'sms';
            }

            settings.pollType = pollType;

            console.log('updating settings');
            console.log(settings);

            this.updateSettings(settings);  
            this.updateVisibilitySettings(visibilitySettings);
            this.setState({ pollType });
          }}
        />

        { emailSettingsForm }
        { emailPreviewForm }

        { pollLocations }
        { embedTarget }

        { postPurchaseTargeting }

        { jsOnClickSnippet }

        { this.state.pollType === 'link' && <PublicPollLinkCard
          account={this.props.account}
          poll={this.props.poll}
          pollId={this.props.pollId}
          toggleVisibility={this.props.toggleVisibility}
          accountId={this.props.accountId}
          integrationSettings={this.props.account.integrationSettings || {}}
          className="no-margin"
          style={{ marginTop: 25, marginBottom: 25 }}
        /> }

        { abandonedCheckoutSettings }

        { exitIntentSettings }
        { popupSettings }

        { ['link', 'api', 'email', 'sms'].indexOf(this.state.pollType) === -1 && <><SectionHeader
          title=<span><i class="fas fa-window-restore"></i>Visibility Strategy</span>
          subtitle="Adjust how your survey will behave after being filled out or closed."
          bottom={true}
          className="small"
        />

        <div className="card has-advanced-options has-dynamic-input account-settings">
          { !(shareOnlyWarning || modalWarning) && <div className={false ? 'disabled' : ''} style={{ position: 'relative', marginBottom: 20 }}>
            <label>Pop-up Visibility Strategy<Tooltip>Select which behavior you would like enabled from the options below. Different strategies are useful for different types of surveys.</Tooltip></label>
            <p className="subtle bold" style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}>{this.props.isAccountVisibilitySettingsForm ? 'All surveys will' : 'This survey will'}<i className="subtle-arrow fas fa-arrow-turn-down" /></p>
            <div className="inline-toggle">
              <Switch
                value={strategy}
                vertical={true}
                options={[
                  { label: 'Hide immediately when closed even if not completed.', value: 'hideAfterClose' },
                  { label: 'Hide immediately when closed and completed.', value: 'hideAfterSubmission' },
                  { label: 'Remain visible when completed until page is refreshed.', value: 'hideAfterPageRefresh' },
                  { label: 'Keep appearing even after completed.', value: 'showAfterSubmission' },
                ]}
                onChange={(value) => {
                  this.clearStrategySettings();
                  this.updateVisibilitySetting(value, true);
                }}
              />
            </div>
          </div> }

          {/*
          <div style={{ display: hideAfterSubmissionsTooltip ? 'none' : 'block', position: 'relative' }} className={embedWarning ? 'disabled' : ''}>
            {embedWarning}
            <label>Hide Launcher Once Completed{hideAfterSubmissionsTooltip}</label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.inputs.visibilitySettings.hideAfterSubmission}
                disabled={this.state.inputs.visibilitySettings.showAfterSubmission}
                onChange={(value) => {
                  this.updateSetting('hideAfterSubmission', value);
                }}
              />
              <div className="subtle">The poll will disappear after it has been completed.</div>
            </div>
          </div>

          <div>
            <label>Continue to appear once completed{ this.state.inputs.visibilitySettings.hideAfterSubmission ? <Tooltip>This can only be enabled if "Hide After Submission" is disabled.</Tooltip> : <Tooltip>If you think people will want to revisit the results, enable this. If you think people will want to complete the pull multiple times, enable "Allow Resubmissions" on the general tab instead.</Tooltip> }</label>
            <div className="inline-toggle">
              <Toggle
                active={this.state.inputs.visibilitySettings.showAfterSubmission}
                // disabled={this.state.inputs.visibilitySettings.hideAfterSubmission}
                onChange={(value) => {
                  this.updateSetting('showAfterSubmission', value );
                }}
              />
              <div className="subtle">The poll will still render after it's been completed.</div>
            </div>
          </div>
          */}

          <div style={{ position: 'relative' }} className={`${false ? 'disabled' : ''} has-dynamic-input`} style={{ marginBottom: 12, marginTop: 0 }}>
            { shareOnlyWarning }
            <label>Presentation Options<Tooltip>By default the survey will appear forever until it's completed. If you prefer to not show the survey as often you can adjust how many time's it's shown by filling in the input box below.</Tooltip></label>

            <div className="inline-toggle">
              <Switch
                  value={ this.state.inputs.visibilitySettings.openAttempts === true ? true : false }
                  vertical={true}
                  options={[
                    { 
                      inlineInput: true,
                      name: "openAttempts",
                      value: false,
                      inputValue: this.state.inputs.visibilitySettings.openAttempts === true ? '' :  this.state.inputs.visibilitySettings.openAttempts,
                      prefix: "Stop showing survey after ",
                      suffix: " attempts."
                     },
                    { label: this.props.isAccountVisibilitySettingsForm ? 'Present the surveys forever if not completed.' : 'Present the survey forever if not completed.', value: true },
                  ]}
                  onChange={(value) => {
                    if (value === true) {
                      this.updateVisibilitySetting('openAttempts', value);
                    } else if ((value === false)) {
                      if (this.state.inputs.visibilitySettings.openAttempts === true || this.state.inputs.visibilitySettings.openAttempts === false) {
                        this.updateVisibilitySetting('openAttempts', '10'); 
                      }
                    } else {
                      this.updateVisibilitySetting('openAttempts', value); 
                    }
                  }}
                />
            </div>
          </div>

          <div className="inline-toggle">
            <label>URL Dependant<Tooltip>This will treat each survey as unique if it is visited on a new url. This is useful if you have dynamic pages and want to run the same survey multiple times depending on the context. For example: running a survey on a post purchase page.</Tooltip></label>
            <div className="inline-toggle absolute">
              <Switch
                value={this.state.inputs.settings.urlDependant || false}
                vertical={true}
                options={[
                  { label: 'Keep the survey the same regardless of where it was delivered.', value: false },
                  { label: 'Reset the survey when presented on a new url.', value: true }
                ]}
                onChange={(value) => {
                  const settings = this.state.inputs.settings;
                  settings.urlDependant = value;
                  this.updateSettings(settings);
                }}
              />
            </div>
          </div>

          { advancedOptions }

          <div className="show-advanced" onClick={() => this.setState({ showAdvancedOptions: !this.state.showAdvancedOptions})} >{ !this.state.showAdvancedOptions ? 'More Options' : 'Less Options' }</div>
        </div></>}
      </div>
    );
  }

  // renderModals() {
  //   return <span>
  //     { this.state.showCreateEmailTemplateModal && <CreateEmailTemplateModal 
  //       isOpen={this.state.showCreateEmailTemplateModal}
  //       settings={this.props.poll.emailDisplaySettings || this.props.account.emailDisplaySettings}
  //       poll={this.props.poll}
  //       account={this.props.account}
  //       onClose={() => this.state.onCreateModalClose()}
  //       onSave={(emailTemplateId) => this.state.onCreateModalSave(emailTemplateId)}
  //     /> }
  //     { this.state.showEditEmailTemplateModal && <CreateEmailTemplateModal 
  //       isOpen={this.state.showEditEmailTemplateModal}
  //       isEdit={true}
  //       settings={this.state.showEditEmailTemplateModal}
  //       poll={this.props.poll}
  //       account={this.props.account}
  //       onClose={() => {
  //         this.setState({ showEditEmailTemplateModal: false })
  //       }}
  //       onSave={() => {
  //         this.setState({ showEditEmailTemplateModal: false })
  //       }}
  //     /> }
  //   </span>
  // }

  renderActions() {
    return (
      <div className="actions card">
        <AuthSubmitButton
          title="Update Survey" 
          disabled={!this.hasChanged()}
        />

        <div className="login">
          <div>Need help understanding surveys?</div>
          <a href="https://docs.zigpoll.com/polls" target="_blank" rel="noopener noreferrer">Learn more</a>
        </div>

        <HeaderButtons>
          <button 
            disabled={!this.hasChanged()}
            onClick={this.onSubmit.bind(this)}>Update Survey</button>
        </HeaderButtons>
      </div>
    )
  }

  render() {
    let pageDisplaySettings = this.props.account.pageDisplaySettings || generatePageDisplaySettings(this.props);
    if (this.props.poll && this.props.poll.pageDisplaySettings) {
      pageDisplaySettings = this.props.poll.pageDisplaySettings;
    }
    let settings = this.props.account.displaySettings;
    if (this.props.displaySettings) {
      settings = this.props.displaySettings;
    }

    const pollType = getPollDisplayType(this.props.poll);

    let belowVisualDisplay = <div className="below-visual-display"><i className="fas fa-arrow-turn-up" />A preview of the survey titled: <Link to={`/dashboard/a/${encode(this.props.accountId)}/p/${encode(this.props.poll._id)}`}>{this.props.poll.title}</Link> is printed above. <Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.poll._id)}?tab=slides`}>Click here</Link> to make edits or <a href={`/preview/${this.props.accountId}/${this.props.poll._id.toString()}`} target="_blank">click here to try a full demo</a>.</div>

    return (
    <div className="poll-edit-form">
      <div className="split big">
        <div>
          <form onSubmit={this.onSubmit.bind(this)} className={`${this.state.valid === false ? 'invalid' : ''} ${this.state.loading || this.props.loading ? 'loading' : ''} ${this.props.className}`}>
            { this.renderInputs() }
            { this.renderActions() }
            {/*{ this.renderModals() }*/}
          </form>
        </div>

        <div className="preview-container-wrapper">
          <SurveyPreviewHeader
            account={this.props.account}
            poll={this.props.poll}
          />

          <div className="preview-container">
            <VisualDisplay
              slides={ [...this.props.poll.slides] }
              // currentIdx={this.state.currentIdx}
              // prev={this.prev.bind(this)}
              // next={this.next.bind(this)}
              // goto={this.goto.bind(this)}
              singleSlidePage={true}
              // showCounter={showCounter}
              belowVisualDisplay={belowVisualDisplay}
              pageDisplaySettings={pageDisplaySettings}
              pollType={this.state.pollType}
              hideCloseButton={this.props.poll.settings.hideCloseButton}
              hideXButton={this.props.poll.settings.hideXButton}
              {...settings }
            />
          </div>
        </div>
      </div>
    </div>
    );
  }
}

class VisualDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = { currentIdx: 0, active: true, prevIndexes: [] };
  }

  componentDidUpdate(prevProps, prevState) {  
    if (!_.isEqual(prevProps.slides, this.props.slides)) {
      this.reset();
    }
  }

  next() {
    this.state.prevIndexes.push(this.state.currentIdx);
    this.setState({ currentIdx: this.state.currentIdx + 1 });
  }

  prev() {
    const idx = this.state.prevIndexes.pop();
    if (idx !== undefined) {
      this.setState({ currentIdx: idx });
    }
  }

  goto(slideId) {
    let idx = undefined;
    this.props.slides.forEach(({ _id }, i) => {
      if (_id === slideId) {
        idx = i;
      }
    })
    if (idx === undefined) { return this.next(); }

    this.state.prevIndexes.push(this.state.currentIdx);
    this.setState({ currentIdx: idx });
  }

  reset() {
    this.setState({ currentIdx: 0, prevIndexes: [] });
  }

  render() {
    let showEmbed = !this.props.loading;

    let subtitle;
    let title;
    let showTitle;
    if (this.props.slides.length === 0) {
      showTitle = true;
      subtitle = <div><i className="fas fa-info-circle warning"/>There are no slides for this survey.</div>
      showEmbed = false;
    }
    if (this.props.loading) {
      subtitle = 'Loading...';
    }

    let pollPreview = <Embed 
      { ...this.props }
      toggleActive={() => { this.setState({ active: !this.state.active})}}
      active={this.state.active}
      next={this.next.bind(this)}
      prev={this.prev.bind(this)}
      goto={this.goto.bind(this)}
      currentIdx={this.state.currentIdx}
      isEmbed={this.props.pollType === 'embed'}
    />

    if (['widget', 'embed', 'api', 'exit-intent'].indexOf(this.props.pollType) === -1) {
      pollPreview = <PagePreview 
        { ...this.props }
        toggleActive={() => { this.setState({ active: !this.state.active})}}
        active={this.state.active}
        next={this.next.bind(this)}
        prev={this.prev.bind(this)}
        goto={this.goto.bind(this)}
        currentIdx={this.state.currentIdx}
      />
    }

    if (!showEmbed) {
      pollPreview = null;
    }

    let belowVisualDisplay = this.props.belowVisualDisplay || null;

    return (
    <div className="visual-display-wrapper">
      <div className="visual-display">
        <BrowserFrame 
          showTitle={showTitle}
          title={title}
          subtitle={subtitle}
        />
        { pollPreview }
      </div>
      { belowVisualDisplay }
    </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.polls.loading,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...FlashNotificationActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PollEditForm);
