import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import $ from 'jquery';

import { loadContactUsSurvey } from '../utils';

import * as UserActions from '../actions/Users';

function decodeJwtResponse(token) {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

class BelowLogin extends Component {
  googleAuthPrompt() {
    const client = window.google.accounts.oauth2.initCodeClient({
      client_id: "752064899126-8t30jc5jml997ic80qjkebfg8410fdun",
      scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
      ux_mode: 'popup',
      callback: (response) => {
        if (this.props.type === 'login') {
          this.props.googleLogin(response.code);
        } else {
          this.props.googleSignup(response.code);
        }
      }
    });
    client.requestCode();
  }

  render() {
    return (
      <div className="below-login-info">
        <div className="or"><span>Or</span></div>

        <a href="https://app.zigpoll.com/shopify/auth" className="login-button shopify"><i className="fab fa-shopify"/>{this.props.type === 'login' ? 'Log in' : 'Sign up'} with Shopify</a>
        <a className="login-button google" onClick={() => {
          this.googleAuthPrompt();
        }}><i className="fab fa-google"/>{this.props.type === 'login' ? 'Log in' : 'Sign up'} with Google</a>

        <div className="signup-assistance">Need help logging in? <a href="https://www.zigpoll.com/contact" target="_blank" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          loadContactUsSurvey();
        }}>Contact us</a>.</div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...UserActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BelowLogin);
