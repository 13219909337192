import React, { Component } from 'react';

import LayoutAbstract from './LayoutAbstract'
import Sidebar from './Sidebar';
import Breadcrumbs from './Breadcrumbs';
import PaywallModal from './PaywallModal';
import ShopifyCheckoutExtensionModal from './ShopifyCheckoutExtensionModal';
import $ from 'jquery';

function getScrollbarWidth() {

  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;

}

const sw = getScrollbarWidth();

export default class Layout extends LayoutAbstract {
  constructor(props) {
    super(props);
    this.extraClass = 'has-sidebar'
    this.foreground = React.createRef();
  }

  renderContent() {
    setTimeout(() => {
      const $foreground = $(this.foreground.current);
      if ($foreground[0] && $foreground[0].scrollHeight > $foreground.innerHeight()) {
        $foreground.addClass('has-scrollbar');
        $("#header .title").css('padding-right', sw);
      } else {
        $foreground.removeClass('has-scrollbar');
        $("#header .title").css('padding-right', 0);
      }
    }, 0);

    return (
      <div className="column-layout">
        <Sidebar />

        <div className={`foreground ${this.props.className}`} id="foreground" ref={this.foreground}>

          <Breadcrumbs
            currentPageTitle={this.props.title}
            prefix={this.props.prefix}
          />

          { this.props.headerNotification || null }
          <div id="top-bar-notification" />

          { this.props.children }

          <PaywallModal />
          <ShopifyCheckoutExtensionModal />
        </div>


        <div id="search-modal">
          <div class="overlay"></div>

          <div class="content">
            <div class="form">
              <i class="fas fa-search"></i>
              <input placeholder="Search" />
            </div>
            <div class="results"></div>
          </div>
        </div>

        <div id="search-modal">
          <div class="overlay"></div>

          <div class="content">
            <div class="form">
              <i class="fas fa-search"></i>
              <input placeholder="Search" />
            </div>
            <div class="results"></div>
          </div>
        </div>
      </div>
    );
  }
}