import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as UserActions from '../actions/Users';

import { NameInput, EmailInput } from './SimpleInputs';
import { AuthSubmitButton } from './Buttons';

import AuthForm from './AuthForm';

class UserEditForm extends AuthForm {
  constructor(props)  {
    super(props);

    this.state = { 
      inputs: {
        email: props.user.email,
        name: props.user.name 
      },
      valid: undefined
    };
    this.inputStates = {};
  }

  submitAction() {
    const user = this.state.inputs;
    user._id = this.props.user._id;
    this.props.updateUser(user);
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.user !== this.props.user) && !this.props.user.updating) {
      this.setState({
        inputs: {
          email: this.props.user.email,
          name: this.props.user.name
        }
      });
    }
  }

  renderInputs() {
    let moreInfo = null;
    if (this.props.user.type === 'shopify') {
      moreInfo = 'This account is integrated with Shopify. If you want to change this information please do it within your Shopify dashboard.'
    }
    if (this.props.user.type === 'google') {
      moreInfo = 'This account is integrated with Google. If you want to change this information please do it within your Google account settings.'
    }

    return (
      <div className="inputs">
        <NameInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Full Name"
          type="text"
          placeholder="Full Name"
          name="name"
          value={this.state.inputs.name}
          errorMessage="Please enter your full name."
          ref={this.setRef.bind(this)}
          disabled={this.props.user.type === 'shopify' || this.props.user.type === 'google'}
          moreInfo={moreInfo}
        />

        <EmailInput
          onChange={this.onChange.bind(this)}
          onValidate={this.onValidate.bind(this)}
          label="Email Address"
          type="email"
          placeholder="name@company.com"
          name="email"
          value={this.state.inputs.email}
          errorMessage="Please enter a valid email address."
          ref={this.setRef.bind(this)}
          disabled={this.props.user.type === 'shopify'}
          moreInfo={moreInfo}
        />
      </div>
    );
  }

  renderActions() {
    return (
      <div className="actions">

        <AuthSubmitButton
          disabled={this.props.user.type === 'shopify'}
          title="Update Settings"
        />

      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.user.updating,
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(UserActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEditForm);

