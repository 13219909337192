import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Tooltip from '../components/Tooltip';
import SectionHeader from '../components/SectionHeader';
import QRCodeModal from '../components/QRCodeModal';

import _ from 'lodash';

import { decode, encode, getShareDomain } from '../utils';

import copy from 'copy-to-clipboard';

import * as PollActions from '../actions/Polls';
import * as FlashNotificationActions from '../actions/FlashNotifications';

class VariableInputs extends Component {
  onChange(e) {
    e.preventDefault();
    e.stopPropagation();

    this.props.onChange(e.target.name, e.target.value, this.props.idx);
  }

  onRemove(e) {
    e.preventDefault();
    e.stopPropagation();

    console.log('removing');
    console.log(this.props.idx);

    this.props.onRemove(this.props.idx);
  }

  render() {
    return (
      <div className="variable">
        <input 
          name="label"
          value={this.props.label}
          onChange={this.onChange.bind(this)}
          placeholder={'shopify_customer_id'}
        />

        <input 
          name="value"
          value={this.props.value}
          onChange={this.onChange.bind(this)}
          placeholder={'{{ customer.id }}'}
        />

        <span className="remove" onClick={this.onRemove.bind(this)} />
      </div>
    );
  }
}

class VariableForm extends Component {
  update(variables) {
    this.props.onChange(variables);
  }

  onRemove(idx) {
    let variables = [ ...this.props.variables ];
    variables.splice(idx, 1);

    this.update(variables);
  }

  onChange(name, value, idx) {
    /* Generate Value */
    const variables = [ ...this.props.variables ];
    variables[idx][name] = value;

    this.update(variables);
  }

  addVariable(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const variables = [ ...this.props.variables ];
    variables.push({ label: '', value: '' });

    this.update(variables);
  }

  render() {
    const { variables } = this.props;
    let content = <div className="explain-variables">
      <span onClick={this.addVariable.bind(this)}><i className="fas fa-plus" />Add query variables to your public link.</span><Tooltip>Use the tool below to easily add query parameters to the public link above. Any values you add will be appended to the link above so you can copy and paste it easily. Learn more about query parameters <a target="_blank" href="https://docs.zigpoll.com/public-link">here</a>.</Tooltip></div>;

    if (variables.length !== 0) {
      content = (<div className="variables">
        { variables.map(({ label, value }, idx) => <VariableInputs label={label} value={value} idx={idx} onRemove={this.onRemove.bind(this)} onChange={this.onChange.bind(this)} />) }
        <div className="add-variable" style={{ marginBottom: 0 }} onClick={this.addVariable.bind(this)}>Add Variable</div>
      </div>);
    }

    return (<div className="input variables">
      { content }
    </div>);
  }
}

class PublicPollLinkCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  buildLink() {
    let link = `${getShareDomain(this.props.account)}${encode(this.props.accountId)}/${encode(this.props.pollId)}`;

    const { variables } = this.props;
    if (variables.length) {
      let params = variables.map(({ label, value }) => {
        if (label && value) {
          return `${label}=${value}`;
        }
      });
      params = _.compact(params);
      link = `${link}?${params.join('&')}`;
    }

    return link;
  }

  render() {
    let link = this.buildLink();
    let info = null;

    let klaviyoInfo = null;
    if (this.props.integrationSettings['klaviyo']) {
      klaviyoInfo = (
        <div className="card info full-width" style={{ marginTop: 10 }}>
          <div className="top">Sending this link using Klaviyo?</div>
          <p>Learn what <a className="underline" href="https://www.zigpoll.com/blog/zigpoll-klaviyo-urls" target="_blank" rel="noopener noreferrer">variables you should include</a> and <a href="https://www.zigpoll.com/blog/klaviyo-segment-zigpoll" target="_blank" rel="noopener noreferrer">how to segment your users</a>.</p>
        </div>
      );
    } else if (this.props.poll.settings.apiOnly) {
      klaviyoInfo = (
        <div className="card info full-width" style={{ marginTop: 10 }}>
          <p>Planning to email this survey? Learn about <a className="underline" href="https://www.zigpoll.com/blog/custom-survey-domain" target="_blank" rel="noopener noreferrer">custom survey domains</a> and <a className="underline" href="https://www.zigpoll.com/blog/how-to-setup-a-custom-sender-domain" target="_blank" rel="noopener noreferrer">custom sender domains</a> that will boost your response rate with a fully white-label experience.</p>
        </div>
      );
    }

    if (!this.props.poll.isVisible) {
      info = (
        <div className="card info full-width" style={{ marginTop: 10 }}>
          <div className="top">This survey is hidden.</div>
          <p><span className="underline" onClick={() => this.props.toggleVisibility(this.props.poll)}>Click here</span> to make the survey visible. This will make it accesable using the link above.</p>
        </div>
      );
      klaviyoInfo = null;
    }

    return (<div style={this.props.style || {}}>
      <SectionHeader
        title=<span><i class="fas fa-link"></i>Public Link</span>
        className={this.props.className}
        subtitle="Share your survey with anyone."
        tooltip="If you don't have Zigpoll embedded in your site, use this link to share the survey with whoever."
      />
      <div className={`card public-poll-link ${!this.props.poll.isVisible ? 'disabled' : ''}`}>
        <div className="split-link">
          <a
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            copy(link);
            this.props.flash('The link has been copied to your clipboard.');
          }}
          target="_blank" title={link} rel="noopener noreferrer" href={link}>{link}<i className="fas fa-copy" /></a>
          <div onClick={() => {
            this.setState({ showQRCodeModal: true });
          }}><i className="fas fa-qrcode" />QR Code</div>
          <QRCodeModal
            isOpen={this.state.showQRCodeModal}
            onClose={() => this.setState({ showQRCodeModal: false })}
            link={link}
            poll={this.props.poll}
          />
        </div>
        <VariableForm
          variables={this.props.variables}
          onChange={(variables) => {
            this.setState({ variables }, () => {
              this.props.updateQueryVars(variables);
            });
          }}
        />
      </div>

      { info }
      { klaviyoInfo }
    </div>)
  }
}

function mapStateToProps(state, ownProps) {
  const pollId = decode(ownProps.match.params.pollId);

  const misc = state.misc;
  const queryVars = misc.queryVars || {};
  const variables = queryVars[pollId] || [];

  return {
    variables
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...FlashNotificationActions, ...PollActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicPollLinkCard));

