import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import _ from 'lodash';
import Papa from 'papaparse'

import * as FlashNotificationActions from '../actions/FlashNotifications';
import * as PollActions from '../actions/Polls';

import CreatableSelect from 'react-select/creatable';

import { NameInput, DisplayRulesInput, RecipientsInput } from './SimpleInputs';
import { AuthSubmitButton } from './Buttons';
import CSVUploader from '../components/CSVUploader';
import PollIdCard from '../components/PollIdCard';
import DeleteConfirm from '../components/DeleteConfirm';
import DuplicateModal from '../components/DuplicateModal';

import Toggle from './Toggle';
import Confirm from './Confirm';
import Tooltip from './Tooltip';
import Switch from './Switch';
import DateInput from './DateInput';
import NumberInput from './NumberInput';
import AccountSettingsVisualDisplay from '../components/AccountSettingsVisualDisplay';

import HeaderButtons from './HeaderButtons';
import AuthForm from './AuthForm';
import SectionHeader from './SectionHeader';
import Modal from '../components/Modal';

import PublicPollLinkCard from '../components/PublicPollLinkCard';
import JSSnippets from '../components/JSSnippets';
import CodeBlock from '../components/CodeBlock';
import EmailSettingsVisualDisplay from '../components/EmailSettingsVisualDisplay';
import SmsSettingsVisualDisplay from '../components/SmsSettingsVisualDisplay';
import Embed from '../components/Embed';
import PagePreview from '../components/PagePreview';
import BrowserFrame from '../components/BrowserFrame';
import SurveyPreviewHeader from '../components/SurveyPreviewHeader';

import SingleImageUploader from './SingleImageUploader';

import { DisplayRulesTemplatesInput } from '../components/TemplateInputs';

import { displayRulesTemplates, defaultEmailDisplaySettings, defaultSmsDisplaySettings } from '../settings';
import { encode, getTemplateFromDisplayRules, prepareShopifyCustomerTargeting, customerTargetingOptions, shopifyCustomerTargetingOptions, getRecipients, getPollDisplayType, generatePageDisplaySettings, previewSlides } from '../utils';

import topPlacement from '../images/ppp_1.png';
import bottomPlacement from '../images/ppp_2.png'
import bottomRightPlacement from '../images/ppp_3.png'

import CreateEmailTemplateModal from './CreateEmailTemplateModal';

class PollTypeOption extends Component {
  render() {
    let selected;
    if (this.props.active) {
      selected = <div className="selected"><i className="fas fa-check" /></div>
    }
    return (<div className={`block ${this.props.active ? 'active' : ''}`} onClick={() => this.props.onChange(this.props.handle)}>
      { this.props.active ? selected : null}
      <div className="block-icon-wrapper">
        <div className="block-icon"><i className={`fas ${this.props.icon}`} /></div>
      </div>
      <div className="block-title">{this.props.title}</div>
      <div className="block-subtitle">{this.props.subtitle}</div>
    </div>)
  }
}

const pollTypes = [
{
  title: "On-site Survey",
  subtitle: "Include the survey as a pop-up on one or more pages of your website.",
  icon: 'fa-poll',
  handle: "widget"
},
{
  title: "Site Embed",
  subtitle: "Embed the survey within a designated spot on your webpage.",
  icon: 'fa-arrows-to-dot',
  handle: "embed"
},
{ 
  title: "Link",
  subtitle: "Present your survey using a link that can be accessed from anywhere.",
  icon: 'fa-link',
  handle: "link"
},
{ 
  title: "API",
  subtitle: "Load your survey programatically using javascript.",
  icon: 'fa-code',
  handle: "api"
},
{ 
  title: "Email",
  subtitle: "Email your survey to your customers after certain events.",
  icon: 'fa-envelope',
  handle: "email",
  shopify: true
},
{ 
  title: "SMS",
  subtitle: "Send your survey via SMS to customers after certain events.",
  icon: 'fa-sms',
  handle: "sms",
  shopify: true
}
];

const applyTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#2167f5",
    danger: "#F26c57",
  }
});

class TargetingRow extends Component {
  render() {
    const [ value, operator, inputVal ] = this.props.row;
    console.log(this.props.row);
    const selectedOption = _.find(shopifyCustomerTargetingOptions, (option) => option.value === value);

    let operators;
    let inputType = null;
    if (selectedOption) {
      inputType = selectedOption.inputType;
      operators = <div className="select-wrapper"><select value={operator} onChange={(e) => { this.props.onChange(e.target.value, 1, this.props.rowIdx, this.props.sectionIdx) }}>
      { selectedOption.operators.map((operator) => <option value={operator.value}>{operator.name}</option>) }
      </select></div>
    }

    let input = null;

    if (value) {
      input = <input type="text" value={inputVal} onChange={(e) => { this.props.onChange(e.target.value, 2, this.props.rowIdx, this.props.sectionIdx) }} />;

      if (inputType === 'number') {
        input = <NumberInput
          value={inputVal}
          onChange={(value) => { 
            console.log('onchange');
            console.log(value);
            this.props.onChange(value, 2, this.props.rowIdx, this.props.sectionIdx) }} />
      }
      if (inputType === 'date') {
        input = <DateInput
          type="date"
          value={inputVal}
          onChange={(value) => { console.log('on change'); console.log(value); this.props.onChange(value, 2, this.props.rowIdx, this.props.sectionIdx) }} />
      }
      if (inputType === 'tags') {
        let list = [];
        if (inputVal) {
          list = inputVal.split(',').map((val) => ({ label: val, value: val }));
        }

        input = <CreatableSelect
          value={list}
          isMulti
          name="react-select"
          placeholder={selectedOption.placeholder || 'Add tags...'}
          className={true ? 'react-select active' : 'react-select'}
          // onMenuOpen={() => this.setState({ isMenuOpen: true })}
          // onMenuClose={() => this.setState({ isMenuOpen: false })}
          theme={applyTheme}
          components={{ 
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null
           }}
          onChange={(value) => {
            let val = value;
            if (value) {
              val = value.map(({ value }) => _.trim(value).replace(/\./g, "")).join(',');
            }
            this.props.onChange(val, 2, this.props.rowIdx, this.props.sectionIdx);
          }}
        />
      }
    }

    return (
    <div className="targeting-row">
      {/*<div>{ value }{ operator }{ inputVal }</div>*/}

      <div className="select-wrapper"><select value={value} onChange={(e) => {
        const value = e.target.value;
        const selectedOption = _.find(shopifyCustomerTargetingOptions, (option) => option.value === value);
        this.props.onChange(value, 0, this.props.rowIdx, this.props.sectionIdx, selectedOption.operators[0].value);
      }}>
        <option disabled="disabled" selected={true}>Select a condition...</option>
        { shopifyCustomerTargetingOptions.map((option) =>{
          if (option.requireOrder && !this.props.hasOrder) { return null }
          if (option.requireCustomer && !this.props.hasCustomer) { return null }
          return (<option value={option.value}>{ option.name }</option>);
        }
        )}
      </select></div>

      { operators }

      { input }

      { value ? <div className="remove" onClick={() => this.props.removeRow(this.props.rowIdx, this.props.sectionIdx)} ></div> : null }
    </div>);
  }
}

class ShopifyCustomerTargetingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nextTopInput: false,
      nextBottomInput: false
    };
  }

  removeRow(rowIdx, sectionIdx) {
    const updates = [ ...this.props.shopifyCustomerTargeting ];
    const section = [ ...updates[sectionIdx] ];
    section.splice(rowIdx, 1);

    if (section.length === 0) {
      if (sectionIdx === 0) {
        this.setState({ nextTopInput: true });
      } else {
        this.setState({ nextBottomInput: true });        
      }
    }

    updates[sectionIdx] = section;
    this.props.onChange(updates);
  }

  onChange(value, updateIdx, rowIdx, sectionIdx, operator) { 
    const updates = [ ...this.props.shopifyCustomerTargeting ];
    const isFirst = rowIdx === undefined;

    if (isFirst) {
      if (sectionIdx === 0) {
        this.setState({ nextTopInput: false });
      } else {
        this.setState({ nextBottomInput: false });        
      }
      updates[sectionIdx].push([]);
      rowIdx = updates[sectionIdx].length - 1;
    }

    const val = updates[sectionIdx][rowIdx];
    val[updateIdx] = value;

    /* Operator, value, and input move in lock step */
    if (operator) {
      val[1] = operator;
      val[2] = undefined;
    }

    updates[sectionIdx][rowIdx] = val;


    this.props.onChange(updates);
  }

  render() {
    let [ top, bottom ] = this.props.shopifyCustomerTargeting;


    let bottomSection = null;
    if (bottom.length !== 0 || this.state.showAnd) {
      bottomSection = <div className="bottom">{ bottom.map((row, idx) => <TargetingRow hasCustomer={this.props.hasCustomer} hasOrder={this.props.hasOrder} key={idx} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={row} sectionIdx={1} rowIdx={idx} /> )}
        { this.state.nextBottomInput || bottom.length === 0 ? <TargetingRow hasCustomer={this.props.hasCustomer} hasOrder={this.props.hasOrder} key={-1} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={[]} sectionIdx={1} isLast={true}/> : null }
        { this.state.nextBottomInput ? null : <div className="or-button" onClick={() => this.setState({ nextBottomInput: true })}>OR</div>}</div>
    }

    return (
      <div className="card targeting-form" style={{ marginBottom: 25 }}>
        { top.map((row, idx) => <TargetingRow hasCustomer={this.props.hasCustomer} hasOrder={this.props.hasOrder} key={idx} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={row} sectionIdx={0} rowIdx={idx} /> )}
        { this.state.nextTopInput || top.length === 0 ? <TargetingRow hasCustomer={this.props.hasCustomer} hasOrder={this.props.hasOrder} key={-1} removeRow={this.removeRow.bind(this)} onChange={this.onChange.bind(this)} row={[]} sectionIdx={0} isLast={true}/> : null }
        { this.state.nextTopInput ? null : <div className="or-button" onClick={() => this.setState({ nextTopInput: true })}>OR</div> }

        <div className="and-button-wrapper"><div className="optional">Optional</div><div className="and-button" onClick={() => this.setState({ showAnd: true, nextBottomInput: true })}>AND</div></div>

        { bottomSection }
      </div>
    );
  }
}


class PollTypeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { active: false }
  }

  render() {
    let selectedType;

    return (<div className={`blocks-input thin ${this.state.active ? 'editable' : 'condensed'}`} style={{ marginBottom: 25, width: this.props.isShopify ? 700 : 950 }}>
      { pollTypes.map((type) => {
        if (!this.props.isShopify && type.shopify) { return null; }
        if (this.props.selectedType === type.handle) {
          return (<div className="selected"><PollTypeOption
            active={this.props.selectedType === type.handle}
            onChange={() => {
              console.log('Doing nothing');
            }} 
            {...type} /></div>)
        }
        return null;
      })}

      { !this.state.active ? <div className="edit-block-list" onClick={() => this.setState({ active: true })}><i className="fas fa-plus" />Show more formats</div> : <div className="edit-block-list" onClick={() => this.setState({ active: false })}><i className="fas fa-minus" />Hide Formats</div> }

      { pollTypes.map((type) => {
        if (!this.props.isShopify && type.shopify) { return null; }

        return (<PollTypeOption
          active={this.props.selectedType === type.handle}
          onChange={(handle) => {
            this.setState({ active: false });
            this.props.onChange(handle)
          }} 
          {...type}
        />)
      })}

    </div>);
  }
}

class PollEditForm extends AuthForm {
  constructor(props)  {
    super(props);

    let showPageExcludeRulesInput = false;
    if (this.props.poll.pageExcludeRules && this.props.poll.pageExcludeRules.length) {
      showPageExcludeRulesInput = true;
    }

    let pollType = 'widget';
    if (this.props.poll.settings && this.props.poll.settings.selector) {
      pollType = 'embed';
    }
    if (this.props.poll.settings && this.props.poll.settings.apiOnly) {
      pollType = 'link';
    }
    if (this.props.poll.settings && this.props.poll.settings.apiOnly && this.props.poll.settings.pollType === 'api') {
      pollType = 'api';
    }
    if (this.props.poll.settings && this.props.poll.settings.shopifyAbandonedCheckout) {
      pollType = 'abandoned-checkout';
    }
    if (this.props.poll.settings && this.props.poll.settings.shopifyAbandonedCheckout) {
      pollType = 'email';
    }
    if (this.props.poll.settings && this.props.poll.settings.shopifyWebhooks) {
      pollType = 'email';
    }
    if (this.props.poll.settings && this.props.poll.settings.sms) {
      pollType = 'sms';
    }

    const match = _.find(customerTargetingOptions, ({ value }) => _.isEqual(value, this.props.poll.settings.shopifyCustomerTargeting || [[],[]]));
    let shopifyCustomerTargetingSettingsType = match ? 'basic' : 'advanced';
    if (typeof this.props.poll.settings.shopifyCustomerTargeting === 'string') {
      shopifyCustomerTargetingSettingsType = 'basic';
    }

    this.state = { 
      inputs: {
        title: this.props.poll.title,
        pageRules: this.props.poll.pageRules,
        pageExcludeRules: this.props.poll.pageExcludeRules || [],
        recipients: this.props.poll.recipients,
        settings: this.props.poll.settings,
        visibilitySettings: this.props.visibilitySettings
      },
      showPageExcludeRulesInput,
      valid: undefined,
      pollType: pollType,
      shopifyCustomerTargetingSettingsType,
      pollLocationsType: getTemplateFromDisplayRules(this.props.poll.pageRules, this.props.poll.pageExcludeRules) ? 'template' : 'manual'
    };

    if (this.props.account.shop === undefined) {
      this.state.pollLocationsType = 'manual';
    }

    this.inputStates = {};
    this.setInitialState();
  }

  updateSettings(settings) {
    const inputs = this.state.inputs;
    inputs.settings = settings;
    this.setState({ inputs });
  }

  submitAction() {
    const poll = {};

    poll.title = this.state.inputs.title;
    poll.pageRules = this.state.inputs.pageRules;
    poll.recipients = this.state.inputs.recipients;
    poll.settings = this.state.inputs.settings;
    poll.pageExcludeRules = this.state.inputs.pageExcludeRules;

    poll._id = this.props.poll._id;
    poll.slides = this.props.poll.slides;

    const settings = poll.settings;
    const shopifyCustomerTargeting = prepareShopifyCustomerTargeting(settings.shopifyCustomerTargeting || '');

    if (shopifyCustomerTargeting) {
      let isValid = true;
      shopifyCustomerTargeting[0].forEach((rule) => {
        if (rule.length !== 3) {
          this.props.flash('Please complete each targeting rule.');
          isValid = false;
          return;
        }
        rule.map((val) => {
          if (val === undefined || val === null) {
            this.props.flash('Please complete each targeting rule.');
            isValid = false;
            return;
          }
        });
      });
      shopifyCustomerTargeting[1].forEach((rule) => {
        if (rule.length !== 3) {
          this.props.flash('Please complete each targeting rule.');
          isValid = false;
          return;
        }
        rule.map((val) => {
          if (val === undefined || val === null) {
            this.props.flash('Please complete each targeting rule.');
            isValid = false;
            return;
          }
        });
      });

      if (!isValid) {
        return;
      }
    }

    let formData;
    if (poll.settings.localLogoImage) {
      formData = new FormData();
      formData.append('logoImage', poll.settings.localLogoImage);
      delete poll.settings.localLogoImage;
      // delete poll.settings.logoImage;
    }

    this.props.updatePoll(poll, undefined, formData);
  }

  showConfirmModal() {
    this.setState({ showConfirmModal: true });
  }

  onConfirm() {
    const settings = this.state.inputs.settings;
    settings.allowResubmissions = !this.state.inputs.settings.allowResubmissions;
    this.updateSettings(settings);
  }

  onLogoImageUpload(image) {
    const inputs = this.state.inputs;
    const settings = inputs.settings;

    if (!image) {
      settings.logoImage = undefined;
      settings.localLogoImage = undefined;
    } else {
      settings.localLogoImage = image;
      settings.logoImage = image.url;
    }

    this.setState(inputs);
  }

  showCreateEmailTemplateModal(onClose, onSave) {
    this.setState({ 
      showCreateEmailTemplateModal: true,
      onCreateModalClose: () => {
        if (onClose) {
          onClose();
        }
        this.setState({ showCreateEmailTemplateModal: false });
      },
      onCreateModalSave: (emailTemplateId) => {
        if (onSave) {
          onSave(emailTemplateId);
        }        
        this.setState({ showCreateEmailTemplateModal: false });
      }
    });
  }

  showEditEmailTemplateModal(emailTemplate) {
    this.setState({ showEditEmailTemplateModal: emailTemplate });
  }

  renderInputs() {
    const isShopify = this.props.account.shop === undefined ? false : true;
    let disabled = this.state.csv ? false : true;
    if (this.state.importing) {
      disabled = true;
    }
    if (this.props.polls.loading) {
      disabled = true;
    }

    return (
      <div>
        <SectionHeader
          title=<span><i className="fas fa-cog"/>General Settings</span>
          subtitle="Adjust your survey's title."
          bottom={true}
          className="no-margin"
        />

        <div className="inputs email-notifications-form card" style={{ marginBottom: 25, paddingBottom: 1 }}>
          <NameInput
            onChange={this.onChange.bind(this)}
            onValidate={this.onValidate.bind(this)}
            label="Survey Title"
            type="text"
            placeholder="Title for the survey"
            name="title"
            value={this.state.inputs.title}
            errorMessage="Please a title for the survey."
            ref={this.setRef.bind(this)}
          />
        </div>

        <SectionHeader
          title=<span><i class="fas fa-tasks" />Management Options</span>
          subtitle="Control your account title and domain settings."
          className="no-margin small"
        />

        <div className="poll-management card account-settings" style={{ marginBottom: 20 }}>
          <div style={{marginBottom: 25}}>
            <label>Duplicate Survey<Tooltip>This will copy the survey exactly. No results or interactions will be ported over to the duplicate.</Tooltip></label>

            <div>
              <div className="subtle">This will make a copy the survey and its slides.</div>
              <button
                className="duplicate-poll"
                onClick={(value) => {
                  this.setState({ showDuplicateModal: true });
                }}
              >Duplicate</button>
            </div>
          </div>

          <div style={{marginBottom: 25}}>
            <label>Archive Survey<Tooltip>This will archive the survey so that it is only visible on the dashboard. You can unarchive at any time.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={this.props.poll.isArchived}
                onChange={(value) => {
                  if (this.props.poll.isArchived) {
                    this.props.unarchivePoll();
                  } else {
                    this.setState({ showArchiveConfirm: true });
                  }
                }}
              />
              <div className="subtle">Hide everywhere except on your dashboard.</div>
            </div>
          </div>

          <div>
            <label>Delete Survey</label>
            <div>
              <div className="subtle">This will erase the survey, its slides, and responses.</div>
              <button
                className="delete-poll"
                onClick={(value) => {
                  this.setState({ showDeleteConfirm: true });
                }}
              >Delete</button>
            </div>
          </div>
        </div>

        <PollIdCard _id={this.props.poll._id} />

        <div style={{ marginBottom: 20 }}></div>

        <SectionHeader
          title=<span><i class="fas fa-file-import" />Import Data</span>
          subtitle="Migrate existing data into this survey using a CSV import."
          className="small no-margin"
        />

        <div className="poll-management card full-width">
          <label>Upload data csv<Tooltip><a href="https://docs.zigpoll.com/polls/import" target="_blank">Click here</a> to learn more about our CSV structure and to download an example template.</Tooltip></label>
          <CSVUploader
            file={this.state.file}
            onChange={(file) => {
              if (!file) {
                const state = { ...this.state };
                state.file = undefined;
                state.csv = undefined;
                this.setState(state);
                return;
              }

              Papa.parse(file, {
                skipEmptyLines: true,
                error: (err, file, inputElem, reason) => {
                  console.log(err);
                },
                complete: ({ data }) => {
                  const state = { ...this.state };
                  state.csv = data;
                  state.file = file;
                  console.log(this.state);
                  this.setState(state);
                }
              });
            }}
          />

          <button
            className={`duplicate-poll ${this.state.csv ? 'active' : 'disabled'}`}
            disabled={disabled}
            onClick={(value) => {
              this.setState({ importing: true });
              this.props.runCsvImport(this.state.csv).then(() => {
                this.setState({ showImportSuccessModal: true });
              });
            }}
          >{ this.state.importing ? 'Uploading...' : 'Upload' }</button>
        </div>

        <SectionHeader
          title=<span><i class="fas fa-file-export" />Export Survey</span>
          className="small no-margin"
          subtitle="Export your survey questions & settings to a file."
          tooltip="Export your survey questions & settings to a .json file so you can import them easily into a different account."
        />

        <div className="poll-management full-width card">
          <p class="subtle" style={{ fontSize: 18, marginBottom: 15, lineHeight: 1.25 }}>Click below to generate an export file for this survey. You can use this file to easily import the survey into a new account.</p>
          <button
            className={`duplicate-poll ${this.state.exporting ? 'active' : 'disabled'}`}
            disabled={this.state.exporting}
            onClick={(value) => {
              this.setState({ exporting: true });
              this.props.generateExportFile()
              .then(() => {
                this.setState({ exporting: false });
              })
            }}
          >{ this.state.exporting ? 'Exporting...' : 'Generate Export File' }</button>
        </div>

        <DuplicateModal 
          title="Duplicate Survey"
          subtitle="What should the copy be called?"
          show={this.state.showDuplicateModal}
          onConfirm={(value) => this.props.duplicatePoll(value)}
          value={`${this.props.poll.title}`}
          onCancel={() => {
            this.setState({ showDuplicateModal: false });
          }}
        />

        <DeleteConfirm 
          title="Are you sure?"
          subtitle="This will permanently erase the survey, its slides, and responses forever."
          show={this.state.showDeleteConfirm}
          onConfirm={this.props.deletePoll}
          onCancel={() => {
            this.setState({ showDeleteConfirm: false });
          }}
        />

        <Modal 
          isOpen={this.state.showImportSuccessModal}
          onRequestClose={() => { this.setState({ showImportSuccessModal: false, csv: undefined, file: undefined, importing: true }) }}
        >
          <div className="frame">
            <div className="close" onClick={() => { this.setState({ showImportSuccessModal: false, csv: undefined, file: undefined, importing: true }) }} />
            <div className="title">Upload Successful</div>
            <div className="content">
              <div className="subtitle" style={{ marginBottom: 10 }}>Your import has started running.</div>
              <p className="copy">You will receive an email when it has been completed. You can close this window and navigate to another page.</p>
              <div className="actions">
                <button className="positive" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ showImportSuccessModal: false, csv: undefined, file: undefined, importing: true })
                }}>Close</button>
              </div>
            </div>
          </div>
        </Modal>

        <Confirm 
          title="Are you sure?"
          subtitle="This will set the survey's design settings to hidden and make it only accessible from the dashboard."
          show={this.state.showArchiveConfirm}
          onConfirm={this.props.archivePoll}
          onCancel={() => {
            this.setState({ showArchiveConfirm: false });
          }}
        />
      </div>
    );
  }

  renderModals() {
    return <span>
      { this.state.showCreateEmailTemplateModal && <CreateEmailTemplateModal 
        isOpen={this.state.showCreateEmailTemplateModal}
        settings={this.props.poll.emailDisplaySettings || this.props.account.emailDisplaySettings}
        poll={this.props.poll}
        account={this.props.account}
        onClose={() => this.state.onCreateModalClose()}
        onSave={(emailTemplateId) => this.state.onCreateModalSave(emailTemplateId)}
      /> }
      { this.state.showEditEmailTemplateModal && <CreateEmailTemplateModal 
        isOpen={this.state.showEditEmailTemplateModal}
        isEdit={true}
        settings={this.state.showEditEmailTemplateModal}
        poll={this.props.poll}
        account={this.props.account}
        onClose={() => {
          this.setState({ showEditEmailTemplateModal: false })
        }}
        onSave={() => {
          this.setState({ showEditEmailTemplateModal: false })
        }}
      /> }
    </span>
  }

  renderActions() {
    return (
      <div className="actions card">
        <AuthSubmitButton
          title="Update Survey" 
          disabled={!this.hasChanged()}
        />

        <div className="login">
          <div>Need help understanding surveys?</div>
          <a href="https://docs.zigpoll.com/polls" target="_blank" rel="noopener noreferrer">Learn more</a>
        </div>

        <HeaderButtons>
          <button 
            disabled={!this.hasChanged()}
            onClick={this.onSubmit.bind(this)}>Update Survey</button>
        </HeaderButtons>
      </div>
    )
  }

  render() {
    let pageDisplaySettings = this.props.account.pageDisplaySettings || generatePageDisplaySettings(this.props);
    if (this.props.poll && this.props.poll.pageDisplaySettings) {
      pageDisplaySettings = this.props.poll.pageDisplaySettings;
    }
    let settings = this.props.account.displaySettings;
    if (this.props.poll.displaySettings) {
      settings = this.props.poll.displaySettings;
    }

    const pollType = getPollDisplayType(this.props.poll);

    let belowVisualDisplay = <div className="below-visual-display"><i className="fas fa-arrow-turn-up" />A preview of the survey titled: <Link to={`/dashboard/a/${encode(this.props.accountId)}/p/${encode(this.props.poll._id)}`}>{this.props.poll.title}</Link> is printed above. <Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.poll._id)}?tab=slides`}>Click here</Link> to make edits or <a href={`/preview/${this.props.accountId}/${this.props.poll._id.toString()}`} target="_blank">click here to try a full demo</a>.</div>

    return (
    <div className="poll-edit-form">
      <div className="split big">
        <div>
          <form onSubmit={this.onSubmit.bind(this)} className={`${this.state.valid === false ? 'invalid' : ''} ${this.state.loading || this.props.loading ? 'loading' : ''} ${this.props.className}`}>
            { this.renderInputs() }
            { this.renderActions() }
            { this.renderModals() }
          </form>
        </div>

        <div className="preview-container-wrapper">
          <SurveyPreviewHeader
            account={this.props.account}
            poll={this.props.poll}
          />

          <div className="preview-container">
            <VisualDisplay
              slides={ [...this.props.poll.slides] }
              // currentIdx={this.state.currentIdx}
              // prev={this.prev.bind(this)}
              // next={this.next.bind(this)}
              // goto={this.goto.bind(this)}
              singleSlidePage={true}
              // showCounter={showCounter}
              belowVisualDisplay={belowVisualDisplay}
              pageDisplaySettings={pageDisplaySettings}
              pollType={pollType}
              hideCloseButton={this.props.poll.settings.hideCloseButton}
              hideXButton={this.props.poll.settings.hideXButton}
              {...settings }
            />
          </div>
        </div>
      </div>
    </div>
    );
  }
}

class VisualDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = { currentIdx: 0, active: true, prevIndexes: [] };
  }

  componentDidUpdate(prevProps, prevState) {  
    if (!_.isEqual(prevProps.slides, this.props.slides)) {
      this.reset();
    }
  }

  next() {
    this.state.prevIndexes.push(this.state.currentIdx);
    this.setState({ currentIdx: this.state.currentIdx + 1 });
  }

  prev() {
    const idx = this.state.prevIndexes.pop();
    if (idx !== undefined) {
      this.setState({ currentIdx: idx });
    }
  }

  goto(slideId) {
    let idx = undefined;
    this.props.slides.forEach(({ _id }, i) => {
      if (_id === slideId) {
        idx = i;
      }
    })
    if (idx === undefined) { return this.next(); }

    this.state.prevIndexes.push(this.state.currentIdx);
    this.setState({ currentIdx: idx });
  }

  reset() {
    this.setState({ currentIdx: 0, prevIndexes: [] });
  }

  render() {
    let showEmbed = !this.props.loading;

    let subtitle;
    let title;
    let showTitle;
    if (this.props.slides.length === 0) {
      showTitle = true;
      subtitle = <div><i className="fas fa-info-circle warning"/>There are no slides for this survey.</div>
      showEmbed = false;
    }
    if (this.props.loading) {
      subtitle = 'Loading...';
    }

    let pollPreview = <Embed 
      { ...this.props }
      toggleActive={() => { this.setState({ active: !this.state.active})}}
      active={this.state.active}
      next={this.next.bind(this)}
      prev={this.prev.bind(this)}
      goto={this.goto.bind(this)}
      currentIdx={this.state.currentIdx}
      isEmbed={this.props.pollType === 'embed'}
    />

    if (['widget', 'embed', 'api', 'exit-intent'].indexOf(this.props.pollType) === -1) {
      pollPreview = <PagePreview 
        { ...this.props }
        toggleActive={() => { this.setState({ active: !this.state.active})}}
        active={this.state.active}
        next={this.next.bind(this)}
        prev={this.prev.bind(this)}
        goto={this.goto.bind(this)}
        currentIdx={this.state.currentIdx}
      />
    }

    if (!showEmbed) {
      pollPreview = null;
    }

    let belowVisualDisplay = this.props.belowVisualDisplay || null;

    return (
    <div className="visual-display-wrapper">
      <div className="visual-display">
        <BrowserFrame 
          showTitle={showTitle}
          title={title}
          subtitle={subtitle}
        />
        { pollPreview }
      </div>
      { belowVisualDisplay }
    </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    polls: state.polls || {},
    loading: state.polls.loading,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...FlashNotificationActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PollEditForm);

