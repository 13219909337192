import React, { Component } from 'react';
import Tooltip from './Tooltip';

export default class SectionHeader extends Component {
  render() {
    let tooltip = null;
    if (this.props.tooltip) {
      tooltip = (
        <Tooltip bottom={this.props.bottom}>{ this.props.tooltip }</Tooltip>
      );
    }
    let rightComponent = null;
    if (this.props.rightComponent) {
      rightComponent = <div className="right">{this.props.rightComponent}</div>
    }
    return (
      <div className={`section-header ${this.props.className ? this.props.className : ''}`} style={this.props.style || {}}>
        <div className="top">
          <div className="section-subtitle">{this.props.title}</div>
          { tooltip }
          { rightComponent }
        </div>
        <div className="section-description">{ this.props.subtitle }</div>
      </div>
    );
  }
}
