import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import * as SlideActions from '../actions/Slides';

import { AuthSubmitButton } from './Buttons';

import { SlideEditFormComponent } from './SlideEditForm';

import SlideBankButton from './SlideBankButton';

import _ from 'lodash';

import HeaderButtons from './HeaderButtons';

import { stripTags } from '../utils';

class SlideCreateForm extends SlideEditFormComponent {
  constructor(props)  {
    super(props);
    this.state = { 
      inputs: {
        title: '',
        handle: '',
        subtitle: '',
        answers: this.props.slide.answers || [],
        actions: [],
        copy: '',
        rewardCode: '',
        rewardCodeType: 'static',
        type: props.slide.type,
        showTitle: props.slide.settings.showTitle,
        disableSentimentAnalysis: props.slide.settings.disableSentimentAnalysis,
        hideConfetti: props.slide.settings.hideConfetti,
        showConfetti: props.slide.settings.showConfetti,
        charLimits: props.slide.settings.charLimits,
        showResults: false,
        randomizeAnswers: false,
        reverseOrder: false,
        showResponses: false,
        autoApproveResponses: false,
        sortBy: 'votes',
        optional: false,
        hidden: false,
        nextSlide: undefined,
        responseLimit: 1,
        logic: [],
        // terminal: false
      },
      valid: undefined
    };

    this.prevType = props.slide.type;
    this.hasSubtitle = false;
    this.inputStates = {};
  }

  submitAction() {
    const type = this.state.inputs.type;

    if (type === 'form' && this.state.inputs.formInputs.length === 0) {
      return this.props.flash('Please enter form inputs if you want to create a form slide.');
    }

    // if (!this.props.poll.isVisible) {
      this.submit()
    // } else {
    //   this.setState({ showConfirmModal: true })
    // }
  }

  submit() {
    const slide = _.cloneDeep(this.state.inputs);

    slide.pollId = this.props.pollId;
    slide.title = this.state.inputs.title;
    slide.handle = this.state.inputs.handle || stripTags(this.state.inputs.title);
    slide.subtitle = this.state.inputs.subtitle;
    slide.copy = this.state.inputs.copy;
    slide.answers = _.cloneDeep(this.state.inputs.answers);
    slide.actions = this.state.inputs.actions;
    slide.placeholder = this.state.inputs.placeholder;
    slide.type = this.state.inputs.type;
    slide.leftLabel = this.state.inputs.leftLabel;
    slide.rightLabel = this.state.inputs.rightLabel;
    slide.minDate = this.state.inputs.minDate;
    slide.maxDate = this.state.inputs.maxDate;
    slide.singleFileOnly = this.state.inputs.singleFileOnly;
    slide.nextSlide = this.state.inputs.nextSlide;
    slide.rewardCode = this.state.inputs.rewardCode;
    slide.rewardCodeType = this.state.inputs.rewardCodeType;
    slide.storeCreditValues = this.state.inputs.storeCreditValues;
    slide.storeCreditExpiration = this.state.inputs.storeCreditExpiration;
    slide.rewardDiscountValue = this.state.inputs.rewardDiscountValue;
    slide.rewardDiscountType = this.state.inputs.rewardDiscountType;
    slide.rewardDiscountExpiration = this.state.inputs.rewardDiscountExpiration;
    slide.rewardDiscountAppliesToSelection = this.state.inputs.rewardDiscountAppliesToSelection;
    slide.rewardDiscountAppliesTo = this.state.inputs.rewardDiscountAppliesTo;
    slide.rewardDiscountMinimumPurchase = this.state.inputs.rewardDiscountMinimumPurchase;
    slide.rewardDiscountMinimumPurchaseValue = this.state.inputs.rewardDiscountMinimumPurchaseValue;
    slide.rewardDiscountCombinesWith = this.state.inputs.rewardDiscountCombinesWith;
    slide.rewardDiscountPurchaseType = this.state.inputs.rewardDiscountPurchaseType;
    slide.emojiStyle = this.state.inputs.emojiStyle;
    slide.displayStyle = this.state.inputs.displayStyle;
    slide.allowedFileTypes = this.state.inputs.allowedFileTypes;
    slide.fileLimit = this.state.inputs.fileLimit;
    slide.maxFileSize = this.state.inputs.maxFileSize;
    slide.rewardBanner = this.state.inputs.rewardBanner;
    slide.logic = this.state.inputs.logic;
    slide.customCTA = this.state.inputs.customCTA;

    /* Maybe need to clean this */
    if (this.state.inputs.formInputs) {
      const cleanFormInputs = [];

      this.state.inputs.formInputs.forEach((input) => {
        input.options = _.compact(input.options);
        if (input.options.length === 0 && (input.type === 'radio' || input.type === 'checkbox' || input.type === 'select' || input.type === 'inline-multiple-choice')) {
          console.log('Not including.');
        } else {
          cleanFormInputs.push(input);
        }
      });

      slide.formInputs = cleanFormInputs;
    }

    slide.settings = {};
    slide.settings.showResults = this.state.inputs.showResults;
    slide.settings.randomizeAnswers = this.state.inputs.randomizeAnswers;
    slide.settings.reverseOrder = this.state.inputs.reverseOrder;
    slide.settings.showResponses = this.state.inputs.showResponses;
    slide.settings.autoApproveResponses = this.state.inputs.autoApproveResponses;
    slide.settings.sortBy = this.state.inputs.sortBy;
    slide.settings.showTitle = this.state.inputs.showTitle;
    slide.settings.inputType = this.state.inputs.inputType;
    slide.settings.optional = this.state.inputs.optional;
    slide.settings.hidden = this.state.inputs.hidden;
    slide.settings.disableSentimentAnalysis = this.state.inputs.disableSentimentAnalysis;
    slide.settings.hideConfetti = this.state.inputs.hideConfetti;
    slide.settings.showConfetti = this.state.inputs.showConfetti;
    slide.settings.charLimits = this.state.inputs.charLimits;
    slide.settings.showAnswerTitle = this.state.inputs.showAnswerTitle;
    slide.settings.numColumns = this.state.inputs.numColumns;
    slide.settings.format = this.state.inputs.format;
    slide.settings.dateFormat = this.state.inputs.dateFormat;
    slide.settings.hideMonth = this.state.inputs.hideMonth;
    slide.settings.hideDay = this.state.inputs.hideDay;
    slide.settings.hideYear = this.state.inputs.hideYear;
    slide.settings.shopifyMetafieldConfig = this.state.inputs.shopifyMetafieldConfig;

    // this.props.createSlide(slide);
    const formData = new FormData();

    if (this.state.localImage) {
      if (this.state.localImage === -1) {
        slide.image = undefined;
      } else {
        formData.append('image', this.state.localImage);
      }
    }

    if (this.state[`local-slide-image-0`]) {
      if (this.state[`local-slide-image-0`] === -1) {
        slide['slide-image-0'] = undefined;
      } else {
        formData.append('slide-image-0', this.state[`local-slide-image-0`]);
      }
    }
    if (this.state[`local-slide-image-1`]) {
      if (this.state[`local-slide-image-1`] === -1) {
        slide['slide-image-1'] = undefined;
      } else {
        formData.append('slide-image-1', this.state[`local-slide-image-1`]);
      }
    }
    if (this.state[`local-slide-image-2`]) {
      if (this.state[`local-slide-image-2`] === -1) {
        slide['slide-image-2'] = undefined;
      } else {
        formData.append('slide-image-2', this.state[`local-slide-image-2`]);
      }
    }

    let answerImages = [];
    if (slide.type === 'image-choice') {
      slide.answers.forEach((answer, idx) => {
        if (answer.image) {
          answerImages.push({ key: `answer-image-${idx}`, value: answer.image });
          delete answer.image;
        }
      });
    }

    answerImages.forEach((image) => {
      formData.append(image.key, image.value);
    })

    formData.append('slide', JSON.stringify(slide));
    if (this.props.idx !== undefined) {
      formData.append('idx', this.props.idx);
    }

    this.props.createSlide(formData, null, null, this.props.idx);
  }

  renderActions() {
    return (
      <div className="actions">
        <AuthSubmitButton
          title="Create Slide"
          className="blue"
          onClick={this.onSubmit.bind(this)}
        />

        <div className="login">
          <div>Don't understand slides?</div>
          <a href="https://docs.zigpoll.com/slides" target="_blank" rel="noopener noreferrer">Learn more</a>
        </div>

        <HeaderButtons>
          <button
            className=""
            onClick={this.onSubmit.bind(this)}
          >Create Slide</button>
          <SlideBankButton />
        </HeaderButtons>

      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const params = qs.parse(ownProps.location.search);
  const idx = params.idx || params.insertIdx;

  return {
    loading: state.slides.loading,
    pollId: ownProps.pollId,
    idx
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(SlideActions, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SlideCreateForm));

