import React, { Component, useCallback, useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { url } from '../settings';
import { post } from '../ajax';
import { parseUrl } from '../utils';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { decode, encode } from '../utils';

import * as RouterActions from '../actions/Router';
import * as AccountActions from '../actions/Accounts';

import ColumnLoading from './ColumnLoading';
import ColumnLayout from '../components/ColumnLayout';

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

const { stripeApiKey } = require('../settings');
const stripePromise = loadStripe(stripeApiKey);

class StripeEmbeddedCheckoutReturn extends Component {  
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    const planId = urlParams.get('planId');
    const accountId = urlParams.get('accountId');

    post(`${url}/session-status`, { _id: this.props.user._id, planId, sessionId, accountId, ga_id: window.ga_id })
      .then((res) => res.json())
      .then((data) => {
        this.setState({ status: data.status });
      })
  }

  render() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    const planId = urlParams.get('planId');
    const accountId = urlParams.get('accountId');

    if (this.state.status) {
      if (this.state.status !== 'complete') {
        this.props.navigateTo(`/checkout?planId=${planId}`);
      } else {
        window.location.href = `/dashboard/a/${encode(accountId)}`;
      }
    }

    return null;
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];
  const polls = state.polls;
  const user = state.user;
  const planId = new URLSearchParams(ownProps.location.search).get('planId');

  return {
    accountId,
    accounts,
    account,
    planId,
    user,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...RouterActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StripeEmbeddedCheckoutReturn));
