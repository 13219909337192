import React, { Component, useCallback, useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { url } from '../settings';
import { post } from '../ajax';
import { parseUrl } from '../utils';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { decode } from '../utils';

import * as RouterActions from '../actions/Router';
import * as AccountActions from '../actions/Accounts';

import ColumnLoading from './ColumnLoading';
import ColumnLayout from '../components/ColumnLayout';

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

const { stripeApiKey } = require('../settings');
const stripePromise = loadStripe(stripeApiKey);

class StripeEmbeddedCheckout extends Component {  
  render() {
    const fetchClientSecret = () => {
      if (!this.props.user) { return; }
      const [ accountId ] = parseUrl(window);

      // Create a Checkout Session
      return post(`${url}/create-checkout-session`, { _id: this.props.user._id, accountId, planId: this.props.planId })
        .then((res) => res.json())
        .then((data) => data.clientSecret);
    };

    const options = { 
      fetchClientSecret,
    };

    return (
      <ColumnLayout title="Activity" className="white" graphics={true}>

        <div id="stripe-checkout" style={{ background: "#ffffff", padding: '0px 0' }}>
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={options}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </div>

      </ColumnLayout>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];
  const polls = state.polls;
  const user = state.user;
  const planId = new URLSearchParams(ownProps.location.search).get('planId');

  return {
    accountId,
    accounts,
    account,
    planId,
    user,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...RouterActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StripeEmbeddedCheckout));
