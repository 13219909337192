import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DisplaySettingsForm from './DisplaySettingsForm';

import * as AccountActions from '../actions/Accounts';
import * as FlashNotificationActions from '../actions/FlashNotifications';

class AccountDisplaySettingsForm extends DisplaySettingsForm {

}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...FlashNotificationActions }, dispatch);
}

function mapStateToProps(state) {
  return {
    loading: state.accounts.loading,
    poll: { settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: false,
      automaticallySwitchSlides: true,
      autoClose: false,
      allowEdits: false,
      apiOnly: false
    } }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDisplaySettingsForm);
