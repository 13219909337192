import _ from 'lodash';

import { replace } from 'connected-react-router';
import { pushServerError, clearNotifications } from './Notifications';
import { post, postFormData } from '../ajax';
import { encode, parseUrl } from '../utils';
import { url } from '../settings';

import { fetchAccount } from './Accounts';
import { fetchPoll } from './Polls';
import { flash } from './FlashNotifications';

export const CREATING_SLIDE = 'CREATING_SLIDE';
export const CREATED_SLIDE = 'CREATED_SLIDE';

export const UPDATING_SLIDE = 'UPDATING_SLIDE';
export const UPDATED_SLIDE = 'UPDATED_SLIDE';

export const FETCHING_SLIDE = 'FETCHING_SLIDE';
export const FETCHED_SLIDE = 'FETCHED_SLIDE';

export const DELETING_SLIDE = 'DELETING_SLIDE';
export const DELETED_SLIDE = 'DELETED_SLIDE';

export const RESET_SLIDE_STATE = 'RESET_SLIDE_STATE';

function creatingSlide() {
  return {
    type: CREATING_SLIDE
  };
}

function createdSlide(json, pollId, idx) {
  return {
    type: CREATED_SLIDE,
    slide: json,
    idx,
    pollId
  };
}

function updatingSlide() {
  return {
    type: UPDATING_SLIDE
  };
}

function updatedSlide(json, pollId) {
  return {
    type: UPDATED_SLIDE,
    slide: json,
    pollId
  };
}

function fetchingSlide() {
  return {
    type: FETCHING_SLIDE
  };
}

function fetchedSlide(json) {
  return {
    type: FETCHED_SLIDE,
    slide: json
  };
}

function deletingSlide() {
  return {
    type: DELETING_SLIDE
  };
}

function deletedSlide(pollId, slideId) {
  return {
    type: DELETED_SLIDE,
    pollId,
    slideId
  };
}

export function createSlide(data, message, skipRedirect, idx) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(creatingSlide());

    const [ accountId, pollId ] = parseUrl(getState().router);

    return postFormData(`${url}/account/${accountId}/poll/${pollId}/slide`, data)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch({ type: RESET_SLIDE_STATE });
        dispatch(flash(json.error))
      } else {
        dispatch(createdSlide(json, pollId, idx))
        if (!skipRedirect) {
          dispatch(replace(`/a/${encode(accountId)}/p/${encode(pollId)}/s/${encode(json._id)}?tab=details`));
        }
        if (message) {
          dispatch(flash(message));
        }
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

function prepareSlideForDuplication(slide) {
  const dupe = { ...slide };

  delete dupe.viewCount;
  delete dupe.emailCount;
  delete dupe.responseCount;
  delete dupe.sentiment;
  delete dupe._id;
  delete dupe.lastModified;
  delete dupe.presentationCount;
  delete dupe.engagementCount;
  delete dupe.replyCount;
  delete dupe.nextSlide;
  delete dupe.updatedInsightsAt;
  delete dupe.insights;
  delete dupe.responseInsights;
  delete dupe.responses;
  delete dupe.participantCount;
  delete dupe.diffSinceCounted;
  delete dupe.combinedAnswers;

  if (dupe.answers) {
    dupe.answers.forEach((answer) => {
      answer.votes = 0;
      answer.rankings = undefined;
      answer.totalOrderValue = 0;
      answer.votesSinceOrderValueAdded = 0;
      answer.votesSinceTotalOrderValueAdded = 0;
      delete answer.nextSlide;
    });
  }

  if (dupe.actions) {
    dupe.actions.forEach((action) => {
      action.clicks = 0;
      action.totalOrderValue = 0;
      action.votesSinceOrderValueAdded = 0;
      action.votesSinceTotalOrderValueAdded = 0;
    });
  }

  return dupe;
}

export function duplicateSlide(slideId) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(creatingSlide());

    const [ accountId, pollId ] = parseUrl(getState().router);
    const slide = prepareSlideForDuplication(_.cloneDeep(getState().slides[slideId]));

    const formData = new FormData();
    delete slide._id;
    formData.append('slide', JSON.stringify(slide));

    return postFormData(`${url}/account/${accountId}/poll/${pollId}/slide`, formData)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch({ type: RESET_SLIDE_STATE });
        dispatch(flash(json.error))
      } else {
        dispatch(createdSlide(json, pollId))
        dispatch(replace(`/a/${encode(accountId)}/p/${encode(pollId)}/s/${encode(json._id)}?tab=details`));
        dispatch(flash('Your slide has been duplicated'));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function duplicateFromIdx(idx) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(creatingSlide());

    const [ accountId, pollId ] = parseUrl(getState().router);
    const poll = getState().polls[pollId];
    const slide = prepareSlideForDuplication(_.cloneDeep(poll.slides[idx]));

    const formData = new FormData();
    delete slide._id;
    formData.append('slide', JSON.stringify(slide));

    return postFormData(`${url}/account/${accountId}/poll/${pollId}/slide`, formData)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch({ type: RESET_SLIDE_STATE });
        dispatch(flash(json.error))
      } else {
        dispatch(createdSlide(json, pollId))
        // dispatch(replace(`/a/${encode(accountId)}/p/${encode(pollId)}/s/${encode(json._id)}?tab=details`));
        // dispatch(flash('Your slide has been duplicated'));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function updateSlide(data) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingSlide());

    const [ accountId, pollId, slideId ] = parseUrl(getState().router);

    return postFormData(`${url}/account/${accountId}/poll/${pollId}/slide/${slideId}/update`, data)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch({ type: RESET_SLIDE_STATE });
        dispatch(flash(json.error))
      } else {
        dispatch(updatedSlide(json, pollId));
        dispatch(flash('The slide has been updated successfully.'))
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function showSlide() {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingSlide());

    const [ accountId, pollId, slideId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/slide/${slideId}/show`)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch({ type: RESET_SLIDE_STATE });
        dispatch(flash(json.error))
      } else {
        dispatch(updatedSlide(json, pollId));
        dispatch(flash('The slide has been updated successfully.'))
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function fetchAccountPollAndSlide() {
  return (dispatch, getState) => {
    dispatch(fetchAccount())
      .then(d => dispatch(fetchPoll()))
      .then(d => dispatch(fetchSlide()));
  }
}

export function fetchSlide(_id, forceUpdate, manualPollId) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(fetchingSlide());

    let [ accountId, pollId, slideId ] = parseUrl(getState().router);

    if (manualPollId) {
      pollId = manualPollId;
    }

    if (_id === undefined) {
      _id = slideId;
    }

    return post(`${url}/account/${accountId}/poll/${pollId}/slide/${_id}/fetch`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedSlide(json));
        if (forceUpdate) {
          dispatch(updatedSlide(json, pollId));
        }
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function fetchFullSlide(_id, pollIdOverride) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(fetchingSlide());

    let [ accountId, pollId, slideId ] = parseUrl(getState().router);

    if (_id === undefined) {
      _id = slideId;
    }

    if (pollIdOverride) {
      pollId = pollIdOverride;
    }

    return post(`${url}/account/${accountId}/poll/${pollId}/slide/${_id}/fetch-full`)
    .then(response => {
      if (response.status === 404) {
        dispatch(replace('/404'));
      }
      return response.json()
    })
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error));
      } else {
        dispatch(fetchedSlide(json));

        /* Hack to get the poll to update the slide, not sure if this should just be default behavior though...*/
        if (pollIdOverride) {
          dispatch(updatedSlide(json, pollIdOverride)); 
        }
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function remove() {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(deletingSlide());

    const [ accountId, pollId, slideId ] = parseUrl(getState().router);

    return post(`${url}/account/${accountId}/poll/${pollId}/slide/${slideId}/delete`)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(deletedSlide(pollId, slideId));
        dispatch(replace(`/a/${encode(accountId)}/p/${encode(pollId)}?tab=slides`));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function removeById(_id) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(deletingSlide());

    const [ accountId, pollId ] = parseUrl(getState().router);
    const slideId = _id;

    return post(`${url}/account/${accountId}/poll/${pollId}/slide/${slideId}/delete`)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        dispatch(deletedSlide(pollId, slideId));
        dispatch(replace(`/a/${encode(accountId)}/p/${encode(pollId)}?tab=slides`));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function fetchVotes(accountId, pollId, slideId, dateRange) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(fetchingSlide());

    return post(`${url}/account/${accountId}/poll/${pollId}/slide/${slideId}/votes`, { dateRange })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        console.log('got data');
        console.log(json);
        return dispatch(updatedSlide(json, pollId));
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

export function crossTabulate(accountId, pollId, slideAId, slideBId, dateRange) {
  return (dispatch, getState) => {
    return post(`${url}/account/${accountId}/poll/${pollId}/slide/${slideAId}/cross-tabulate`, { dateRange, slideBId })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(pushServerError(json.error))
      } else {
        console.log('got data');
        console.log(json);
        return json;
      }
    })
    .catch(error => dispatch(pushServerError(error)))
  };
}

