import React, { Component } from 'react';

import Layout from '../components/Layout';
import UserLoginForm from '../components/UserLoginForm';
import SingleFormPageHeader from '../components/SingleFormPageHeader';
import BelowLogin from '../components/BelowLogin';

import { loadContactUsSurvey } from '../utils';

import shopifyLogo from '../images/shopifyLogo.png';

class CreateAccount extends Component {
  render() {
    return (
      <Layout title="Log In" graphics={true}>
        <SingleFormPageHeader
          title="Keep iterating."
          subtitle="Log in, listen, and learn."
        />
        <UserLoginForm />
        <BelowLogin type="login" />
      </Layout>
    );
  }
}

export default CreateAccount;
