import { replace } from 'connected-react-router';
import { pushServerError, pushNotification, clearNotifications } from './Notifications';

import _ from 'lodash';

import { post } from '../ajax';
import { encode, getAccountId } from '../utils';
import { url } from '../settings';
import { flash } from './FlashNotifications';
import ReactGA from 'react-ga'

export const CREATING_USER = 'CREATING_USER';
export const CREATED_USER = 'CREATED_USER';

export const UPDATING_USER = 'UPDATING_USER';
export const UPDATED_USER = 'UPDATED_USER';

export const DELETING_USER = 'DELETING_USER';
export const DELETED_USER = 'DELETED_USER';

export const FETCHING_USER = 'FETCHING_USER';
export const FETCHED_USER = 'FETCHED_USER';

export const LOGGING_OUT = 'LOGGING_OUT';
export const LOGGED_OUT = 'LOGGED_OUT';

export const FETCHING_STRIPE_DATA = 'FETCHING_STRIPE_DATA';
export const FETCHED_STRIPE_DATA = 'FETCHED_STRIPE_DATA';

export const FETCHING_AFFILIATE_DATA = 'FETCHING_AFFILIATE_DATA';
export const FETCHED_AFFILIATE_DATA = 'FETCHED_AFFILIATE_DATA';

export const RESET_USER = 'RESET_USER';

function creatingUser() {
  return {
    type: CREATING_USER
  };
}

function createdUser(json) {
  return {
    type: CREATED_USER,
    user: json
  };
}

function updatingUser() {
  return {
    type: UPDATING_USER
  };
}

function updatedUser(json) {
  return {
    type: UPDATED_USER,
    user: json
  };
}

function deletingUser() {
  return {
    type: DELETING_USER
  };
}

function deletedUser(json) {
  return {
    type: DELETED_USER
  };
}

function fetchingUser() {
  return {
    type: FETCHING_USER
  };
}

function fetchedUser(json = {}) {
  return {
    type: FETCHED_USER,
    user: json
  };
}

function handleLoginError(dispatch, error) {
  let msg = error;
  if ( typeof error === 'object') {
    msg = error.toString();
  }

  dispatch(pushServerError(msg));
  dispatch({ type: RESET_USER });
  dispatch(logout());
}

function pushError(dispatch, error) {
  dispatch(pushServerError(error));
  dispatch({ type: RESET_USER });
}

export function logout() {
  return (dispatch, getState) => {
    if (!getState().user._id) { return; }

    dispatch({ type: LOGGING_OUT });

    return post(`${url}/log-out`)
    .then(response => {
      dispatch({ type: LOGGED_OUT });
      dispatch(replace(`/log-in`))
    });
  };
}

const handleRedirect = (dispatch, user) => {
  if (!user) { 
    console.log('No user provided');
    return;
  }

  if (user.accounts.length === 0) {
    dispatch(replace(`/a/create`));
  } else if (user.accounts.length === 1) {
    dispatch(replace(`/dashboard/a/${encode(getAccountId(user.accounts[0]))}`));
  }
}

function trackNewUser(user) {
  // console.log('Tracking new user!');
  // console.log(user);

  /* Give facebook pixel time to set cookies... */
  return setTimeout(() => {
    user.ga_id = window.ga_id;
    return post(`${url}/user/track`, user)
    .then(response => {
      // console.log('Tracked new user');
      // console.log(response);
    })
  }, 1000 * 5);
}

export function createUser(user) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(creatingUser());

    user.ga_id = window.ga_id;
    return post(`${url}/user`, user)
    .then(response => {
      if (response.status === 429) {
        throw new Error("You're doing that too much. Please wait and try again.");
      }
      return response;
    })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        pushError(dispatch, json.error);
      } else {
        dispatch(createdUser(json))
        handleRedirect(dispatch, json);

        if (json.firstSession) {
          trackNewUser(json);
        }
      }
    })
    .catch(error => pushError(dispatch, error))
  };
}

export function createLifetimeUser(user) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(creatingUser());

    return post(`${url}/lifetime-user`, user)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        pushError(dispatch, json.error);
      } else {
        dispatch(createdUser(json))
        handleRedirect(dispatch, json);
      }
    })
    .catch(error => pushError(dispatch, error))
  };
}

export function updateUser(user, skipFlash) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingUser());

    return post(`${url}/user/update`, user)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedUser({}));
      } else {
        if (!skipFlash) {
          dispatch(flash('Your profile has been updated.'));
        }
        dispatch(updatedUser(json));
      }
    })
    .catch(error => pushError(dispatch, error));
  };
}

export function deleteUser(user) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(deletingUser());

    return post(`${url}/user/delete`, { _id: getState().user._id })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedUser({}));
      } else {
        dispatch(deletedUser(json));
      }
    })
    .catch(error => pushError(dispatch, error));
  };
}


export function addCard(token) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingUser());

    const _id = getState().user._id;

    return post(`${url}/user/add-card`, { token: token, _id })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedUser({}));
      } else {
        // ReactGA.event({
        //   category: 'zigpoll',
        //   action: 'add-credit-card',
        //   label: 'add-credit-card'
        // });
        dispatch(updatedUser(json));
        dispatch(flash("Your payment information has been saved."));
      }
    })
    .catch(error => pushError(dispatch, error));
  };
}

export function removeCard(id) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingUser());

    const user = { ...getState().user };
    const _id = user._id;

    user.customer.sources.data = _.filter(user.customer.sources.data, (card) => card.id !== id);
    dispatch(updatedUser(user));

    return post(`${url}/user/remove-card`, { id, _id })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedUser({}));
      } else {
        dispatch(updatedUser(json));
        dispatch(flash("The card has been removed."));
      }
    })
    .catch(error => pushError(dispatch, error));
  };
}

export function setDefaultCard(id) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingUser());

    const user = { ...getState().user };
    const _id = user._id;

    user.customer.default_source = id;

    let card;
    user.customer.sources.data = _.filter(user.customer.sources.data, (c) => {
      if (c.id === id) {
        card = c
      }
      return c.id !== id
    });
    user.customer.sources.data = [ card, ...user.customer.sources.data ];

    dispatch(updatedUser(user));

    return post(`${url}/user/set-default-card`, { id, _id })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedUser({}));
      } else {
        dispatch(updatedUser(json));
        dispatch(flash("Your payment information has been updated."));
      }
    })
    .catch(error => pushError(dispatch, error));
  };
}

export function fetchStripeData() {
  return (dispatch, getState) => {
    dispatch({ type: FETCHING_STRIPE_DATA });
    dispatch(clearNotifications());
    dispatch(updatingUser());

    const { _id } = getState().user;

    return post(`${url}/user/stripe-data`, { _id })
    .then(response => response.json())
    .then(json => {
      dispatch({ type: FETCHED_STRIPE_DATA });

      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedUser({}));
      } else {
        dispatch(updatedUser(json));
      }
    })
    .catch(error => pushError(dispatch, error));
  };
}

export function login(user) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(fetchingUser());

    user.ga_id = window.ga_id;
    return post(`${url}/log-in`, user)
    .then(response => {
      if (response.status === 429) {
        throw new Error("You're doing that too much. Please wait and try again.");
      }
      return response;
    })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        handleLoginError(dispatch, json.error);
      } else {
        dispatch(fetchedUser(json));
        handleRedirect(dispatch, json);

        if (json.firstSession) {
          trackNewUser(json);
        }
      }
    })
    .catch(error => handleLoginError(dispatch, error))
  };
}

export function googleLogin(code) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(fetchingUser());

    return post(`${url}/google/log-in`, { code, ga_id: window.ga_id })
    .then(response => {
      if (response.status === 429) {
        throw new Error("You're doing that too much. Please wait and try again.");
      }
      return response;
    })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        handleLoginError(dispatch, json.error);
      } else {
        dispatch(fetchedUser(json));
        handleRedirect(dispatch, json);

        if (json.firstSession) {
          trackNewUser(json);
        }
      }
    })
    .catch(error => handleLoginError(dispatch, error))
  };
}

export function googleSignup(code) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(creatingUser());

    return post(`${url}/google/sign-up`, { code, ga_id: window.ga_id })
    .then(response => {
      if (response.status === 429) {
        throw new Error("You're doing that too much. Please wait and try again.");
      }
      return response;
    })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        pushError(dispatch, json.error);
      } else {
        dispatch(createdUser(json))
        handleRedirect(dispatch, json);

        if (json.firstSession) {
          trackNewUser(json);
        }
      }
    })
    .catch(error => pushError(dispatch, error))
  };
}

export function restore(_id) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(fetchingUser());

    return post(`${url}/user/restore`, { _id, ga_id: window.ga_id })
    .then(response => {
      if (response.status === 401) {
        // throw new Error('Unauthorized');
        /* Go back to login */
        handleLoginError(dispatch, "Your session has timed out. Please log in again.");
        return dispatch(replace('/log-in'));
      }
      return response.json()
    })
    .then(json => {
      if (!json) { return; }

      if (json.error) {
        pushError(dispatch, json.error);
      } else {
        if (json.firstSession) {
          trackNewUser(json);
        }
        return dispatch(fetchedUser(json));
      }
    })
    .catch(error => handleLoginError(dispatch, error))
  };
}

export function restoreFromToken(_token) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(fetchingUser());

    return post(`${url}/user/restoreFromToken`, { _token, ga_id: window.ga_id })
    .then(response => {
      if (response.status === 401) {
        // throw new Error('Unauthorized');
        /* Go back to login */
        handleLoginError(dispatch, "Your session has timed out. Please log in again.");
        return dispatch(replace('/log-in'));
      }
      return response.json()
    })
    .then(json => {
      if (!json) { return; }

      if (json.error) {
        pushError(dispatch, json.error);
      } else {
        if (json.firstSession) {
          trackNewUser(json);
        }
        return dispatch(fetchedUser(json));
      }
    })
    .catch(error => handleLoginError(dispatch, error))
  };
}

export function updateGaId() {
  return (dispatch, getState) => {
    const user = getState().user;
    if (!user || !user._id) { return; }

    return post(`${url}/user/ga_id`, { _id: window.userId, ga_id: window.ga_id });
  };
}

export function sendPasswordResetEmail(email) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(fetchingUser());

    return post(`${url}/send-reset-password-email`, { email })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        return pushError(dispatch, json.error);
      }

      dispatch(pushNotification('An email has been sent with instructions to reset your password.'));
      return dispatch({ type: RESET_USER });
    })
    .catch(error => pushError(dispatch, error))
  }
}

export function validateToken(token) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(fetchingUser());

    return post(`${url}/validate-reset-token`, { token })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        pushError(dispatch, json.error);
      } else {
        if (!json.email) {
          dispatch({ type: RESET_USER });
          dispatch(replace('/forgot-password'));
          return pushError(dispatch, 'This link is no longer valid. Please try again.');
        }
        return dispatch(fetchedUser({ email: json.email }));
      }
    })
    .catch(error => pushError(dispatch, error))
  }
}

export function resetPassword(user) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(fetchingUser());

    return post(`${url}/reset-password`, user)
    .then(response => response.json())
    .then(json => {
      dispatch(fetchedUser({ type: RESET_USER }));
      dispatch(replace('/log-in'));
      return dispatch(pushNotification('Your password has been updated. Please log in.'));
    })
    .catch(error => pushError(dispatch, error))
  }
}

export function sendValidationEmail() {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingUser());

    const user = getState().user;
    return post(`${url}/user/send-validation-email`, { _id: user._id })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        return pushError(dispatch, json.error);
      }

      // dispatch(pushNotification('An email has been sent with instructions to validate your email.'));
      return dispatch(updatedUser({}));
    })
    .catch(error => pushError(dispatch, error))
  }
}

export function validateEmailToken(token) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(fetchingUser());

    return post(`${url}/validate-email-token`, { token })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        pushError(dispatch, json.error);
      } else {
        setTimeout(() => {
          window.location.href = '/';
        }, 1000);

        if (!json.email) {
          return dispatch(flash('This link is no longer valid. Please try again.'));
        }

        dispatch(flash('Success. Your email has been validated.'));
      }
    })
    .catch(error => pushError(dispatch, error))
  }
}

export function setDefaultAccount(account) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingUser());

    const user = getState().user;
    let accounts = [ ...user.accounts ];

    if (typeof accounts[0] === 'object') {
      accounts = accounts.map(({_id}) => _id);
    }

    _.remove(accounts, (_id) => _id === account._id);
    accounts = [ account._id, ...accounts ];

    return post(`${url}/user/accounts/reorder`, { _id: user._id, accounts })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedUser({}));
      } else {
        dispatch(updatedUser(json));
      }
    })
    .catch(error => pushError(dispatch, error));
  };
}

export function leaveAccount(userId, accountId) {
  return (dispatch, getState) => {
    dispatch(clearNotifications());
    dispatch(updatingUser());

    return post(`${url}/user/accounts/leave`, { _id: userId, accountId: accountId })
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedUser({}));
      } else {
        dispatch(updatedUser(json));
      }
    })
    .catch(error => pushError(dispatch, error));
  };
}

export function loadStripeDashboard(userId) {
  return (dispatch, getState) => {
    return post(`${url}/stripe-connect/dashboard`)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        dispatch(flash(json.error));
      } else {
        console.log('here');
        console.log(json);
        var win = window.open(json.url, '_blank');
        win.focus();
      }
    })
    .catch(error => pushError(dispatch, error));
  };
}

export function fetchAffiliateData() {
  return (dispatch, getState) => {
    dispatch({ type: FETCHING_AFFILIATE_DATA });
    dispatch(clearNotifications());
    dispatch(updatingUser());

    const { _id } = getState().user;

    return post(`${url}/user/affiliate-data`, { _id })
    .then(response => response.json())
    .then(json => {
      dispatch({ type: FETCHED_AFFILIATE_DATA });

      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedUser({}));
      } else {
        dispatch(updatedUser(json));
      }
    })
    .catch(error => pushError(dispatch, error));
  };
}

export function setPaypalEmail(email) {
  return (dispatch, getState) => {
    dispatch({ type: UPDATING_USER });
    dispatch(clearNotifications());
    dispatch(updatingUser());

    const { _id } = getState().user;

    return post(`${url}/user/paypal`, { _id, email })
    .then(response => response.json())
    .then(json => {
      dispatch({ type: UPDATED_USER });

      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedUser({}));
      } else {
        dispatch(flash('Your PayPal settings have been saved.'));
        dispatch(updatedUser(json));
      }
    })
    .catch(error => pushError(dispatch, error));
  };
}


export function createPrivateApiKey(label) {
  return (dispatch, getState) => {
    dispatch({ type: UPDATING_USER });
    dispatch(clearNotifications());
    dispatch(updatingUser());

    const { _id } = getState().user;
    return post(`${url}/user/apiKey`, { _id, label })
    .then(response => response.json())
    .then(json => {
      dispatch({ type: UPDATED_USER });

      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedUser({}));
      } else {
        dispatch(flash('The API key has been created.'));
        dispatch(updatedUser(json));
      }
    })
    .catch(error => pushError(dispatch, error));
  };
}

export function removePrivateApiKey(key) {
  return (dispatch, getState) => {
    dispatch({ type: UPDATING_USER });
    dispatch(clearNotifications());
    dispatch(updatingUser());

    const { _id } = getState().user;
    return post(`${url}/user/remove-apiKey`, { _id, key })
    .then(response => response.json())
    .then(json => {
      dispatch({ type: UPDATED_USER });

      if (json.error) {
        dispatch(flash(json.error));
        dispatch(updatedUser({}));
      } else {
        dispatch(flash('The API key has been removed.'));
        dispatch(updatedUser(json));
      }
    })
    .catch(error => pushError(dispatch, error));
  };
}

