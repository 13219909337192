const _ = require('lodash');

let url = '//localhost:8080';
if (process.env.NODE_ENV === 'production') {
  url = '//app.zigpoll.com';
}
module.exports.url = url;
  
/* Old plans */
// let plans = [
//   { label: 'Lite', id: 'plan_Ecr4DlMSeMw27u', key: 'lite', price: 0, submissionLimit: 100, accountLimit: 1, forceBranding: true },
//   { label: 'Basic', id: 'plan_Ecr4iWn1LSstaA', key: 'basic', price: 10, submissionLimit: 300, accountLimit: 2, forceBranding: true },
//   // { label: 'Custom', id: 'custom-plan', key: 'custom', price: 10, submissionLimit: 900, accountLimit: 2, forceBranding: false },
//   { label: 'Standard', id: 'plan_Ecr4rniyWIjCEn', key: 'standard', price: 25, submissionLimit: 1000, accountLimit: 4, forceBranding: false },
//   { label: 'Plus', id: 'plan_Ecr5Wn7TWHcVuy', key: 'plus', price: 50, submissionLimit: 5000, accountLimit: 8, forceBranding: false },
//   { label: 'Pro', id: 'plan_Ecr5sSQ2pnblVP', key: 'pro', price: 100, submissionLimit: 10000, accountLimit: 20, forceBranding: false },
//   { label: 'Enterprise', id: 'plan_Ft8VAR3snXgSIJ', key: 'enterprise', price: 200, submissionLimit: 30000, accountLimit: 50, forceBranding: false },
// ];

module.exports.googleClientId = '752064899126-8t30jc5jml997ic80qjkebfg8410fdun.apps.googleusercontent.com';
module.exports.googleClientSecret = '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';
module.exports.googleRedirectUri = 'https://app.zigpoll.com/google/auth/callback';

let planDetails = {
  lite: [
    '100 responses/mo',
    // '1 team member'
  ],
  basic: [
    '200 responses/mo',
    '1,000 outbound emails/mo',
    // '2 team members'
  ],
  standard: [
    '500 responses/mo',
    '2,500 outbound emails/mo',
    '500 SMS messages/mo',
    'Hide Zigpoll Branding',
    // '4 team members'
  ],
  plus: [
    '1,000 responses/mo',
    '5,000 outbound emails/mo',
    '1,000 SMS messages/mo',
    'Hide Zigpoll Branding',
    // '8 team members'
  ],
  pro: [
    'Unlimited responses/mo',
    '10,000 outbound emails/mo',
    '2,000 SMS messages/mo',
    'Hide Zigpoll Branding',
    // '20 team members'
  ],
  enterprise: [
    'Unlimited responses/mo',
    '10,000 outbound emails/mo',
    '2,000 outbound emails/mo',
    'Hide Zigpoll Branding',
    // '50 team members'
  ]
}
module.exports.planDetails = planDetails;

let plans;
// plans = [
//   { label: 'Lite', id: 'plan_Ecr4DlMSeMw27u', key: 'lite', price: 0, submissionLimit: 100, accountLimit: 2, forceBranding: true, emailLimit: 100, smsLimit: 10 },
//   { label: 'Basic', id: 'plan_Ecr4iWn1LSstaA', key: 'basic', price: 10, submissionLimit: 200, accountLimit: 3, forceBranding: true, emailLimit: 1000, smsLimit: 10 },
//   // { label: 'Custom', id: 'custom-plan', key: 'custom', price: 10, submissionLimit: 900, accountLimit: 2, forceBranding: false },
//   { label: 'Standard', id: 'plan_Ecr4rniyWIjCEn', key: 'standard', price: 25, submissionLimit: 500, accountLimit: 4, forceBranding: false, emailLimit: 2500, smsLimit: 500 },
//   { label: 'Plus', id: 'plan_Ecr5Wn7TWHcVuy', key: 'plus', price: 50, submissionLimit: 1000, accountLimit: 8, forceBranding: false, emailLimit: 5000, smsLimit: 1000 },
//   { label: 'Pro', id: 'plan_Ecr5sSQ2pnblVP', key: 'pro', annualId: 'price_1R7eI7LP9mizAxE0XbzcYdHm', price: 100, submissionLimit: 10000000, accountLimit: 20, forceBranding: false, emailLimit: 10000, smsLimit: 2000 },
//   { label: 'Enterprise', id: 'plan_Ft8VAR3snXgSIJ', key: 'enterprise', price: 200, submissionLimit: 10000000, accountLimit: 50, forceBranding: false, emailLimit: 10000, smsLimit: 2000 },
// ];

// if (process.env.NODE_ENV === 'production' || process.env.DEBUG_PROD) {
  plans = [
    { label: 'Lite', id: 'plan_EthbyzrheBNH7H', annualId: 'plan_EthbyzrheBNH7H', key: 'lite', price: 0, submissionLimit: 100, accountLimit: 2, forceBranding: false, emailLimit: 100, smsLimit: 10 },
    { label: 'Basic', id: 'plan_Ethbe3NvJjVb4T', annualId: 'price_1R1U5DLP9mizAxE05TlW01ed', key: 'basic', price: 10, submissionLimit: 200, accountLimit: 3, forceBranding: false, emailLimit: 1000, smsLimit: 10 },
    { label: 'Standard', id: 'plan_Ethb7TcxR3RISY', annualId: 'price_1R1U4oLP9mizAxE0ebbBn3iJ', key: 'standard', price: 25, submissionLimit: 500, accountLimit: 4, forceBranding: false, emailLimit: 2500, smsLimit: 500 },
    { label: 'Plus', id: 'plan_Ethc1SahzBcwLp', annualId: 'price_1R1U4PLP9mizAxE0JwSkvzt2', key: 'plus', price: 50, submissionLimit: 1000, accountLimit: 8, forceBranding: false, emailLimit: 5000, smsLimit: 1000 },
    { label: 'Pro', id: 'plan_EthcBZQN1DCLfD', annualId: 'price_1R1U2PLP9mizAxE0ttZys3yk', key: 'pro', price: 100, submissionLimit: 10000000, accountLimit: 20, forceBranding: false, emailLimit: 10000, smsLimit: 2000 },
    { label: 'Enterprise', id: 'plan_Ft8RjT3Hn1ZIYB', annualId: 'price_1R1U3yLP9mizAxE0XCQy64BB', key: 'enterprise', price: 200, submissionLimit: 10000000, accountLimit: 50, forceBranding: false, emailLimit: 10000, smsLimit: 2000 },
  ];
// }

module.exports.plans = plans;

const defaultDisplaySettings = {
  backgroundColor: '#212a2f',
  actionColor: '#212a2f',
  notificationColor: '#F26c57',
  showLink: false,
  align: 'right',
  borderRadius: 8,
  borderWidth: 1,
  maxWidth: 440,
  maxHeight: 575,
  position: [ 20, 20 ],
  shape: 'bumper',
  hideLauncher: true,
  showCounter: true,
  launcherSettings: {
    'before-submission': {
      launcherIcon: 'fa-check-circle',
      launcherText: 'Take Our Survey',
      launcherDisplayType: 'text-only',
      launcherDisplayTypeMobile: 'text-only',
    },
  }
};

module.exports.defaultDisplaySettings = defaultDisplaySettings;

const defaultEmailDisplaySettings = {
  subject: 'Have a minute for a quick survey?',
  backgroundColor: '#f5f7f9',
  foregroundColor: '#ffffff',
  buttonColor: '#212a2f',
  buttonFontColor: '#ffffff',
  fontColor: '#000000',
  fontFamily: 'sans',
  showLink: false,
  linkType: 'input',
  content: {
    header: "Hey! Got a second?",
    body: "We want your feedback! Click below to complete our survey.",
    button: "Click To Get Started",
    footer: "We appreciate your support!",
    subject: "Have a minute for a quick survey?"
  }
};

module.exports.defaultEmailDisplaySettings = defaultEmailDisplaySettings;

const defaultSmsDisplaySettings = {
  message: "We want your feedback! Click the link to complete our survey.",
};

module.exports.defaultSmsDisplaySettings = defaultSmsDisplaySettings;

const defaultVisibilitySettings = {
  autoOpenDevice: false,
  autoOpenDuration: 0,
  autoOpenPosition: 0,
  autoOpenDurationType: 'page',
  renderOnExitIntent: false,
  renderDuration: false,
  renderPosition: false,
  renderDevice: false,
  renderDurationType: 'page',
  hideAfterSubmission: false,
  hideAfterClose: true,
  showAfterSubmission: false,
  openAttempts: true
};

module.exports.defaultVisibilitySettings = defaultVisibilitySettings;


let stripeSecretKey = 'sk_test_iEEs5JTGOkYtNvcwey0SPOPt';
let stripeApiKey = 'pk_test_7OZQJAhn0Tjp26lcuqFndzir';
let stripeConnectId = 'ca_GIIPrZXbnivqJGyc82OQHANYFbqDxpJh'

// if (process.env.NODE_ENV === 'production' || process.env.DEBUG_PROD) {
  stripeSecretKey = 'sk_live_nLbZOdWfKtDVzNQ94HzFGuyi';
  stripeApiKey = 'pk_live_51DvAFKLP9mizAxE0DSe80hJSphz2Pwk6AfLrLUDPsTh4k7LQLa8ylOq453W0Zmm8AZRkdZyEWy5mbf6a5YRXYwgy00E4tG9Yjk';
  stripeConnectId = 'ca_GIIPM6zpyKHbSx0cqM2RZwu5WVOP5UrK'
// }

module.exports.stripeSecretKey = stripeSecretKey
module.exports.stripeApiKey = stripeApiKey
module.exports.stripeConnectId = stripeConnectId;

let shopifyConfig = {
  "oauth": {
    "api_key": "119e4fae1a8d075f4398538efd163de6",
    "client_secret": "205ce0e1c83f714ef87bf31da3bdc065",
    "redirect_uri": "https://localhost:8080/shopify/access_token",
    "scope": "read_script_tags,write_script_tags,read_customers,write_customers,read_orders,write_orders,read_all_orders,read_fulfillments,write_discounts,read_discounts,write_store_credit_account_transactions,read_products"
  },
  "app_url": "https://localhost:8080/shopify/auth"
}
if (process.env.NODE_ENV === 'production') {
  shopifyConfig = {
    "oauth": {
      "api_key": "03b0ee31c378e592b1c5c9da3dbe6651",
      "client_secret": "4080d98c5adc22dba5e1b39687b27372",
      "redirect_uri": "https://app.zigpoll.com/shopify/access_token",
      "scope": "read_script_tags,write_script_tags,read_customers,write_customers,read_orders,write_orders,read_all_orders,read_fulfillments,write_discounts,read_discounts,write_store_credit_account_transactions,read_products"
    },
    "app_url": "https://app.zigpoll.com/shopify/auth"
  }
}

module.exports.shopifyConfig = shopifyConfig;

module.exports.promos = {
  'TE0dartE1': { planKey: 'basic', description: '1 free month of Zigpoll Basic.' },
  'svnu065Cl': { planKey: 'standard', description: '1 free month of Zigpoll Standard.' },
  'BAo5KQJZe': { planKey: 'plus', description: '1 free month of Zigpoll Plus.' },
  'VjyLKKYJj': { planKey: 'pro', description: '1 free month of Zigpoll Pro.' }
};

let discounts = {
  '7JefRO3F1': { description: '10% off each month for any plan.', percentOff: 10, couponId: 'Zl7kbqmQ' },
  'GwT8z4FCE': { description: '20% off each month for any plan.', percentOff: 20, couponId: 'nJpHqLjc' },
  'GwT8z8QAB': { description: '25% off each month for any plan.', percentOff: 25, couponId: '3ujLsLjA' },
  'GwT8z5FCQ': { description: '50% off each month for any plan.', percentOff: 50, couponId: '2ORElSrV' },
  'kZMBRua81': { description: '75% off each month for any plan.', percentOff: 75, couponId: 'hRYHHyGM' }
};
if (process.env.NODE_ENV === 'production') {
  discounts = {
    '7JefRO3F1': { description: '10% off each month for any plan.', percentOff: 10, couponId: 'Zl7kbqmQ' },
    'GwT8z4FCE': { description: '20% off each month for any plan.', percentOff: 20, couponId: '7HEG50fj' },
    'GwT8z8QAB': { description: '25% off each month for any plan.', percentOff: 25, couponId: '3ujLsLjA' },
    'GwT8z5FCQ': { description: '50% off each month for any plan.', percentOff: 50, couponId: '2ORElSrV' },
    'kZMBRua81': { description: '75% off each month for any plan.', percentOff: 75, couponId: 'hRYHHyGM' }
  };
}
module.exports.discounts = discounts;

const freeTrials = {
  'Y96W6S': { description: '50% off the pro plan. Free for 7 days.', percentOff: 50, couponId: 'hRYHHyGM', code: 'pro-50', plan: 'pro' },
  'M90R32': { description: '75% off the pro plan. Free for 7 days.', percentOff: 75, couponId: '2ORElSrV', code: 'pro-75', plan: 'pro' },
};
module.exports.freeTrials = freeTrials;

module.exports.pollTemplates = [
  {
    title: 'Build from scratch',
    description: "Know what you want to ask? Write all of your questions and answer options on your own.",
    icon: "fa-plus",
    handle: "build-from-scratch",
    hasProduct: false,
    slides: []
  },
  {
    title: 'Attribution Survey',
    description: "Understand what channels are most effective & how customers are finding you.",
    icon: "fa-code-branch",
    handle: "attribution-survey",
    previewLink: "https://www.zigpoll.com/examples/attribution-survey",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How did you hear about us?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        type: 'question',
        title: 'How did you hear about us?',
        copy: '',
        handle: 'Attribution Survey - Question',
        settings: {
          showTitle: true,
          showResults: false,
          randomizeAnswers: true,
          showResponses: false,
          optional: false,
          hidden: false,
          disableSentimentAnalysis: true
        },
        answers: [
          { title: 'Friends', handle: 'friends', nextSlide: 2 },
          { title: 'TikTok', handle: 'tiktok', nextSlide: 1 },
          { title: 'Instagram', handle: 'instagram', nextSlide: 1 },
          { title: 'Facebook', handle: 'facebook', nextSlide: 1 },
          { title: 'Youtube', handle: 'youtube', nextSlide: 1 },
          { title: 'Google', handle: 'google', nextSlide: 2 },
          { title: 'Other', handle: 'other', nextSlide: 2, dynamic: true },
        ],
        logic: [
          {
            rules: [
              { rule: '=', ruleValue: 'TikTok' },
              { rule: '=', ruleValue: 'Instagram' },
              { rule: '=', ruleValue: 'Facebook' },
              { rule: '=', ruleValue: 'Youtube' },
            ],
            actions: [
              { action: 'skip', actionValue: 1 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Friends' },
              { rule: '=', ruleValue: 'Google' },
              { rule: '=', ruleValue: 'Other' }
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          }
        ]
      },
      {
        title: "Do you rememember where in particular?",
        handle: 'Attribution Survey - Detail',
        type: 'question',
        copy: '',
        optional: true,
        settings: {
          showTitle: true,
          showResults: false,
          randomizeAnswers: true,
          showResponses: false,
          optional: true,
          hidden: false,
          disableSentimentAnalysis: true
        },
        answers: [
          { title: 'Your brand page', handle: 'your-brand-page' },
          { title: 'An ad', handle: 'an-ad' },
          { title: 'Other', handle: 'other', dynamic: true },
        ],
      },
      {
        title: 'Thanks!',
        handle: 'Attribution Survey - Outro',
        type: 'reward',
        copy: 'We really appreciate your feedback.',
      }
    ]
  },
  {
    title: 'General Post Purchase Survey',
    description: "Understand where customers come from and how they feel.",
    icon: "fa-comments-dollar",
    handle: "post-purchase-general-survey",
    previewLink: "https://www.zigpoll.com/examples/post-purchase-survey",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How did you hear about us?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        type: 'question',
        title: 'How did you hear about us?',
        copy: '',
        handle: 'Post Purchase Survey - Attribution',
        settings: {
          showTitle: true,
          showResults: false,
          randomizeAnswers: true,
          showResponses: false,
          optional: false,
          hidden: false,
          disableSentimentAnalysis: true
        },
        answers: [
          { title: 'Friends', handle: 'friends', nextSlide: 2 },
          { title: 'TikTok', handle: 'tiktok', nextSlide: 1 },
          { title: 'Instagram', handle: 'instagram', nextSlide: 1 },
          { title: 'Facebook', handle: 'facebook', nextSlide: 1 },
          { title: 'Youtube', handle: 'youtube', nextSlide: 1 },
          { title: 'Google', handle: 'google', nextSlide: 2 },
          { title: 'Other', handle: 'other', nextSlide: 2, dynamic: true },
        ],
        logic: [
          {
            rules: [
              { rule: '=', ruleValue: 'TikTok' },
              { rule: '=', ruleValue: 'Instagram' },
              { rule: '=', ruleValue: 'Facebook' },
              { rule: '=', ruleValue: 'Youtube' },
            ],
            actions: [
              { action: 'skip', actionValue: 1 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Friends' },
              { rule: '=', ruleValue: 'Google' },
              { rule: '=', ruleValue: 'Other' }
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          }
        ]
      },
      {
        title: "Do you rememember where in particular?",
        handle: 'Post Purchase Survey - Attribution Detail',
        type: 'question',
        copy: '',
        optional: true,
        settings: {
          showTitle: true,
          showResults: false,
          randomizeAnswers: true,
          showResponses: false,
          optional: true,
          hidden: false,
          disableSentimentAnalysis: true
        },
        answers: [
          { title: 'Your brand page', handle: 'your-brand-page' },
          { title: 'An ad', handle: 'an-ad' },
          { title: 'Other', handle: 'other', dynamic: true },
        ],
      },
      {
        type: 'satisfaction',
        title: 'How was your shopping experience?',
        copy: '',
        handle: 'Post Purchase Survey - Satisfaction',
        answers: [
          { title: 'Very Unsatisfied', handle: 'very-unsatisfied', nextSlide: 3 },
          { title: 'Unsatisfied', handle: 'unsatisfied', nextSlide: 3 },
          { title: 'Neutral', handle: 'neutral', nextSlide: 4 },
          { title: 'Satisfied', handle: 'satisfied', nextSlide: 5 },
          { title: 'Very Satisfied', handle: 'very-satisfied', nextSlide: 5 },
        ],
        logic: [
          {
            rules: [
              { rule: '=', ruleValue: 'Very Unsatisfied' },
              { rule: '=', ruleValue: 'Unsatisfied' },
            ],
            actions: [
              { action: 'skip', actionValue: 3 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Neutral' },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Satisfied' },
              { rule: '=', ruleValue: 'Very Satisfied' },
            ],
            actions: [
              { action: 'skip', actionValue: 5 }
            ]
          }
        ],
        leftLabel: 'Negative',
        rightLabel: 'Positive'
      },
      {
        title: 'Sorry you feel that way.',
        handle: 'Post Purchase Survey - Negative',
        type: 'long-answer',
        copy: 'What can we do to make the experience better?',
        hide: true,
        nextSlide: 6,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 6 }
            ]
          },
        ]
      },
      {
        title: 'Thanks for your feedback!',
        handle: 'Post Purchase Survey - Neutral',
        type: 'long-answer',
        copy: 'What can we do to make the experience better?',
        hide: true,
        nextSlide: 6,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 6 }
            ]
          },
        ]
      },
      {
        title: "We're happy to hear that!",
        handle: 'Post Purchase Survey - Positive',
        type: 'long-answer',
        copy: 'Is there anything we can we do to make it better?',
        nextSlide: 6,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 6 }
            ]
          },
        ]
      },
      {
        title: 'Thanks!',
        handle: 'Post Purchase Survey - Outro',
        type: 'reward',
        copy: 'We really appreciate your feedback.',
      }
    ]
  },
  {
    title: 'Returning Customer Post Purchase Survey',
    description: "Keep learning about how your customers discovered you and how they feel.",
    icon: "fa-comments-dollar",
    handle: "post-purchase-general-survey-returning",
    previewLink: "https://www.zigpoll.com/examples/post-purchase-survey",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How was your shopping experience today?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        type: 'satisfaction',
        title: 'How was your shopping experience today?',
        copy: '',
        handle: 'Post Purchase Survey - Satisfaction',
        answers: [
          { title: 'Very Unsatisfied', handle: 'very-unsatisfied', nextSlide: 1 },
          { title: 'Unsatisfied', handle: 'unsatisfied', nextSlide: 1 },
          { title: 'Neutral', handle: 'neutral', nextSlide: 2 },
          { title: 'Satisfied', handle: 'satisfied', nextSlide: 3 },
          { title: 'Very Satisfied', handle: 'very-satisfied', nextSlide: 3 },
        ],
        logic: [
          {
            rules: [
              { rule: '=', ruleValue: 'Very Unsatisfied' },
              { rule: '=', ruleValue: 'Unsatisfied' },
            ],
            actions: [
              { action: 'skip', actionValue: 1 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Neutral' },
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Satisfied' },
              { rule: '=', ruleValue: 'Very Satisfied' },
            ],
            actions: [
              { action: 'skip', actionValue: 3 }
            ]
          }
        ],
        leftLabel: 'Negative',
        rightLabel: 'Positive'
      },
      {
        title: 'Sorry you feel that way.',
        handle: 'Post Purchase Survey - Negative',
        type: 'long-answer',
        copy: 'What can we do to make the experience better?',
        hide: true,
        nextSlide: 4,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ]
      },
      {
        title: 'Thanks for your feedback!',
        handle: 'Post Purchase Survey - Neutral',
        type: 'long-answer',
        copy: 'What can we do to make the experience better?',
        hide: true,
        nextSlide: 4,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ]
      },
      {
        title: "We're happy to hear that!",
        handle: 'Post Purchase Survey - Positive',
        type: 'long-answer',
        copy: 'Is there anything we can we do to make it better?',
        nextSlide: 4,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ]
      },
      {
        type: 'binary',
        title: 'Has your opinion on our brand changed since you last shopped with us?',
        copy: '',
        handle: 'Post Purchase Survey - HYOC',
        settings: {
          showTitle: true,
          showResults: false,
          randomizeAnswers: false,
          showResponses: false,
          optional: false,
          hidden: false,
          disableSentimentAnalysis: true
        },
        answers: [
          { title: 'Yes', handle: 'yes', nextSlide: 5 },
          { title: 'No', handle: 'no', nextSlide: 6 },
        ],
        logic: [
          {
            rules: [
              { rule: '=', ruleValue: 'Yes' },
            ],
            actions: [
              { action: 'skip', actionValue: 5 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'No' },
            ],
            actions: [
              { action: 'skip', actionValue: 6 }
            ]
          },
        ],
      },
      {
        type: 'long-answer',
        title: 'How has it changed?',
        copy: '',
        handle: 'Post Purchase Survey - HYOC - Open Ended',
      },
      {
        title: 'Thanks!',
        handle: 'Post Purchase Survey - Outro',
        type: 'reward',
        copy: 'We really appreciate your feedback.',
      }
    ]
  },
  {
    title: 'Post Purchase Satisfaction Survey',
    description: "Understand how your customers feel about your checkout process.",
    icon: "fa-comment-dollar",
    handle: "post-purchase-survey",
    previewLink: "https://www.zigpoll.com/examples/post-purchase-survey",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How was your shopping experience?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        type: 'satisfaction',
        title: 'How was your shopping experience?',
        copy: '',
        handle: 'Post Purchase Survey - Satisfaction',
        answers: [
          { title: 'Very Unsatisfied', handle: 'very-unsatisfied', nextSlide: 1 },
          { title: 'Unsatisfied', handle: 'unsatisfied', nextSlide: 1 },
          { title: 'Neutral', handle: 'neutral', nextSlide: 2 },
          { title: 'Satisfied', handle: 'satisfied', nextSlide: 3 },
          { title: 'Very Satisfied', handle: 'very-satisfied', nextSlide: 3 },
        ],
        logic: [
          {
            rules: [
              { rule: '=', ruleValue: 'Very Unsatisfied' },
              { rule: '=', ruleValue: 'Unsatisfied' },
            ],
            actions: [
              { action: 'skip', actionValue: 1 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Neutral' },
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Satisfied' },
              { rule: '=', ruleValue: 'Very Satisfied' },
            ],
            actions: [
              { action: 'skip', actionValue: 3 }
            ]
          }
        ],
        leftLabel: 'Negative',
        rightLabel: 'Positive'
      },
      {
        title: 'What can we do to make the experience better?',
        handle: 'Post Purchase Survey - Negative',
        type: 'long-answer',
        copy: '',
        nextSlide: 4,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ],
        hide: true,
      },
      {
        title: 'What can we do to make the experience better?',
        handle: 'Post Purchase Survey - Neutral',
        type: 'long-answer',
        copy: '',
        hide: true,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ],
        nextSlide: 4
      },
      {
        title: "Is there anything we can we do to make it better?",
        handle: 'Post Purchase Survey - Positive',
        type: 'long-answer',
        copy: '',
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ],
        nextSlide: 4
      },
      {
        title: 'Thanks!',
        handle: 'Post Purchase Survey - Outro',
        type: 'reward',
        copy: 'We really appreciate your feedback.',
      }
    ]
  },
  {
    title: 'In-Depth Attribution Survey',
    description: "Understand what channels are effective plus how and when customers find you.",
    icon: "fa-code-branch",
    handle: "in-depth-attribution-survey",
    previewLink: "https://www.zigpoll.com/examples/in-depth-attribution-survey",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How did you hear about us?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        type: 'question',
        title: 'How did you hear about us?',
        copy: '',
        handle: 'Attribution Survey - Intro',
        settings: {
          showTitle: true,
          showResults: false,
          randomizeAnswers: true,
          showResponses: false,
          optional: false,
          hidden: false,
          disableSentimentAnalysis: true
        },
        answers: [
          { title: 'Friends', handle: 'friends', nextSlide: 2 },
          { title: 'TikTok', handle: 'tiktok', nextSlide: 1 },
          { title: 'Instagram', handle: 'instagram', nextSlide: 1 },
          { title: 'Facebook', handle: 'facebook', nextSlide: 1 },
          { title: 'Youtube', handle: 'youtube', nextSlide: 1 },
          { title: 'Google', handle: 'google', nextSlide: 2 },
          { title: 'Other', handle: 'other', nextSlide: 2, dynamic: true },
        ],
        logic: [
          {
            rules: [
              { rule: '=', ruleValue: 'TikTok' },
              { rule: '=', ruleValue: 'Instagram' },
              { rule: '=', ruleValue: 'Facebook' },
              { rule: '=', ruleValue: 'Youtube' },
            ],
            actions: [
              { action: 'skip', actionValue: 1 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Friends' },
              { rule: '=', ruleValue: 'Google' },
              { rule: '=', ruleValue: 'Other' }
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          }
        ]
      },
      {
        title: "Do you rememember where in particular?",
        handle: 'Attribution Survey - Detail',
        type: 'question',
        copy: '',
        optional: true,
        settings: {
          showTitle: true,
          showResults: false,
          randomizeAnswers: true,
          showResponses: false,
          optional: true,
          hidden: false,
          disableSentimentAnalysis: true
        },
        answers: [
          { title: 'Your brand page', handle: 'your-brand-page' },
          { title: 'An ad', handle: 'an-ad' },
          { title: 'Other', handle: 'other', dynamic: true },
        ],
      },
      {
        type: 'question',
        title: 'What brought you to our site today?',
        handle: 'Attribution Survey - What brought you?',
        answers: [
          { title: 'I received a text message', handle: 'i-received-a-text-message' },
          { title: 'I saw an ad', handle: 'i-saw-an-add' },
          { title: 'Someone told me about you', handle: 'someone-told-me-about-you' },
          { title: 'I was searching for something', handle: 'i-was-searching-for-something' },
          { title: 'I received an email', handle: 'i-received-an-email' },
          { title: 'I remembered you from the past', handle: 'i-remembered-you-from-the-past' },
          { title: 'Other', handle: 'other', dynamic: true },
        ],
      },
      {
        type: 'question',
        title: 'How long did you know about us before placing your first purchase?',
        handle: 'Attribution Survey - How long?',
        answers: [
          { title: 'Less than a day', handle: 'less-than-a-day' },
          { title: 'Less than a week', handle: 'less-than-a-week' },
          { title: 'Less than a month', handle: 'less-than-a-month' },
          { title: '1 - 3 months', handle: '1-3-months' },
          { title: '3 - 12 months', handle: '3-12-months' },
          { title: 'Over 12 months', handle: 'over-12-months' },
        ],
      },
      {
        title: 'Thanks!',
        handle: 'Attribution Survey - Outro',
        type: 'reward',
        copy: 'We really appreciate your feedback.',
      }
    ]
  },
  {
    title: 'Customer Experience Survey',
    description: "Get a 360 degree view of how your customers feel about your website.",
    icon: 'fa-street-view',
    handle: "site-experience-survey",
    previewLink: "https://www.zigpoll.com/examples/site-experience",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'So... what do you think?',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How likely are you to recommend our website to your friends, colleagues and family?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'So... what do you think?',
      }
    },
    slides: [
      {
        type: 'range',
        title: 'How likely are you to recommend our website to your friends, colleagues and family?',
        copy: '',
        handle: 'CES - Recommendation',
        answers: [
          { title: '1', handle: '1', nextSlide: 1 },
          { title: '2', handle: '2', nextSlide: 1 },
          { title: '3', handle: '3', nextSlide: 1 },
          { title: '4', handle: '4', nextSlide: 1 },
          { title: '5', handle: '5', nextSlide: 1 },
          { title: '6', handle: '6', nextSlide: 1 },
          { title: '7', handle: '7', nextSlide: 2 },
          { title: '8', handle: '8', nextSlide: 2 },
          { title: '9', handle: '9', nextSlide: 2 },
          { title: '10', handle: '10', nextSlide: 2 },
        ],
        logic: [
          {
            rules: [
              { rule: '<=', ruleValue: '6' },
            ],
            actions: [
              { action: 'skip', actionValue: 1 }
            ]
          },
          {
            rules: [
              { rule: '>=', ruleValue: '7' },
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          }
        ],
        leftLabel: 'Not Likely',
        rightLabel: 'Very Likely'
      },
      {
        title: "Sorry to hear that. Can you let us know why?",
        handle: "CES - Recommendation - Negative",
        type: 'long-answer',
        copy: "",
        optional: true,
        hide: true,
      },
      {
        type: 'range',
        title: 'Overall, how easy was it to purchase from our website?',
        copy: '',
        handle: 'CES - Ease of use',
        answers: [
          { title: '1', handle: '1', nextSlide: 3 },
          { title: '2', handle: '2', nextSlide: 3 },
          { title: '3', handle: '3', nextSlide: 3 },
          { title: '4', handle: '4', nextSlide: 3 },
          { title: '5', handle: '5', nextSlide: 4 },
          { title: '6', handle: '6', nextSlide: 4 },
          { title: '7', handle: '7', nextSlide: 4 },
        ],
        logic: [
          {
            rules: [
              { rule: '<=', ruleValue: '4' },
            ],
            actions: [
              { action: 'skip', actionValue: 3 }
            ]
          },
          {
            rules: [
              { rule: '>=', ruleValue: '5' },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          }
        ],
        leftLabel: 'Not Easy',
        rightLabel: 'Very Easy'
      },
      {
        title: "Sorry to hear that. Can you let us know why it was difficult to make a purchase?",
        handle: "CES - Ease of use - Negative",
        type: 'long-answer',
        copy: "",
        optional: true,
        hide: true,
      },
      {
        title: 'How satisfied are you with your purchasing experience on our website?',
        handle: "CES - Satisfaction",
        type: 'satisfaction',
        copy: "",
        answers: [
          { title: 'Very Unsatisfied', handle: 'very-unsatisfied', nextSlide: 5 },
          { title: 'Unsatisfied', handle: 'unsatisfied', nextSlide: 5 },
          { title: 'Neutral', handle: 'neutral', nextSlide: 6 },
          { title: 'Satisfied', handle: 'satisfied', nextSlide: 6 },
          { title: 'Very Satisfied', handle: 'very-satisfied', nextSlide: 6 },
        ],
        logic: [
          {
            rules: [
              { rule: '=', ruleValue: 'Very Unsatisfied' },
              { rule: '=', ruleValue: 'Unsatisfied' },
            ],
            actions: [
              { action: 'skip', actionValue: 5 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Neutral' },
              { rule: '=', ruleValue: 'Satisfied' },
              { rule: '=', ruleValue: 'Very Satisfied' },
            ],
            actions: [
              { action: 'skip', actionValue: 6 }
            ]
          }
        ],
        leftLabel: 'Very Unsatisfied',
        rightLabel: 'Very Satisfied'
      },
      {
        title: "Sorry to hear that. Can you let us know why you are unsatisfied with the website?",
        handle: "CES - Satisfaction - Negative",
        type: 'long-answer',
        copy: "",
        optional: true,
        hide: true,
      },
      {
        type: 'question',
        title: 'How did you hear about us?',
        copy: '',
        handle: 'CES - Attribution',
        settings: {
          showTitle: true,
          showResults: false,
          randomizeAnswers: true,
          showResponses: false,
          optional: false,
          hidden: false,
          disableSentimentAnalysis: true
        },
        answers: [
          { title: 'Friends', handle: 'friends', nextSlide: 8 },
          { title: 'TikTok', handle: 'tiktok', nextSlide: 7 },
          { title: 'Instagram', handle: 'instagram', nextSlide: 7 },
          { title: 'Facebook', handle: 'facebook', nextSlide: 7 },
          { title: 'Youtube', handle: 'youtube', nextSlide: 7 },
          { title: 'Google', handle: 'google', nextSlide: 8 },
          { title: 'Other', handle: 'other', nextSlide: 8, dynamic: true },
        ],
        logic: [
          {
            rules: [
              { rule: '=', ruleValue: 'TikTok' },
              { rule: '=', ruleValue: 'Instagram' },
              { rule: '=', ruleValue: 'Facebook' },
              { rule: '=', ruleValue: 'Youtube' },
            ],
            actions: [
              { action: 'skip', actionValue: 7 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Friends' },
              { rule: '=', ruleValue: 'Google' },
              { rule: '=', ruleValue: 'Other' }
            ],
            actions: [
              { action: 'skip', actionValue: 8 }
            ]
          }
        ]
      },
      {
        title: "Do you rememember where in particular?",
        handle: 'CES - Attribution - Detail',
        type: 'question',
        copy: '',
        optional: true,
        settings: {
          showTitle: true,
          showResults: false,
          randomizeAnswers: true,
          showResponses: false,
          optional: true,
          hidden: false,
          disableSentimentAnalysis: true
        },
        hide: true,
        answers: [
          { title: 'Your brand page', handle: 'your-brand-page' },
          { title: 'An ad', handle: 'an-ad' },
          { title: 'Other', handle: 'other', dynamic: true },
        ],
      },
      {
        title: "Please share any additional feedback or recommendations about our website. We are listening!",
        handle: "CES - Additional",
        type: 'long-answer',
        copy: "",
        optional: true,
      },
      {
        title: 'Thank you for your feedback!',
        handle: 'CES - Outro',
        type: 'reward',
        copy: 'We really appreciate you taking the time to help us improve and exceed your expectations. Use the code below for <strong>10% off</strong> your next order.',
        rewardCode: 'Zigpoll10',
        rewardBanner: '<p>Get 10% off your next order by completing this survey!</p>'
      }
    ]
  },
  {
    title: 'Net Promoter Score (NPS)',
    description: "Gauge the loyalty of your customer relationships.",
    icon: "fa-theater-masks",
    handle: "net-promoter-score",
    previewLink: "https://www.zigpoll.com/examples/net-promoter-score",
    hasProduct: true,
    emailDisplaySettings: {
      subject: 'So... what do you think?',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How likely are you to recommend us to a friend or family member?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'So... what do you think?',
      }
    },
    slides: [
      {
        type: 'range',
        title: 'How likely are you to recommend {{ product }} to a friend?',
        copy: '',
        handle: 'Net Promoter Score (Range)',
        answers: [
          { title: '1', handle: '1', nextSlide: 1 },
          { title: '2', handle: '2', nextSlide: 1 },
          { title: '3', handle: '3', nextSlide: 1 },
          { title: '4', handle: '4', nextSlide: 1 },
          { title: '5', handle: '5', nextSlide: 1 },
          { title: '6', handle: '6', nextSlide: 1 },
          { title: '7', handle: '7', nextSlide: 2 },
          { title: '8', handle: '8', nextSlide: 2 },
          { title: '9', handle: '9', nextSlide: 3 },
          { title: '10', handle: '10', nextSlide: 3 }
        ],
        logic: [
          {
            rules: [
              { rule: '<=', ruleValue: '6' },
            ],
            actions: [
              { action: 'skip', actionValue: 1 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: '7' },
              { rule: '=', ruleValue: '8' },
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          },
          {
            rules: [
              { rule: '>=', ruleValue: '9' },
            ],
            actions: [
              { action: 'skip', actionValue: 3 }
            ]
          }
        ],
        leftLabel: 'Less Likely',
        rightLabel: 'Very Likely'
      },
      {
        type: 'long-answer',
        title: 'Sorry to hear that. Can you tell us a bit more about why you feel that way?',
        copy: '',
        handle: 'Net Promoter Score (Detractor Response)',
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ],
        nextSlide: 4,
        hide: true,
      },
      {
        type: 'long-answer',
        title: 'Thanks for letting us know. Can you tell us a bit more about why you feel that way?',
        copy: '',
        handle: 'Net Promoter Score (Passive Response)',
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ],
        nextSlide: 4,
        hide: true,
      },
      {
        type: 'long-answer',
        title: 'We appreciate it! Can you tell us a bit more about why you feel that way?',
        copy: '',
        handle: 'Net Promoter Score (Promoter Response)',
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ],
        nextSlide: 4,
      },
      {
        type: 'reward',
        title: 'Thanks for your feedback!',
        copy: 'We really appreciate your response! Have a great day.',
        handle: 'Net Promoter Score (Thank You)'
      }
    ]
  },
  {
    title: 'Customer Segmentation Survey',
    description: "Understand who your customer is and their average order value.",
    icon: 'fa-user-friends',
    handle: "customer-segmentation-survey",
    previewLink: "https://www.zigpoll.com/examples/customer-segmentation-survey",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "What best describes you?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        type: 'question',
        title: 'What best describes you?',
        copy: '',
        handle: 'Customer Segmentation',
        settings: {
          showTitle: true,
          showResults: false,
          randomizeAnswers: true,
          showResponses: false,
          optional: false,
          hidden: false,
          disableSentimentAnalysis: true
        },
        answers: [
          { title: 'Option 1', handle: 'option-1' },
          { title: 'Option 2', handle: 'option-2' },
          { title: 'Option 3', handle: 'option-3' },
          { title: 'Option 4', handle: 'option-4' },
          { title: 'Other', handle: 'other', dynamic: true },
        ],
      },
     ]
  },
  {
    title: 'Exit Intent Survey',
    description: "Understand why customers are leaving your site without converting.",
    icon: "fa-person-walking-arrow-right",
    handle: "exit-intent-survey",
    previewLink: "https://www.zigpoll.com/examples/exit-intent-survey",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How did you hear about us?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        type: 'checkbox',
        title: 'Was there anything that stopped you from making a purchase?',
        copy: '',
        handle: 'Was there anything that stopped you from making a purchase?',
        settings: {
          showTitle: true,
          showResults: false,
          randomizeAnswers: true,
          showResponses: false,
          optional: false,
          hidden: false,
          disableSentimentAnalysis: true
        },
        answers: [
          { title: "Couldn't find what I was looking for", handle: 'couldnt-find-what-i-was-looking-for', nextSlide: 2 },
          { title: "Price was too expensive", handle: 'price-was-too-expensive', nextSlide: 1 },
          { title: "Not enough information about the product", handle: 'not-enough-information-about-the-product', nextSlide: 1 },
          { title: 'Other', handle: 'other', dynamic: true },
        ],
        logic: []
      },
      {
        title: "Is there anything else you can share?",
        handle: 'Is there anything else you can share?',
        type: 'long-answer',
        copy: '',
        optional: true,
        settings: {
          showTitle: true,
          showResults: false,
          randomizeAnswers: true,
          showResponses: false,
          optional: true,
          hidden: false,
          disableSentimentAnalysis: true
        },
      },
      {
        title: 'Thanks!',
        handle: 'Thanks!',
        type: 'reward',
        copy: 'We really appreciate your feedback!',
      }
    ]
  },
  {
    title: 'Abandoned Checkout',
    description: "Engage your users after an abandoned checkout and win them back.",
    icon: 'fa-shopping-bag',
    handle: "abandoned-checkout",
    previewLink: "https://www.zigpoll.com/examples/abandoned-checkout",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'You left something behind.',
      showLink: false,
      linkType: 'input',
      content: {
        header: "What's the primary reason that stopped you from making a purchase today?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'You left something behind.',
      }
    },
    slides: [
      {
        title: "What's the primary reason that stopped you from making a purchase today?",
        handle: "Abandoned Cart - 1",
        type: 'long-answer',
        copy: "",
      },
      {
        title: "What would have been required for you to complete the purchase of the item(s) in your cart?",
        handle: "Abandoned Cart - 2",
        type: 'long-answer',
        copy: "",
      },
      {
        title: "Do you have anything else you would like to share with us?",
        handle: "Abandoned Cart - 3",
        type: 'long-answer',
        copy: "",
      },
      {
        type: 'range',
        title: 'How likely are you to return and shop with us in the future?',
        copy: '',
        handle: 'Abandoned Cart - 4',
        answers: [
          { title: '1', handle: '1' },
          { title: '2', handle: '2' },
          { title: '3', handle: '3' },
          { title: '4', handle: '4' },
          { title: '5', handle: '5' },
        ],
        leftLabel: 'Not Likely',
        rightLabel: 'Very Likely'
      },
      {
        type: 'range',
        title: 'Based on your experience, how likely are you to recommend our website so friends and family?',
        copy: '',
        handle: 'Abandoned Cart - 5',
        answers: [
          { title: '1', handle: '1' },
          { title: '2', handle: '2' },
          { title: '3', handle: '3' },
          { title: '4', handle: '4' },
          { title: '5', handle: '5' },
          { title: '6', handle: '6' },
          { title: '7', handle: '7' },
          { title: '8', handle: '8' },
          { title: '9', handle: '9' },
          { title: '10', handle: '10' },
        ],
        leftLabel: 'Not Likely',
        rightLabel: 'Very Likely'
      },
      {
        title: 'Thank you for your feedback!',
        handle: 'Abandoned Cart - Outro',
        type: 'reward',
        copy: 'We really appreciate you taking the time to help us improve and meet your expectations. If you want to make a purchase later on use the code below for <strong>10% off</strong> your order.',
        rewardCode: 'Zigpoll10',
        rewardBanner: '<p>Get 10% off your next order by completing this survey!</p>'
      }
    ]
  },
  {
    title: 'Contact Us',
    description: "Easily stay in touch with your customers.",
    icon: 'fa-comments',
    handle: "contact-us",
    previewLink: "https://www.zigpoll.com/examples/contact-us",
    hasProduct: false,
    slides: [
      {
        title: 'Contact Us',
        handle: 'Contact Us - 1',
        type: 'form',
        copy: '<p>Fill out the the form below to send us a note.</p><p><br></p><p>If you want us to get back to you, please include your email address.</p>',
        formInputs: [
          {label: "Question/Comment", type: "long-answer", optional: false, options: []},
          {label: "Email", type: "email-capture", optional: true, options: []}
        ],
        settings: { showTitle: true, disableSentimentAnalysis: true },
        hasResponses: true,
      },
      {
        title: 'Thanks!',
        handle: 'Contact Us - 2',
        type: 'copy',
        copy: "We appreciate your feedback. Have a great day!",
      }
    ]
  },
  {
    title: 'Customer Satisfaction Survey',
    description: "Understand how your customers really feel about their purchase.",
    icon: 'fa-smile-wink',
    handle: "customer-satisfaction-survey",
    previewLink: "https://www.zigpoll.com/examples/customer-satisfaction-survey",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How was your shopping experience?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        type: 'satisfaction',
        title: 'How was your shopping experience?',
        copy: '',
        handle: 'Customer Satisfaction - Satisfaction',
        answers: [
          { title: 'Very Unsatisfied', handle: 'very-unsatisfied' },
          { title: 'Unsatisfied', handle: 'unsatisfied' },
          { title: 'Neutral', handle: 'neutral' },
          { title: 'Satisfied', handle: 'satisfied' },
          { title: 'Very Satisfied', handle: 'very-satisfied' },
        ],
        leftLabel: 'Negative',
        rightLabel: 'Positive'
      },
      {
        type: 'long-answer',
        title: 'Was there anything that almost made you not purchase?',
        handle: 'Customer Satisfaction - Abandoned purchase risk',
        copy: "",
        optional: true,
      },
      {
        type: 'long-answer',
        title: 'Why did you purchase from us?',
        handle: 'Customer Satisfaction - Purchase reason',
        copy: "",
      },
      {
        type: 'reward',
        title: 'Thanks for your feedback!',
        copy: 'We really appreciate hearing from you! Have a great day.',
        handle: 'Customer Satisfaction - Outro'
      }
     ]
  },
  {
    title: 'Word of Mouth',
    description: "Discover how converting customers discovered your brand.",
    icon: 'fa-bullhorn',
    handle: "word-of-mouth",
    previewLink: "https://www.zigpoll.com/examples/word-of-mouth",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "Who can we thank for your purchase today?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        title: 'Who can we thank for your purchase today?',
        handle: 'Word of Mouth - 1',
        type: 'long-answer',
        copy: ''
      },
      {
        title: 'Thank You!',
        handle: 'Word of Mouth - 2',
        type: 'copy',
        copy: "We really appreciate your feedback!"
      }
    ]
  },
  {
    title: 'Giveaway',
    description: "Incentivize your customers to submit their email provide feedback.",
    icon: 'fa-gift',
    handle: "giveaway",
    previewLink: "https://www.zigpoll.com/examples/giveaway",
    hasProduct: false,
    slides: [
      {
        title: 'Enter to win!',
        handle: 'Giveaway - 1',
        type: 'email-capture',
        copy: 'Enter your email for a chance to win 10% off your next order'
      },
      {
        title: 'Enter to win!',
        handle: 'Giveaway - 2',
        type: 'reward',
        copy: "Thanks! We will be in touch shortly if you've won."
      }
    ]
  },
  {
    title: 'Abandoned Purchase Intent',
    description: "Understand why people are not converting on your website.",
    icon: 'fa-cart-arrow-down',
    handle: "abandoned-cart",
    previewLink: "https://www.zigpoll.com/examples/abandoned-cart",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'You left something behind.',
      showLink: false,
      linkType: 'input',
      content: {
        header: "What's your primary reason for not purchasing today?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'You left something behind.',
      }
    },
    slides: [
      {
        title: "Before you go",
        handle: "Exit Intent - 1",
        type: 'long-answer',
        copy: "What's your primary reason for not purchasing today?",
      },
      {
        title: 'Before you go',
        handle: "Exit Intent - 2",
        type: 'satisfaction',
        copy: "How would you rate your overall experience with our site?",
        answers: [
          { title: 'Very Unsatisfied', handle: 'very-unsatisfied', nextSlide: 2 },
          { title: 'Unsatisfied', handle: 'unsatisfied', nextSlide: 2 },
          { title: 'Neutral', handle: 'neutral', nextSlide: 3 },
          { title: 'Satisfied', handle: 'satisfied', nextSlide: 4 },
          { title: 'Very Satisfied', handle: 'very-satisfied', nextSlide: 4 },
        ],
        logic: [
          {
            rules: [
              { rule: '=', ruleValue: 'Very Unsatisfied' },
              { rule: '=', ruleValue: 'Unsatisfied' },
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Neutral' },
            ],
            actions: [
              { action: 'skip', actionValue: 3 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Satisfied' },
              { rule: '=', ruleValue: 'Very Satisfied' },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          }
        ],
        leftLabel: 'Hated It',
        rightLabel: 'Loved It'
      },
      {
        title: 'Sorry you feel that way.',
        handle: 'Exit Intent - 3: Negative',
        type: 'long-answer',
        copy: 'What can we do to make the experience better?',
        nextSlide: 5,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 5 }
            ]
          },
        ],
        hide: true,
      },
      {
        title: 'Thanks for your feedback!',
        handle: 'Exit Intent - 3: Neutral',
        type: 'long-answer',
        copy: 'What can we do to make the experience better?',
        hide: true,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 5 }
            ]
          },
        ],
        nextSlide: 5
      },
      {
        title: "We're glad you liked it!",
        handle: 'Exit Intent - 3: Positive',
        type: 'long-answer',
        copy: 'Is there anything we can we do to make it better?',
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 5 }
            ]
          },
        ],
        nextSlide: 5
      },
      {
        title: "Anything else to share?",
        handle: 'Exit Intent - 4',
        type: 'long-answer',
        copy: 'Leave us any other thoughts you might have.',
        optional: true,
      },
      {
        title: 'Thanks!',
        handle: 'Exit Intent - Outro',
        type: 'reward',
        copy: 'We really appreciate your feedback! If you want to make a purchase later on use the code below for <strong>10% off</strong> your order.',
        rewardCode: 'Zigpoll10',
        rewardBanner: '<p>Get 10% off your next order by completing this survey!</p>'
      }
    ]
  },
  {
    title: 'Exit Probe',
    description: "Understand why a customer is leaving your website and try to win them back.",
    icon: 'fa-person-running',
    handle: "exit-probe",
    previewLink: "https://www.zigpoll.com/examples/abandoned-cart",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'Sorry to see you go...',
      showLink: false,
      linkType: 'input',
      content: {
        header: "Did you find everything you were looking for today?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Sorry to see you go...',
      }
    },
    slides: [
      {
        title: "Did you find everything you were looking for today?",
        handle: "Exit Probe - 1",
        type: 'long-answer',
        copy: "",
      },
      {
        title: "Is there anything that we could help you with?",
        handle: "Exit Probe - 2",
        type: 'form',
        copy: "",
        formInputs: [
          {label: "How can we help?", type: "long-answer", optional: false, options: []},
          {label: "Email", type: "email-capture", optional: true, options: []}
        ],
      },
      {
        title: 'Thanks!',
        handle: 'Exit Probe - 3',
        type: 'copy',
        copy: "We really appreciate your feedback!",
      }
    ]
  },
  {
    title: 'Site Feedback',
    description: "General feedback about your website.",
    icon: "fa-comment-dots",
    handle: "feedback-slides",
    previewLink: "https://www.zigpoll.com/examples/feedback",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How would your rate your experience?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        type: 'satisfaction',
        title: 'How would your rate your experience?',
        copy: 'We really appreciate your honest feedback!',
        handle: 'Feedback Rating',
        answers: [
          { title: 'Very Unsatisfied', handle: 'very-unsatisfied', nextSlide: 1 },
          { title: 'Unsatisfied', handle: 'unsatisfied', nextSlide: 1 },
          { title: 'Neutral', handle: 'neutral', nextSlide: 2 },
          { title: 'Satisfied', handle: 'satisfied', nextSlide: 3 },
          { title: 'Very Satisfied', handle: 'very-satisfied', nextSlide: 3 },
        ],
        logic: [
          {
            rules: [
              { rule: '=', ruleValue: 'Very Unsatisfied' },
              { rule: '=', ruleValue: 'Unsatisfied' },
            ],
            actions: [
              { action: 'skip', actionValue: 1 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Neutral' },
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Satisfied' },
              { rule: '=', ruleValue: 'Very Satisfied' },
            ],
            actions: [
              { action: 'skip', actionValue: 3 }
            ]
          }
        ],
        leftLabel: 'Terrible',
        rightLabel: 'Great'
      },
      {
        type: 'long-answer',
        title: 'Sorry to hear that',
        copy: 'Can you tell us a bit more about why you feel that way?',
        handle: 'Feedback Short Answer (Negative)',
        nextSlide: 4,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ],
        hide: true,
      },
      {
        type: 'long-answer',
        title: 'Thanks for letting us know',
        copy: 'Can you tell us a bit more about why you feel that way?',
        handle: 'Feedback Short Answer (Neutral)',
        nextSlide: 4,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ],
        hide: true,
      },
      {
        type: 'long-answer',
        title: "Glad you're enjoying!",
        copy: 'Can you tell us a bit more about why you feel that way?',
        handle: 'Feedback Short Answer (Positive)',
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ],
        nextSlide: 4,
      },
      {
        type: 'copy',
        title: 'Thanks for your feedback',
        copy: 'We really appreciate your response! Have a great day.',
        handle: 'Feedback (Thank You)'
      }
    ]
  },
  {
    title: 'Feature Feedback',
    description: "Discover what your customers think about recent changes.",
    icon: 'fa-comment-dots',
    handle: "feature-feedback",
    previewLink: "https://www.zigpoll.com/examples/feature-feedback",
    hasProduct: true,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How satisfied are you with our product?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        type: 'satisfaction',
        title: 'Quick question...',
        handle: 'Feature Feedback - 1',
        copy: 'How satisfied are you with {{ product }}?',
        answers: [
          { title: 'Very Unsatisfied', handle: 'very-unsatisfied' },
          { title: 'Unsatisfied', handle: 'unsatisfied' },
          { title: 'Neutral', handle: 'neutral' },
          { title: 'Satisfied', handle: 'satisfied' },
          { title: 'Very Satisfied', handle: 'very-satisfied' },
        ]
      },
      {
        type: 'long-answer',
        title: 'Quick question...',
        handle: 'Feature Feedback - 2',
        copy: 'How would you feel if you could no longer use {{ product }}?'
      },
      {
        type: 'long-answer',
        title: 'Quick question...',
        handle: 'Feature Feedback - 3',
        copy: 'What would you use as an alternative if {{ product }} were no longer available?'
      },
      {
        type: 'short-answer',
        title: 'Quick question...',
        handle: 'Feature Feedback - 4',
        copy: 'How many times did you use {{ product }} in the last week?'
      },
      {
        type: 'short-answer',
        title: 'Quick question...',
        handle: 'Feature Feedback - 5',
        copy: "Describe how you're currently using {{ product }} in a few words."
      },
      {
        type: 'long-answer',
        title: 'Quick question...',
        handle: 'Feature Feedback - 6',
        copy: "Have you encountered any issues?",
        optional: true,
      },
      {
        type: 'long-answer',
        title: 'Quick question...',
        handle: 'Feature Feedback - 7',
        copy: "Any other ideas, comments, or request for {{ product }}?",
        optional: true,
      }
    ]
  },
  {
    title: 'Reward Email Capture',
    description: "Incentivize your customers to submit their email provide feedback.",
    icon: 'fa-gift',
    handle: "reward-email-capture",
    previewLink: "https://www.zigpoll.com/examples/reward-email-capture",
    hasProduct: false,
    slides: [
      {
        title: 'Get 10% off your order!',
        handle: 'Giveaway - 1',
        type: 'email-capture',
        copy: 'Add your email for 10% off your next order'
      },
      {
        title: 'Thanks!',
        handle: 'Giveaway - 2',
        type: 'reward',
        copy: "Use the code below for 10% off.",
        rewardCode: 'ZIGPOLL10',
        rewardBanner: '<p>Get 10% off your next order by completing this survey!</p>'
      }
    ]
  },
  {
    title: 'New Visitor',
    description: "Understand out how people are finding your site.",
    icon: 'fa-user-plus',
    handle: "new-visitor",
    previewLink: "https://www.zigpoll.com/examples/new-visitor",
    hasProduct: true,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How did you find out about us?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        title: 'Welcome!',
        handle: 'New Visitor - 1',
        type: 'long-answer',
        copy: 'How did you discover {{ product }}?',
        settings: {
          showTitle: false,
          showResults: false,
          randomizeAnswers: false,
          showResponses: false,
          optional: false,
          hidden: false,
        }
      },
      {
        title: 'Welcome!',
        handle: 'New Visitor - 2',
        type: 'long-answer',
        copy: 'What would you use as an alternative if {{ product }} were no longer available?',
        settings: {
          showTitle: false,
          showResults: false,
          randomizeAnswers: false,
          showResponses: false,
          optional: false,
          hidden: false
        }
      },
      {
        title: 'Welcome!',
        handle: 'New Visitor - 3',
        type: 'long-answer',
        copy: 'What does {{ product }} do well?',
        settings: {
          showTitle: false,
          showResults: false,
          randomizeAnswers: false,
          showResponses: false,
          optional: false,
          hidden: false
        }
      },
      {
        title: 'Welcome!',
        handle: 'New Visitor - 4',
        type: 'long-answer',
        copy: 'How could {{ product }} improve?',
        settings: {
          showTitle: false,
          showResults: false,
          randomizeAnswers: false,
          showResponses: false,
          optional: false,
          hidden: false
        }
      },
      {
        title: 'Welcome!',
        handle: 'New Visitor - 5',
        type: 'short-answer',
        copy: "Describe how you're using {{ product }} in a few words.",
        settings: {
          showTitle: false,
          showResults: false,
          randomizeAnswers: false,
          showResponses: false,
          optional: false,
          hidden: false
        }
      },
      {
        title: 'Welcome!',
        handle: 'New Visitor - 6',
        type: 'long-answer',
        optional: true,
        copy: 'Do you have any other ideas, comments, or requests for {{ product }}?',
        settings: {
          showTitle: false,
          showResults: false,
          randomizeAnswers: false,
          showResponses: false,
          optional: false,
          hidden: false
        }
      },
      {
        title: 'Welcome!',
        handle: 'New Visitor - 7',
        type: 'range',
        copy: 'How likely are you to recommend {{ product }} to a friend?',
        settings: {
          showTitle: false,
          showResults: false,
          randomizeAnswers: false,
          showResponses: false,
          optional: false,
          hidden: false
        },
        answers: [
          { title: '1', handle: '1' },
          { title: '2', handle: '2' },
          { title: '3', handle: '3' },
          { title: '4', handle: '4' },
          { title: '5', handle: '5' },
          { title: '6', handle: '6' },
          { title: '7', handle: '7' },
          { title: '8', handle: '8' },
          { title: '9', handle: '9' },
          { title: '10', handle: '10' }
        ],
        leftLabel: 'Less Likely',
        rightLabel: 'Very Likely'
      }
    ]
  },
  {
    title: 'Customer Satisfaction (CSAT)',
    description: "Understand how customers feel about an interaction.",
    icon: 'fa-smile',
    handle: "customer-satisfaction",
    previewLink: "https://www.zigpoll.com/examples/customer-satisfaction",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How did you feel about our checkout process?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        title: 'Quick Question',
        handle: 'Customer Satisfaction - Intro',
        type: 'satisfaction',
        copy: 'How did you feel about our checkout process?',
        answers: [
          { title: 'Very Unsatisfied', handle: 'very-unsatisfied', nextSlide: 1 },
          { title: 'Unsatisfied', handle: 'unsatisfied', nextSlide: 1 },
          { title: 'Neutral', handle: 'neutral', nextSlide: 2 },
          { title: 'Satisfied', handle: 'satisfied', nextSlide: 3 },
          { title: 'Very Satisfied', handle: 'very-satisfied', nextSlide: 3 },
        ],
        logic: [
          {
            rules: [
              { rule: '=', ruleValue: 'Very Unsatisfied' },
              { rule: '=', ruleValue: 'Unsatisfied' },
            ],
            actions: [
              { action: 'skip', actionValue: 1 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Neutral' },
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Satisfied' },
              { rule: '=', ruleValue: 'Very Satisfied' },
            ],
            actions: [
              { action: 'skip', actionValue: 3 }
            ]
          }
        ],
        leftLabel: 'Hated It',
        rightLabel: 'Loved It'
      },
      {
        title: 'Sorry you feel that way.',
        handle: 'Customer Satisfaction - Negative',
        type: 'long-answer',
        copy: 'What can we do to make the experience better?',
        hide: true,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ],
        nextSlide: 4,
      },
      {
        title: 'Thanks for your feedback!',
        handle: 'Customer Satisfaction - Neutral',
        type: 'long-answer',
        copy: 'What can we do to make the experience better?',
        hide: true,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ],
        nextSlide: 4
      },
      {
        title: "We're glad you liked it!",
        handle: 'Customer Satisfaction - Positive',
        type: 'long-answer',
        copy: 'Is there anything we can we do to make it better?',
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ],
        nextSlide: 4
      },
      {
        title: 'Thanks!',
        handle: 'Customer Satisfaction - Outro',
        type: 'copy',
        copy: 'We really appreciate your feedback.',
      }
    ]
  },
  {
    title: 'Customer Effort (CES)',
    description: "Measure the difficulty of an interaction.",
    icon: 'fa-thumbs-up',
    handle: "customer-effort",
    previewLink: "https://www.zigpoll.com/examples/customer-effort",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How easy was it to accomplish this task?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        title: 'Quick Question',
        handle: 'Customer Effort - Intro',
        type: 'range',
        copy: 'How easy was it to accomplish this task?',
        answers: [
          { title: '1', handle: '1', nextSlide: 1 },
          { title: '2', handle: '2', nextSlide: 1 },
          { title: '3', handle: '3', nextSlide: 1 },
          { title: '4', handle: '4', nextSlide: 2 },
          { title: '5', handle: '5', nextSlide: 2 },
          { title: '6', handle: '6', nextSlide: 3 },
          { title: '7', handle: '7', nextSlide: 3 },
        ],
        logic: [
          {
            rules: [
              { rule: '<=', ruleValue: '3' },
            ],
            actions: [
              { action: 'skip', actionValue: 1 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: '4' },
              { rule: '=', ruleValue: '5' },
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          },
          {
            rules: [
              { rule: '>=', ruleValue: '6' },
            ],
            actions: [
              { action: 'skip', actionValue: 3 }
            ]
          }
        ],
        leftLabel: 'Very Difficult',
        rightLabel: 'Very Easy'
      },
      {
        title: 'Sorry you feel that way.',
        handle: 'Customer Effort - Negative',
        type: 'long-answer',
        copy: 'What can we do to make the experience better?',
        nextSlide: 4,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ],
        hide: true,
      },
      {
        title: 'Thanks for your feedback!',
        handle: 'Customer Effort - Neutral',
        type: 'long-answer',
        copy: 'What can we do to make the experience better?',
        hide: true,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ],
        nextSlide: 4
      },
      {
        title: "We're glad you liked it!",
        handle: 'Customer Effort - Positive',
        type: 'long-answer',
        copy: 'Is there anything we can we do to make it better?',
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ],
        nextSlide: 4
      },
      {
        title: "Anything else to share?",
        handle: 'Customer Effort - Closing',
        type: 'long-answer',
        copy: 'Leave us any other thoughts you might have.',
        optional: true,
      },
      {
        title: 'Thanks!',
        handle: 'Customer Effort - Outro',
        type: 'copy',
        copy: 'We really appreciate your feedback.',
      }
    ]
  },
  {
    title: 'Identify Customer Goals',
    description: "Use this survey to understand how customers plan to use your product.",
    icon: 'fa-bullseye',
    handle: "customer-goals",
    previewLink: "https://www.zigpoll.com/examples/customer-goals",
    hasProduct: true,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "What is your primary goal for our product?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        title: 'Quick Question',
        handle: 'Customer Goals - Intro',
        type: 'question',
        copy: 'What is your primary goal for {{ product }}?',
        settings: {
          showTitle: true,
          showResults: false,
          randomizeAnswers: true,
          showResponses: false,
          optional: false,
          hidden: false,
          disableSentimentAnalysis: true
        },
        answers: [
          { title: 'Replace with Goal #1', handle: 'goal-1' },
          { title: 'Replace with Goal #2', handle: 'goal-2' },
          { title: 'Replace with Goal #3', handle: 'goal-3' },
          { title: 'Replace with Goal #4', handle: 'goal-4' },
          { title: 'Other? Write it in.', handle: 'other', dynamic: true },
        ],
      },
      {
        title: 'Thanks!',
        handle: 'Customer Goals - Outro',
        type: 'copy',
        copy: 'We really appreciate your feedback.',
      }
    ]
  },
  {
    title: 'Identify Sign-Up Blockers',
    description: "This survey helps identify issues with your product or messaging.",
    icon: 'fa-question-circle',
    handle: "sign-up-blockers",
    previewLink: "https://www.zigpoll.com/examples/sign-up-blockers",
    hasProduct: true,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How likely are you to use our product?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        type: 'range',
        title: 'Quick question...',
        copy: 'How likely are you to use {{ product }}?',
        handle: 'Sign-Up Blockers (Range)',
        answers: [
          { title: '1', handle: '1' },
          { title: '2', handle: '2' },
          { title: '3', handle: '3' },
          { title: '4', handle: '4' },
          { title: '5', handle: '5' },
        ],
        leftLabel: 'Not Likely',
        rightLabel: 'Very Likely'
      },
      {
        title: 'Quick Question...',
        handle: 'Sign-Up Blockers (Question)',
        type: 'question',
        copy: 'What, if anything, is holding you back from trying {{ product }}?',
        copy: 'This will help us improve our product so we can better meet your goals.',
        settings: {
          showTitle: true,
          showResults: false,
          randomizeAnswers: true,
          showResponses: false,
          optional: false,
          hidden: false,
          disableSentimentAnalysis: true
        },
        answers: [
          { title: 'Pricing is a concern', handle: 'goal-1' },
          { title: 'Considering other options', handle: 'goal-2' },
          { title: 'Seems too complicated', handle: 'goal-3' },
          { title: "Doesn't have what I'm looking for", handle: 'goal-4' },
          { title: 'Other? Write it in.', handle: 'other', dynamic: true },
        ],
      },
      {
        title: 'Thanks!',
        handle: 'Sign-Up Blockers (Outro)',
        type: 'copy',
        copy: 'We really appreciate your feedback.',
      }
    ]
  },
  {
    title: 'Improve Your Sign Up Experience',
    description: "Measure the sign-up experience and guage user expectations.",
    icon: 'fa-user-plus',
    handle: "sign-up-experience",
    previewLink: "https://www.zigpoll.com/examples/sign-up-experience",
    hasProduct: true,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How easy was it to create your account?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        type: 'range',
        title: 'Quick question...',
        copy: 'How easy was it to create your account?',
        handle: 'Sign-Up Experience (Range)',
        answers: [
          { title: '1', handle: '1', nextSlide: 1 },
          { title: '2', handle: '2', nextSlide: 1 },
          { title: '3', handle: '3', nextSlide: 1 },
          { title: '4', handle: '4', nextSlide: 2 },
          { title: '5', handle: '5', nextSlide: 2 },
        ],
        logic: [
          {
            rules: [
              { rule: '<=', ruleValue: '3' },
            ],
            actions: [
              { action: 'skip', actionValue: 1 }
            ]
          },
          {
            rules: [
              { rule: '>=', ruleValue: '4' },
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          },
        ],
        leftLabel: 'Not Easy',
        rightLabel: 'Very Easy'
      },
      {
        title: 'Quick Question...',
        handle: 'Sign-Up Experience (Negative Response)',
        type: 'long-answer',
        copy: 'Oh no! What challenges did you run into?',
        optional: true,
      },
      {
        title: 'Quick Question...',
        handle: 'Sign-Up Experience (Range 2)',
        type: 'range',
        copy: 'How well do you expect {{ product }} to satisfy your needs?',
        answers: [
          { title: '1', handle: '1' },
          { title: '2', handle: '2' },
          { title: '3', handle: '3' },
          { title: '4', handle: '4' },
          { title: '5', handle: '5' },
        ],
      },
      {
        title: 'Quick Question...',
        handle: 'Sign-Up Experience (Open Ended)',
        type: 'long-answer',
        copy: 'What questions or concerns do you have about using {{ product }}?',
        optional: true,
      },
      {
        title: 'Thanks!',
        handle: 'Sign-Up Experience (Outro)',
        type: 'copy',
        copy: 'We really appreciate your feedback.',
      }
    ]
  },
  {
    title: 'Improve Product Descriptions',
    description: "Understand how effective your product descriptions are to customers.",
    icon: 'fa-info-circle',
    handle: "improve-product-descriptions",
    previewLink: "https://www.zigpoll.com/examples/improve-product-descriptions",
    hasProduct: false,
    slides: [
      {
        type: 'question',
        title: 'Quick question...',
        copy: 'Does this page tell you everything you need to know about the product?',
        handle: 'Improve Product Descriptions (Question)',
        settings: {
          showTitle: true,
          showResults: false,
          randomizeAnswers: false,
          showResponses: false,
          optional: false,
          hidden: false,
          disableSentimentAnalysis: true
        },
        answers: [
          { title: 'Yes, definitely!', handle: 'yes', nextSlide: 2 },
          { title: 'Sort of...', handle: 'sort-of', nextSlide: 1 },
          { title: 'Not at all', handle: 'no', nextSlide: 1 },
        ],
        logic: [
          {
            rules: [
              { rule: '=', ruleValue: 'Sort of...' },
              { rule: '=', ruleValue: 'Not at all' },
            ],
            actions: [
              { action: 'skip', actionValue: 1 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Yes, definitely!' },
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          },
        ],
      },
      {
        title: 'Quick Question...',
        handle: 'Improve Product Descriptions (Negative Response)',
        type: 'long-answer',
        copy: "Sorry to hear that - what's missing?",
        optional: true,
      },
      {
        title: 'Thanks!',
        handle: 'Improve Product Descriptions (Outro)',
        type: 'copy',
        copy: 'We really appreciate your feedback.',
      }
    ]
  },
  {
    title: 'Improve Site Navigation',
    description: "Understand how effective your site navigation is to your customers.",
    icon: 'fa-directions',
    handle: "improve-site-navigation",
    previewLink: "https://www.zigpoll.com/examples/improve-site-navigation",
    hasProduct: true,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How easy is it to find what you are look for on our site?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        type: 'range',
        title: 'Quick question...',
        copy: "How easy is it to find what you're looking for on {{ product }}?",
        handle: 'Improve Site Navigation (Question)',
        answers: [
          { title: '1', handle: '1', nextSlide: 1 },
          { title: '2', handle: '2', nextSlide: 1 },
          { title: '3', handle: '3', nextSlide: 1 },
          { title: '4', handle: '4', nextSlide: 2 },
          { title: '5', handle: '5', nextSlide: 2 },
        ],
        logic: [
          {
            rules: [
              { rule: '<=', ruleValue: '3' },
            ],
            actions: [
              { action: 'skip', actionValue: 1 }
            ]
          },
          {
            rules: [
              { rule: '>=', ruleValue: '4' },
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          },
        ],
      },
      {
        title: 'Quick Question...',
        handle: 'Improve Site Navigation (Negative Response)',
        type: 'long-answer',
        copy: 'Sorry to hear that - what is missing?',
        optional: true,
      },
      {
        title: 'Thanks!',
        handle: 'Improve Site Navigation (Outro)',
        type: 'copy',
        copy: 'We really appreciate your feedback.',
      }
    ]
  },
  {
    title: 'Reduce Abandoned Carts',
    description: "Understand why your customer left an item in his or her cart.",
    icon: 'fa-cart-arrow-down',
    handle: "reduce-abandoned-carts",
    previewLink: "https://www.zigpoll.com/examples/reduce-abandoned-carts",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'You left something behind...',
      showLink: false,
      linkType: 'input',
      content: {
        header: "We notice you left an item in your cart. Why is that?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'You left something behind...',
      }
    },
    slides: [
      {
        type: 'question',
        title: "We notice you left an item in your cart. Why is that?",
        copy: "",
        handle: 'Reduce Abandoned Carts (Question)',
        settings: {
          showTitle: true,
          showResults: false,
          randomizeAnswers: false,
          showResponses: false,
          optional: false,
          hidden: false,
          disableSentimentAnalysis: true
        },
        answers: [
          { title: "Decided I didn't want it", handle: 'dont-want', nextSlide: 1 },
          { title: "Still deciding if I want it", handle: 'still-deciding', nextSlide: 2 },
          { title: "Planning to purchase later", handle: 'will-buy-later', nextSlide: 3 },
          { title: "Other", handle: 'other', nextSlide: 4 }
        ],
        logic: [
          {
            rules: [
              { rule: '=', ruleValue: "Decided I didn't want it" },
            ],
            actions: [
              { action: 'skip', actionValue: 1 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Still deciding if I want it' },
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Planning to purchase later' },
            ],
            actions: [
              { action: 'skip', actionValue: 3 }
            ]
          },
          {
            rules: [
              { rule: '=', ruleValue: 'Other' },
            ],
            actions: [
              { action: 'skip', actionValue: 4 }
            ]
          },
        ],
      },
      {
        title: 'What made you change your mind',
        handle: "Reduce Abandoned Carts (Don't want it)",
        type: 'long-answer',
        copy: '',
        optional: true,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 5 }
            ]
          },
        ],
        nextSlide: 5,
      },
      {
        title: "What's holding you back? Is there anything we can do to help?",
        handle: "Reduce Abandoned Carts (Still Deciding)",
        type: 'long-answer',
        copy: "",
        optional: true,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 5 }
            ]
          },
        ],
        nextSlide: 5
      },
      {
        title: "Sounds good, let us know if there's anything we can do to help!",
        handle: "Reduce Abandoned Carts (Will Buy Later)",
        type: 'long-answer',
        copy: "",
        optional: true,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 5 }
            ]
          },
        ],
        nextSlide: 5
      },
      {
        title: "Please let us know more about why you left the items behind.",
        handle: "Reduce Abandoned Carts (Other)",
        type: 'long-answer',
        copy: "",
        optional: true,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 5 }
            ]
          },
        ],
        nextSlide: 5
      },
      {
        title: 'Thanks!',
        handle: "Reduce Abandoned Carts (Outro)",
        type: 'copy',
        copy: 'We really appreciate your feedback!',
      }
    ]
  },
  {
    title: 'Improve Delivery Process',
    description: "Understand any issues with your delivery process.",
    icon: 'fa-truck',
    handle: "improve-delivery-process",
    previewLink: "https://www.zigpoll.com/examples/improve-delivery-process",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How satisfied are you with the delivery of your most recent order from us?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        type: 'range',
        title: 'Quick question...',
        copy: "How satisfied are you with the delivery of your most recent order from us?",
        handle: 'Improve Delivery Process (Range)',
        answers: [
          { title: '1', handle: '1', nextSlide: 1 },
          { title: '2', handle: '2', nextSlide: 1 },
          { title: '3', handle: '3', nextSlide: 1 },
          { title: '4', handle: '4', nextSlide: 2 },
          { title: '5', handle: '5', nextSlide: 2 },
        ],
        logic: [
          {
            rules: [
              { rule: '<=', ruleValue: '3' },
            ],
            actions: [
              { action: 'skip', actionValue: 1 }
            ]
          },
          {
            rules: [
              { rule: '>=', ruleValue: '4' },
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          },
        ],
      },
      {
        title: 'Quick Question...',
        handle: "Improve Delivery Process (Negative)",
        type: 'long-answer',
        copy: 'Sorry to hear that! What can we do to make it better?',
        optional: true,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 3 }
            ]
          },
        ],
        nextSlide: 3
      },
      {
        title: 'Quick Question...',
        handle: "Improve Delivery Process (Positive)",
        type: 'long-answer',
        copy: "Is there anything we could do to make it better?",
        optional: true,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 3 }
            ]
          },
        ],
        nextSlide: 3
      },
      {
        title: 'Thanks!',
        handle: "Reduce Delivery Process (Outro)",
        type: 'copy',
        copy: 'We really appreciate your feedback.',
      }
    ]
  },
  {
    title: 'Improve Returns Process',
    description: "Understand any issues with your returns process.",
    icon: 'fa-exchange-alt',
    handle: "improve-returns-process",
    previewLink: "https://www.zigpoll.com/examples/improve-returns-process",
    hasProduct: false,
    emailDisplaySettings: {
      subject: 'Quick question!',
      showLink: false,
      linkType: 'input',
      content: {
        header: "How satisfied are you with your recent experience returning something to us?",
        body: "",
        button: "Click To Get Started",
        footer: "",
        subject: 'Quick question!',
      }
    },
    slides: [
      {
        type: 'range',
        title: 'Quick question...',
        copy: "How satisfied are you with your recent experience returning something to us?",
        handle: 'Improve Returns Process (Range)',
        answers: [
          { title: '1', handle: '1', nextSlide: 1 },
          { title: '2', handle: '2', nextSlide: 1 },
          { title: '3', handle: '3', nextSlide: 1 },
          { title: '4', handle: '4', nextSlide: 2 },
          { title: '5', handle: '5', nextSlide: 2 },
        ],
        logic: [
          {
            rules: [
              { rule: '<=', ruleValue: '3' },
            ],
            actions: [
              { action: 'skip', actionValue: 1 }
            ]
          },
          {
            rules: [
              { rule: '>=', ruleValue: '4' },
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          },
        ],
      },
      {
        title: 'Quick Question...',
        handle: "Improve Returns Process (Negative)",
        type: 'long-answer',
        copy: 'Sorry to hear that! What can we do to make the return experience better?',
        optional: true,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 3 }
            ]
          },
        ],
        nextSlide: 3
      },
      {
        title: 'Quick Question...',
        handle: "Improve Returns Process (Positive)",
        type: 'long-answer',
        copy: "Is there anything we could do to make the return experience even better?",
        optional: true,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 3 }
            ]
          },
        ],
        nextSlide: 3
      },
      {
        title: 'Thanks!',
        handle: "Improve Returns Process (Outro)",
        type: 'copy',
        copy: 'We really appreciate your feedback.',
      }
    ]
  },
  {
    title: 'Improve Search Experience',
    description: "Understand any issues with your search functionality.",
    icon: 'fa-search',
    handle: "improve-search-experience",
    previewLink: "https://www.zigpoll.com/examples/improve-search-experience",
    hasProduct: false,
    slides: [
      {
        type: 'range',
        title: 'Quick question...',
        copy: "How relevant are these search results?",
        handle: 'Improve Search Experience (Range)',
        answers: [
          { title: '1', handle: '1', nextSlide: 1 },
          { title: '2', handle: '2', nextSlide: 1 },
          { title: '3', handle: '3', nextSlide: 1 },
          { title: '4', handle: '4', nextSlide: 2 },
          { title: '5', handle: '5', nextSlide: 2 },
        ],
        logic: [
          {
            rules: [
              { rule: '<=', ruleValue: '3' },
            ],
            actions: [
              { action: 'skip', actionValue: 1 }
            ]
          },
          {
            rules: [
              { rule: '>=', ruleValue: '4' },
            ],
            actions: [
              { action: 'skip', actionValue: 2 }
            ]
          },
        ],
      },
      {
        title: 'Quick Question...',
        handle: "Improve Search Experience (Negative)",
        type: 'long-answer',
        copy: 'Sorry to hear that! How could the results be improved?',
        optional: true,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 3 }
            ]
          },
        ],
        nextSlide: 3
      },
      {
        title: 'Quick Question...',
        handle: "Improve Search Experience (Positive)",
        type: 'long-answer',
        copy: "Is there anything we could do to make the search experience better?",
        optional: true,
        logic: [
          {
            rules: [
              { rule: 'submitted', ruleValue: null },
            ],
            actions: [
              { action: 'skip', actionValue: 3 }
            ]
          },
        ],
        nextSlide: 3
      },
      {
        title: 'Thanks!',
        handle: "Improve Search Experience (Outro)",
        type: 'copy',
        copy: 'We really appreciate your feedback.',
      }
    ]
  }
]

module.exports.displayRulesTemplates = [
  {
    title: 'Link Only',
    shortTitle: 'Link Only',
    noModal: true,
    description: "Access this survey via a public URL only. Useful for one-off campaigns.",
    icon: "fa-link",
    handle: "link-only",
    type: 'link',
    settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: true,
      automaticallySwitchSlides: true,
      autoClose: false,
      allowEdits: true,
      apiOnly: true,
    },
    // shopify: true,
    // shopifyOnly: true,
    // showExtensibilityNotice: true,
    createPollOnly: true,
    pageRules: [],
    pageExcludeRules: [],
    pageRuleDescriptions: [],
  },
  {
    title: 'Every Page',
    shortTitle: 'Every Page',
    noModal: true,
    description: "Show this survey on every page of your website.",
    icon: "fa-globe",
    handle: "every-page",
    // shopify: true,
    // shopifyOnly: true,
    // showExtensibilityNotice: true,
    pageRules: [],
    pageExcludeRules: [],
    pageRuleDescriptions: [],
  },
  {
    title: 'API Only',
    shortTitle: 'API Only',
    noModal: true,
    description: "Make this survey available to be triggered using our JS API only.",
    icon: "fa-code",
    handle: "api-only",
    settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: true,
      automaticallySwitchSlides: true,
      autoClose: false,
      allowEdits: true,
      apiOnly: true,
      pollType: 'api',
    },
    // shopify: true,
    // shopifyOnly: true,
    // showExtensibilityNotice: true,
    createPollOnly: true,
    pageRules: [],
    pageExcludeRules: [],
    pageRuleDescriptions: [],
  },
  {
    title: 'Configure Manually',
    shortTitle: 'Configure Manually',
    description: "Display the survey only on the page or pages that you specify.",
    icon: "fa-gear",
    handle: "configure-manually",
    // shopify: true,
    // shopifyOnly: true,
    // showExtensibilityNotice: true,
    createPollOnly: true,
    pageRules: [],
    pageExcludeRules: [],
    pageRuleDescriptions: [],
  },
  {
    title: 'Post Purchase <strong>All Customers</strong>',
    shortTitle: 'Post Purchase',
    description: "Show this survey on your thank you page to all customers.",
    icon: "fa-shopping-cart",
    handle: "post-purchase-only",
    // shopify: true,
    // shopifyOnly: true,
    showExtensibilityNotice: true,
    pageRules: ['/(.*)/thank_you', "/:order_number/orders/:variable"],
    pageExcludeRules: ["/account/(.*)"],
    pageRuleDescriptions: [
      'All pages ending with <strong>thank_you</strong>',
      'All order status pages.'
    ],
    settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: true,
      automaticallySwitchSlides: true,
      autoClose: false,
      apiOnly: false,
      allowEdits: false,
      hideBackButton: true,
      writeToOrder: true,
      selector: "#zigpoll-embed-target",
      shopifyCheckoutPosition: "top-left",
      hideXButton: true,
    },
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { autoOpenDuration: 0, autoOpenPosition:  0 }),
    displaySettings: {
      align: 'right',
      shape: 'bumper',
      launcherSettings: {
        'before-submission': {
          launcherIcon: 'fa-check-circle',
          launcherText: 'Take Our Survey',
          launcherDisplayType: 'icon-text',
          launcherDisplayTypeMobile: 'text-only',
        },
      }
    },
  },
  {
    title: 'Post Purchase <strong>New Customers</strong>',
    shortTitle: 'Post Purchase',
    description: "Show this survey on your thank you page for new customers only.",
    icon: "fa-shopping-cart",
    handle: "post-purchase-new-customers-only",
    shopify: true,
    createPollOnly: true,
    shopifyOnly: true,
    showExtensibilityNotice: true,
    pageRules: ['/(.*)/thank_you', "/:order_number/orders/:variable"],
    pageExcludeRules: ["/account/(.*)"],
    pageRuleDescriptions: [
      'All pages ending with <strong>thank_you</strong>',
      'All order status pages.',
      '<strong>Show to new customers only</strong>'
    ],
    settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: true,
      automaticallySwitchSlides: true,
      autoClose: false,
      apiOnly: false,
      allowEdits: false,
      hideBackButton: true,
      writeToOrder: true,
      selector: "#zigpoll-embed-target",
      shopifyCheckoutPosition: "top-left",
      hideXButton: true,
      shopifyCustomerTargeting: [[
        ['orders_count', 'lt', 2]
      ],[]]
    },
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { autoOpenDuration: 0, autoOpenPosition:  0 }),
    displaySettings: {
      align: 'right',
      shape: 'bumper',
      launcherSettings: {
        'before-submission': {
          launcherIcon: 'fa-check-circle',
          launcherText: 'Take Our Survey',
          launcherDisplayType: 'icon-text',
          launcherDisplayTypeMobile: 'text-only',
        },
      }
    },
  },
  {
    title: 'Post Purchase <strong>Returning Customers</strong>',
    shortTitle: 'Post Purchase',
    description: "Show this survey on your thank you page for existing customers only.",
    icon: "fa-shopping-cart",
    handle: "post-purchase-existing-customers-only",
    shopify: true,
    shopifyOnly: true,
    showExtensibilityNotice: true,
    createPollOnly: true,
    pageRules: ['/(.*)/thank_you', "/:order_number/orders/:variable"],
    pageExcludeRules: ["/account/(.*)"],
    pageRuleDescriptions: [
      'All pages ending with <strong>thank_you</strong>',
      'All order status pages.',
      '<strong>Show to returning customers only</strong>'
    ],
    settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: true,
      automaticallySwitchSlides: true,
      autoClose: false,
      apiOnly: false,
      allowEdits: false,
      hideBackButton: true,
      writeToOrder: true,
      selector: "#zigpoll-embed-target",
      shopifyCheckoutPosition: "top-left",
      hideXButton: true,
      shopifyCustomerTargeting: [[
        ['orders_count', 'gt', 1]
      ], []]
    },
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { autoOpenDuration: 0, autoOpenPosition:  0 }),
    displaySettings: {
      align: 'right',
      shape: 'bumper',
      launcherSettings: {
        'before-submission': {
          launcherIcon: 'fa-check-circle',
          launcherText: 'Take Our Survey',
          launcherDisplayType: 'icon-text',
          launcherDisplayTypeMobile: 'text-only',
        },
      }
    },
  },
  {
    title: 'Abandoned Checkout',
    description: "Email a survey to all customers who abandon their checkout.",
    icon: "fa-cart-arrow-down",
    handle: "abandoned-checkout",
    shopify: true,
    createPollOnly: true,
    shopifyOnly: true,
    type: 'email',
    pageRules: [],
    pageExcludeRules: [],
    pageRuleDescriptions: [
      'Automatically email this survey whenever a customer abandons his or her checkout.'
    ],
    settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: true,
      allowEdits: true,
      automaticallySwitchSlides: true,
      autoClose: false,
      apiOnly: true,
      shopifyAbandonedCheckout: true,
      emailDelay: 1
    },
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { autoOpenDuration: 0, autoOpenPosition:  0 }),
    emailDisplaySettings: {
      subject: 'Changed your mind? Let us know how we can improve.',
      showLink: false,
      linkType: 'input',
      content: {
        header: "Got a second?",
        body: "We want your feedback! Click below to complete our survey.",
        button: "Click To Get Started",
        footer: "We appreciate your support!",
        subject: "Changed your mind? Let us know how we can improve."
      }
    }
  },
  {
    title: 'Order Fulfillment',
    description: "Email this survey to customers when an order is marked as fulfilled.",
    icon: "fa-truck",
    handle: "order-fulfillment",
    shopify: true,
    shopifyOnly: true,
    createPollOnly: true,
    type: 'email',
    pageRules: [],
    pageExcludeRules: [],
    pageRuleDescriptions: [
      'Automatically email this survey whenever an order is fulfilled.'
    ],
    settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: true,
      automaticallySwitchSlides: true,
      autoClose: false,
      allowEdits: true,
      apiOnly: true,
      shopifyWebhooks: ['orders/fulfilled'],
      emailDelay: 1,
      writeToOrder: true
    },
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { autoOpenDuration: 0, autoOpenPosition: 0 }),
    emailDisplaySettings: {
      subject: 'Thanks for your order! Please share your feedback',
      showLink: false,
      linkType: 'input',
      content: {
        header: "Thank you for your recent online purchase!",
        body: "We'd love to hear your feedback on your recent experience with us. Thank you in advance!",
        button: "Click To Get Started",
        footer: "We appreciate your support!",
        subject: "Thanks for your order! Please share your feedback"
      }
    }
  },
  {
    title: 'Checkout <em>Shopify Plus Only</em>',
    shortTitle: 'Checkout',
    description: "Show this survey directly on your checkout page.",
    icon: "fa-money-check-dollar",
    handle: "checkout",
    shopify: true,
    shopifyOnly: true,
    shopifyPlusOnly: true,
    showExtensibilityNotice: true,
    createPollOnly: false,
    pageRules: ['/checkouts/(.*)'],
    pageExcludeRules: [],
    pageRuleDescriptions: [
      'All pages starting with <strong>checkouts</strong>',
    ],
    settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: true,
      automaticallySwitchSlides: true,
      autoClose: false,
      apiOnly: false,
      allowEdits: false,
      hideBackButton: true,
      writeToOrder: true,
      hideXButton: true,
    },
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { autoOpenDuration: 0, autoOpenPosition:  0 }),
    displaySettings: {
      align: 'right',
      shape: 'bumper',
      launcherSettings: {
        'before-submission': {
          launcherIcon: 'fa-check-circle',
          launcherText: 'Take Our Survey',
          launcherDisplayType: 'icon-text',
          launcherDisplayTypeMobile: 'text-only',
        },
      }
    },
  },
  {
    title: 'Order Delivered',
    description: "Email this survey to customers when an order is marked as delivered.",
    icon: "fa-box-open",
    handle: "order-delivered",
    shopify: true,
    shopifyOnly: true,
    createPollOnly: true,
    type: 'email',
    pageRules: [],
    pageExcludeRules: [],
    pageRuleDescriptions: [
      'Automatically email this survey whenever an order is marked as delivered.'
    ],
    settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: true,
      automaticallySwitchSlides: true,
      autoClose: false,
      allowEdits: true,
      apiOnly: true,
      shopifyWebhooks: ['fulfillment_events/create'],
      emailDelay: 1,
      writeToOrder: true
    },
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { autoOpenDuration: 0, autoOpenPosition: 0 }),
    emailDisplaySettings: {
      subject: "Your order has been delivered! Please share your experience.",
      showLink: false,
      linkType: 'input',
      content: {
        header: "Your order has been delivered!",
        body: "We'd love to hear your feedback on your recent experience with us. Thank you in advance!",
        button: "Click To Get Started",
        footer: "We appreciate your support!",
        subject: "Your order has been delivered! Please share your experience."
      }
    }
  },
  {
    title: 'Exit Intent (Cart)',
    description: "Show this survey when the user intends to exit your website with an item in their cart.",
    shopify: true,
    shopifyOnly: true,
    createPollOnly: true,
    icon: "fa-person-running",
    handle: "exit-intent-with-cart",
    pageRules: [],
    pageExcludeRules: ['/(.*)/thank_you', "/:order_number/orders/:variable"],
    pageRuleDescriptions: [
      'Exit intent will be applied to all pages.'
    ],
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { renderOnExitIntent: true, openAttempts: 1, exitIntentCondition: 'cartOnly' }),
    displaySettings: { align: 'modal' }
  },
  {
    title: 'Exit Intent (No Cart)',
    description: "Show this survey when the user intends to exit your website without an item in their cart.",
    shopify: true,
    shopifyOnly: true,
    createPollOnly: true,
    icon: "fa-person-running",
    handle: "exit-intent-without-cart",
    pageRules: [],
    pageExcludeRules: ['/(.*)/thank_you', "/:order_number/orders/:variable"],
    pageRuleDescriptions: [
      'Exit intent will be applied to all pages.'
    ],
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { renderOnExitIntent: true, openAttempts: 1, exitIntentCondition: 'emptyCartOnly' }),
    displaySettings: { align: 'modal' }
  },
  {
    title: 'Exit Intent',
    description: "Show this survey when the user intends to exit your website.",
    icon: "fa-person-walking-dashed-line-arrow-right",
    handle: "exit-intent",
    createPollOnly: true,
    pageRules: [],
    pageExcludeRules: ['/(.*)/thank_you', "/:order_number/orders/:variable"],
    pageRuleDescriptions: [
      'Exit intent will be applied to all pages.'
    ],
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { renderOnExitIntent: true, openAttempts: 1 }),
    displaySettings: { align: 'modal' }
  },
  {
    title: 'Order Cancelled',
    description: "Email this survey to customers when an order is cancelled.",
    icon: "fa-ban",
    handle: "order-cancel",
    shopify: true,
    shopifyOnly: true,
    createPollOnly: true,
    type: 'email',
    pageRules: [],
    pageExcludeRules: [],
    pageRuleDescriptions: [
      'Automatically email this survey whenever an order is cancelled.'
    ],
    settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: true,
      automaticallySwitchSlides: true,
      autoClose: false,
      allowEdits: true,
      apiOnly: true,
      shopifyWebhooks: ['orders/cancelled'],
      emailDelay: 1,
      writeToOrder: true
    },
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { autoOpenDuration: 0, autoOpenPosition: 0 }),
    emailDisplaySettings: {
      subject: "Your order has been cancelled. Please share your feedback.",
      showLink: false,
      linkType: 'input',
      content: {
        header: "Sorry you had to cancel your order",
        body: "We'd love to hear your feedback on your recent experience with us. Thank you in advance!",
        button: "Click To Get Started",
        footer: "We appreciate your support!",
        subject: "Your order has been cancelled. Please share your feedback."
      }
    }
  },
  {
    title: 'Order Paid',
    description: "Email this survey to customers when an order is marked as paid.",
    icon: "fa-dollar-sign",
    handle: "order-paid",
    shopify: true,
    createPollOnly: true,
    shopifyOnly: true,
    type: 'email',
    pageRules: [],
    pageExcludeRules: [],
    pageRuleDescriptions: [
      'Automatically email this survey whenever an order is marked as paid.'
    ],
    settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: true,
      automaticallySwitchSlides: true,
      autoClose: false,
      allowEdits: true,
      apiOnly: true,
      shopifyWebhooks: ['orders/paid'],
      emailDelay: 1,
      writeToOrder: true
    },
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { autoOpenDuration: 0, autoOpenPosition:  0 }),
    emailDisplaySettings: {
      subject: 'Thanks for your order! Please share your feedback',
      showLink: false,
      linkType: 'input',
      content: {
        header: "Thank you for your recent online purchase!",
        body: "We'd love to hear your feedback on your recent experience with us. Thank you in advance!",
        button: "Click To Get Started",
        footer: "We appreciate your support!",
        subject: "Thanks for your order! Please share your feedback"
      }
    }
  },
  {
    title: 'Order Delivered',
    description: "Send this survey via SMS when an order is marked as delivered.",
    icon: "fa-box-open",
    handle: "order-delivered-sms",
    shopify: true,
    shopifyOnly: true,
    createPollOnly: true,
    type: 'SMS',
    pageRules: [],
    pageExcludeRules: [],
    pageRuleDescriptions: [
      'Automatically send this survey via SMS whenever an order is marked as delivered.'
    ],
    settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: true,
      automaticallySwitchSlides: true,
      autoClose: false,
      allowEdits: true,
      apiOnly: true,
      shopifyWebhooks: ['fulfillment_events/create'],
      emailDelay: 1,
      writeToOrder: true,
      sms: true
    },
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { autoOpenDuration: 0, autoOpenPosition: 0 }),
    smsDisplaySettings: {
      message: 'How are you enjoying your order? Please share your feedback by clicking the link below.'
    }
  },
  {
    title: 'Order Fulfillment',
    description: "Send this survey via SMS when an order is fulfilled.",
    icon: "fa-truck",
    handle: "order-fulfillment-sms",
    shopify: true,
    shopifyOnly: true,
    createPollOnly: true,
    type: 'SMS',
    pageRules: [],
    pageExcludeRules: [],
    pageRuleDescriptions: [
      'Automatically send this this survey via SMS whenever an order is fulfilled.'
    ],
    settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: true,
      automaticallySwitchSlides: true,
      autoClose: false,
      allowEdits: true,
      apiOnly: true,
      shopifyWebhooks: ['orders/fulfilled'],
      emailDelay: 1,
      writeToOrder: true,
      sms: true
    },
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { autoOpenDuration: 0, autoOpenPosition: 0 }),
    smsDisplaySettings: {
      message: 'How are you enjoying your order? Please share your feedback by clicking the link below.'
    }
  },
  {
    title: 'Order Paid',
    description: "Send this survey via SMS when an order is marked as paid.",
    icon: "fa-dollar-sign",
    handle: "order-paid-sms",
    shopify: true,
    createPollOnly: true,
    shopifyOnly: true,
    type: 'SMS',
    pageRules: [],
    pageExcludeRules: [],
    pageRuleDescriptions: [
      'Automatically send this survey via SMS whenever an order is marked as paid.'
    ],
    settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: true,
      automaticallySwitchSlides: true,
      autoClose: false,
      allowEdits: true,
      apiOnly: true,
      shopifyWebhooks: ['orders/paid'],
      emailDelay: 1,
      writeToOrder: true,
      sms: true
    },
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { autoOpenDuration: 0, autoOpenPosition:  0 }),
    smsDisplaySettings: {
      message: 'Thanks for your order! Please share your feedback by clicking the link below.'
    }
  },
 {
    title: 'Order Cancelled',
    description: "Email this survey when an order is cancelled.",
    icon: "fa-ban",
    handle: "order-cancel-sms",
    shopify: true,
    shopifyOnly: true,
    createPollOnly: true,
    type: 'SMS',
    pageRules: [],
    pageExcludeRules: [],
    pageRuleDescriptions: [
      'Automatically SMS this survey whenever an order is cancelled.'
    ],
    settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: true,
      automaticallySwitchSlides: true,
      autoClose: false,
      allowEdits: true,
      apiOnly: true,
      shopifyWebhooks: ['orders/cancelled'],
      emailDelay: 1,
      writeToOrder: true,
      sms: true
    },
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { autoOpenDuration: 0, autoOpenPosition: 0 }),
    smsDisplaySettings: {
      message: 'Your order has been cancelled. Please share your feedback by clicking the link below.'
    }
  },
  {
    title: 'Order Status',
    description: "Show this survey on your order status page.",
    icon: "fa-shipping-fast",
    handle: "order-status-only",
    shopify: true,
    shopifyOnly: true,
    showExtensibilityNotice: true,
    pageRules: ["/:order_number/orders/:variable"],
    pageExcludeRules: ["/account/(.*)"],
    pageRuleDescriptions: [
      'All pages that include <strong>/orders/</strong>'
    ],
    settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: true,
      automaticallySwitchSlides: true,
      autoClose: false,
      allowEdits: false,
      hideBackButton: true,
      apiOnly: false,
      writeToOrder: true,
      selector: "#zigpoll-embed-target",
      shopifyCheckoutPosition: "top-left"
    },
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { autoOpenDuration: 0, autoOpenPosition:  0 })
  },
  {
    title: 'Homepage Only',
    description: "Show this survey on your homepage only.",
    icon: "fa-home",
    handle: "homepage-only",
    pageRules: ['/'],
    pageExcludeRules: [],
    pageRuleDescriptions: [
      "Your website's homepage only."
    ]
  },
  {
    title: 'Feedback',
    description: "Unobtrusive survey on every page prompting feedback.",
    icon: "fa-comment-dots",
    handle: "feedback-poll",
    createPollOnly: true,
    pageRules: [],
    pageExcludeRules: [],
    pageRuleDescriptions: [
      "Appears on all pages.",
      "Sticky on the right hand side."
    ],
    displaySettings: {
      align: 'right-middle',
      shape: 'bumper',
      hideLauncher: false,
      launcherSettings: {
        'before-submission': {
          launcherIcon: 'fa-comment-dots',
          launcherText: 'Feedback',
          launcherDisplayType: 'text-only'
        },
        // 'after-submission': {
        //   launcherIcon: 'fa-smile-beam',
        //   launcherText: 'Thanks!',
        //   launcherDisplayType: 'text-only'
        // }
      }
    },
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { hideAfterSubmission: true, hideAfterClose: false }),
  },
  {
    title: 'Homepage Pop Up',
    description: "Show this survey as a modal when the user lands on your homepage.",
    icon: "fa-home",
    handle: "homepage-modal",
    createPollOnly: true,
    pageRules: ['/'],
    pageExcludeRules: [],
    pageRuleDescriptions: [
      "Pop up modal on you website's homepage only."
    ],
    visibilitySettings: Object.assign(_.clone(defaultVisibilitySettings), { modalOpenDuration: 0, modalOpenPosition: 0, modalOpenDevice: false, openAttempts: 1 }),
    displaySettings: { align: 'modal' }
  },
  {
    title: 'Product Pages',
    description: "Show this survey on all of your product pages.",
    icon: "fa-tshirt",
    handle: "product-page-only",
    shopify: true,
    shopifyOnly: true,
    pageRules: ["/(.*)/products/:variable", "/products/(.*)"],
    pageExcludeRules: [],
    pageRuleDescriptions: [
      'All pages ending with <strong>/products/some_handle</strong>',
      'All pages starting with <strong>/products/some_handle</strong>'
    ],
  },
  {
    title: 'Collections Pages',
    description: "Show this survey on all of your collection pages.",
    icon: "fa-list",
    handle: "collection-page-only",
    shopify: true,
    shopifyOnly: true,
    pageRules: ['/collections/(.*)'],
    pageExcludeRules: ["/(.*)/products/:variable"],
    pageRuleDescriptions: [
      'All pages starting with <strong>/collections</strong>'
    ],
  },
  {
    title: 'Customer Account',
    description: "Show this survey on your customer account pages.",
    icon: "fa-id-card",
    handle: "customer-account-only",
    shopify: true,
    showExtensibilityNotice: true,
    shopifyOnly: true,
    pageRules: ["/account", "/account/(.*)"],
    pageExcludeRules: [],
    pageRuleDescriptions: [
      'All pages starting with <strong>/account</strong>'
    ],
  },
  {
    title: 'Email Campaign',
    description: "Email this survey out to your customers.",
    icon: "fa-file",
    handle: "email-campaign",
    createPollOnly: true,
    type: 'email',
    pageRules: [],
    pageExcludeRules: [],
    pageRuleDescriptions: [
      'Manually email this survey out to your customers using our built in tools or a third party tool of your choice.'
    ],
    settings: {
      showCounter: true,
      allowResubmissions: false,
      notifyOnComplete: true,
      urlDependant: false,
      automaticallySwitchSlides: true,
      autoClose: false,
      allowEdits: true,
      apiOnly: true,
    }
  },
]

module.exports.previewSlides = {
  'reward': {
    type: 'reward',
    title: 'Thanks for your feedback!',
    subtitle: "We really appreciate your opinion.",
    copy: 'Use the discount code below for <strong>10% off</strong> your next order!',
    rewardCode: 'ZIGPOLL10',
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  'long-answer': {
    type: 'long-answer',
    title: 'Long Answer Example',
    subtitle: "We're curious...",
    copy: 'What do you really think about our new collection?',
    settings: { showTitle: true, disableSentimentAnalysis: true },
    hasResponses: true,
  },
  'long-answer': {
    type: 'long-answer',
    title: "We're curious...",
    subtitle: "What do you like most about our brand?",
    copy: '',
    settings: { showTitle: true, disableSentimentAnalysis: true },
    hasResponses: true,
  },
  'short-answer': {
    type: 'short-answer',
    title: 'Quick Question',
    subtitle: "How did you find us?",
    copy: '',
    settings: { showTitle: true, disableSentimentAnalysis: true },
    hasResponses: true
  },
  'copy': {
    type: 'copy',
    title: 'Got a second?',
    subtitle: "We have a couple questions for you.",
    copy: "In exchange for your feedback, we'll give you a discount code for <strong>10% Off</strong> your next order.",
    settings: { showTitle: true, disableSentimentAnalysis: true, showResults: false }
  },
  'question': {
    type: 'question',
    title: 'What color do you prefer?',
    subtitle: "This will help us plan our fall product line.",
    copy: "",
    answers: [
      { title: 'Red', handle: 'red' },
      { title: 'Green', handle: 'green' },
      { title: 'Blue', handle: 'blue' }
    ],
    settings: { showTitle: true, disableSentimentAnalysis: true, showResults: false },
    hasResponses: true
  },
  'checkbox': {
    type: 'checkbox',
    title: 'What colors do you prefer?',
    subtitle: "Select as many as you like.",
    copy: '',
    answers: [
      { title: 'Red', handle: 'red' },
      { title: 'Green', handle: 'green' },
      { title: 'Blue', handle: 'blue' },
      { title: 'Orange', handle: 'orange' }
    ],
    minimumResponses: 1,
    hasResponses: true,
    settings: { showTitle: true, disableSentimentAnalysis: true, showResults: false }
  },
  'email-capture': {
    type: 'email-capture',
    title: 'Stay in touch!',
    subtitle: "Enter your email for the latest and greatest updates from our store.",
    copy: '',
    settings: { showTitle: true, disableSentimentAnalysis: true }
  },
  'range': {
    type: 'range',
    title: 'We just launched our new version!',
    subtitle: "How do you think it performs on a scale from 1 to 5.",
    copy: '',
    answers: [
      { title: '1', handle: '1' },
      { title: '2', handle: '2' },
      { title: '3', handle: '3' },
      { title: '4', handle: '4' },
      { title: '5', handle: '5' }
    ],
    leftLabel: 'Not Satisfied',
    rightLabel: 'Very Satisfied',
    settings: { showTitle: true }
  },
  'satisfaction': {
    type: 'satisfaction',
    title: "Well it's officially live!",
    subtitle: "How satisfied are you with the redesign?",
    copy: '',
    answers: [
      { title: 'Very Unsatisfied', handle: 'very-unsatisfied' },
      { title: 'Unsatisfied', handle: 'unsatisfied' },
      { title: 'Neutral', handle: 'neutral' },
      { title: 'Satisfied', handle: 'satisfied' },
      { title: 'Very Satisfied', handle: 'very-satisfied' }
    ],
    leftLabel: 'Not Satisfied',
    rightLabel: 'Very Satisfied',
    settings: { showTitle: true }
  },
  'star-rating': {
    type: 'star-rating',
    title: "How would you rate our new redesign?",
    subtitle: "",
    copy: '',
    answers: [
      { title: '1 Star', handle: '1-star' },
      { title: '2 Stars', handle: '2-stars' },
      { title: '3 Stars', handle: '3-stars' },
      { title: '4 Stars', handle: '4-stars' },
      { title: '5 Stars', handle: '5-stars' }
    ],
    leftLabel: "Much worse.",
    rightLabel: 'Way better!',
    settings: { showTitle: true }
  },
  'binary': {
    type: 'binary',
    title: "Well it's officially live!",
    subtitle: "Do you like our redesign?",
    copy: '',
    answers: [
      { title: 'Yes', handle: 'yes' },
      { title: 'No', handle: 'no' }
    ],
    settings: { showTitle: true }
  },
  'form': {
    type: 'form',
    title: "Contact Us",
    subtitle: "Fill out the form below to send us a note.",
    copy: 'If you want us to get back to you, please include your email.',
    formInputs: [
      {label: "Question/Comment", type: "long-answer", optional: false, options: []},
      {label: "Email", type: "email-capture", optional: true, options: []}
    ],
    settings: { showTitle: true, disableSentimentAnalysis: true },
    hasResponses: true,
  },
  'legal-consent': {
    type: 'legal-consent',
    answers: [
      { title: '✔', handle: 'checked', label: 'I have read and agree to the terms and conditions.' }
    ],
    title: 'Please check the box below to confirm you have read our <a target="_blank" href="https://www.zigpoll.com/terms-and-policies">terms & conditions</a>.',
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  'dropdown': {
    type: 'dropdown',
    answers: [
      { title: 'Red', handle: 'red' },
      { title: 'Green', handle: 'green' },
      { title: 'Blue', handle: 'blue' },
      { title: 'Orange', handle: 'orange' }
    ],
    title: 'What color do you prefer?',
    hasResponses: true,
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  'inline-multiple-choice': {
    type: 'inline-multiple-choice',
    answers: [
      { title: 'Red', handle: 'red' },
      { title: 'Green', handle: 'green' },
      { title: 'Blue', handle: 'blue' },
      { title: 'Orange', handle: 'orange' },
      { title: 'Pink', handle: 'pink' },
    ],
    title: "What's your favorite color(s)?",
    copy: "",
    hasResponses: true,
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  'action': {
    type: 'action',
    actions: [
      { title: 'Follow us on Twitter', color: "#1DA1F2", icon: 'fab fa-twitter', link: 'https://www.twitter.com' },
      { title: 'Follow us on Facebook', color: "#3b5998", icon: 'fab fa-facebook', link: 'https://www.facebook.com' }
    ],
    title: 'Click the links below to follow us on social!',
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  'date': {
    type: 'date',
    title: "Enter your birthday and we will send you a surprise!",
    copy: '',
    settings: { showTitle: true, disableSentimentAnalysis: true },
    hasResponses: true,
  },
  'country': {
    type: 'country',
    title: "What country are you from?",
    copy: '',
    answers: [],
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  'calendar': {
    type: 'calendar',
    title: "Book a time on our calendar.",
    copy: '',
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  // 'matrix': {
  //   type: 'matrix',
  //   title: "Book a time on our calendar.",
  //   copy: 'We look forward to chatting then!',
  //   settings: { showTitle: true, disableSentimentAnalysis: true },
  // },
  'rank': {
    type: 'rank',
    answers: [
      { title: 'Red', handle: 'red' },
      { title: 'Green', handle: 'green' },
      { title: 'Blue', handle: 'blue' },
      { title: 'Orange', handle: 'orange' },
      { title: 'Pink', handle: 'pink' },
    ],
    title: "Please rank your favorite colors",
    copy: "",
    hasResponses: false,
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  'image-choice': {
    type: 'image-choice',
    answers: [
      { url: 'http://via.placeholder.com/400x450', handle: 'red', title: "Red" },
      { url: 'http://via.placeholder.com/400x410', handle: 'green', title: "Green" },
      { url: 'http://via.placeholder.com/400x420', handle: 'blue', title: "Blue" },
      { url: 'http://via.placeholder.com/400x440', handle: 'orange', title: "Orange" },
    ],
    title: "What's your favorite image below?",
    copy: "",
    hasResponses: true,
    settings: { showTitle: true, disableSentimentAnalysis: true },
  },
  'file-upload': {
    type: 'file-upload',
    title: "Please upload your id card.",
    copy: "",
    settings: { showTitle: true, disableSentimentAnalysis: true },
    hasResponses: true
  },
  'slider': {
    type: 'slider',
    title: "How much would you be willing to pay for this item?",
    copy: "",
    answers: [],
    hasResponses: false,
    settings: { showTitle: true, disableSentimentAnalysis: true, range: [ 0, 100 ], unit: 5 },
  },
};

module.exports.getManualInsightLimit = function getManualInsightLimit(account) {
  const { plan } = account;
  let manualInsightsLimit = plan.manualInsightsLimit;
  if (!manualInsightsLimit) {
    manualInsightsLimit = 5;

    if (plan.key === 'lite') {
      manualInsightsLimit = 5;
    }
    if (plan.key === 'basic') {
      manualInsightsLimit = 10;
    }
    if (plan.key === 'standard') {
      manualInsightsLimit = 20;
    }
    if (plan.key === 'plus') {
      manualInsightsLimit = 50;
    }
    if (plan.key === 'pro') {
      manualInsightsLimit = 100;
    }
    if (plan.key === 'enterprise') {
      manualInsightsLimit = 200;
    }
  }
  return manualInsightsLimit;
}
