import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import $ from 'jquery';
import _ from 'lodash';
import qs from 'query-string';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as PollActions from '../actions/Polls';
import * as DashboardActions from '../actions/Dashboard';

import Pagination from '../components/Pagination';
import PopoutCheckbox from '../components/PopoutCheckbox';

import { encode, decode, renderNumber, getTemplateFromDisplayRules, getVisibilitySettings, loadContactUsSurvey } from '../utils';

function getPollElements(account, accountId, poll) {
  let isEmail = null;
  let isSms = null;
  if (poll.settings.shopifyWebhooks || poll.settings.shopifyAbandonedCheckout) {
    isEmail = true;
    if (poll.settings.sms) {
      isSms = true;
    }
  }

  let pollType = <i className="fas fa-poll" title="This an on-site type survey." />
  let status = <span className="no-cursor"><i className="fas fa-eye-slash" title="This survey is hidden." /><div className="tooltip-content">This survey is hidden.</div></span>;
  if (poll.settings.selector) {
    pollType = <i className="fas fa-arrows-to-dot" title="This an embedded survey." />
  }
  if (poll.isVisible) {
    status = <span className="no-cursor"><i className="fas fa-eye" title="This survey is visible." /><div className="tooltip-content">This survey is visible.</div></span>
    // status = null;
  }
  if (poll.isArchived) {
    status = <span className="no-cursor"><i className="fas fa-archive" title="This survey is archived." /><div className="tooltip-content">This survey is archived.</div></span>;
  }

  const template = getTemplateFromDisplayRules(poll.pageRules, poll.pageExcludeRules, true);
  if (template) {
    pollType = <i className={`fas ${template.icon}`} title={template.title} />
  }
  if (poll.settings.apiOnly) {
    pollType = <i className="fas fa-link" title="This survey is only visiable via a link." />
  }
  if (poll.settings.apiOnly && poll.settings.pollType === 'api') {
    pollType = <i className="fas fa-code" title="This survey is only visiable via a link and via API calls." />
  }
  if (isEmail) {
    isEmail = <i className="fas fa-envelope" title="This survey is sent via email." />;      
    pollType = <i className="fas fa-envelope" title="This survey is sent via email." />
    if (isSms) {
      isEmail = <i className="fas fa-sms" title="This survey is sent via SMS." />;      
      pollType = <i className="fas fa-sms" title="This survey is sent via SMS." />
    }
  }

  const visibilitySettings = getVisibilitySettings(account, poll);
  if (visibilitySettings.renderOnExitIntent) {
    pollType = <i className="fas fa-person-walking-dashed-line-arrow-right" title="This survey is triggered via exit intent." />
  }

  let notifications = null;
  // if (poll.hasNotifications) {
    notifications = <span className={`no-cursor has-notifications ${ poll.hasNotifications ? 'active' : '' }`} ><span>{ renderNumber(poll.hasNotifications) }</span><div className="tooltip-content">{ renderNumber(poll.hasNotifications) } new notifications.</div></span>
  // }

  let edit = <span><Link to={`/a/${encode(accountId)}/p/${encode(poll._id)}`}><i title="Edit survey" className="fas fa-edit"/></Link><div className="tooltip-content">Edit survey</div></span>

  let dashboard = <span><Link to={`/dashboard/a/${encode(accountId)}/p/${encode(poll._id)}`}><i title="View survey dashboard" className="fas fa-chart-simple"/></Link><div className="tooltip-content">View dashboard</div></span>

  return { notifications, dashboard, edit, status, pollType };
}

function filterPolls(polls, query) {
  if (!query) {
    return polls;
  }

  const regex = new RegExp(".*" + query + "+", 'i');

  return _.filter(polls, ({ title }) => {
    if (regex.test(title)) { return true; }
    return false;
  });
}

class PollSelector extends Component {
  constructor(props) {
    super(props);
    this.state = { hideHelpBlock: window.localStorage.getItem('zigpoll-hide-help-block'), filter: ['visible', 'hidden', 'archived'] }
  }

  setPollSelectorState(val) {
    if (!val) {
      this.setState({ filter: [ 'visible', 'hidden', 'archived' ] });
    }
    this.setState({ pollSelectorActive: val });
  }

  render() {
    let polls = [];
    this.props.polls.forEach((poll, idx) => {
      if (poll.isArchived) {
        if (this.state.filter.indexOf('archived') !== -1) {
          polls.push(poll);
        }
      } else if (poll.isVisible) {
        if (this.state.filter.indexOf('visible') !== -1) {
          polls.push(poll);
        }
      } else {
        if (this.state.filter.indexOf('hidden') !== -1) {
          polls.push(poll);
        }
      }
    });

    let filteredPolls = filterPolls(polls, this.state.pollFilter);
    
    let activePoll;
    let pollId = this.props.pollId;
    if (!pollId) {
      pollId = window.localStorage.getItem('lastPollId');
    }
    if (pollId) {
      activePoll = _.find(this.props.account.polls, (poll) => poll._id === pollId);
    }
    if (!activePoll) {
      activePoll = this.props.account.polls[0];
    }

    if (!activePoll || !activePoll.settings) { return null; }

    let { notifications, status, dashboard, edit, pollType } = getPollElements(this.props.account, this.props.accountId, activePoll);

    return (
      <div className="poll-selector-container">
        <div className="section-header small no-margin">
          <div className="top">
            <div className="section-subtitle"><i className="fas fa-list" />Analyze your surveys</div>
          </div>
          <div className="section-description">Select a survey from the dropdown below for detailed analysis.</div>
        </div>

        <div className={`${this.state.pollSelectorActive ? 'active' : ''} selected-poll-container`}>
          <div className="selected-poll" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setTimeout(() => {
              $(window).one('click.poll-selector', () => {
                this.setPollSelectorState(false);
              });
            }, 0);

            // if (this.props.polls.length > 6) {
              setTimeout(() => {
                $('#poll-filter input').focus();
              }, 0);
            // }

            this.setPollSelectorState(!this.state.pollSelectorActive);
          }}>
            {/*<div className="poll-icons">{notifications}{status}{dashboard}{edit}</div>*/}
            <div className="poll-title">{activePoll.title}</div>
            <div className="poll-type">{pollType}</div>

            <i className={`fas fa-chevron-${this.state.pollSelectorActive ? 'up' : 'down'}`} />
          </div>
        </div>

        <div className={`poll-selector object-list ${this.state.pollSelectorActive ? 'active' : ''}`}>

          { <div id='poll-filter' className={`poll-filter ${this.state.pollFilter ? 'active' : ''}`} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}>
            <i className="fas fa-search" />
            <input
              type="text"
              value={this.state.pollFilter}
              placeholder="Filter Surveys"
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.setState({ pollFilter: e.target.value });
              }}
            />
            <div className="right">
              <PopoutCheckbox
                value={this.state.filter}
                title={<i className="fas fa-filter" />}
                options={[
                  { label: "Visible", value: "visible" },
                  { label: "Hidden", value: "hidden" },
                  { label: "Archived", value: "archived" },
                ]}
                onClick={(value) => {
                  this.setState({ filter: value })
                }}
              />
              <Link className="create-poll" to={`/a/${encode(this.props.accountId)}/p/create`} ><i className="fas fa-plus" />New Survey</Link>
            </div>
          </div> }

          <div className="poll-scroll-container">
          { filteredPolls.length === 0 ? <div className="empty-object-list search">
            <h3>No surveys found.</h3>
            <p>Try again with a different filter.</p>
          </div> : filteredPolls.map((poll) => {
            if (!poll || !poll.settings) { return null; }

            const { notifications, status, dashboard, edit, pollType } = getPollElements(this.props.account, this.props.accountId, poll);
            return (
              <div key={poll._id} className={`${poll._id === activePoll._id ? 'active' : ''}`} onClick={(e) => { 
                this.setPollSelectorState(false);
                window.localStorage.setItem('lastPollId', poll._id)
                this.props.setPollId(poll._id)} 
              }>
                <div className="poll-icons">{notifications}{status}{dashboard}{edit}</div>
                <div className="poll-title">{poll.title}</div>
                <div className="poll-type">{pollType}</div>
              </div>);
            }
          )}
          </div>
  
          {/*
          <Link className="create-poll" to={`/a/${encode(this.props.accountId)}/p/create`} ><i className="fas fa-plus" />New Survey</Link>
          */}  
        </div>

      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];

  const params = qs.parse(ownProps.location.search);
  const pollId = decode(params.id);

  let polls = account.polls || [];
  polls = _.orderBy(polls, (poll, idx) => {
    return poll.isArchived ? -10 : idx;
  }, 'desc');

  return {
    accounts,
    accountId,
    account,
    polls,
    user: state.user,
    pollId,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...DashboardActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PollSelector));
