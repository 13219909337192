import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Confirm from './Confirm';

import moment from 'moment';

import * as AccountActions from '../actions/Accounts';
import * as FlashNotificationActions from '../actions/FlashNotifications';

import { AuthSubmitButton } from './Buttons'

import { decode } from '../utils';

class LifetimeDealPromotion extends Component {
  render() {
    return (
      <div className="card promo">
        <div className="title">Promotion Applied</div>

        <p>{ this.props.description }</p>
        <p className="exp">This promotion will never expire. If you want to upgrade your plan or have any questions please <a href="mailto:support@zigpoll.com">contact us</a>.</p>
      </div>
    );
  }
}
class Promotion extends Component {
  constructor(props) {
    super(props);
    this.state = { showConfirm: false };
  }

  render() {
    return (
      <div className="card promo">
        <div className="title">Promotion Applied</div>

        <p>{ this.props.description }</p>
        <p className="exp">This promotion expires on <strong>{ moment(this.props.appliedAt).add(1, 'M').format('MM/DD/YYYY') }</strong>.</p>

        <div className="cancel" onClick={() => { this.setState({ showConfirm: true }) }} />

        <Confirm 
          title="Are you sure?"
          subtitle="This will cancel your promotion and set you to the free plan. The promotion cannot be applied again."
          show={this.state.showConfirm}
          onConfirm={() => {
            this.props.cancelPromo();
          }}
          onCancel={() => {
            this.setState({ showConfirm: false });
          }}
        />
      </div>
    );
  }
}
class FreeTrialPromotion extends Component {
  constructor(props) {
    super(props);
    this.state = { showConfirm: false };
  }

  render() {
    let specialPrice = 50;
    if (this.props.account.promo.indexOf('75') !== -1) {
      specialPrice = 25;
    }
    let specialPlan = 'Pro';

    return (
      <div className="card promo">
        <div className="title">Free Trial Applied</div>

        <p>{ this.props.description }</p>
        <p className="exp">Your free trial expires on <strong>{ moment.unix(this.props.account.trialExpiration).format('MM/DD/YYYY') }</strong>. After this date you will be charged a special price of <strong>${specialPrice}</strong> monthly for the <strong>{specialPlan}</strong> plan.</p>

        <div className="cancel" onClick={() => { this.setState({ showConfirm: true }) }} />

        <Confirm 
          title="Are you sure?"
          subtitle="This will cancel your promotion and set you to the free plan. The promotion cannot be applied again to this account."
          show={this.state.showConfirm}
          onConfirm={() => {
            this.props.cancelPromo();
          }}
          onCancel={() => {
            this.setState({ showConfirm: false });
          }}
        />
      </div>
    );
  }
}

class Discount extends Component {
  render() {
    return (
      <div className="card promo">
        <div className="title">Discount Applied</div>
        <p>{ this.props.description }</p>
        <p className="subtle" style={{ fontWeight: 400, marginTop: 10 }}>This discount is automatically applied whenever your plan is changed. If you have already subscribed to a plan at full price, please switch to the free plan then switch back to a plan of your choice in order to apply the discount.</p>
      </div>
    );
  }
}

class PromotionInput extends Component {
  constructor(props) {
    super(props);
    this.state = { showPromo: false, value: '' }
  }

  toggle(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ showPromo: !this.state.showPromo });
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.state.value) {
      this.props.flash('Please enter a promo code');
      return;
    }

    this.props.applyPromoCode(this.state.value);
  }

  onChange(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ value: e.target.value });
  }

  render() {
    if (this.props.account.lifetimeDeal && this.props.account.lifetimeDeal.planKey === this.props.account.plan.key) {
      // return <LifetimeDealPromotion {...this.props.account.lifetimeDeal} />
      return null;
    }

    if (this.props.account.promo && this.props.account.trialExpiration) {
      // return (
      //   <FreeTrialPromotion
      //     account={this.props.account}
      //     user={this.props.user}
      //     {...this.props.account.promo }
      //     cancelPromo={() => {
      //       this.setState({ value: '', showPromo: false });
      //       this.props.cancelPromo()
      //     }} />
      // )
      return null;
    }

    if (this.props.account.promo) {
      // return (
      //   <Promotion {...this.props.account.promo } cancelPromo={() => {
      //     this.setState({ value: '', showPromo: false });
      //     this.props.cancelPromo()
      //   }} />
      // )
      return null;
    }

    const discount = this.props.account.discount || this.props.user.discount;
    if (discount) {
      // return (
      //   <Discount {...discount } />
      // )    
      // return null;  
    }

    let promoForm = null;
    if (this.state.showPromo) {
      promoForm = (
        <div className="card add-promo">
          <form onSubmit={this.onSubmit.bind(this)}>
            <label>Promo Code</label>

            <div>
              <input 
                type="text"
                onChange={this.onChange.bind(this)}
                name="text"
                value={this.state.value}
                placeholder="Promo Code"
              />
              <AuthSubmitButton
                title="Use Code"
                disabled={this.props.accounts.loading}
              />
            </div>
          </form>
        </div>
      );
    }

    let toggle = (<div key="toggle" onClick={this.toggle.bind(this)} className="promo-toggle">Have a promo code? Click Here.</div>);
    if (this.state.showPromo) {
      toggle = (<div key="cancel" onClick={this.toggle.bind(this)} className="cancel">Cancel</div>)
    }

    return (
      <div className="promotions-input">
        { toggle }
        { promoForm }
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];
  const user = state.user;

  return {
    accountId,
    accounts,
    account,
    user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...FlashNotificationActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PromotionInput));
