import React, { Component } from 'react';

import Layout from '../components/Layout';
import UserCreateForm from '../components/UserCreateForm';
import SingleFormPageHeader from '../components/SingleFormPageHeader';
import BelowLogin from '../components/BelowLogin';

import shopifyLogo from '../images/shopifyLogo.png';

class CreateAccount extends Component {
  render() {
    return (
      <Layout title="Sign Up" graphics={true}>
        <SingleFormPageHeader
          title="Knowledge is power."
          subtitle="Create an account to start getting insights."
        />
        <UserCreateForm />
        <BelowLogin type="signup" />
      </Layout>
    );
  }
}

export default CreateAccount;
